<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body">
              <h5 class="card-title" style="margin-left: 10px;">{{ $t('settingspage.title') }}</h5>
              <div class="card-body header-div d-flex" style="height: auto !important;">
                <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
                </div>
                <div class="mt-2 ml-3 invite-helper">{{ $t('settingspage.info') }}:</div>
              </div>
              <div class="settingsform">
                  <h3>Уведомления</h3>
                  <!-- <hr>
                  Push-уведомления
                  <hr>
                  SMS-уведомления
                  <hr>
                  Email уведомления -->
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="profile.settings.emailNotifications">
                    <label class="custom-control-label" for="customSwitch1">Получать Email уведомления</label>
                  </div>
                  <!-- <div class="d-flex justify-content-between mb-2">
                    <span class="ml-3">Email адреса для уведомлений:</span>
                    <span class=""><div @click.prevent="add_diapason"><div class="green-circle"></div></div></span>
                  </div>
                  <div v-for="email in emaillist" :key="email">
                    <div class="d-flex justify-content-between pl-5 mb-2">
                      <span class="ml-3 mr-1">Адрес</span>
                      <input type="text" class="myform" style="font-size: 16px; max-width: 180px; min-width: 60px;" placeholder="start" maxlength="150"/>
                      <div class="red-circle ml-3" @click.prevent="delete_diapason(diapason)"></div>
                    </div>
                  </div> -->
                  <div @click.prevent="updateProfile()" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 76px; text-transform: uppercase;">{{ $t('editthingpage.save-button') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { userService } from '../_services';
  import VueSimpleSpinner from 'vue-simple-spinner';

  export default {
    name: 'SettingsPage',
    components: {
      VueSimpleSpinner,
    },
    data () {
      return {
        preloading: false,
        profile : {},
      }
    },
    async created () {
      this.preloading = true;
      await this.init();
      this.preloading = false;
    },
    methods: {
      async init() {
        console.log('initializing');
        await this.getProfile();
      },
      async getProfile() {
        let res = await userService.getProfile();

        // console.log(res)

        if (res.error) {
          console.error(res.error);

          userService.logout();
          window.location.href = '/login';

          return;
        }

        this.profile = res.result;
      },
      async updateProfile() {
        this.preloading = true;

        let res = await userService.updateProfile(this.profile);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.profile = res.profile;

        this.preloading = false;

        // this.$router.go(0);
      },
    }
  }
</script>
<style>
  .settingsform {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    font-family: "Segoe UI Light";
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 10px;
    padding: 20px;
  }
  .facilityname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .container {
    max-width: 1920px;
    width: 100%;
  }
  @media (max-width: 640px) {
    .invite-helper { font-size: 26px !important; }
    .card-body.header-div { margin: 0px !important; }
    .invitesform { margin: 0px !important; margin-top: 10px !important; font-size: 16px !important; padding: 5px !important; }
  }

  .custom-control-input:focus ~ 
  .custom-control-label::before {
    border-color: #8EDC28 !important;
    box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
  }
  .custom-control-input:checked ~ 
  .custom-control-label::before {
    border-color: #8EDC28 !important;
    background-color: #8EDC28 !important;
  }
  .custom-control-input:active ~ 
  .custom-control-label::before {
    background-color: #8EDC28 !important;
    border-color: #8EDC28 !important;
  }
</style>