<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body">
              <h5 class="card-title" style="margin-left: 10px;">{{ $t('editequipmentpage.title') }}</h5>
              <div class="card-body header-div d-flex" style="height: auto !important;">
                <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="hover"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                </div>
                <div class="mt-2 ml-3 editequip-helper">{{ $t('editequipmentpage.info') }}:</div>
              </div>
              <div class="editform" style="height: auto !important;">
                <div class="row" style="height: auto !important;">
                  <div class="col-sm-3 p-3">
                    <div class="facility-img mt-0"></div>
                  </div>
                  <div class="col-sm-8 p-2" style="border-left: 1px solid black; padding: 10px;">
                    <input type="text" v-model="equipment.name" class="form-control form-edit ml-2" placeholder="Имя оборудования" maxlength="150"/>
                  </div>
                  <div class="col-sm-1 p-2" style="padding: 10px;">
                    <div class="circle confirm" @click.prevent="update_equipment()"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import navbar from './Navbar.vue'
  import {equipmentsService} from "../_services";

  export default {
    name: 'EquipmentEdit',
    components: {
      // navbar,
    },
    data () {
      return {
        facilityId: this.$route.params.facility_id,
        equipmentId: this.$route.params.equipment_id,
        equipment: ''
      }
    },
    created () {
      this.init();
      this.get_equipment(this.facilityId, this.equipmentId);
    },
    methods: {
      init() {
        console.log('initializing');
      },
      async get_equipment(facilityId, equipmentId) {
          let res = await equipmentsService.equipment_get(facilityId, equipmentId);

          if (res.error) {
            console.error(res.error);

            return;
          }

          console.log(res);

          this.equipment = res.result;
      },
      async update_equipment() {
        let res = await equipmentsService.equipment_update(this.facilityId, this.equipmentId, this.equipment.name)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.equipment = res.result;

        this.$router.go(-1);
      }
    }
  }
</script>

<style>
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .hover:hover {
    cursor: pointer;
  }
  @media (max-width: 640px) {
    .editequip-helper { font-size: 26px !important; }
    .card-body.header-div { margin: 0px !important; }
    .editform { margin: 0px !important; margin-top: 10px !important; font-size: 16px !important; padding: 5px !important; }
  }
</style>