<template>
  <div>
    <div class="gradient"></div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row h-100 mt-5">
        <div class="col-sm-12 my-auto">
          <!-- <div class="card card-block w-25 mx-auto">I am Groot.</div> -->
          <!-- <form class="form-signin w-25 mx-auto" @submit.prevent="handleSubmit" oninput='up2.setCustomValidity(up2.value != up.value ? "Пароли не совпадают" : "")'>
            <h1 class="h3 mb-3 font-weight-normal" style="color: #FFFFFF">Введите ваше имя,<br> придумайте, и введите пароль,<br> и нажмите "Войти"</h1>
            <label for="inputEmail" class="sr-only">Email address</label>
            <input type="email" id="inputEmail" class="form-control mb-2" placeholder="Эл. почта" required disabled v-model="email">
            <label for="inputName" class="sr-only">Имя</label>
            <input type="text" id="inputName" class="form-control mb-2" placeholder="Имя" required autofocus v-model="username">

            <label for="password1" class="sr-only">Password</label>
            <input type="password" id="password1" class="form-control mb-2" placeholder="Пароль" required name=up v-model="password">

            <label for="password2" class="sr-only">Password</label>
            <input type="password" id="password2" class="mb-2" placeholder="Подтверждение пароля" name=up2>
            <button class="btn btn-lg btn-secondary btn-block" type="submit">Войти</button>
            <div v-if="error != ''" class="error-div mt-2">{{ error }}</div>
          </form> -->

          <form class="form-signin w-25 mx-auto" style="color: #FFFFFF" @submit.prevent="handleSubmit" oninput='up2.setCustomValidity(up2.value != up.value ? "Пароли не совпадают" : "")'>
            <h1 class="h3 mb-3 font-weight-normal" style="color: #FFFFFF">Введите ваше имя,<br> придумайте, и введите пароль<br> и нажмите "Войти"</h1>
            <div class="form-group row mb-3">
              <label for="inputEmail" class="col-sm-2 col-form-label p-0 pt-2">Эл. почта</label>
              <div class="col-sm-10">
                <input type="email" class="form-control" id="inputEmail" placeholder="Email" required disabled readonly v-model="email" maxlength="150">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="inputLastname" class="col-sm-2 col-form-label p-0 pt-2">Фамилия</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="inputLastname" placeholder="введите фамилию" required autofocus v-model="lastname" maxlength="100">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="inputName" class="col-sm-2 col-form-label p-0 pt-2">Имя</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="inputName" placeholder="введите имя" required autofocus v-model="name" maxlength="100">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="inputMiddlename" class="col-sm-2 col-form-label p-0 pt-2">Отчество</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="inputMiddlename" placeholder="введите отчество" required autofocus v-model="middlename" maxlength="100">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="inputPassword1" class="col-sm-2 col-form-label p-0 pt-2">Пароль</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="inputPassword1" placeholder="введите пароль" required name=up v-model="password" maxlength="50">
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="inputPassword2" class="col-sm-2 col-form-label p-0">Подтверд. пароль</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="inputPassword2" placeholder="подтвердите пароль" name=up2 maxlength="50">
              </div>
            </div>

            <div class="form-group row">
              <button class="btn btn-lg btn-secondary btn-block mybtn" type="submit">Войти</button>
            </div>
          </form>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import navbar from './Navbar.vue'
  import {userService} from "../_services";
  import {router} from "../_helpers";

  export default {
    name: 'RegistrationPage',
    components: {
      // navbar,
    },
    data () {
      return {
        // user: {},
        // profile : {},
        email: '',
        // username: '',
        lastname: '',
        name: '',
        middlename: '',
        password: '',
        submitted: false,
        error: ''
      }
    },
    async created () {
      this.init();
    },
    methods: {
      init() {
        console.log('initializing');
        console.log(this.$route.query.email)

        this.email = this.$route.query.email;
      },
      async checkInvite() {
        // TODO: do some check for invite existing
        console.log('checking invite');
      },
      setUrl(user) {
        console.log(user);

        return '/invites'
      },
      async handleSubmit() {

        // "lastname": lastname,
        // "name": name,
        // "middlename": middlename,
        // "email": email,
        // "password": password

        this.submitted = true;
        const { email, lastname, name, middlename, password } = this;

        if (!(email && lastname && name && middlename && password)) {

          return;
        }

        this.loading = true;

        userService.registration(lastname, name, middlename, email, password)
          .then(
            user => router.push(this.setUrl(user)),
            error => {
              if(error.password) {
                this.error = error.password[0];
              } else if(error.email) {
                this.error = error.email[0];
              } else {
                this.error = error;
              }

              this.loading = false;
            }
          );
      }
    }
  };
</script>

<style>
/*  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../assets/SnowstormLight.woff2');
  }
  @font-face {
    font-family: 'Tahoma Bold';
    src: url('../assets/Tahoma Bold V2.woff2');
  }*/
  /*.form-control {*/
  /*  border-radius: 50px;*/
  /*  height: 46px;*/
  /*}*/

  /*input:focus, textarea:focus, select:focus{*/
  /*  outline: none;*/
  /*}*/
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .mybtn {
    background-color: #8EDC28;
    border-radius: 50px;
    color: black;
  }
.mybtn:hover {
  background-color: #6DBB07;
}
</style>
