<template>
  <div class="mr-5">
    <!-- <a class="dropdown-item" href="#">РУС</a>
    <a class="dropdown-item disabled" href="#">ENG</a> -->
    <select v-model="$i18n.locale" class="lang-select">
      <option
        class="lang-option"
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang">
          {{ setLang(lang) }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SwitchLocale',
  data() {
    return {
      langs: ['en', 'ru']
    }
  },
  methods: {
    setLang(lang) {

      let langs = {
        'en': 'ENG',
        'ru': 'РУС'
      }

      return langs[lang]
    },
  }
}
</script>

<style>
select.lang-select {
    font-size: 22px;
    text-align: center;
    text-align-last: center;
    font-family: "Comfortaa normal";
    letter-spacing: 1.2px;
    color: #FFFFFF;
}
.lang-select {
    padding-top: 5px;
    width: 100px;
    height: 50px;
    /* background: rgba(110, 110, 110, 0.1); */
    /* background: transparent linear-gradient(180deg, #FFFFFF4D 0%, #FFFFFF33 100%) 0% 0% no-repeat padding-box; */
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    text-transform: uppercase;
    text-align-last: center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: rgba(222, 222, 222, 0.2);
    /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIy NCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9 Ik0wIDBoMjR2MjRoLTI0eiIgZmlsbD0ibm9uZSIvPgogICAgPHBhdGggZD0iTTEw LjA5IDE1LjU5bDEuNDEgMS40MSA1LTUtNS01LTEuNDEgMS40MSAyLjU4IDIuNTlo LTkuNjd2Mmg5LjY3bC0yLjU4IDIuNTl6bTguOTEtMTIuNTloLTE0Yy0xLjExIDAt MiAuOS0yIDJ2NGgydi00aDE0djE0aC0xNHYtNGgtMnY0YzAgMS4xLjg5IDIgMiAy aDE0YzEuMSAwIDItLjkgMi0ydi0xNGMwLTEuMS0uOS0yLTItMnoiLz4KPC9zdmc+ Cg=='); */
    /* background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); */
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 10px;
    /* border: 1px solid #dfdfdf; */
    opacity: 0.85;
}
.lang-select:hover {
  opacity: 1;
}
.lang-option {
     background: rgba(22, 22, 22, 0.8);
     border-radius: 10px;
}
</style>
