// import { API_LOCATION } from '../config';
// import { authHeader } from '../_helpers';
import { userService } from '../_services';

export const responseHandler = {
    handleResponse,
    handleFileResponse
};

function handleResponse(response) {
    return response.json().then(json => {
        const data = json;

        if (!response.ok) {
            // console.log(response.statusText);

            if (response.status === 400) {
                // alert('400!!!!!')
                console.log('400');
                // auto logout if 400 response returned from api
                // userService.logout();
                // location.reload(true);
            }

            if (response.status === 401) {
                console.log('------------------- OOOPS, 401, Unauthorized')
                // TODO: Renew session
                userService.logout();
                window.location.href = "/login";
            }

            const error = data.result.message;
            return Promise.reject(error);
        }

        // console.log("handleResponse: ", data);
        return data;
    });
}

function handleFileResponse(response) {

    if (!response.ok) {
        // return response.json().then(data => {
        return response.json().then(() => {
            // const data = json;

            if (response.status === 400) {
                // console.log('User avatar is empty');
            }
    
            // console.log('____________________________________________0');
            // console.log(data);
            // const error = data.result.message;
            // return Promise.reject(error);
        });
    } else {
        // console.log('____________________________________________0');
        
        const contentType = response.headers.get("content-type");

        // console.log(contentType);

        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => {
                // process your JSON data further
                // console.log(data);

                return data;
            });
        } else if (contentType && contentType.indexOf("image/png") !== -1) {
            return response.blob();
        } else if (contentType && contentType.indexOf("image/jpeg") !== -1) {
            return response.blob();            
        } else {
            return response.text().then(text => {
                // this is text, do something with it
                console.log(text);

                return text;
            });
        }

        // return response.blob();
    }
}

// function handleFileResponse(response) {
//     // console.log('handleFileResponse: ' + response)
//     console.log('----------------------------');
    
//     if (!response.ok) {
//         let data = {}; 

//         if (response.status === 400) {
//             console.log('400!!');
//         }
//         if (response.status === 401) {
//             console.log('------------------- OOOPS, 401, Unauthorized')
//             userService.logout();
//             window.location.href = "/login";
//         }
        
//         response.json().then(json => {
//             data = json;
//             console.log('---------------------------- json.result.message');
//             console.log(json);
//             // const error = data.result.message;
//             // return Promise.reject(error);
//         })

//         const error = data.result.message;
//         return Promise.reject(error);
//     }

//     return response.blob();
// }
