<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <div class="row">
        <div class="col-sm-6">
            <div class="card card-main transparent">
                <div class="card-body">
                    <div class="header-div m-0 desktop-header">
                        <div class="header-upper">
                            <div class="d-flex bd-highlight">
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a>
                                </div>
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <div v-if="thing.shadow.online">
                                        <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                                    </div>
                                    <div v-else>
                                        <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                                    </div>
                                </div>

                                <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
                                    {{ facility.name }}
                                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                                    <span v-else>
                                        <span v-if="thing.type.id == 'BZP01'">, БЗП-01 <span class="serial-small">(№ {{ thing.number }})</span></span>
                                        <span v-else>
                                            , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                                        </span>
                                    </span>
                                </span>

                                <div class="p-2 bd-highlight">
                                    <div class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; get_event_logs(); get_system_logs();" v-bind:class="{ active: active_button == 'current-parameters' }"></div>
                                </div>

                            </div>
                        </div>
                        <div class="header-body" style="padding: 0px;">
                            <div class="row">
                                <div class="col-6 d-flex align-items-center">
                                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="color: #ff0000"><span class="nowrap-span">⚠ Modbus устройство оффлайн</span></li>
                                        <li style="font-size: 14px;">Последнее обновление:</li>
                                        <li>{{ date }} {{ time }}</li>
                                    </ul>
                                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="font-size: 14px;">Обновлено:</li>
                                        <li>{{ date }}</li>
                                        <li>{{ time }}</li>
                                    </ul>
                                </div>
                                <div class="col-6 d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                                    <ul style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2 mb-2">
                                        <li style="font-size: 14px;">Интерфейс:</li>
                                        <li>Порт: {{ thing.shadow.interface.port }}</li>
                                        <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

<!-- MOBILE ********************************************************************** -->
                    <div class="header-div m-0 mobile-header" style="height: auto !important;">
                      <div class="header-upper pb-3">
                        <div class="d-flex bd-highlight">
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
                          </div>
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <div v-if="thing.shadow.online">
                              <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                            </div>
                            <div v-else>
                              <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                            </div>

                          </div>
                          <span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
                            {{ facility.name }}
                            <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                            <span v-else>
                            <span v-if="thing.type.id == 'BZP01'">, БЗП-01 <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
                            <span v-else>
                              , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                            </span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px;">
                        <div class="d-flex">
                          <div class="d-flex align-items-center">
                            <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="color: #ff0000"><span class="nowrap-span">⚠ БЗП-01 оффлайн</span></li>
                            <li style="font-size: 14px;">Последнее обновление:</li>
                            <li>{{ date }} {{ time }}</li>
                            </ul>
                            <ul v-else style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="font-size: 14px;">Обновлено:</li>
                            <li>{{ date }}</li>
                            <li>{{ time }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px; border-top: 1px solid black;">
                        <div class="d-flex">
                          <ul style="list-style: none; padding-left: 10px; margin-bottom: 0px;" class="mt-2 mb-2">
                              <li style="font-size: 14px;">Интерфейс:</li>
                              <li>Порт: {{ thing.shadow.interface.port }}</li>
                              <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                          </ul>
                        </div>
                      </div>

                    </div>
<!-- MOBILE ********************************************************************** -->

                    <div class="row mt-3">
                        <div class="overflow-auto detail-overflow">
                            <div class="card card-second transparent mt-0">
                                <div class="card-body">
                                    <div class="detailed-div">
                                        <vue-json-pretty
                                                :path="'res'"
                                                :data=thing.shadow.registers
                                                :showDoubleQuotes="false"
                                                :deep=2
                                                @click="handleClick">
                                        </vue-json-pretty>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="col-sm-6">
        <div class="card card-main transparent">
          <div class="card-body">
            <div v-if="active_button == 'current-parameters'">
              <div class="parameters-div">
                <div class="row">
                  <div class="col-6">
                    <div class="d-flex justify-content-between">
                      <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.eventjournal') }}</span>
                      <div class="circle-35 files journal-download" v-on:click="event_journal_datepicker_show = !event_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
                      <div v-if="event_journal_datepicker_show" class="journal-datepicker-div">
                        <div class="d-flex flex-column">
                          <span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать журнал событий</span>
                          <date-picker v-model="event_range" :model-config="modelConfig" is-range color="green" is-dark/>
                          <div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('event_log')">Скачать</div></div>
                        </div>
                      </div>
                    </div>
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!event_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                      <div v-for="record in event_logs" :key="record.id">
                        <div class="row">
                          <div class="col-5 p-0">
                            <span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
                          </div>
                          <div class="col-7">
                            <p v-for="elem in getEventDescription(record.data)" :key="elem" style="font-size: 14px;">{{ elem }}</p>
                          </div>
                        </div>
                        <hr class="mt-0 mb-0 mr-2" style="background: #333;">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="d-flex justify-content-between">
                      <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.messagejournal') }}</span>
                      <div class="circle-35 files journal-download" v-on:click="message_journal_datepicker_show = !message_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
                      <div v-if="message_journal_datepicker_show" class="journal-datepicker-div">
                        <div class="d-flex flex-column">
                          <span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать системный журнал</span>
                          <date-picker v-model="message_range" :model-config="modelConfig" is-range color="green" is-dark/>
                          <div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('message_log')">Скачать</div></div>
                        </div>
                      </div>
                    </div>
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!message_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                      <div v-for="record in message_logs" :key="record.id">
                        <div class="row">
                          <div class="col-5 p-0">
                            <span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
                          </div>
                          <div class="col-7">
                            <span style="font-size: 14px;">{{ getMessageDescription(record.data.event_code) }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueSimpleSpinner from 'vue-simple-spinner';
  import {facilitiesService, thingsService, journalsService, filesService} from "../../_services";
  import VueJsonPretty from 'vue-json-pretty';
  import moment from 'moment';
  import download from 'downloadjs';


  moment.locale('ru');

  export default {
    // name: 'Lyutik',
    name: 'BZP01',
    props: ['facilityId', 'thingId'],
    components: {
      VueSimpleSpinner,
      VueJsonPretty,
    },
    data () {
      return {
        // facilityId: this.$route.params.facility_id,
        // thingId: this.$route.params.thing_id,
        preloading: false,
        thing: '',
        facility: '',
        date: "",
        time: "",
        // details: '',
        event_logs: [],
        message_logs: [],
        active_button: 'current-parameters',
        event_range: {
            start: new Date(new Date().setHours(0,0,0,0)),
            end: new Date(new Date().setHours(23,59,59,0)),
        },
        message_range: {
            start: new Date(new Date().setHours(0,0,0,0)),
            end: new Date(new Date().setHours(23,59,59,0)),
        },
        modelConfig: {
            start: {
                timeAdjust: '00:00:00',
            },
            end: {
                timeAdjust: '23:59:59',
            },
        },
        event_journal_datepicker_show: false,
        message_journal_datepicker_show: false,
      }
    },
    async created() {
      this.preloading = true;
      await this.init();
      this.preloading = false;
      // this.getNow();

      setInterval(function () {
        this.warning_list = [];
        this.critical_list = [];
        this.getThing();
        this.getFacility();
        this.getNow();
      }.bind(this), 15000);
    },
    methods: {
      async init() {
        console.log('Rendering "BZP01" component');
        await this.getThing();
        await this.getFacility();
        this.getNow();
        await this.get_event_logs();
        await this.get_system_logs();
      },
      async getThing() {
        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        // console.log(this.thing);
      },
      getNow: function() {
        let today = new Date(this.thing.updated + 'Z');
        this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      },
      async getFacility() {
        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facility = res.result.facility;
      },
      handleClick() {
        // alert('handleClick')
      },
      async get_event_logs() {
        let args = {
          types: 'event_log',
          start: 0,
          limit: 50,
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.event_logs = res.result.items;
      },
      async get_system_logs() {
        let args = {
          types: 'message_log',
          start: 0,
          limit: 50,
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.message_logs = res.result.items;
      },
      formatDate(value) {
        if (value) {
          // subtract 60 * 60 * 3 (3 hours)
          return moment((value-10800) * 1000).format("L LTS")
        }

        return value;
      },
      getEventDescription(data) {
        let answers_0 = []

        for (var i = 0; i < data.status_register_0.length; i++) {

          if (data.status_register_0[i] == 1) {
            answers_0.push(i);
          }
        }


        let status_register_0_answers = answers_0.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return ''
          }
          if (id == 1 + i) {
            return 'Вход тригера "Неиспр 1"'
          }
          if (id == 2 + i) {
            return 'Вход тригера "Неиспр 2"'
          }
          if (id == 3 + i) {
            return 'Раб. АПВ 1'
          }
          if (id == 4 + i) {
            return 'Раб. АПВ 2'
          }
          if (id == 5 + i) {
            return 'АПВ пуск'
          }
          if (id == 6 + i) {
            return 'АПВ успешное'
          }
          if (id == 7 + i) {
            return 'Подготовка АПВ'
          }
          if (id == 8 + i) {
            return 'Готов АВР'
          }
          if (id == 9 + i) {
            return 'Пуск АВР'
          }
          if (id == 10 + i) {
            return 'ВКЛ по ВНР'
          }
          if (id == 11 + i) {
            return 'ОТКЛ по АВР'
          }
          if (id == 12 + i) {
            return 'Неусп. АВР'
          }
          if (id == 13 + i) {
            return 'ВКЛ СВ по АВР'
          }
          if (id == 14 + i) {
            return 'ОТКЛ СВ по ВНР'
          }
          if (id == 15 + i) {
            return 'ОТКЛ ВВ по АВР'
          }

          return id;
        });

        let answers_1 = []

        for (var i1 = 0; i1 < data.status_register_1.length; i1++) {

          if (data.status_register_1[i1] == 1) {
            answers_1.push(i1);
          }
        }

        let status_register_1_answers = answers_1.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return 'ОТКЛ от защит'
          }
          if (id == 1 + i) {
            return 'ОТКЛ от защит 1'
          }
          if (id == 2 + i) {
            return 'ОТКЛ от защит 2'
          }
          if (id == 3 + i) {
            return 'ЗМН-1'
          }
          if (id == 4 + i) {
            return 'ЗМН-2'
          }
          if (id == 5 + i) {
            return 'ЗМН-3'
          }
          if (id == 6 + i) {
            return 'Внеш. ВКЛ'
          }
          if (id == 7 + i) {
            return 'ВКЛ по ЧАПВ'
          }
          if (id == 8 + i) {
            return '-'
          }
          if (id == 9 + i) {
            return 'Переход на "Уставки 2"'
          }
          if (id == 10 + i) {
            return 'ЗЗ-2'
          }
          if (id == 11 + i) {
            return 'ЗЗ-2 cигнал'
          }
          if (id == 12 + i) {
            return 'ВКЛ ТУ'
          }
          if (id == 13 + i) {
            return 'ОТКЛ ТУ'
          }
          if (id == 14 + i) {
            return 'СБРОС ТУ'
          }
          if (id == 15 + i) {
            return 'СБРОС ПУ'
          }

          return id;
        });

        let answers_2 = []

        for (var i2 = 0; i2 < data.status_register_2.length; i2++) {

          if (data.status_register_2[i2] == 1) {
            answers_2.push(i2);
          }
        }

        let status_register_2_answers = answers_2.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return 'ВКЛ по АПВ'
          }
          if (id == 1 + i) {
            return 'Неусп. АПВ'
          }
          if (id == 2 + i) {
            return 'УРОВ'
          }
          if (id == 3 + i) {
            return 'Пуск УРОВ'
          }
          if (id == 4 + i) {
            return 'Пуск по I'
          }
          if (id == 5 + i) {
            return 'Неиспр. ШП'
          }
          if (id == 6 + i) {
            return 'Несоотв. цепей упр.'
          }
          if (id == 7 + i) {
            return 'Отказ ВВ'
          }
          if (id == 8 + i) {
            return 'Защита ЭМ'
          }
          if (id == 9 + i) {
            return 'Самопр. ОТКЛ'
          }
          if (id == 10 + i) {
            return 'Блок. от защит'
          }
          if (id == 11 + i) {
            return 'Откл СШ от УРОВ'
          }
          if (id == 12 + i) {
            return 'Неусп. АПВ 1'
          }
          if (id == 13 + i) {
            return 'Неусп. АПВ 2'
          }
          if (id == 14 + i) {
            return 'Неиспр. 3'
          }
          if (id == 15 + i) {
            return 'ЛЗШ'
          }

          return id;
        });

        let answers_3 = []

        for (var i3 = 0; i3 < data.status_register_3.length; i3++) {

          if (data.status_register_3[i3] == 1) {
            answers_3.push(i3);
          }
        }

        let status_register_3_answers = answers_3.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return 'ВКЛЮЧЕНО'
          }
          if (id == 1 + i) {
            return 'ОТКЛЮЧЕНО'
          }
          if (id == 2 + i) {
            return '-'
          }
          if (id == 3 + i) {
            return '-'
          }
          if (id == 4 + i) {
            return 'РВ'
          }
          if (id == 5 + i) {
            return 'РО'
          }
          if (id == 6 + i) {
            return 'Неисправность БЗП'
          }
          if (id == 7 + i) {
            return 'ЗМЧ-2'
          }
          if (id == 8 + i) {
            return 'Блокировка'
          }
          if (id == 9 + i) {
            return 'Квитирование'
          }
          if (id == 10 + i) {
            return 'Неиспр.'
          }
          if (id == 11 + i) {
            return 'Авария'
          }
          if (id == 12 + i) {
            return 'Готовность'
          }
          if (id == 13 + i) {
            return 'Вызов'
          }
          if (id == 14 + i) {
            return 'РПВ'
          }
          if (id == 15 + i) {
            return 'РПО'
          }

          return id;
        });

        let answers_4 = []

        for (var i4 = 0; i4 < data.status_register_4.length; i4++) {

          if (data.status_register_4[i4] == 1) {
            answers_4.push(i4);
          }
        }

        let status_register_4_answers = answers_4.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return 'ЗМЧ-1'
          }
          if (id == 1 + i) {
            return 'ЗМН'
          }
          if (id == 2 + i) {
            return 'ЗПН'
          }
          if (id == 3 + i) {
            return 'Ав. ТН сигн'
          }
          if (id == 4 + i) {
            return 'ЗПН блок'
          }
          if (id == 5 + i) {
            return 'Uсш'
          }
          if (id == 6 + i) {
            return 'ВМБ'
          }
          if (id == 7 + i) {
            return '1 сигн'
          }
          if (id == 8 + i) {
            return '2 сигн'
          }
          if (id == 9 + i) {
            return '3 сигн'
          }
          if (id == 10 + i) {
            return 'ЗДЗ сигн'
          }
          if (id == 11 + i) {
            return '1 откл'
          }
          if (id == 12 + i) {
            return '2 откл'
          }
          if (id == 13 + i) {
            return '3 откл'
          }
          if (id == 14 + i) {
            return 'ЗДЗ откл'
          }
          if (id == 15 + i) {
            return 'Нагрузка'
          }

          return id;
        });

        let answers_5 = []

        for (var i5 = 0; i5 < data.status_register_5.length; i5++) {

          if (data.status_register_5[i5] == 1) {
            answers_5.push(i5);
          }
        }

        let status_register_5_answers = answers_5.map(function(id) {

          let i = 0;

          if (id == 0 + i) {
            return 'МТЗ 1'
          }
          if (id == 1 + i) {
            return 'МТЗ 2'
          }
          if (id == 2 + i) {
            return 'МТЗ 3'
          }
          if (id == 3 + i) {
            return 'УМТЗ'
          }
          if (id == 4 + i) {
            return 'МТЗ 3 сигн'
          }
          if (id == 5 + i) {
            return 'Тяжелый пуск'
          }
          if (id == 6 + i) {
            return 'Запрет пуска'
          }
          if (id == 7 + i) {
            return 'Пуск МТЗ'
          }
          if (id == 8 + i) {
            return 'ЗМТ'
          }
          if (id == 9 + i) {
            return 'ЗМТ cигнал'
          }
          if (id == 10 + i) {
            return 'ЗНФ'
          }
          if (id == 11 + i) {
            return 'ЗНФ cигнал'
          }
          if (id == 12 + i) {
            return 'ЗПТ'
          }
          if (id == 13 + i) {
            return 'ЗПТ cигнал'
          }
          if (id == 14 + i) {
            return 'ЗЗ-1'
          }
          if (id == 15 + i) {
            return 'ЗЗ-1 cигнал'
          }

          return id;
        });

        let full_answers = status_register_0_answers.concat(status_register_1_answers, status_register_2_answers, status_register_3_answers, status_register_4_answers, status_register_5_answers);

        return full_answers
      },
      // getEventDescription(events) {

      //   let answers = []

      //   for (var i = 0; i < events.length; i++) {

      //     if (events[i] == 1) {
      //       answers.push(i);
      //     }

      //   }

      //   let full_answers = answers.map(function(id) {

      //     let i = 0;

      //     if (id == 1 + i) {
      //       return 'Питание снято с блока БЗП'
      //     }

      //     return id;
      //   });

      //   return full_answers;
      // },
      uintToString(hex) {
        try {
          hex = hex.replace( /../g , hex2=>('%'+hex2));

          var string = decodeURIComponent(hex);

          return string;
        } catch(e) {
          console.log(e);

          return 'Cant decode';
        }
      },
      getMessageDescription(event_code) {
        let i = 0;

        if (event_code == 1 + i) {
          return 'Питание снято с блока БЗП'
        }
        if (event_code == 2 + i) {
          return 'Питание подано на блок БЗП'
        }
        if (event_code == 3 + i) {
          return 'Часы откорректированы'
        }
        if (event_code == 4 + i) {
          return 'Часы скорректированы после сбоя'
        }
        if (event_code == 5 + i) {
          return 'Изменение даты и времени'
        }
        if (event_code == 6 + i) {
          return 'Переход в режим тестирования'
        }
        if (event_code == 7 + i) {
          return 'Возврат из режима тестирования'
        }
        if (event_code == 8 + i) {
          return ''
        }
        if (event_code == 9 + i) {
          return 'Очистка счетчиков моточасов'
        }
        if (event_code == 10 + i) {
          return 'Очистка счетчиков моточасов'
        }
        if (event_code == 11 + i) {
          return 'Очистка счётчиков энергии'
        }
        if (event_code == 12 + i) {
          return 'Очистка NAND Flash'
        }
        if (event_code == 13 + i) {
          return 'ВКЛ ВВ местное'
        }
        if (event_code == 14 + i) {
          return 'ОТКЛ ВВ местное'
        }
        if (event_code == 15 + i) {
          return 'ВКЛ ВВ через ТУ'
        }
        if (event_code == 16 + i) {
          return 'ОТКЛ ВВ через ТУ'
        }
        if (event_code == 17 + i) {
          return 'Квитирование через ПУ'
        }
        if (event_code == 18 + i) {
          return 'Квитирование через ТУ'
        }
        if (event_code == 19 + i) {
          return 'Очистка общих счетчиков ВВ'
        }
        if (event_code == 20 + i) {
          return 'Очистка счетчиков срабатывания защит'
        }
        if (event_code == 21 + i) {
          return 'ВКЛ ВВ по АПВ'
        }
        if (event_code == 22 + i) {
          return 'Программирование заводских настроек'
        }
        if (event_code == 23 + i) {
          return 'Квитирование местное'
        }
        if (event_code == 24 + i) {
          return 'Программирование уставок для режима теста (не используется)'
        }
        if (event_code == 25 + i) {
          return 'Программирование заводских коэффициентов приведения'
        }
        if (event_code == 26 + i) {
          return 'Восстановление заводских коэффициентов приведения'
        }
        if (event_code == 27 + i) {
          return 'Автоматический переход на зимнее время'
        }
        if (event_code == 28 + i) {
          return 'Автоматический переход на летнее время'
        }
        if (event_code == 29 + i) {
          return 'ОТКЛ ВВ по АВР'
        }
        if (event_code == 30 + i) {
          return 'ВКЛ ВВ по АВР'
        }
        if (event_code == 31 + i) {
          return 'ОТКЛ CВ по АВР'
        }
        if (event_code == 32 + i) {
          return 'ВКЛ CВ по АВР'
        }
        if (event_code == 33 + i) {
          return 'Восстановление заводской конфигурации входов и выходов ОТ'
        }
        if (event_code == 34 + i) {
          return 'Восстановление заводской конфигурации входов и выходов ВВ'
        }
        if (event_code == 35 + i) {
          return 'Восстановление заводской конфигурации входов и выходов СВ'
        }
        if (event_code == 36 + i) {
          return 'Восстановление заводской конфигурации входов и выходов ТН'
        }
        if (event_code == 37 + i) {
          return 'Программирование заводских уставок ОТ'
        }
        if (event_code == 38 + i) {
          return 'Программирование заводских уставок ВВ'
        }
        if (event_code == 39 + i) {
          return 'Программирование заводских уставок СВ'
        }
        if (event_code == 40 + i) {
          return 'Программирование заводских уставок ТН'
        }
        if (event_code == 41 + i) {
          return 'Автосброс ошибки CRC5 при старте.'
        }
        if (event_code == 42 + i) {
          return 'Сброс ошибки CRC5 по команде.'
        }
        if (event_code == 43 + i) {
          return 'Останов часов RTC'
        }
        if (event_code == 44 + i) {
          return 'Запуск часов RTC'
        }
        if (event_code == 48 + i) {
          return 'Рестарт по питанию'
        }
        if (event_code == 49 + i) {
          return 'Рестарт по внешнему сигналу'
        }
        if (event_code == 50 + i) {
          return 'Рестарт по аппаратному сторожевому таймеру'
        }
        if (event_code == 51 + i) {
          return 'Рестарт по программному сторожевому таймеру'
        }
        if (event_code == 52 + i) {
          return 'Рестарт по запросу микропрограммы'
        }
        if (event_code == 53 + i) {
          return ''
        }
        if (event_code == 54 + i) {
          return 'Авто-отключение режима калибровки часов'
        }
        if (event_code == 55 + i) {
          return 'Блочная запись настроек'
        }

        return event_code
      },
      // getMessageDescription(message) {
      //   let utf8 = this.uintToString(message);

      //   return utf8
      // },
      _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }

        return bytes.buffer;
      },
      async downloadFile(file) {
        let res = await filesService.file_get(file.id);

        if (res.error) {
          console.error(res.error);
        }

        let item = res.result;

        var mybytes = this._base64ToArrayBuffer(item.base64);

        // download(mybytes, item.filename, "application/octet-stream");
        download(mybytes, this.set_filename(item.timestamp), "application/octet-stream");
      },
      set_filename(timestamp) {
        // 20210916_122053.cff
        // console.log(timestamp);

        let date = new Date(parseInt(timestamp));
        let mydate = (date.getFullYear().toString()
                      + ('0' + (date.getMonth() + 1)).slice(-2)
                      + ('0' + date.getDate()).slice(-2)
                      + '_'
                      + ('0' + date.getHours()).slice(-2)
                      + ('0' + date.getMinutes()).slice(-2)
                      + ('0' + date.getSeconds()).slice(-2))

        let filename = mydate + '.cff';
        return filename;
      },
      async download_log(type) {
        let start_time = 0;
        let end_time = 0;

        if (type == 'event_log') {
          start_time = this.event_range.start.getTime() / 1000;
          end_time = this.event_range.end.getTime() / 1000;
        } else if (type == 'message_log') {
          start_time = this.message_range.start.getTime() / 1000;
          end_time = this.message_range.end.getTime() / 1000;
        }

        let args = {
          types: type,
          timestampGte: start_time,
          timestampLte: end_time,
          // start:,
          // limit:,
          // limit: 1000,
          limit: 10000
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        let temp_log = res.result.items;

        if (temp_log.length > 0){
          if (type == 'event_log') {
            await this.csvExportEvent(temp_log, res.result.filters);
          } else if ( type == 'message_log') {
            await this.csvExportMessage(temp_log, res.result.filters);
          }
        } else {
          alert('Журнал за указанный период пуст!');
        }
      },
      async csvExportEvent(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getEventDescription(item.data) + ";"

            return data_value
          })  // Object.values(item.data).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte * 1000).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte * 1000).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "BZP-01_" + this.thing.number + "_Журнал_событий_" + start_end_date + ".csv");
        link.click();
      },
      async csvExportMessage(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getMessageDescription(item.data.event_code) + ";"

            return data_value
          }) // Object.values(item.data).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte * 1000).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte * 1000).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "BZP-01_" + this.thing.number + "_Системный_журнал_" + start_end_date + ".csv");

        link.click();
      },
      toDate(updated_date) {
        let today = new Date(updated_date + 'Z');
        let date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        let time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);

        return time + " | " + date
      },
      getTitles(data) {

        let titles = {
          // 'events': 'События',
          'timestamp': 'Время',
          // 'MsgID': 'События',
          // 'status_register_0': 'События',
          // 'status_register_1': 'События',
          // 'status_register_2': 'События',
          // 'status_register_3': 'События',
          // 'status_register_4': 'События',
          'status_register_5': 'События',
          'event_code': 'События',
        }

        let answ = ''

        for (let key in data) {
          if (titles[key]) {
            // answ += titles[key] + ';'
            // reversing
            answ = titles[key] + ';' + answ
          } else {
            // answ += key + ';'
          }
        }

        return answ
      },
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../../assets/SnowstormLight.woff2');
  }
  .card {
    margin-top: 20px;
  }
  .card-second {
  }
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  .subject-div1 {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 90px;
    /* width: 180px; */
    margin: 12px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 10px;*/
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    position: relative;
    z-index: 99;
  }
  .subject-div1:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .header-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /* height: 165px; */
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .header-upper {
    border-bottom: 1px solid black;
    padding: 10px
  }
  .back-img {
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 5px;
  }
  .back-img img{
    max-width:100%;
    height:auto;
  }
  .back-img-battery img{
    width:30px;
    height:auto;
  }
  .header-body {
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 20px;*/
    padding: 0px;
  }
  .subject-logo {
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subject-info {
    flex-grow: 7;
    font-size: 16px;
    padding: 0px;
    padding-top: 15px;
  }
  .my-bar {
    min-width: 650px;
    min-height: 340px;
    max-width: 950px;
    max-height: 820px;
    background-color: #202020;
    border-radius: 10px;
  }
  .bar-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-right: 40px;
    height: 340px;
  }
  .circle-35 {
    background: #8EDC28;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .circle-35.sort-linear {
    background: url(../../assets/sort_linear.svg) no-repeat center center #000;
  }
  .circle-35.sort-linear.active {
    background: url(../../assets/sort_linear_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-bars {
    background: url(../../assets/sort_bars.svg) no-repeat center center #000;
  }
  .circle-35.sort-bars.active {
    background: url(../../assets/sort_bars_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-cols {
    background: url(../../assets/sort_cols.svg) no-repeat center center #000;
  }
  .circle-35.sort-cols.active {
    background: url(../../assets/sort_cols_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.sort-linear {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-bars {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-cols {
    opacity: 0.5;
    cursor: pointer;
  }
  .background-text {
    z-index: 1;
  }
  .col-2 {
    display: flex;
    flex-direction: row;
  }
  .small-info {
    position: relative;
    font-size: 14px;
    font-family: "Segoe UI Light";
    letter-spacing: 0px;
    /*color: #FFFFFF;*/
    opacity: 1;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    display: block;
  }
  @media screen and (max-width: 1366px) {
    .small-info {
      width: 100px;
    }
    .col-md-3 {
      padding: 0px;
    }
  }
  .subject-info-values {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circle-50 {
    background: #000000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
  }
  .circle-50:hover {
    background-color: rgba(142,220,40,0.1);
  }
  .circle-50.active {
    background: #8EDC28;
  }
  .nowrap-span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .interact-img:hover img {
    opacity: 0.8;
    transform: scale(1.05);
  }
  .mobile-header {
    display: none;
  }
  @media (max-width: 640px) {
    .battery-info-div { right: 0px !important;}
    .date-picker {
      top: 18%;
      /*position: absolute;*/
      float: right;
      right: 0px;
      /*top: 50%;*/
      width: 50px;
      height: 166px;
      border-radius: 50px;
      background-color: #000000;
      color: #FFFFFF;
    }
    .desktop-header {
      display: none;
    }
    .mobile-header {
      display: block;
    }
    .header-div {
      height: 220px !important;
      margin: 10px;
      font-family: "Segoe UI Light";
      font-size: 30px;
      color: #FFFFFF;
    }
    .small-info {
      font-size: 12px !important;
    }
  }
  .detailed-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /*height: 170px;*/
    /* width: 180px; */
    /* margin: 10px; */
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 0px !important;
    /*padding: 10px;*/
    /* margin-bottom: 30px; */
    /* padding: 10px; */
  }
  .detail-overflow {
    position: relative;
    max-height: 560px;
    min-width: 500px;
  }
</style>
