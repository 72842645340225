<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body pb-0">
              <h5 class="card-title" style="margin-left: 10px;">{{ $t('homepage.title') }}</h5>
              <div class="d-flex bd-highlight">

                <div class="p-2 flex-grow-1 bd-highlight search-form">
                  <img alt="focus" src="../assets/surface.svg" @click.prevent="search" class="search-icon">
                  <input type="text" name="search" v-model="searchQuery" id="search" class="form-control" maxlength="40"/>
                </div>
                <div class="p-2 bd-highlight"><div class="circle sort" @click.prevent="sort"></div></div>
                <div class="p-2 bd-highlight"><div v-if="editableSubjectDiv==false" class="circle edit" @click.prevent="edit"></div><div v-else class="circle confirm" @click.prevent="edit"></div></div>
                <div class="p-2 bd-highlight"><div class="circle add" @click.prevent="add"></div></div>
              </div>

              <div class="overflow-auto subjects-div scroller">
                <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
                <div v-else>
                <div v-if="facilitylist.length == 0">
                  <div class="subject-div row mt-3" style="background-color: #2E2E2E">
                    <label style="padding: 10px; font-size: 18px; text-align: center; vertical-align: middle;">
                      <!-- У вас нет объектов мониторинга. Вы можете добавить свой объект, либо поросить кого-либо пригласить вас по адресу электронной почты. -->
                      {{ $t('homepage.empty') }} <a href="#" @click="$router.push({ name: 'invites'})" style="color: #8EDC28; font-weight:bold">{{ $t('homepage.empty_link') }}</a>
                    </label>
                  </div>
                </div>
                <div v-else>
                    <div v-for="facility in facilitylist" :key="facility.id" class="m-2 mb-3"> <!--  @click="gotoFacility(facility.facility.id)" -->
                      <!-- <a class="div-link" :href="'/batteries/' + facility.facility.id"> -->
                      <!-- <a class="div-link" :href="'/facility/' + facility.facility.id + '/things'"> -->
                      <!-- <a class="div-link" @click="$router.push({ name: 'things', params: { id: facility.facility.id }})"> -->
                        <div v-if="editableSubjectDiv==false">
                          <a class="div-link" @click="$router.push({ name: 'things', params: { id: facility.facility.id }})">
                            <div class="object-div d-flex bd-highlight">
                              <img alt="repey_online" src="../assets/green_circle.svg" class="mr-2 p-2 bd-highlight mt-2 mb-2">
                              <span v-html="matchName(facility.facility.name)" class="wrap-span p-2 bd-highlight mt-2 mb-2"></span>
                            </div>
                          </a>
                        </div>
                        <div v-else>

                          <div class="object-div d-flex bd-highlight">
                            <img alt="repey_online" src="../assets/green_circle.svg" class="mr-2 p-2 bd-highlight mt-2 mb-2">
                            <span v-html="matchName(facility.facility.name)" class="wrap-span p-2 bd-highlight mt-2 mb-2"></span>
                            <div v-if="facility.role.id == 'Owner' || facility.role.id == 'Admin'" style="border-left: 1px solid black; padding-left: 60px;" class="mr-2 bd-highlight ml-auto d-flex align-items-center justify-content-end">
                              <div class="circle delete m-2 ml-5" @click.prevent="deleteFacility(facility.facility.id)"></div>
                              <!-- <div class="circle edit m-2" @click.prevent="editFacility(facility.facility.id)"></div> -->
                              <div class="circle edit m-2" @click="$router.push({ name: 'edit_facility', params: { id:facility.facility.id }})"></div>
                            </div>
                            <div v-else style="border-left: 1px solid black; padding-left: 60px;" class="mr-2 bd-highlight ml-auto d-flex align-items-center justify-content-end">
                              <div class="m-2" style="width: 146px"></div>
                            </div>
                          </div>
                        </div>
                       <!-- </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card card-second transparent mt-0">
            <div class="card-body" v-if="profile.organization">
              <h5 class="card-title">Организация</h5>
              <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
              <div v-else class="card card-info border-0">
                <div class="card-header">
                  <div class="d-flex bd-highlight">
                    <div v-if="editableOrganizationDiv==false" class="p-2 w-100 bd-highlight ml-2 mb-1"><span v-if="profile.organization.name">{{ profile.organization.name }}</span><span v-else>Название организации</span></div>
                    <div v-else class="p-2 w-100 bd-highlight ml-2"><input v-model="profile.organization.name" class="form-transparent w-100" placeholder="Название организации"></div>
                    <!-- <div class="p-2 bd-highlight">
                      <div v-if="editableOrganizationDiv==false" class="circle-30 edit" @click.prevent="edit_organization">
                        <div class="circle-line">
                        </div>
                      </div>
                      <div v-else class="circle-30 confirm" @click.prevent="update_organization">
                        <div class="circle-line">
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="d-flex mb-4">
                    <div v-if="editableOrganizationDiv==false" class="inn mr-3"><span class="ml-3">ИНН <span v-if="profile.organization.inn">{{ profile.organization.inn }}</span><span v-else>123 456 78 90</span></span></div>
                    <div v-else class="inn mr-3"><span class="ml-3">ИНН</span><input v-model="profile.organization.inn" class="form-transparent ml-2" style="width: 135px;"></div>
                    <div v-if="editableOrganizationDiv==false" class="address"><img alt="pin" src="../assets/pin.svg" class="ml-4 mr-2"><span v-if="profile.organization.address">{{ profile.organization.address }}</span><span v-else>Адрес</span></div>
                    <div v-else class="address"><img alt="pin" src="../assets/pin.svg" class="ml-4 mr-2"><input v-model="profile.organization.address" class="form-transparent" style="width: 320px;" placeholder="Адрес"></div>
                  </div>
                  <div class="d-flex mb-4">
                    <div v-if="editableOrganizationDiv==false" class="phone mr-4"><img alt="pin" src="../assets/phone.svg" class="ml-3 mr-2"><span v-if="profile.organization.phone">{{ profile.organization.phone }}</span><span v-else>Телефон</span></div>
                    <div v-else class="phone mr-3"><img alt="pin" src="../assets/phone.svg" class="ml-3 mr-2"><input v-model="profile.organization.phone" class="form-transparent" style="width: 125px;" placeholder="Телефон"></div>
                    <div v-if="editableOrganizationDiv==false" class="email ml-3" style="padding-left: 2px;"><img alt="pin" src="../assets/mail.svg" class="ml-5 mr-2"><span v-if="profile.organization.email">{{ profile.organization.email }}</span><span v-else>Эл. почта</span></div>
                    <div v-else class="email"><img alt="pin" src="../assets/mail.svg" class="ml-5 mr-2"><input v-model="profile.organization.email" class="form-transparent" placeholder="Эл. почта"></div>
                  </div>
                  <div class="d-flex org-logo">
                    <div v-if="editableOrganizationDiv==false" class="logo-circle"></div>
                    <div v-else class="logo-circle-edit"></div>
                  </div>
                </div>
                <div class="card-body second-body">
                  Сотрудники
                  <div v-if="profile.organization.staff.length != 0">
                    <div v-for="staff in profile.organization.staff" :key="staff.id" class="staff-div">
                      <div class="row mb-2">
                        <div v-if="editableOrganizationDiv==false" class="col-2 pl-0 underline w-100">{{ staff.role }}</div>
                        <div v-else class="col-2 pl-0"><input v-model="staff.role" class="form-transparent w-100"></div>

                        <div v-if="editableOrganizationDiv==false" class="col-3 pl-0 underline w-100">{{ staff.name }}</div>
                        <div v-else class="col-3"><input v-model="staff.name" class="form-transparent w-100"></div>

                        <div v-if="editableOrganizationDiv==false" class="col-3 pl-0 underline w-100">{{ staff.position }}</div>
                        <div v-else class="col-3"><input v-model="staff.position" class="form-transparent w-100"></div>

                        <div v-if="editableOrganizationDiv==false" class="col-2 pl-0 underline">{{ staff.phone }}</div>
                        <div v-else class="col-2"><input v-model="staff.phone" class="form-transparent w-100"></div>

                        <div v-if="editableOrganizationDiv==false" class="col-2 pl-0 pr-0 underline">
                          {{ staff.email }}
                        </div>
                        <div v-else class="col-2"><input v-model="staff.email" class="form-transparent w-100"><div class="red-circle" @click.prevent="delete_staff(staff)"></div></div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <span class="small-text">Сотрудники не добавлены</span>
                  </div>
                  <div v-if="editableOrganizationDiv==true" @click.prevent="add_staff"><div class="green-circle"></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { facilitiesService, userService } from '../_services';
  // import navbar from './Navbar.vue'
  import VueSimpleSpinner from 'vue-simple-spinner'

  export default {
    name: 'HomePage',
    components: {
      // navbar,
      VueSimpleSpinner
    },
    data () {
      return {
        user: {},
        profile : {},
        facilitylist: [],
        editableSubjectDiv: false,
        openedEditform: false,
        editableOrganizationDiv: false,
        sortAscend: false,
        searchQuery: '',
        preloading: false
      }
    },
    async created () {
      this.preloading = true;

      this.user = JSON.parse(localStorage.getItem('user'));
      await this.getProfile();
      await this.getFacilityList();
      this.preloading = false;

      setInterval(function () {
      }.bind(this), 15000);
    },
    methods: {
      init() {
        // console.log('initializing')
      },
      sortf: function(sortOrder){
        return this.orderedListOptions[sortOrder]()
      },
      async gotoFacility(facilityId) {
        console.log('--------------')
        console.log(facilityId)
        this.$router.push({ path: `/facility/${facilityId}/things` })
      },
      async getFacilityList() {
        let res = await facilitiesService.facilities_get();

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facilitylist = res.result.items;
      },
      async getProfile() {
        let res = await userService.getProfile();

        // console.log(res)

        if (res.error) {
          console.error(res.error);

          userService.logout();
          window.location.href = '/login';

          return;
        }

        this.profile = res.result;
      },
      async deleteFacility(facility_id) {
        if (facility_id === '5f3e2982b669a6b7b377d6fe') {
          alert('Вам нельзя удалять этот объект!');
        } else {
          let res = await facilitiesService.facility_delete(facility_id);

          if (res.error) {
            console.error(res.error);

            return;
          }

          await this.getFacilityList();
          await this.getFacilityValues();
        }
      },
      async editFacility(facility_id) {
        // window.location.href = '/facility/edit/' + facility_id;
        this.$router.push({ name: 'edit_facility', params: { id:facility_id }});
      },
      edit() {
        // console.log('Editing mode toggle');
        this.editableSubjectDiv = !this.editableSubjectDiv;
      },
      add() {
        // console.log('Add mode toggle');
        // this.openedEditform = true;
        this.$router.push({ name: 'add_facility'});
        // window.location.href = '/facility/add';
      },
      sort() {
        this.sortAscend = !this.sortAscend;

        if(this.sortAscend) {
          this.facilitylist.sort(function(a, b){
            var nameA=a.facility.name.toLowerCase(), nameB=b.facility.name.toLowerCase();
            if (nameA < nameB) //сортируем строки по возрастанию
              return -1
            if (nameA > nameB)
              return 1
            return 0 // Никакой сортировки
          })
        } else {
          this.facilitylist.sort(function(a, b){
            var nameA=a.facility.name.toLowerCase(), nameB=b.facility.name.toLowerCase();
            if (nameA < nameB) //сортируем строки по убыванию
              return 1
            if (nameA > nameB)
              return -1
            return 0 // Никакой сортировки
          })
        }
      },
      edit_organization() {
        // console.log('edit org handled!');
        this.editableOrganizationDiv = !this.editableOrganizationDiv;
      },
      async update_organization() {
        let res = await userService.updateProfile(this.profile);

        // console.log(res);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.editableOrganizationDiv = !this.editableOrganizationDiv;

        this.getProfile();
      },
      async delete_staff(staff) {
        this.profile.organization.staff = this.profile.organization.staff.filter(function( obj ) {
          return obj !== staff;
        });
      },
      async add_staff() {
        // alert('add staff!');
        let new_staff = {"role": "Роль", "name": "Имя", "position": "Должность", "phone": "Телефон", "email":"Email"}
        this.profile.organization.staff.push(new_staff)
      },
      async search() {
        await this.getFacilityList();
        // alert('searching');
        let text = document.getElementById('search').value;

        this.facilitylist = this.facilitylist.filter(function(item){
          // alert(item.facility.name)
          let test = item.facility.name.match(text);
          if (test) {
            return item
          }
        })

        await this.getFacilityValues();
      },
      matchName(current) {
        let reggie = new RegExp(this.searchQuery, "ig");
        let found = current.search(reggie) !== -1;
        return !found ? current : current.replace(reggie, '<b class="styled">' + this.searchQuery + '</b>');
      }
    }
  };
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../assets/SnowstormLight.woff2');
  }
  @font-face {
    font-family: 'Tahoma Bold';
    src: url('../assets/Tahoma Bold V2.woff2');
  }
  .card {
    margin-top: 20px;
    /*background-color: rgba(255, 255, 255, 0.9);*/
    /*color: red;*/
    /*overflow: hidden;*/
    /*position: relative;*/
  }
  .card-second {
    /*background-color: rgba(0,0,0,0.4);*/
  }
  .card-title {
    font-family: "Snowstorm Light";
    font-size: 70px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .circle {
    background: #000;
    border-radius: 50%;
    height: 46px;
    width: 46px;
    /*display: table-cell;*/
    /*text-align: center;*/
    /*vertical-align: middle;*/
  }
  .circle-30 {
    background: #8EDC28;
    border-radius: 50%;
    height: 28px;
    width: 28px;
  }
  .circle-30.edit {
    background: url(../assets/small-pencil.svg) no-repeat center center #8EDC28;
  }
  .circle-30.confirm {
    background: url(../assets/confirm-30.svg) no-repeat center center #8EDC28;
  }
  .circle.sort {
    background-size: cover;
    background: url(../assets/sort.svg) no-repeat center center #000;
  }
  .circle.edit {
    background-size: cover;
    background: url(../assets/pencil.svg) no-repeat center center #000;
  }
  .circle.add {
    background-size: cover;
    background: url(../assets/plus.svg) no-repeat center center #000;
  }
  .circle.confirm {
    background-size: cover;
    background: url(../assets/confirm.svg) no-repeat center center #000;
  }
  .circle.delete {
    background-size: cover;
    background: url(../assets/delete.svg) no-repeat center center #000;
  }
  /*.circle.edit::before {*/
  /*  background-color: rgba(0, 0, 0, 0.37);*/
  /*}*/
  .circle:hover {
    background-color: rgba(0,0,0,0.3);
    cursor: pointer;
  }
  .circle-30:hover {
    /*#8EDC28*/
    background-color: rgba(142,220,40,0.4);
    cursor: pointer;
  }
  .manage-block {
    border: 1px solid black;
    height: 200px;
  }
  .input-block {
    height: 100px;
    display: inline-block;
    background: red;
    width: 50%;
  }
  .button-block {
    height: 100px;
    display: inline-block;
    background: red;
    width: 10%;
  }
  .form-control {
    border-radius: 50px;
    height: 46px;
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  .subject-div {
    /*position: relative;*/
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 80px;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 10px;*/
  }
  .object-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding-bottom: 10px;*/
    /*padding-top: 10px;*/
    padding-left: 10px;
  }
  .object-div:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .subject-div:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .subject-data {
    border-left: 1px solid black;
    /*margin-left: 10px;*/
  }
  .card-header {
    /*border: 1px solid green;*/
    /*border-radius: 100px;*/
    /*border-top-left-radius: 50px;*/
    /*border-top-right-radius: 50px;*/
    /*background: #2020ff 0% 0% no-repeat padding-box;*/
    /*!*opacity: 1;*!*/
    /*!*border-color: #606060;*!*/
    /*background-color:#7A991A;*/
    /*border-radius: 50px;*/
    padding: 0;
  }
  /*.card-body {*/
  /*  opacity: 0.7;*/
  /*}*/
  .second-body {
    min-height: 250px;
    max-height: 450px;
    background: #606060 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 1;
    /*backdrop-filter: blur(10px);*/
    /*-webkit-backdrop-filter: blur(10px);*/
    border-color: #606060;
  }
  .card-info {
    /*position: relative;*/
    /*border-radius: 10px;*/
    font-family: "Segoe UI Light";
    font-size: 30px;
    color: #fff;
    letter-spacing: 0px;
    background: #202020 0% 0% no-repeat padding-box;
    /*opacity: 1;*/
  }
  .div-link {
    position: relative;
    /*height: 80px;*/
    display: block;
    margin-top: 2px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
  }
  .div-link:hover {
    color: #CCCCCC;
    text-decoration: none;
  }
  .org-list {
    font-family: "Segoe UI Light";
    font-size: 18px;
  }
  .subject-div.row {
    display: flex;
    align-items: center;
  }
  .subjects-div {
    margin-top: 15px;
    /*height: 38rem;*/
    margin-bottom: 4px;
    /*height:calc(100vh - 350px);*/
    height:calc(100vh - 350px);
    min-height: 80px;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    width: 1px;
    /*background-color: rgba(180,180,180,0.4);*/
    background-color:#000;
    /*background-color: rgba(180,180,180,0.4);*/
    /*-webkit-box-shadow: inset 0 0 4px rgba(200,200,200,1);*/
    /*-webkit-box-shadow: inset 0 0 5px rgba(200,200,200,0.2);*/
    /*border-radius: 10px;*/
  }

  ::-webkit-scrollbar-thumb {
    /*border-radius: 10px;*/
    /*background-color:#000;*/
    background-color: rgba(180,180,180,0.4);
    /*-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,1);*/
  }
  .scroller {
    scrollbar-color: rgba(180,180,180,0.4) #000; /* thumb and track color */
    scrollbar-width: thin;
  }
  /*.background-text {*/
  /*  position: absolute;*/
  /*  !*top: 0;*!*/
  /*  !*left: 35px;*!*/
  /*  !*bottom: 10px;*!*/
  /*  right: 10px;*/
  /*  z-index: 0;*/
  /*  color: #191818;*/
  /*  !*font-family: "Segoe UI Black";*!*/
  /*  font-family: "Tahoma Bold";*/
  /*  font-size: 90px;*/
  /*  opacity: 0.5;*/
  /*}*/
  .inn {
    background-color: #000000;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    /*padding-right: 15px;*/
    font-family: "Segoe UI Light";
    font-size: 20px;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .address {
    width: 400px;
    font-family: "Segoe UI Light";
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .phone {
    /*width: 10px;*/
    font-family: "Segoe UI Light";
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*max-width: 175px;*/
    /*width: 175px;*/
  }
  .email {
    font-family: "Segoe UI Light";
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .org-logo {
    position: absolute;
    /*top: 23%;*/
    top: 70px;
    right: 36px;
  }
  .circle-line {
    position: absolute;
    width: 38px;
    height: 2px;
    background: #FFFFFF;
    /*top: 41%;*/
    top: 120px;
  }
  .logo-circle {
    background-image: url("../assets/logo_v_kruge.png");
    background-position: center;
    background-repeat: no-repeat;
    /*background-size: cover;*/
    /*background: #222222;*/
    border: 2px solid white;
    width: 95px;
    height: 95px;
    border-radius: 50%;
  }
  .logo-circle-edit {
    background-image: url("../assets/photo.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid white;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    cursor: pointer;
  }
  .staff-div {
    font-family: "Segoe UI Light";
    font-size: 14px;
  }
  .underline:before {
    content: "";
    position: absolute;
    left : 0px;
    bottom: -1px;
    /*display: block;*/
    /*!*margin: 0 auto; !* This will center the border. *!*!*/
    /*width: 100%;*/
    padding-top: 1px;
    width: 90%;
    border-bottom: 2px solid #707070;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .underline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .underline:before {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /*.underline:after {*/
  /*  content: "";*/
  /*  display: block;*/
  /*  !*margin: 0 auto; !* This will center the border. *!*!*/
  /*  width: 100%;*/
  /*  padding-top: 1px;*/
  /*  border-bottom: 2px solid #ff7070;*/
  /*}*/
  .form-transparent {
    /*display: inline;*/
    /*width: 100%;*/
    /*width: auto;*/
    background: transparent;
    border: none;
    /*font-family: "Segoe UI Light";*/
    /*font-size: 30px;*/
    color: #fff;
    letter-spacing: 0px;
    padding: 0px;
    border-bottom: 1px solid #CCC;
  }
  .red-circle {
    min-width: 20px;
    height: 20px;
    background-size: cover;
    background: url(../assets/minus-small.svg) no-repeat center center #C40D0D;
    border-radius: 50px;
    position: relative;
    left: 45px;
    top: 3px;
    z-index: 999999;
  }
  .red-circle:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .green-circle {
    float: right;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 30px;
    z-index: 999999;
    border-radius: 50px;
    color: #000000;
    background: url(../assets/plus-small.svg) no-repeat center center #8EDC28;
  }
  .green-circle:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .search {
    /*position: absolute;*/
    /*float: right;*/
    /*right: 230px;*/
    /*top: 130px;*/
    position: relative;
    right: 45px;
    cursor: pointer;
  }
  .search-form {
    /*border: 1px solid black;*/
  }
  .search-icon {
    position: absolute;
    float: right;
    top: 131px;
    right: 233px;
    cursor: pointer;
  }
  .search-icon:hover {
    opacity: 0.7;
  }
  .styled {
    color: #8EDC28;
  }
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  .name-span {
    /*position: relative;*/
    /*display: inline-block;*/
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /*width: 400px;*/
    /*height: 1.2em;*/
  }
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .wrap-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: auto;
  }
  @media (max-width: 640px) {
    .card-body {padding: 0px;}
    .card-title {font-size: 38px !important; padding-top: 10px;}
    .search-icon { position: absolute; top: 83px; right: 210px; }
  }
</style>
