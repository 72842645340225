import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const logsService = {
  logs_get,
  logs_temperature,
  logs_voltage,
  logs_temperature_bar,
  logs_voltage_bar,
  logs_laurel,
  logs_laurel_state
};

async function logs_get(facilityId, thingId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs`, requestOptions).then(responseHandler.handleResponse);
}

async function logs_temperature(facilityId, thingId, params) {
// async function logs_temperature(facilityId, equipmentId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "sensor_tag": params.sensor_tag,
      "period": params.period,
      "group_by": params.group_by
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/temperature`, requestOptions).then(responseHandler.handleResponse);
  // return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}/logs/temperature`, requestOptions).then(responseHandler.handleResponse);
}

async function logs_voltage(facilityId, thingId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "sensor_tag": params.sensor_tag,
      "period": params.period,
      "group_by": params.group_by
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/voltage`, requestOptions).then(responseHandler.handleResponse);
  // return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}/logs/voltage`, requestOptions).then(responseHandler.handleResponse);
}

async function logs_temperature_bar(facilityId, thingId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "period": params.period
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/temperature/bars`, requestOptions).then(responseHandler.handleResponse);
  // return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}/logs/temperature/bars`, requestOptions).then(responseHandler.handleResponse);
}

async function logs_voltage_bar(facilityId, thingId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "period": params.period
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/voltage/bars`, requestOptions).then(responseHandler.handleResponse);
  // return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}/logs/voltage/bars`, requestOptions).then(responseHandler.handleResponse);
}

async function logs_laurel(facilityId, thingId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "table": params.table,
      "period": params.period,
      "group_by": params.group_by
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/laurel`, requestOptions).then(responseHandler.handleResponse);
}


async function logs_laurel_state(facilityId, thingId, params) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "equipment_id": params.equipment_id,
      "table": params.table,
      "period": params.period,
      "group_by": params.group_by
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/logs/laurel/state`, requestOptions).then(responseHandler.handleResponse);
}
