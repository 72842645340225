import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const invitesService = {
  invites_get,
  invite_send,
  invites_to_facility_get,
  invite_delete,
  invite_accept,
  invite_decline
};

async function invites_get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/invites`, requestOptions).then(responseHandler.handleResponse);
}

async function invites_to_facility_get(facilityId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/invites`, requestOptions).then(responseHandler.handleResponse);
}

async function invite_send(facilityId, email, role) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "email": email,
      "role": role
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/invites`, requestOptions).then(responseHandler.handleResponse);
}

async function invite_delete(facilityId, inviteId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/invites/${inviteId}`, requestOptions).then(responseHandler.handleResponse);
}

// /invites/{inviteId}
async function invite_accept(inviteId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
  };

  return fetch(API_LOCATION + `/invites/${inviteId}`, requestOptions).then(responseHandler.handleResponse);
}

async function invite_decline(inviteId) {
  const requestOptions = {
    method: 'DELETE',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
  };

  return fetch(API_LOCATION + `/invites/${inviteId}`, requestOptions).then(responseHandler.handleResponse);
}