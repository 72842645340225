<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <!-- <navbar></navbar> -->
    <div class="container">

      <div class="d-flex pt-2" style="padding-left: 20px;">
          <div class="back-img mt-4 mr-3" style="cursor: pointer;">
              <a @click="$router.go(-1)"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
          </div>
          <h5 class="card-title mb-0">{{ $t('editthingpage.title') }}:</h5>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body">
              <!-- <div class="card-body header-div d-flex" style="height: 100px">
                <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="hover"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                </div>
                <div class="mt-2 ml-3">Редактирование устройства:</div>
              </div> -->
              <!-- <div class="editform" style="height: 200px;"> -->
                <!-- <div class="row" style="height: 200px;">
                  <div class="col-sm-11 mt-3">
                    <input type="text" v-model="thing.name" class="form-control form-edit ml-2" placeholder="Имя устройства" maxlength="150"/>
                    <p class="ml-3 mt-2">Тип: {{ thing.type.id }}</p>
                    <p class="ml-3">Серийный номер: {{ thing.serial }}</p>
                    <p class="ml-3">interface: {{ thing.shadow.interface }}</p>
                    <p class="ml-3">registers: {{ thing.shadow.registers }}</p>
                    <p class="ml-3">registers_list: {{ thing.shadow.registers_list }}</p>
                  </div>
                  <div class="col-sm-1 mt-1" style="padding: 10px;">
                    <div class="circle confirm" @click.prevent="update_thing()"></div>
                  </div>
                </div> -->
                <!-- <hr>
              </div> -->
              <div class="thing-edit-div">
                <div class="row">
                  <div class="col-3">
                    <span>Название устройства</span>
                  </div>
                  <div class="col-9">
                    <input type="text" v-model="thing.name" class="myform" style="font-size: 16px; color: fff; width: 250px;" placeholder="Название" maxlength="150"/>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-3">
                    <span>Серийный номер</span>
                  </div>
                  <div class="col-9">
                    <input type="text" v-model="thing.serial" class="myform" style="font-size: 16px; width: 250px;" placeholder="Серийный номер" maxlength="150" readonly/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3">Интерфейс:</div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Порт</span>
                  </div>
                  <div class="col-8">
                    <select class="myform" style="font-size: 16px; width: 180px;" v-model="thing.shadow.interface.port">
                      <option v-for="option in port_list" v-bind:key="option.id" v-bind:value="option.id">{{ option.text }}</option>
                    </select>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Адрес Modbus</span>
                  </div>
                  <div class="col-8">
                    <input type="number" v-model="thing.shadow.interface.slave_address" class="myform" style="font-size: 16px;" placeholder="Название" maxlength="150"/>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Скорость, бод</span>
                  </div>
                  <div class="col-8">
                    <!-- <input type="text" v-model="new_device.interface.baudrate" class="myform" style="font-size: 16px;" placeholder="baudrate" maxlength="150"/> -->

                    <select class="myform" style="font-size: 16px; width: 180px;" v-model="thing.shadow.interface.baudrate">
                      <option v-for="option in baudrate_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.id }}</option>
                    </select>

                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Размер байта</span>
                  </div>
                  <div class="col-8">
                    <!-- <input type="text" v-model="new_device.interface.bytesize" class="myform" style="font-size: 16px;" placeholder="baudrate" maxlength="150"/> -->
                    <select class="myform" style="font-size: 16px; width: 180px;" v-model="thing.shadow.interface.bytesize">
                      <option v-for="option in bytesize_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                    </select>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Четность</span>
                  </div>
                  <div class="col-8">
                    <!-- <input type="text" v-model="new_device.interface.parity" class="myform" style="font-size: 16px;" placeholder="parity" maxlength="150"/> -->
                    <select class="myform" style="font-size: 16px; width: 180px;" v-model="thing.shadow.interface.parity">
                      <option v-for="option in parity_list" v-bind:key="option.id" v-bind:value="option.id">{{ option.text }}</option>
                    </select>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Стоп-бит</span>
                  </div>
                  <div class="col-8">
                    <!-- <input type="text" v-model="new_device.interface.stopbits" class="myform" style="font-size: 16px;" placeholder="stopbits" maxlength="150"/> -->
                    <select class="myform" style="font-size: 16px; width: 180px;" v-model="thing.shadow.interface.stopbits">
                      <option v-for="option in stopbits_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                    </select>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-3">
                    <span>Таймаут</span>
                  </div>
                  <div class="col-8">
                    <input type="number" step="0.01" min="0.05" max="3.00" v-model="thing.shadow.interface.timeout" class="myform" style="font-size: 16px; width: 180px;" placeholder="timeout" maxlength="150"/>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-3">Диапазоны опроса:</div>
                  <div class="col-8"></div>
                  <div class="col-1"><div @click.prevent="add_diapason"><div class="green-circle"></div></div></div>
                </div> -->

                <div class="d-flex justify-content-between mb-2">
                  <span class="ml-3">Диапазоны опроса:</span>
                  <span class=""><div @click.prevent="add_diapason"><div class="green-circle"></div></div></span>
                </div>

                <!-- <div v-for="diapason in thing.shadow.registers_list" :key="diapason.start">
                  <div class="row mt-1 mb-2">
                    <div class="col-1">
                    </div>
                    <div class="col-2">
                      <span>Адрес</span>
                    </div>
                    <div class="col-2">
                      <input type="text" v-model="diapason.start" class="myform" style="font-size: 16px;" placeholder="start" maxlength="150"/>
                    </div>
                    <div class="col-2">
                      <span>Количество</span>
                    </div>
                    <div class="col-2">
                      <input type="number" min="1" max="300" v-model="diapason.quantity" class="myform" style="font-size: 16px; width: 180px;" placeholder="quantity" maxlength="150"/>
                    </div>
                    <div class="col-2">
                      <input type="number" min="1" max="300" v-model="diapason.quantity" class="myform" style="font-size: 16px; width: 180px;" placeholder="quantity" maxlength="150"/>
                    </div>
                    <div class="col-1">
                      <div class="red-circle" @click.prevent="delete_diapason(diapason)"></div>
                    </div>
                  </div>
                </div> -->

                <div v-for="diapason in thing.shadow.registers_list" :key="diapason.start">
                  <div class="d-flex justify-content-between pl-5 mb-2">
                    <span class="ml-3 mr-1">Адрес</span>
                    <input type="text" v-model="diapason.start" class="myform" style="font-size: 16px; max-width: 180px; min-width: 60px;" placeholder="start" maxlength="150"/>
                    <span class="ml-3 mr-1">Количество</span>
                    <input type="number" min="1" max="300" v-model="diapason.quantity" class="myform" style="font-size: 16px; min-width: 60px; max-width: 160px;" placeholder="quantity" maxlength="3"/>
                    <span class="ml-3 mr-1">Функция</span>
                    <!-- <input type="number" min="1" max="300" v-model="diapason.functioncode" class="myform" style="font-size: 16px; width: 60px;" placeholder="functioncode" maxlength="10"/> -->

                    <select class="myform" style="font-size: 16px; min-width: 60px;" v-model="diapason.functioncode">
                      <option v-for="option in functioncode_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                    </select>

                    <div class="red-circle ml-3" @click.prevent="delete_diapason(diapason)"></div>
                  </div>
                </div>

                <div @click.prevent="update_thing()" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 76px; text-transform: uppercase;">{{ $t('editthingpage.save-button') }}</div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body pb-0">
              <div class="thing-edit-div">
                <div class="row ml-3">
                  <span style="font-weight: bold; font-size: 18px;">Телеуправление</span>
                </div>
                <div class="row mt-3">
                  <div class="col-5">
                    <div @click.prevent="send_modbus_command_tu()" class="green-button" style="width: 240px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">Отправить команду</div>
                  </div>
                  <div class="col-7">
                    
                    <div v-if="tu_command_sended == true">
                      <div class="row" v-if="tu_command_status == false">
                        <vue-simple-spinner :speed="1.5" :size="35" :line-size="4" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                        <p class="float-left m-1 ml-3">{{ this.tu_modbus_command_status_text }}</p>
                      </div>
                    </div>
                    <div class="row" v-if="tu_modbus_answer">
                      <!-- <p class="float-left m-1 ml-3"> Результат: {{ this.format(this.tu_modbus_answer) }}</p> -->
                      <span v-if="success" class="mr-1" style="color: rgb(0, 255, 0);">Успех!</span>
                      <p class="mr-1">Результат:<the-mask mask="FFFF FFFF" :tokens="hexTokens" v-model="tu_modbus_answer" readonly class="myform ml-1" style="font-size: 16px; color: fff; width: auto;"/></p>
                      <!-- <p class="float-left m-1 ml-3">Результат: {{ this.tu_modbus_answer.replace(/(\d{1,2}(?=(?:\d\d)+(?!\d)))/g, "$1" + ' ') }}</p> -->
                    </div>
                    <div class="row" v-if="tu_error">
                      <span class="mr-1" style="color: rgb(255, 0, 0);">Ошибка!</span><span>{{ tu_error }}</span>
                    </div>

                  </div>
                </div>

                <div class="d-flex justify-content-around mt-3">
                  <span>ПАРОЛЬ</span>
                  <span>КОМАНДА</span>
                </div>
                <div class="d-flex justify-content-around ml-2 mr-2 mb-2">
                  <div class="d-flex justify-content-around ml-2 mr-2">
                    <div align="center" class="ml-1 mr-1">
                      <span>Адрес (HEX)</span>
                      <the-mask mask="FFFF" :tokens="hexTokens" v-model="modbus_password_address" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                    <div align="center" class="ml-1 mr-1">
                      <span>Значение (DEC)</span>
                      <input type="number" v-model="modbus_password_value_dec" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                  </div>
                  <div class="d-flex justify-content-around ml-2 mr-2 mb-2">
                    <div align="center" class="ml-1 mr-1">
                      <span>Адрес (HEX)</span>
                      <the-mask mask="FFFF" :tokens="hexTokens" v-model="modbus_command_address" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                    <div align="center" class="ml-1 mr-1">
                      <span>Значение (HEX)</span>
                      <the-mask mask="FFFF" :tokens="hexTokens" v-model="modbus_command_value_hex" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="card-body pb-0">
              <div class="thing-edit-div">
                <div class="row ml-3">
                  <span style="font-weight: bold; font-size: 18px;">Ручной ввод команды</span>
                </div>
                <div class="row mt-3">
                  <div class="col-5">
                    <div @click.prevent="send_modbus_command_r()" class="green-button" style="width: 240px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">Отправить команду</div>
                  </div>
                  <div class="col-7">

                    <div v-if="r_command_sended == true">
                      <div class="row" v-if="r_command_status == false">
                        <vue-simple-spinner :speed="1.5" :size="35" :line-size="4" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                        <p class="float-left m-1 ml-3">{{ this.r_modbus_command_status_text }}</p>
                      </div>
                    </div>
                    <div class="row" v-if="r_modbus_answer">
                      <span v-if="r_success" class="float-left m-1 ml-2" style="color: rgb(0, 255, 0);">Успех!</span>
                      <p class="float-left m-1 ml-1">Результат: <span class="p-1" style="background-color: #3C3C3C; border-radius: 10px;">{{ format(this.r_modbus_answer) }}</span></p>
                      <!-- <p class="mr-1">Результат:<the-mask mask="FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF" :tokens="hexTokens" v-model="r_modbus_answer" readonly class="myform ml-1" style="font-size: 16px; color: fff; width: auto;"/></p> -->
                    </div>
                    <div class="row" v-if="r_error">
                      <span class="mr-1" style="color: rgb(255, 0, 0);">Ошибка!</span><span>{{ r_error }}</span>
                    </div>

                  </div>
                </div>

                <div class="d-flex justify-content-around m-3">
                  <div class="d-flex justify-content-around ml-2 mr-2">
                    <div align="center" class="ml-1 mr-3">
                      <span>Адрес</span>
                      <input type="text" readonly v-model="thing.shadow.interface.slave_address" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                    <div align="center">
                      <span>Команда (HEX)</span>
                      <the-mask mask="FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF FF" :tokens="hexTokens" v-model="modbus_command" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                    <div align="center" class="ml-3 mr-1">
                      <span>CRC16</span>
                      <input type="text" readonly v-model="crc" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="card-body pb-0">
              <div class="thing-edit-div">
                <div class="row ml-3">
                  <span style="font-weight: bold; font-size: 18px;">Стандартные команды</span>
                </div>


                <div class="row mt-3 mb-2">
                  <div class="col-6 mt-3">

                    <div v-if="s_command_sended == true">
                      <div class="row" v-if="s_command_status == false">
                        <vue-simple-spinner :speed="1.5" :size="35" :line-size="4" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                        <p class="float-left m-1 ml-3">{{ this.s_modbus_command_status_text }}</p>
                      </div>
                    </div>
                    <div class="row" v-if="s_modbus_answer">
                      <!-- <p class="float-left m-1 ml-3">Результат: {{ format(this.s_modbus_answer) }}</p> -->
                      <span v-if="s_success" class="float-left m-1 ml-2" style="color: rgb(0, 255, 0);">Успех!</span>
                      <p class="float-left m-1 ml-1">Результат: <span class="p-1" style="background-color: #3C3C3C; border-radius: 10px;">{{ format(this.s_modbus_answer) }}</span></p>
                    </div>
                    <div class="row" v-if="s_error">
                      <span class="mr-1" style="color: rgb(255, 0, 0);">Ошибка!</span><span>{{ s_error }}</span>
                    </div>

                  </div>
                  <div class="col-6">
                    <div class="d-flex justify-content-around">
                      <span>ПАРОЛЬ</span>
                    </div>
                    <div class="d-flex justify-content-around">
                      <div align="center" class="ml-1 mr-3">
                        <span>Адрес (HEX)</span>
                        <the-mask mask="FFFF" :tokens="hexTokens" v-model="modbus_password_address" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                      </div>
                      <div align="center">
                        <span>Значение (DEC)</span>
                        <input type="number" v-model="modbus_password_value_dec" class="myform" style="font-size: 16px; color: fff; width: 100%;"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3 mb-2">
                  <div class="col-6">
                    <div @click.prevent="send_modbus_command_s('41050108' + ('0000000000000000' + Date.now().toString(16).toUpperCase()).slice(-16))" class="green-button" style="width: 298px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">Синхронизировать время</div>
                  </div>
                  <div class="col-3">
                    <div @click.prevent="send_modbus_command_s('0301000001')" class="gray-button disabled" style="width: 140px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">ВКЛ</div>
                  </div>
                  <div class="col-3">
                    <div @click.prevent="send_modbus_command_s('0301000001')" class="gray-button disabled" style="width: 140px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">ОТКЛ</div>
                  </div>
                </div>

                <div class="row mt-3 mb-2">
                  <div class="col-6">
                    <!-- <div @click.prevent="send_modbus_command_s('0301000001')" class="gray-button disabled" style="width: 380px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">Синхронизировать время на всех</div> -->
                  </div>
                  <div class="col-3">
                    <div @click.prevent="send_modbus_command_s('412701020000')" class="green-button" style="width: 248px; height: 36px; font-size: 18px; padding-top: 5px; padding-left: 26px; text-transform: uppercase;">Съем сигнализации</div>
                  </div>
                  <div class="col-3">
                  </div>
                </div>

              </div>
            </div>

            <!-- <div class="card-body">
              <div class="thing-edit-div">
                <div class="row">
                  <div class="col-3">
                    <span>Пароль</span>
                  </div>
                  <div class="col-9">
                    <input type="number" v-model="modbus_psw" class="myform" style="font-size: 16px; color: fff; width: 250px;" placeholder="41 04 01 00" maxlength="150" value="41 04 01 00"/>
                  </div>

                  <hr>

                  <div class="col-3">
                    <span>Modbus команда</span>
                  </div>
                  <div class="col-9">
                    <input type="text" v-model="modbus_command" class="myform" style="font-size: 16px; color: fff; width: 250px;" placeholder="41 04 01 00" maxlength="150" value="41 04 01 00"/>
                  </div>

                  <div @click.prevent="send_modbus_command()" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 76px; text-transform: uppercase;">Отправить</div>

                  <hr>

                  <div v-if="command_sended == true">
                    <div class="row m-3" v-if="command_status == false">
                      <vue-simple-spinner :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                      <p class="float-left m-3">{{ this.modbus_command_status_text }}</p>
                    </div>
                  </div>
                  <div class="row m-3" v-if="modbus_answer">
                    <p class="float-left m-3">Результат получен: {{ this.modbus_answer }}</p>
                  </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import navbar from './Navbar.vue'
  import {thingsService} from "../_services";
  import VueSimpleSpinner from 'vue-simple-spinner';
  import {mask} from 'vue-the-mask'
  import {TheMask} from 'vue-the-mask'

  export default {
    name: 'ThingEdit',
    components: {
      // navbar,
      VueSimpleSpinner,
      TheMask
    },
    directives: {mask},
    data () {
      return {
        facilityId: this.$route.params.facility_id,
        thingId: this.$route.params.thing_id,
        thing: '',
        preloading: false,
        port_list: [
          {id: "/dev/serialUSB", text: "USB"},
          {id: "/dev/ttyS1", text: "COM1"},
          {id: "/dev/ttyS2", text: "COM2"},
        ],
        baudrate_list: [
          {id: "2400", text: "2400"},
          {id: "4800", text: "4800"},
          {id: "9600", text: "9600"},
          {id: "19200", text: "19200"},
          {id: "38400", text: "38400"},
          {id: "57600", text: "57600"},
          {id: "115200", text: "115200"},
        ],
        parity_list: [
          {id: "N", text: "NONE"},
          {id: "E", text: "EVEN"},
          {id: "O", text: "ODD"},
          {id: "M", text: "MARK"},
          {id: "S", text: "SPACE"},
        ],
        bytesize_list: [
          {id: "5", text: "5"},
          {id: "6", text: "6"},
          {id: "7", text: "7"},
          {id: "8", text: "8"},
        ],
        stopbits_list: [
          {id: "1", text: "1"},
          {id: "1.5", text: "1.5"},
          {id: "2", text: "2"},
        ],
        functioncode_list: [
          {id: 2, text: 2},
          {id: 3, text: 3},
        ],
        modbus_psw: null,
        modbus_command: '',
        command_sended: false,
        tu_command_sended: false,
        r_command_sended: false,
        s_command_sended: false,
        command_status: false,
        tu_command_status: false,
        r_command_status: false,
        s_command_status: false,
        modbus_command_status_text: 'Modbus команда отправлена...',
        tu_modbus_command_status_text: 'Modbus команда отправлена...',
        r_modbus_command_status_text: 'Modbus команда отправлена...',
        s_modbus_command_status_text: 'Modbus команда отправлена...',
        modbus_answer: '',
        tu_modbus_answer: '',
        tu_command_full: '',
        success: false,
        r_success: false,
        s_success: false,
        r_modbus_answer: '',
        s_modbus_answer: '',
        modbus_password_address: '',
        modbus_password_value_dec: null,
        modbus_command_address: '',
        modbus_command_value_hex: null,
        crc: '',
        tu_error: '',
        r_error: '',
        s_error: '',
        hexTokens: {
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: v => v.toLocaleUpperCase()
          }
        },
        timeout: 40
      }
    },
    async created () {
      this.preloading = true;
      await this.init();
      this.preloading = false;
    },
    methods: {
      async init() {
        console.log('initializing');
        await this.get_thing(this.facilityId, this.thingId);
      },
      async get_thing(facilityId, thingId) {
        let res = await thingsService.thing_get(facilityId, thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.thing = res.result;
      },
      format(str) {
          const s = str.length;
          const chars = str.split('');
          const strWithSpaces = chars.reduceRight((acc, char, i) => {
              const spaceOrNothing = ((((s - i) % 2) === 0) ? ' ' : '');
              return (spaceOrNothing + char + acc);
          }, '');

          return ((strWithSpaces[0] === ' ') ? strWithSpaces.slice(1) : strWithSpaces);
      },
      d2h(d) {
        // var h = (d).toString(16);
        // return h.length % 2 ? '0' + h : h;
        return ('0000' + d.toString(16).toUpperCase()).slice(-4)
      },
      format_h(str) {
        return ('0000' + str.toUpperCase()).slice(-4)
      },
      async send_modbus_command_tu() {
        this.success = false;
        this.tu_error = '';
        this.tu_command_sended = true;
        this.tu_command_status = false;
        this.tu_modbus_answer = '';
        this.tu_modbus_command_status_text = 'Modbus команда отправлена...';

        let data = {}

        if (this.modbus_command_address !== '' && this.modbus_command_value_hex !== null && this.modbus_command_value_hex !== isNaN) {
          let modbus_command_value_hex = this.format_h(this.modbus_command_value_hex)
          console.log(modbus_command_value_hex)
          
          this.tu_command_full = "06" + this.format_h(this.modbus_command_address) + modbus_command_value_hex
          data.command = this.tu_command_full
        }

        if (this.modbus_password_address !== '' && this.modbus_password_value_dec !== null && this.modbus_password_value_dec !== isNaN) {
          let modbus_password_value_hex = this.d2h(parseInt(this.modbus_password_value_dec))
          console.log(modbus_password_value_hex)

          data.password_command = "06" + this.format_h(this.modbus_password_address) + modbus_password_value_hex;
        }

        console.log(data);

        let res = await thingsService.modbus_command(this.facilityId, this.thingId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.tu_command_reqId = res.result.reqId

        console.log('<< =========================== tu_command_reqId');
        console.log(this.tu_command_reqId);

        await this.tu_modbus_command_status(this.tu_command_reqId)
      },
      async send_modbus_command_r() {
        this.r_success = false;
        this.r_error = '';
        this.r_command_sended = true;
        this.r_command_status = false;
        this.r_modbus_answer = '';
        this.r_modbus_command_status_text = 'Modbus команда отправлена...';
        this.getCRC(this.modbus_command)

        let data = {}

        data.command = this.modbus_command

        console.log(data);

        let res = await thingsService.modbus_command(this.facilityId, this.thingId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.r_command_reqId = res.result.reqId

        console.log('<< =========================== r_command_reqId');
        console.log(this.r_command_reqId);

        await this.r_modbus_command_status(this.r_command_reqId)
      },
      async send_modbus_command_s(command) {
        this.s_success = false;
        this.s_error = '';
        this.s_command_sended = true;
        this.s_command_status = false;
        this.s_modbus_answer = '';
        this.s_modbus_command_status_text = 'Modbus команда отправлена...';

        let data = {}

        data.command = command

        if (this.modbus_password_address !== '' && this.modbus_password_value_dec !== null && this.modbus_password_value_dec !== isNaN) {
          let modbus_password_value_hex = this.d2h(parseInt(this.modbus_password_value_dec))
          console.log(modbus_password_value_hex)

          data.password_command = "06" + this.format_h(this.modbus_password_address) + modbus_password_value_hex;
        }

        console.log(data);

        let res = await thingsService.modbus_command(this.facilityId, this.thingId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.s_command_reqId = res.result.reqId

        console.log('<< =========================== s_command_reqId');
        console.log(this.s_command_reqId);

        await this.s_modbus_command_status(this.s_command_reqId)
      },
      crc16(buffer) {
          var crc = 0xFFFF;
          var odd;

          for (var i = 0; i < buffer.length; i++) {
              crc = crc ^ buffer[i];

              for (var j = 0; j < 8; j++) {
                  odd = crc & 0x0001;
                  crc = crc >> 1;
                  if (odd) {
                      crc = crc ^ 0xA001;
                  }
              }
          }

          this.crc = crc;
          console.log(this.crc);
      },
      getCRC(message) {
        let regInit = 0

        if (typeof message === 'string') {
          message = message.split('').map(c => c.charCodeAt(0))
        }

        let crc = regInit + 0
        const polynomial = 0x1021

        for (const b of message) {
          for (let i = 0; i < 8; i++) {
            const bit = ((b >> (7 - i) & 1) === 1)
            const c15 = ((crc >> 15 & 1) === 1)
            crc <<= 1
            if (c15 ^ bit) crc ^= polynomial
          }
        }

        crc &= 0xffff

        this.crc = ('0000' + crc.toString(16).toUpperCase()).slice(-4);
        console.log(this.crc)
      },
      async send_modbus_command() {
        this.command_sended = true;
        this.command_status = false;
        this.modbus_answer = '';
        this.modbus_command_status_text = 'Modbus команда отправлена...';
        this.getCRC(this.modbus_command)

        // let data = {
        //   "command": this.modbus_command,
        //   "password_command": this.modbus_psw
        // }

        let data = {}

        if (this.modbus_command !== '') {
          data.command = this.modbus_command
        }

        if (this.modbus_password_address !== '' && this.modbus_password_value_dec !== null && this.modbus_password_value_dec !== isNaN) {
          let modbus_password_value_hex = this.d2h(parseInt(this.modbus_password_value_dec))
          console.log(modbus_password_value_hex)

          data.password_command = "06" + this.format_h(this.modbus_password_address) + modbus_password_value_hex;
        }

        console.log(data);

        let res = await thingsService.modbus_command(this.facilityId, this.thingId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.command_reqId = res.result.reqId

        console.log('<< =========================== command_reqId');
        console.log(this.command_reqId);

        await this.modbus_command_status(this.command_reqId)
      },
      async tu_modbus_command_status(reqId) {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let answer;
        let res = 'UNRESOLVED';
        let waitSec = 0;

        while (res != 'RESOLVED' && res != 'REJECTED' && res != 'FAILED' && waitSec <= this.timeout) {
          await delay(1000);
          let result = await thingsService.modbus_command_status(this.facilityId, this.thingId, reqId)
          console.log('<< =========================== result');
          console.log(result);
          res = result.result.state;
          answer = result.result.answer;
          console.log('<< =========================== answer');
          console.log(answer);

          if (res == 'ACCEPTED') {
            this.tu_modbus_command_status_text = 'Команда обрабатывается хабом...'
            this.tu_error = ''
          }
          if (res == 'REJECTED') {
            this.tu_modbus_command_status_text = 'Хаб отклонил запрос'
            this.tu_error = result.result.error
            console.log(this.tu_error)
          }
          if (res == 'RESOLVED') {
            this.tu_modbus_command_status_text = 'Команда успешно обработана'
            this.tu_error = ''
          }
          if (res == 'FAILED') {
            this.tu_modbus_command_status_text = 'Произошла ошибка'
            this.tu_error = result.result.error
            console.log(this.tu_error)
          }
          if (waitSec >= this.timeout) {
            this.tu_modbus_command_status_text = 'Таймаут запроса'
            this.tu_error = 'Таймаут запроса'
          }
          waitSec += 1;
        }

        console.log('************************')
        console.log(this.tu_command_full)
        console.log('06' + answer)
        if (this.tu_command_full.split(/\s+/).join('').toUpperCase() == ('06' + answer).split(/\s+/).join('').toUpperCase()) {
          this.success = true;
        }

        this.tu_modbus_answer = answer;
        this.tu_command_status = true;
        this.tu_command_sended = false;
      },
      async r_modbus_command_status(reqId) {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let answer;
        let res = 'UNRESOLVED';
        let waitSec = 0;

        while (res != 'RESOLVED' && res != 'REJECTED' && res != 'FAILED' && waitSec <= this.timeout) {
          await delay(1000);
          let result = await thingsService.modbus_command_status(this.facilityId, this.thingId, reqId)
          console.log('<< =========================== result');
          console.log(result);
          res = result.result.state;
          answer = result.result.answer;
          console.log('<< =========================== answer');
          console.log(answer);

          if (res == 'ACCEPTED') {
            this.r_modbus_command_status_text = 'Команда обрабатывается хабом...'
            this.r_error = ''
          }
          if (res == 'REJECTED') {
            this.r_modbus_command_status_text = 'Хаб отклонил запрос'
            this.r_error = result.result.error
          }
          if (res == 'RESOLVED') {
            this.r_modbus_command_status_text = 'Команда успешно обработана'
            this.r_success = true;
            this.r_error = ''
          }
          if (res == 'FAILED') {
            this.r_modbus_command_status_text = 'Произошла ошибка'
            this.r_error = result.result.error
          }
          if (waitSec >= this.timeout) {
            this.r_modbus_command_status_text = 'Таймаут запроса'
            this.r_error = 'Таймаут запроса'
          }
          waitSec += 1;
        }

        this.r_modbus_answer = answer;
        this.r_command_status = true;
        this.r_command_sended = false;
      },
      async s_modbus_command_status(reqId) {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let answer;
        let res = 'UNRESOLVED';
        let waitSec = 0;

        while (res != 'RESOLVED' && res != 'REJECTED' && res != 'FAILED' && waitSec <= this.timeout) {
          await delay(1000);
          let result = await thingsService.modbus_command_status(this.facilityId, this.thingId, reqId)
          console.log('<< =========================== result');
          console.log(result);
          res = result.result.state;
          answer = result.result.answer;
          console.log('<< =========================== answer');
          console.log(answer);

          if (res == 'ACCEPTED') {
            this.s_modbus_command_status_text = 'Команда обрабатывается хабом...'
            this.s_error = ''
          }
          if (res == 'REJECTED') {
            this.s_modbus_command_status_text = 'Хаб отклонил запрос'
            this.s_error = result.result.error
            console.log(this.s_error)
          }
          if (res == 'RESOLVED') {
            this.s_modbus_command_status_text = 'Команда успешно обработана'
            this.s_success = true;
            this.s_error = ''
          }
          if (res == 'FAILED') {
            this.s_modbus_command_status_text = 'Произошла ошибка'
            this.s_error = result.result.error
            console.log(this.s_error)
          }
          if (waitSec >= this.timeout) {
            this.s_modbus_command_status_text = 'Таймаут запроса'
            this.s_error = 'Таймаут запроса'
          }
          waitSec += 1;
        }

        this.s_modbus_answer = answer;
        this.s_command_status = true;
        this.s_command_sended = false;
      },
      async modbus_command_status(reqId) {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let answer;
        let res = 'UNRESOLVED';

        while (res != 'RESOLVED' && res != 'REJECTED' && res != 'FAILED') {
          await delay(1000);
          let result = await thingsService.modbus_command_status(this.facilityId, this.thingId, reqId)
          console.log('<< =========================== result');
          console.log(result);
          res = result.result.state;
          answer = result.result.answer;
          console.log('<< =========================== answer');
          console.log(answer);

          if (res == 'ACCEPTED') {
            this.modbus_command_status_text = 'Команда обрабатывается хабом...'
          }
          if (res == 'REJECTED') {
            this.modbus_command_status_text = 'Хаб отклонил запрос'
          }
          if (res == 'RESOLVED') {
            this.modbus_command_status_text = 'Команда успешно обработана'
          }
          if (res == 'FAILED') {
            this.modbus_command_status_text = 'Произошла ошибка'
          }
        }

        this.modbus_answer = answer;
        this.command_status = true;
        this.command_sended = false;
      },
      async update_thing() {
        let data = {
          "name": this.thing.name,
          "shadow": {
            "interface": {
              "port": this.thing.shadow.interface.port,
              "baudrate": this.thing.shadow.interface.baudrate,
              "bytesize": this.thing.shadow.interface.bytesize,
              "slave_address": this.thing.shadow.interface.slave_address,
              "parity": this.thing.shadow.interface.parity,
              "stopbits": this.thing.shadow.interface.stopbits,
              "timeout": this.thing.shadow.interface.timeout,
            },
            "registers_list": this.thing.shadow.registers_list
          }
        }

        let res = await thingsService.thing_update(this.facilityId, this.thingId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.thing = res.result;

        // window.location.href = '/facility/' + this.facilityId + '/things'
        this.$router.go(-1);
      },
      async add_diapason() {
        let new_diapason = {"start": "", "quantity": 1, "functioncode": 3}
        this.thing.shadow.registers_list.push(new_diapason)
      },
      async delete_diapason(diapason) {
        this.thing.shadow.registers_list = this.thing.shadow.registers_list.filter(function( obj ) {
          return obj !== diapason;
        });
      }
    }
  }
</script>

<style>
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .hover:hover {
    cursor: pointer;
  }
  .thing-edit-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-family: "Segoe UI Light";
    /* font-size: 20px; */
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 10px;
    padding: 10px;
  }
  .red-circle {
    float: left;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 0px;
    z-index: 999999;
    border-radius: 50px;
    color: #000000;
    /* background: url(../assets/minus-small.svg) no-repeat center center #C40D0D; */
  }
  .green-circle {
    left: 0px;
  }
</style>