import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const equipmentsService = {
  equipment_get,
  equipments_get,
  equipments_add,
  equipments_delete,
  equipment_update
};

async function equipments_get(facilityId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/equipments`, requestOptions).then(responseHandler.handleResponse);
}

async function equipments_add(facilityId, name) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "name": name
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/equipments`, requestOptions).then(responseHandler.handleResponse);
}

async function equipments_delete(facilityId, equipmentId) {
  const requestOptions = {
    method: 'Delete',
    headers: authHeader()

  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}`, requestOptions).then(responseHandler.handleResponse);
}

async function equipment_get(facilityId, equipmentId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}`, requestOptions).then(responseHandler.handleResponse);
}

async function equipment_update(facilityId, equipmentId, name) {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "name": name
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/equipments/${equipmentId}`, requestOptions).then(responseHandler.handleResponse);
}