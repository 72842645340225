import { API_LOCATION } from '../config';

export const leaverequestService = {
    leaverequest,
};

async function leaverequest(name, phone, email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "name": name,
            "phone": phone,
            "email": email
        })
    };

    return fetch(API_LOCATION + `/leaverequest`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.json().then(json => {
        const data = json;

        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 400 response returned from api
                // logout();
                // location.reload(true);
                console.log('400!!!')
            }

            const error = data.result.message;
            return Promise.reject(error);
        }

        console.log("handleResponse: ", data);
        return data.result;
    });
}