<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <div class="row">
      <div class="col-sm-6">
        <div class="card card-main transparent">
          <div class="card-body">
            <div class="header-div m-0 desktop-header">
              <div class="header-upper">
                <div class="d-flex bd-highlight">
                  <div class="back-img">
                    <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                  </div>
                  <!-- <div class="back-img">
                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                  </div> -->
                  <div class="back-img">
                    <div>
                      <img alt="repeyBase_online" src="../../assets/green_circle.svg">
                    </div>
                  </div>
                  <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
                    {{ facility.name }}
                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                    <span v-else>
                      <span v-if="thing.type.id == 'AgaveHub'">, AGAVE-4G <span class="serial-small">(№ {{ parseInt((thing.serial).substring(3), 10) }})</span></span>
                      <span v-else>
                        , {{ thing.type.id }} ({{ thing.serial }})
                      </span>
                    </span>
                  </span>

                  <div class="p-2 bd-highlight">
                    <!-- <div v-if="active_button == 'scan-devices'" class="circle-35 active scan-devices" v-on:click="active_button = 'scan-devices'; scan()"></div>
                    <div v-else class="circle-35 scan-devices" v-on:click="active_button = 'scan-devices';"></div> -->
                    <div v-if="scan_toggle === true" class="circle-35 active scan-devices" v-on:click="scan_toggle = false;"></div>
                    <div v-else class="circle-35 scan-devices" v-on:click="scan_toggle = true; settings_toggle = false;"></div>
                  </div>

                  <div class="p-2 bd-highlight">
                    <div v-if="settings_toggle === true" class="circle-35 active agave-settings" v-on:click="settings_toggle = false;"></div>
                    <div v-else class="circle-35 agave-settings" v-on:click="settings_toggle = true; scan_toggle = false;"></div>
                  </div>

                </div>
              </div>
              <div class="header-body" style="padding: 0px;">
                <div class="row">
                  <div class="col-1 d-flex align-items-center justify-content-center p-0">
                    <div v-if="thing.shadow.settings && thing.shadow.settings.network && thing.shadow.settings.network.network_mode == '4G'" v-bind:title="thing.shadow.rssi">

                      <div v-if="convertToInt(thing.shadow.rssi) >= -65">
                        <div class="signal-bars sizing-box good four-bars">
                          <div class="first-bar bar"></div>
                          <div class="second-bar bar"></div>
                          <div class="third-bar bar"></div>
                          <div class="fourth-bar bar"></div>
                        </div>
                      </div>

                      <div v-if="convertToInt(thing.shadow.rssi) < -65 && convertToInt(thing.shadow.rssi) >= -75">
                        <div class="signal-bars sizing-box good three-bars">
                          <div class="first-bar bar"></div>
                          <div class="second-bar bar"></div>
                          <div class="third-bar bar"></div>
                          <div class="fourth-bar bar"></div>
                        </div>
                      </div>

                      <div v-if="convertToInt(thing.shadow.rssi) < -75 && convertToInt(thing.shadow.rssi) >= -85">
                        <div class="signal-bars sizing-box ok two-bars">
                          <div class="first-bar bar"></div>
                          <div class="second-bar bar"></div>
                          <div class="third-bar bar"></div>
                          <div class="fourth-bar bar"></div>
                        </div>
                      </div>

                      <div v-if="convertToInt(thing.shadow.rssi) < -85 && convertToInt(thing.shadow.rssi) >= -95">
                        <div class="signal-bars sizing-box bad one-bar">
                          <div class="first-bar bar"></div>
                          <div class="second-bar bar"></div>
                          <div class="third-bar bar"></div>
                          <div class="fourth-bar bar"></div>
                        </div>
                      </div>

                      <div v-if="convertToInt(thing.shadow.rssi) < -95">
                        <div class="signal-bars sizing-box bad zero-bar">
                          <div class="first-bar bar"></div>
                          <div class="second-bar bar"></div>
                          <div class="third-bar bar"></div>
                          <div class="fourth-bar bar"></div>
                        </div>
                      </div>

                      <div class="text-muted d-flex align-items-center justify-content-center">{{ thing.shadow.settings.network.network_mode }}</div>

                    </div>
                    <div v-else-if="thing.shadow.settings && thing.shadow.settings.network && thing.shadow.settings.network.network_mode == 'wifi'" v-bind:title="thing.shadow.settings.network.wifi_ssid">
                      <div class="back-img">
                        <div>
                          <img alt="repeyBase_online" src="../../assets/wifi2.png">
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      Сеть не определена
                    </div>
                  </div>
<!--                  <div class="col-1 d-flex align-items-center justify-content-center">-->
<!--                    Режим: <br> {{ thing.shadow.mode }}-->
<!--                  </div>-->
                  <div class="col-3 d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="color: #ff0000">⚠ Хаб оффлайн</li>
                      <li style="font-size: 14px;">Последнее обновление:</li>
                      <li>{{ date }} {{ time }}</li>
                    </ul>
                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="font-size: 14px;">{{ $t('devices.hubpage.updated') }}:</li>
                      <li>{{ date }}</li>
                      <li>{{ time }}</li>
                    </ul>
                  </div>
                  <div class="col-1">
                  </div>
                  <div class="col-3 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black; border-left: 1px solid black; height: 88px;">
                    {{ $t('devices.hubpage.uptime') }}: <br> {{ thing.shadow.uptime }}
                  </div>
                  <div class="col-2 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;">
                    {{ $t('devices.hubpage.cpu_temp') }}: {{ parseInt(thing.shadow.cpu_temp) }} °C
                  </div>
                  <div class="col-2 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;">
                    <!-- Подключенные устройства: <br> пока не задано -->
                    {{ $t('devices.hubpage.mode') }}: <br>
                    <span v-if="thing.shadow.mode == 'normal'">
                      Нормальный
                    </span>
                    <span v-else>
                      {{ thing.shadow.mode }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

<!-- MOBILE ********************************************************************** -->
            <div class="header-div m-0 mobile-header" style="height: 235px !important;">
              <div class="header-upper pb-3">
                <div class="d-flex bd-highlight">
                  <div class="back-img interact-img" style="margin-top: 0px !important;">
                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
                  </div>
                  <div class="back-img interact-img" style="margin-top: 0px !important;">
                    <div>
                      <img alt="repeyBase_online" src="../../assets/green_circle.svg">
                    </div>
                  </div>
                  <span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
                    {{ facility.name }}
                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                    <span v-else>
                      <span v-if="thing.type.id == 'AgaveHub'">, AGAVE-4G <span class="serial-small">(№ {{ parseInt((thing.serial).substring(3), 10) }})</span></span>
                      <span v-else>
                        , {{ thing.type.id }} ({{ thing.serial }})
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="header-body" style="padding: 0px;">
                <div class="d-flex">

                  <div v-if="thing.shadow.settings && thing.shadow.settings.network && thing.shadow.settings.network.network_mode == '4G'" v-bind:title="thing.shadow.rssi" class="m-2">

                    <div v-if="convertToInt(thing.shadow.rssi) >= -65">
                      <div class="signal-bars sizing-box good four-bars">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                      </div>
                    </div>

                    <div v-if="convertToInt(thing.shadow.rssi) < -65 && convertToInt(thing.shadow.rssi) >= -75">
                      <div class="signal-bars sizing-box good three-bars">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                      </div>
                    </div>

                    <div v-if="convertToInt(thing.shadow.rssi) < -75 && convertToInt(thing.shadow.rssi) >= -85">
                      <div class="signal-bars sizing-box ok two-bars">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                      </div>
                    </div>

                    <div v-if="convertToInt(thing.shadow.rssi) < -85 && convertToInt(thing.shadow.rssi) >= -95">
                      <div class="signal-bars sizing-box bad one-bar">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                      </div>
                    </div>

                    <div v-if="convertToInt(thing.shadow.rssi) < -95">
                      <div class="signal-bars sizing-box bad zero-bar">
                        <div class="first-bar bar"></div>
                        <div class="second-bar bar"></div>
                        <div class="third-bar bar"></div>
                        <div class="fourth-bar bar"></div>
                      </div>
                    </div>

                    <div class="text-muted d-flex align-items-center justify-content-center">{{ thing.shadow.settings.network.network_mode }}</div>

                  </div>
                  <div v-else-if="thing.shadow.settings && thing.shadow.settings.network && thing.shadow.settings.network.network_mode == 'wifi'" class="m-2">
                    <div class="back-img">
                      <div>
                        <img alt="repeyBase_online" src="../../assets/wifi.png">
                      </div>
                    </div>
                  </div>
                  <div v-else class="m-2">
                    Сеть не определена
                  </div>

                  <div class="d-flex align-items-center justify-content-middle p-2" style="border-left-color: black; border-left: 1px solid black;">
                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="color: #ff0000">⚠ Хаб оффлайн</li>
                      <li style="font-size: 14px;">Последнее обновление:</li>
                      <li>{{ date }} {{ time }}</li>
                    </ul>
                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="font-size: 14px;">Обновлено:</li>
                      <li>{{ date }}</li>
                      <li>{{ time }}</li>
                    </ul>
                  </div>


                  <div class="ml-auto d-flex align-items-center justify-content-center">
                    <div class="p-2 bd-highlight">
                      <div v-if="scan_toggle === true" class="circle-35 active scan-devices" v-on:click="scan_toggle = false;"></div>
                      <div v-else class="circle-35 scan-devices" v-on:click="scan_toggle = true;"></div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="header-body" style="border-top: 1px solid black;">
                <div class="d-flex justify-content-between mt-2">

                  <div class="d-flex flex-column align-items-center justify-content-center ml-2 pr-1 pl-1">
                    {{ $t('devices.hubpage.uptime') }}: <br> {{ (thing.shadow.uptime).slice(0, 4) }}
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center pr-1 pl-1" style="border-left-color: black;">
                    <!-- Сеть: <br> <span v-if="thing.shadow.settings && thing.shadow.settings.network && thing.shadow.settings.network.network_mode">{{ thing.shadow.settings.network.network_mode }}</span> -->
                    {{ $t('devices.hubpage.cpu_temp') }}: {{ parseInt(thing.shadow.cpu_temp) }} °C
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center mr-2 pr-1 pl-1" style="border-left-color: black;">
                    <!-- Подключенные устройства: <br> пока не задано -->
                    {{ $t('devices.hubpage.mode') }}: <br>
                    <span v-if="thing.shadow.mode == 'normal'" class="nowrap-span">
                      Нормальный
                    </span>
                    <span v-else>
                      {{ thing.shadow.mode }}
                    </span>
                  </div>

                </div>
              </div>
            </div>
<!-- MOBILE ********************************************************************** -->

            <div class="row mt-3">
              <div class="col-12 col-md-4 pl-0 pr-0" v-for="(thing, key) in thinglist" v-bind:key="thing.id" :href="'/'">
                <!-- <a class="div-link" :href="'/facility/' + facility.id + '/things/' + thing.id"> -->
                <a class="div-link">
                  <div class="hub-things-div pb-2">
                    <div class="d-flex justify-content-between pl-2 pr-2 pt-2 mb-2">
                      <span v-if="thing.shadow.online" style="color: #8EDC28;">{{ $t('devices.hubpage.online') }}</span>
                      <span v-else style="color: grey;">{{ $t('devices.hubpage.offline') }}</span>
                      <!-- <span><div class="circle-30 edit"></div></span> -->
                      <!-- <div class="expand_dots"></div> -->


                      <div class="btn-group">
                        <div class="expand_dots" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a :href="'/facility/' + facility.id + '/things/' + thing.id" class="dropdown-item">{{ $t('devices.hubpage.show') }}</a>
                          <a :href="'/facility/' + facility.id + '/things/' + thing.id + '/edit'" class="dropdown-item">{{ $t('devices.hubpage.edit') }}</a>
                          <button class="dropdown-item" type="button" @click.prevent="deleteThing(thing.id)" style="cursor: pointer;">{{ $t('devices.hubpage.delete') }}</button>
                        </div>
                      </div>


                    </div>
                    <div class="row align-items-center">
                      <div class="col-5 pl-2 mr-0 pr-1" style="font-size: 12px;">{{ $t('devices.hubpage.type') }}:</div>
                      <div class="col-7 m-0 pr-0 pl-1">{{ getDeviceTypeName(thing.type.id) }}</div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-5 pl-2 mr-0 pr-1" style="font-size: 12px;">{{ $t('devices.hubpage.serial') }}:</div>
                      <div class="col-7 m-0 pr-0 pl-1">{{ thing.number }}</div>
                    </div>
                    <div class="row align-items-center" v-if="thing.type.id == 'ModbusDevice'">
                      <div class="col-5 pl-2 mr-0 pr-1" style="font-size: 12px;">{{ $t('devices.hubpage.name') }}:</div>
                      <div class="col-7 m-0 pr-0 pl-1">{{ thing.name }}</div>
                    </div>
                    <div class="row align-items-center unselectable" v-if="thing.shadow.device_info && thing.shadow.device_info.program_version">
                      <div class="col-5 pl-2 mr-0 pr-1" style="font-size: 12px;">{{ $t('devices.hubpage.po_version') }}:</div>
                      <div class="col-7 m-0 pr-0 pl-1" style="letter-spacing: 3px;" v-if="thing.type.id != 'RepeyBase' && thing.type.id != 'Laurel'">{{ thing.shadow.device_info.program_version.toString().charAt(0) }}.{{ thing.shadow.device_info.program_version.toString().charAt(1) }}{{ thing.shadow.device_info.program_version.toString().charAt(2) }}</div>
                      <div class="col-7 m-0 pr-0 pl-1" style="letter-spacing: 3px;" v-if="thing.type.id == 'RepeyBase' && thing.shadow.device_info.first_version_number_0x0122_0x0123 && thing.shadow.device_info.second_version_number_0x0124_0x0125 && thing.shadow.device_info.third_version_number_0x0126_0x0127">{{ thing.shadow.device_info.first_version_number_0x0122_0x0123 }}.{{ thing.shadow.device_info.second_version_number_0x0124_0x0125 }}.{{ thing.shadow.device_info.third_version_number_0x0126_0x0127 }} 
                        <span v-if="thing.signalling_po" class="signalling_po" style="color: #EBEB14" @click.prevent="showThingSignallingInfo(key)">⚠</span>
                        <div class="battery-info-div" v-if="thing.toggleSignallingInfo">
                          <div style="letter-spacing: 0px;">
                            {{ thing.signallingMessage }}
                          </div>
                        </div>
                      </div>
                      <div class="col-7 m-0 pr-0 pl-1" style="letter-spacing: 3px;" v-if="thing.type.id == 'Laurel'">{{ thing.shadow.device_info.program_version.toString().charAt(0) }}.{{ thing.shadow.device_info.program_version.toString().charAt(1) }}{{ thing.shadow.device_info.program_version.toString().charAt(2) }} 
                        <span v-if="thing.signalling_po" class="signalling_po" style="color: #EBEB14" @click.prevent="showThingSignallingInfo(key)">⚠</span>
                        <div class="battery-info-div" v-if="thing.toggleSignallingInfo">
                          <div style="letter-spacing: 0px;">
                            {{ thing.signallingMessage }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr style="margin: 0px; margin-top: 4px; margin-bottom: 4px; background: #202020;" class="ml-2 mr-2" v-if="thing.shadow.interface">
                    <div class="row m-0 p-0" v-if="thing.shadow.interface">
                      <div class="col-4 pl-2" style="font-size: 12px;">{{ $t('devices.hubpage.interface') }}:</div>
                      <div class="col-8">
                        <div class="row align-items-center">
                          <div class="col-5 m-0 p-0" style="font-size: 12px;">{{ $t('devices.hubpage.port') }}:</div>
                          <!-- <div class="col-7 m-0 p-0">{{ thing.shadow.interface.port }}</div> -->
                          <div v-if="thing.shadow.interface.port" class="col-7 m-0 p-0" style="font-size: 12px;">{{ getDevicePortName(thing.shadow.interface.port) }}</div>
                        </div>

                        <div class="row align-items-center">
                          <div class="col-5 m-0 p-0" style="font-size: 12px;">{{ $t('devices.hubpage.address') }}:</div>
                          <div v-if="thing.shadow.interface.slave_address" class="col-7 m-0 p-0" style="font-size: 12px;">{{ thing.shadow.interface.slave_address }}</div>
                        </div>

                        <div class="row align-items-center">
                          <div class="col-5 m-0 p-0" style="font-size: 12px;">{{ $t('devices.hubpage.speed') }}:</div>
                          <div v-if="thing.shadow.interface.baudrate" class="col-7 m-0 p-0" style="font-size: 12px;">{{ thing.shadow.interface.baudrate }}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col-12 col-md-4 pl-0 pr-0" v-for="thing in thinglist" v-bind:key="thing.id">
                <div class="hub-things-div p-2">
                  <div class="subject-logo">
                    <img alt="device" src="../../assets/repey2.png" style="width: 80%;">
                  </div>
                  <div class="subject-info pb-3">
                    <span class="subject-info-values"><span class="small-text text-muted">Устройство:</span> {{ getDeviceTypeName(thing.type.id) }}</span>
                    <hr style="margin: 0px; margin-top: 7px; margin-bottom: 7px;">
                    <span class="subject-info-values"><span class="small-text text-muted">Идентификатор:</span> {{ thing.serial }}</span>
                    <hr style="margin: 0px; margin-top: 7px; margin-bottom: 7px;">
                    <span class="subject-info-values"><span class="small-text text-muted">Название:</span> {{ thing.name }}</span>
                    <hr style="margin: 0px; margin-top: 7px; margin-bottom: 7px;">
                    <span class="subject-info-values" v-if="thing.shadow.interface && thing.shadow.interface.port"><span class="small-text text-muted">Интерфейс:</span> {{ thing.shadow.interface.port }} </span>
                    <span class="subject-info-values" v-else><span class="small-text text-muted">Интерфейс:</span> неизвестно</span>
                  </div>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="card card-second transparent">
          <div class="card-body">
            <div v-if="scan_toggle === true">
              <div class="scan-div m-0 p-3">
                <span style="font-size: 22px; font-weight: lighter;">{{ $t('devices.hubpage.pairing.title') }}</span>
                <hr>
                <p><span class="small-text">{{ $t('devices.hubpage.pairing.info') }}</span></p>
                <p><span class="small-text text-muted">{{ $t('devices.hubpage.pairing.info-secondary') }}</span></p>
                <div v-if="pairing_mode == false">
                  <div @click.prevent="runPairing" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 60px;">{{ $t('devices.hubpage.pairing.button') }}</div>
                </div>
                <div v-else>
                  <div class="row m-3" v-if="pairing_status == false">
                      <vue-simple-spinner :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>

                      <p class="float-left m-3">{{ $t('devices.hubpage.pairing.running') }}</p>

                  </div>
                  <div class="row m-3" v-else>
                    <p class="float-left m-3">{{ $t('devices.hubpage.pairing.ending') }}</p>
                  </div>
                </div>

                <hr>

                <!-- <span style="font-size: 22px; font-weight: lighter;">Ручное сопряжение устройств Modbus</span> -->

                <div class="d-flex justify-content-between">
                  <a style="font-size: 22px; font-weight: lighter; text-decoration: none; color: #ffffff;" data-toggle="collapse" href="#collapsePair" role="button" aria-expanded="false" aria-controls="collapsePair" v-on:click="collapse_toggle = !collapse_toggle;">
                    {{ $t('devices.hubpage.pairing.manual') }}
                  </a>
                  <a v-if="collapse_toggle === true" data-toggle="collapse" href="#collapsePair" role="button" aria-expanded="false" aria-controls="collapsePair"><div class="circle-30 down-equipment" v-on:click="collapse_toggle = false;" style="float: right;"></div></a>
                  <a v-else data-toggle="collapse" href="#collapsePair" role="button" aria-expanded="false" aria-controls="collapsePair"><div class="circle-30 left-equipment" v-on:click="collapse_toggle = true;" style="float: right;"></div></a>
                </div>
                <hr>
                <!-- <p><span class="small-text">Добавление устройств вручную:</span></p> -->

                <div class="collapse" id="collapsePair">
                  <div class="row">
                    <div class="col-3">
                      <span>Название устройства</span>
                    </div>
                    <div class="col-9">
                      <input type="text" v-model="new_device.name" class="myform" style="font-size: 16px; color: fff; width: 250px;" placeholder="Название" maxlength="150"/>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-3">
                      <span>Серийный номер</span>
                    </div>
                    <div class="col-9">
                      <input type="text" v-model="new_device.serial" class="myform" style="font-size: 16px; width: 250px;" placeholder="Серийный номер" maxlength="150"/>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3">Интерфейс:</div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Порт</span>
                    </div>
                    <div class="col-8">
                      <!-- <input type="text" v-model="new_device.interface.port" class="myform" style="font-size: 16px;" placeholder="Название" maxlength="150"/> -->
                      <select class="myform" style="font-size: 16px; width: 180px;" v-model="new_device.interface.port">
                        <option v-for="option in port_list" v-bind:key="option.id" v-bind:value="option.id">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Адрес Modbus</span>
                    </div>
                    <div class="col-8">
                      <input type="number" v-model="new_device.interface.slave_address" class="myform" style="font-size: 16px;" placeholder="Название" maxlength="150"/>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Скорость, бод</span>
                    </div>
                    <div class="col-8">
                      <!-- <input type="text" v-model="new_device.interface.baudrate" class="myform" style="font-size: 16px;" placeholder="baudrate" maxlength="150"/> -->

                      <select class="myform" style="font-size: 16px; width: 180px;" v-model="new_device.interface.baudrate">
                        <option v-for="option in baudrate_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.id }}</option>
                      </select>

                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Размер байта</span>
                    </div>
                    <div class="col-8">
                      <!-- <input type="text" v-model="new_device.interface.bytesize" class="myform" style="font-size: 16px;" placeholder="baudrate" maxlength="150"/> -->
                      <select class="myform" style="font-size: 16px; width: 180px;" v-model="new_device.interface.bytesize">
                        <option v-for="option in bytesize_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Четность</span>
                    </div>
                    <div class="col-8">
                      <!-- <input type="text" v-model="new_device.interface.parity" class="myform" style="font-size: 16px;" placeholder="parity" maxlength="150"/> -->
                      <select class="myform" style="font-size: 16px; width: 180px;" v-model="new_device.interface.parity">
                        <option v-for="option in parity_list" v-bind:key="option.id" v-bind:value="option.id">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Стоп-бит</span>
                    </div>
                    <div class="col-8">
                      <!-- <input type="text" v-model="new_device.interface.stopbits" class="myform" style="font-size: 16px;" placeholder="stopbits" maxlength="150"/> -->
                      <select class="myform" style="font-size: 16px; width: 180px;" v-model="new_device.interface.stopbits">
                        <option v-for="option in stopbits_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-1">
                    </div>
                    <div class="col-3">
                      <span>Таймаут</span>
                    </div>
                    <div class="col-8">
                      <input type="number" step="0.01" min="0.05" max="3.00" v-model="new_device.interface.timeout" class="myform" style="font-size: 16px; width: 180px;" placeholder="timeout" maxlength="150"/>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mb-2">
                    <span class="ml-3">Диапазоны опроса:</span>
                    <span class=""><div @click.prevent="add_diapason"><div class="green-circle"></div></div></span>
                  </div>

                  <div v-for="diapason in new_device.registers_list" :key="diapason.start">
                    <div class="d-flex justify-content-between pl-5 mb-2">
                      <span class="ml-3 mr-1">Адрес</span>
                      <input type="text" v-model="diapason.start" class="myform" style="font-size: 16px; max-width: 180px; min-width: 60px;" placeholder="start" maxlength="150"/>
                      <span class="ml-3 mr-1">Количество</span>
                      <input type="number" min="1" max="300" v-model="diapason.quantity" class="myform" style="font-size: 16px; min-width: 60px; max-width: 160px;" placeholder="quantity" maxlength="3"/>
                      <span class="ml-3 mr-1">Функция</span>
                      <!-- <input type="number" min="1" max="300" v-model="diapason.functioncode" class="myform" style="font-size: 16px; width: 60px;" placeholder="functioncode" maxlength="10"/> -->

                      <select class="myform" style="font-size: 16px; min-width: 60px;" v-model="diapason.functioncode">
                        <option v-for="option in functioncode_list" v-bind:key="option.id" v-bind:value="option.text">{{ option.text }}</option>
                      </select>

                      <div class="red-circle ml-3" @click.prevent="delete_diapason(diapason)"></div>
                    </div>
                  </div>

                  <div v-if="!manual_pairing_state" @click.prevent="addThing" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 76px;">{{ $t('devices.hubpage.pairing.add') }}</div>
                  <div v-else class="m-3"><span>Устройство добавлено, и скоро отобразится в блоке сопряженных устройств...</span></div>

                </div>

                <!-- <div class="row mt-1">
                  <div class="col-1">
                  </div>
                  <div class="col-2">
                    <span>start</span>
                  </div>
                  <div class="col-3">
                    <input type="text" v-model="new_device.registers_list.start" class="myform" style="font-size: 16px;" placeholder="start" maxlength="150"/>
                  </div>
                  <div class="col-2">
                    <span>quantity</span>
                  </div>
                  <div class="col-3">
                    <input type="text" v-model="new_device.registers_list.quantity" class="myform" style="font-size: 16px;" placeholder="quantity" maxlength="150"/>
                  </div>
                  <div class="col-1">
                    <span>+</span>
                  </div> 
                </div>-->
              </div>
            </div>

            <div v-if="settings_toggle === true">
              <div class="scan-div m-0 p-3">
                <span style="font-size: 22px; font-weight: lighter;">{{ $t('devices.hubpage.settings.title') }}</span>
                <hr>
                <p><span class="small-text">{{ $t('devices.hubpage.settings.reset_title') }}</span></p>
                <p><span class="small-text text-muted">{{ $t('devices.hubpage.settings.reset_info') }}</span></p>
                <div v-if="reinit_db == false">
                  <div @click.prevent="runDBReset" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 75px;">{{ $t('devices.hubpage.settings.db_reset') }}</div>
                </div>
                <div v-else div class="row m-3">
                  <div v-if="reinit_db_status == false">
                    <vue-simple-spinner :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                    <p class="float-left m-3">Запрос на сброс БД AGAVE-4G...</p>
                  </div>
                  <div v-else>
                    <span>Команда на сброс успешно послана.</span>
                  </div>
                </div>
                <hr>
                <p><span class="small-text">{{ $t('devices.hubpage.settings.reboot_title') }}</span></p>
                <p><span class="small-text text-muted">{{ $t('devices.hubpage.settings.reboot_info') }}</span></p>
                <div v-if="reboot == false">
                  <div @click.prevent="runReboot" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 65px;">{{ $t('devices.hubpage.settings.reboot') }}</div>
                </div>
                <div v-else div class="row m-3">
                  <div v-if="reboot_status == false">
                    <vue-simple-spinner :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                    <p class="float-left m-3">Запрос на перезагрузку AGAVE-4G...</p>
                  </div>
                  <div v-else>
                    <span>Команда на перезапуск успешно послана.</span>
                  </div>
                </div>
                <hr>
                <p><span class="small-text">{{ $t('devices.hubpage.settings.update_title') }}</span></p>
                <p><span class="small-text text-muted">{{ $t('devices.hubpage.settings.dev_soft_version') }}: {{ thing.shadow.software_version }}</span></p>
                <p><span class="small-text text-muted">{{ $t('devices.hubpage.settings.new_soft_version') }}: {{ new_firmware_version }}</span></p>
                <div v-if="new_firmware_version">
                  <div v-if="!unsupported_versions.includes(thing.shadow.software_version)">
                    <div v-if="thing.shadow.software_version != new_firmware_version">
                      <div v-if="firmware_update_mode == false">
                        <span>{{ $t('devices.hubpage.settings.firmware_available') }}</span>
                        <div @click.prevent="runFirmwareUpdate" class="green-button mt-3" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 65px;">{{ $t('devices.hubpage.settings.update') }}</div>
                      </div>
                      <div v-else div class="row m-3">
                        <div v-if="firmware_status == false">
                          <vue-simple-spinner :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                          <p class="float-left m-3">Запуск процесса обновления прошивки AGAVE-4G...</p>
                        </div>
                        <div v-else>
                          <span>Процесс обновления прошивки AGAVE-4G успешно запущен. Во время обновления не выключайте устройство.</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span>{{ $t('devices.hubpage.settings.firmware_not_needed') }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>Версия вашего устройства не поддерживает OTA обновления.</span>
                  </div>
                </div>
                <div v-else>
                  <span>Не найдено обновлений на сервере.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {facilitiesService, thingsService} from "../../_services";
  import VueSimpleSpinner from 'vue-simple-spinner';
  import Vue from "vue";
  

  export default {
    name: 'AgaveHub',
    props: ['facilityId', 'thingId'],
    components: {
      VueSimpleSpinner,
    },
    data () {
      return {
        // facilityId: this.$route.params.facility_id,
        // thingId: this.$route.params.thing_id,
        preloading: false,
        thing: '',
        facility: '',
        date: "",
        time: "",
        thinglist: [],
        // active_button: ""
        scan_toggle: false,
        settings_toggle: false,
        pairing_mode: false,
        firmware_reqId: '',
        pairing_reqId: '',
        new_firmware_version: "",
        firmware_update_mode: false,
        unsupported_versions: ['1.0.0.0', '1.0.0', '1.0.1', '1.1.0', '1.1.1', '1.2.0', '1.2.1', '1.2.2', '1.3.0', '1.4.0', '1.4.1', '1.4.2', '1.4.3'],
        firmware_status: false,
        pairing_status: false,
        reboot: false,
        reinit_db: false,
        reboot_status: false,
        reinit_db_status: false,
        reinit_db_reqId: '',
        reboot_reqId: '',
        manual_pairing_state: false,
        new_device: { 
          "interface": {
            "port": "/dev/ttyS1",
            "baudrate": 9600,
            "parity": "N",
            "stopbits": 1,
            "slave_address": 1,
            "bytesize": 8,
            "timeout": 0.05
          },
          "registers_list": [{"start": "0x0100", "quantity": 2, "functioncode": 3}] },
        port_list: [
          // {id: "/dev/ttyUSB0", text: "USB"},
          {id: "/dev/serialUSB", text: "USB"},
          {id: "/dev/ttyS1", text: "COM1"},
          {id: "/dev/ttyS2", text: "COM2"},
        ],
        baudrate_list: [
          {id: "2400", text: "2400"},
          {id: "4800", text: "4800"},
          {id: "9600", text: "9600"},
          {id: "19200", text: "19200"},
          {id: "38400", text: "38400"},
          {id: "57600", text: "57600"},
          {id: "115200", text: "115200"},
        ],
        parity_list: [
          {id: "N", text: "NONE"},
          {id: "E", text: "EVEN"},
          {id: "O", text: "ODD"},
          {id: "M", text: "MARK"},
          {id: "S", text: "SPACE"},
        ],
        bytesize_list: [
          {id: "5", text: "5"},
          {id: "6", text: "6"},
          {id: "7", text: "7"},
          {id: "8", text: "8"},
        ],
        stopbits_list: [
          {id: "1", text: "1"},
          {id: "1.5", text: "1.5"},
          {id: "2", text: "2"},
        ],
        functioncode_list: [
          {id: 2, text: 2},
          {id: 3, text: 3},
        ],
        collapse_toggle: false
      }
    },
    async created() {
      this.preloading = true;
      await this.init();
      this.preloading = false;
      this.getNow();

      setInterval(function () {
        this.getNow();
        // this.setHeight();
        this.getThing();
        this.getThingList();
      }.bind(this), 10000);
    },
    methods: {
      async init() {
        console.log('Rendering "AgaveHub" component')
        await this.getThing();
        await this.getFacility();
        await this.getThingList();
        await this.getFirmwareVersion();
      },
      async getThing() {
        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        // console.log(this.thing);
      },
      async getFacility() {
        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facility = res.result.facility;
      },
      getNow: function() {
        let today = new Date(this.thing.updated + 'Z');
        this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      },
      convertToInt(rssi) {
        return parseInt(rssi.slice(0, -5));
      },
      async getThingList() {
        // this.thinglist = []

        let res = await thingsService.things_get(this.facilityId, 0, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thinglist = res.result.items;

        // console.log('ThingList: ');
        // console.log(this.thinglist);
        await this.set_signalling_po()
      },
      async set_signalling_po() {
        for (let thing in this.thinglist) {
          // console.log(thing)
          if (this.thinglist[thing].type.id == 'RepeyBase') {
            // console.log('repey!')
            // if (this.thinglist[thing].shadow.device_info.first_version_number_0x0122_0x0123 && this.thinglist[thing].shadow.device_info.second_version_number_0x0124_0x0125) {
            //   if ((this.thinglist[thing].shadow.device_info.first_version_number_0x0122_0x0123).toFixed(0) >= 1 && (this.thinglist[thing].shadow.device_info.second_version_number_0x0124_0x0125).toFixed(0) >= 3) {
            //     // console.log('Для полноценной поддержки требуется обновление ПО AGAVE-4G')
            //     Vue.set(this.thinglist[thing], 'signalling_po', true);
            //     Vue.set(this.thinglist[thing], 'signallingMessage', 'Для полноценной поддержки требуется обновление ПО AGAVE-4G');
            //   }
            //   else if ((this.thinglist[thing].shadow.device_info.first_version_number_0x0122_0x0123).toFixed(0) <= 1 && (this.thinglist[thing].shadow.device_info.second_version_number_0x0124_0x0125).toFixed(0) < 1) {
            //     // console.log('Для полноценной поддержки требуется обновление ПО Репей')
            //     Vue.set(this.thinglist[thing], 'signalling_po', true);
            //     Vue.set(this.thinglist[thing], 'signallingMessage', 'Для полноценной поддержки требуется обновление ПО Репей');
            //   }
            // }
            console.log('RepeyBase')
          } else if (this.thinglist[thing].type.id == 'Laurel') {
            // console.log('lavr!')
            if (this.thinglist[thing].shadow.device_info.program_version) {
              if ((this.thinglist[thing].shadow.device_info.program_version).toString().charAt(0) >= 1 && (this.thinglist[thing].shadow.device_info.program_version).toString().charAt(1) >= 0 && (this.thinglist[thing].shadow.device_info.program_version).toString().charAt(2) >= 6) {
                Vue.set(this.thinglist[thing], 'signalling_po', true);
                Vue.set(this.thinglist[thing], 'signallingMessage', 'Для полноценной поддержки требуется обновление ПО AGAVE-4G');
              } else if ((this.thinglist[thing].shadow.device_info.program_version).toString().charAt(0) <= 1 && (this.thinglist[thing].shadow.device_info.program_version).toString().charAt(1) == 0  && (this.thinglist[thing].shadow.device_info.program_version).toString().charAt(2) < 5) {
                Vue.set(this.thinglist[thing], 'signalling_po', true);
                Vue.set(this.thinglist[thing], 'signallingMessage', 'Для полноценной поддержки требуется обновление ПО LAUREL-12');
              }
            }
          } else {
            // console.log(this.thinglist[thing].type.id);
          }
        }
      },
      showThingSignallingInfo(key) {
        Vue.set(this.thinglist[key], 'toggleSignallingInfo', !this.thinglist[key].toggleSignallingInfo);
      },
      async getFirmwareVersion() {
        let res = await thingsService.get_firmware_version(this.facility.id, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.new_firmware_version = res.result.version
      },
      async runFirmwareUpdate() {
        this.firmware_update_mode = true;

        let res = await thingsService.firmware_update(this.facility.id, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.firmware_reqId = res.result.reqId

        console.log(this.firmware_reqId)

        await this.thing_firmware_status()
      },
      async thing_firmware_status() {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let result;
        do {
          await delay(2000);
          let res = await thingsService.firmware_update_status(this.facilityId, this.thingId, this.firmware_reqId)

          if (res.error) {
            console.error(res.error);

            return;
          }

          result = res.result.state;
        } while(result != 'RESOLVED');

        this.firmware_status = true;
      },
      scan() {
        alert('scan пока не доступен');
      },
      async runPairing() {
        this.pairing_mode = true;

        let res = await thingsService.things_pairing(this.facility.id, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.pairing_reqId = res.result.reqId

        await this.thing_pairing_status()
      },
      async addThing() {
        this.manual_pairing_state = false;

        console.log(this.new_device);

        let res = await thingsService.thing_add(this.facility.id, this.thing.id, this.new_device);

        if (res.error) {
          console.error(res.error);

          this.manual_pairing_state = false;

          return;
        }

        console.log(res);

        this.manual_pairing_state = true;
      },
      async runDBReset() {
        this.reinit_db = true;

        let res = await thingsService.things_reset_db(this.facility.id, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.reinit_db_reqId = res.result.reqId

        await this.thing_reinit_db_status()
      },
      async runReboot() {
        this.reboot = true;

        let res = await thingsService.things_reboot(this.facility.id, this.thing.id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.reboot_reqId = res.result.reqId

        await this.thing_reboot_status()
      },
      async thing_reinit_db_status() {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let res;
        do {
          await delay(2000);
          let result = await thingsService.thing_reinit_db_status(this.facility.id, this.thing.id, this.reinit_db_reqId)
          res = result.result.state;
        } while(res != 'RESOLVED');

        this.reinit_db_status = true;
      },
      async thing_reboot_status() {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let res;
        do {
          await delay(2000);
          let result = await thingsService.thing_reboot_status(this.facility.id, this.thing.id, this.reboot_reqId)
          res = result.result.state;
        } while(res != 'RESOLVED');

        this.reboot_status = true;
      },
      async thing_pairing_status() {
        function delay(t) {
          return new Promise(resolve => setTimeout(resolve, t));
        }

        let res;
        do {
          await delay(2000);
          let result = await thingsService.thing_pairing_status(this.facility.id, this.thing.id, this.pairing_reqId)
          res = result.result.state;
        } while(res != 'RESOLVED');

        this.pairing_status = true;
      },
      getDeviceTypeName(thing_type) {
        let values = {
          'AgaveHub': 'AGAVE-4G',
          'RepeyBase': 'РЕПЕЙ',
          'Laurel': 'LAUREL-12',
          'IrisMi': 'ИРИС-МИ',
          'IrisO': 'ИРИС-О',
          'ModbusDevice': 'Устройство ModBus',
          'BZP02': 'БЗП-02',
          'BZP01': 'БЗП-01',
          'Lyutik': 'ЛЮТИК',
          'Cactus': 'Кактус',
          'AlteyUZT': 'Алтей-УЗТ',
          'AlteyOZT': 'Алтей-ОЗТ',
          'AlteyBZP': 'Алтей-БЗП',
          'AlteyPLK': 'Алтей-ПЛК',
          'LimePlus': 'Лайм+'
        }

        if (values[thing_type]) {
          return values[thing_type];
        } else {
            return 'Неизвестное устройство'
        }
      },
      getDevicePortName(port) {
        let values = {
          '/dev/ttyS1': 'RS-485-1',
          '/dev/ttyS2': 'RS-485-2',
          '/dev/serialUSB': 'USB',
        }

        if (values[port]) {
          return values[port];
        } else {
            return 'Неизвестный порт'
        }
      },      
      async add_diapason() {
        // alert('add diapason');
        let new_diapason = {"start": "", "quantity": 1, "functioncode": 3}
        this.new_device.registers_list.push(new_diapason)
      },
      async delete_diapason(diapason) {
        this.new_device.registers_list = this.new_device.registers_list.filter(function( obj ) {
          return obj !== diapason;
        });
      },
      async deleteThing(thingId) {
        let res = await thingsService.thing_delete(this.facilityId, thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res);

        // await this.getEquipments();
        await this.getThingList();
      },
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../../assets/SnowstormLight.woff2');
  }
  .card {
    margin-top: 20px;
  }
  .card-second {
  }
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  /*.subject-div1 {*/
  /*  background: #202020 0% 0% no-repeat padding-box;*/
  /*  box-shadow: 0px 3px 6px #00000029;*/
  /*  border-radius: 10px;*/
  /*  opacity: 1;*/
  /*  height: 90px;*/
  /*  !* width: 180px; *!*/
  /*  margin: 10px;*/
  /*  font-family: "Segoe UI Light";*/
  /*  font-size: 10px;*/
  /*  letter-spacing: 0px;*/
  /*  color: #FFFFFF;*/
  /*  opacity: 1;*/
  /*  !*padding: 10px;*!*/
  /*  display: flex;*/
  /*  flex-flow: row nowrap;*/
  /*  cursor: pointer;*/
  /*  position: relative;*/
  /*  z-index: 99;*/
  /*}*/
  /*.subject-div1:hover {*/
  /*  background: #2E2E2E 0% 0% no-repeat padding-box;*/
  /*}*/
  .header-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 165px;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .header-upper {
    border-bottom: 1px solid black;
    padding: 10px
  }
  .back-img {
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 5px;
  }
  .back-img img{
    max-width:100%;
    height:auto;
  }
  .back-img-battery img{
    width:30px;
    height:auto;
  }
  .header-body {
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 20px;*/
    padding: 0px;
  }
  .subject-logo {
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subject-info {
    flex-grow: 7;
    font-size: 16px;
    padding: 0px;
    padding-top: 15px;
  }
  .my-bar {
    min-width: 650px;
    min-height: 350px;
    max-width: 950px;
    max-height: 820px;
    background-color: #202020;
    border-radius: 10px;
  }
  .bar-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-right: 40px;
  }
  .circle-35 {
    background: #8EDC28;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .circle-35.sort-linear {
    background: url(../../assets/sort_linear.svg) no-repeat center center #000;
  }
  .circle-35.sort-linear.active {
    background: url(../../assets/sort_linear_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-bars {
    background: url(../../assets/sort_bars.svg) no-repeat center center #000;
  }
  .circle-35.sort-bars.active {
    background: url(../../assets/sort_bars_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-cols {
    background: url(../../assets/sort_cols.svg) no-repeat center center #000;
  }
  .circle-35.sort-cols.active {
    background: url(../../assets/sort_cols_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.sort-linear {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-bars {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-cols {
    opacity: 0.5;
    cursor: pointer;
  }
  .background-text {
    z-index: 1;
  }
  .col-2 {
    display: flex;
    flex-direction: row;
  }
  .small-info {
    position: relative;
    font-size: 14px;
    font-family: "Segoe UI Light";
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    display: block;
  }
  @media screen and (max-width: 1366px) {
    .small-info {
      width: 100px;
    }
    .col-md-3 {
      padding: 0px;
    }
  }
  .subject-info-values {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /*.date-picker {*/
  /*  position: absolute;*/
  /*  float: right;*/
  /*  right: 0px;*/
  /*  top: 310px;*/
  /*  width: 50px;*/
  /*  height: 166px;*/
  /*  border-radius: 50px;*/
  /*  background-color: #000000;*/
  /*  color: #FFFFFF;*/
  /*}*/
  .circle-50 {
    background: #000000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
  }
  .circle-50:hover {
    background-color: rgba(142,220,40,0.1);
  }
  .circle-50.active {
    background: #8EDC28;
  }
  .accumulator-tag {
    /*border: 1px solid black;*/
    float: right;
    position: absolute;
    right: 15px;
    height: 15px;
    font-size: 14px;
    font-family: "Segoe UI";
    color: #FFFFFF;
    letter-spacing: 0.7px;
    margin-top: 5px;
  }
  .accumulator-info {
    position: absolute;
    left: 0px;
    right: 0px;
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 105px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: -5;
    width: auto;
    padding-bottom: 30px;
  }
  #bottom {
    position:relative;
    top: 90px;
  }
  .accumulator-active {
    position: absolute;
    left: 0px;
    right: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 90px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: -4;
    width: auto;
    border: 1px solid gray;
    padding-bottom: 30px;
  }
  .chart-minmax {
    position: absolute;
    right: 80px;
    /*top: 35px;*/
    font-family: "Segoe UI";
    font-size: 14px;
    color: #7e7e7e;
  }
  .fill-battery {
    background-color: #202020;
    width: 30px;
    height:30px;
    position: absolute;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 5;
  }
  .fill-battery-offline {
    background-color: #202020;
    width: 25px;
    height:35px;
    position: absolute;
    margin-top: 14px;
    margin-left: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Snowstorm Light";
    font-size: 22px;
    color: #fff;
  }
  /*.name-span {*/
  /*  overflow: hidden;*/
  /*  text-overflow: ellipsis;*/
  /*  white-space: nowrap;*/
  /*}*/
  .nowrap-span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  * { box-sizing: border-box; }

  .sizing-box {
    height: 50px;
    width: 50px;
  }

  .signal-bars {
    display: inline-block;
  }

  .signal-bars .bar {
    width: 22%;
    margin-left: 2%;
    min-height: 20%;
    display: inline-block;
  }
  .signal-bars .bar.first-bar  { height: 20%; }
  .signal-bars .bar.second-bar { height: 40%; }
  .signal-bars .bar.third-bar  { height: 60%; }
  .signal-bars .bar.fourth-bar { height: 80%; }

  .good .bar {
    background-color: #8EDC28;
    border: thin solid #005030;
  }
  .bad .bar {
    background-color: #e74c3c;
    border: thin solid #aa441a;
  }
  .ok .bar {
    background-color: #f1c40f;
    border: thin solid #a0b000;
  }

  .four-bars .bar.fifth-bar,
  .three-bars .bar.fifth-bar,
  .three-bars .bar.fourth-bar,
  .one-bar .bar:not(.first-bar),
  .zero-bar .bar,
  .two-bars .bar:not(.first-bar):not(.second-bar) {
    background-color: #999999;
    border: thin solid #555555;
  }

  .hub-things-div {
    /* background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: auto;
    margin: 12px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    position: relative;
    z-index: 99; */
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px; 
    font-family: 'Segoe UI Light'; 
    font-size: 16px;
    letter-spacing: 0px; 
    color: #FFFFFF; 
    margin: 10px;
  }
  .hub-things-div:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  .circle-35.scan-devices {
    background: url(../../assets/radar2_black.svg) no-repeat center center #555;
  }
  .circle-35.scan-devices.active {
    background: url(../../assets/radar2_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.scan-devices {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35.agave-settings {
    background: url(../../assets/settings.png) no-repeat center center #555;
  }
  .circle-35.agave-settings.active {
    background: url(../../assets/settings.png) no-repeat center center #8EDC28;
  }
  .circle-35:hover.agave-settings {
    opacity: 0.5;
    cursor: pointer;
  }
  .scan-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 10px;
    border-radius: 10px;
    opacity: 1;
    height: auto;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
    z-index: 99;
  }
  .myform {
    border-radius: 10px;
    border: 0;
    box-shadow: none;
    background:rgba(60,60,60,1);
    color: fff !important;
  }

  input.myform, select.myform, textarea.myform{
    color: #fff;
  }

  textarea.myform:focus, input.myform:focus {
      color: #fff;
  }

  select.myform, option.myform {
    background:rgba(60,60,60,1);
    color: #fff;
  }

  .red-circle {
    float: left;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 30px;
    z-index: 999999;
    border-radius: 50px;
    color: #000000;
    /* background: url(../assets/minus-small.svg) no-repeat center center #C40D0D; */
  }
  .green-circle {
    float: left;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    left: 30px;
    z-index: 999999;
    border-radius: 50px;
    color: #000000;
    /* background: url(../assets/plus-small.svg) no-repeat center center #8EDC28; */
  }
  .expand_dots:after {
    content: '\2807';
    font-size: 16px;
  }
  .expand_dots:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .signalling_po {

  }
  .signalling_po:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .battery-info-div {
    position: absolute !important;
    bottom: 26px !important;
    right: 10px !important;
    width: auto;
    min-width: 270px;
    max-width: 300px;
    z-index: 300 !important;
  }
</style>