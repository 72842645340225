import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';


export const facilitiesService = {
  facilities_get,
  facility_get,
  facility_add,
  facility_edit,
  facility_delete,
  updateFacilityPhoto,
  getFacilityPhoto
};

async function facilities_get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function facility_get(facility_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/` + facility_id + '?' + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function facility_add(facility) {
  const requestOptions = {
    method: 'POST',
    // headers: authHeader(),
    // headers: { 'Content-Type': 'application/json' },
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "name": facility.name,
      "address": facility.address
    })
  };

  return fetch(API_LOCATION + `/facilities?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function facility_edit(facility_id, facility) {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    // headers: authHeader(),
    // headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "name": facility.name,
      "address": facility.address
    })
  };

  return fetch(API_LOCATION + `/facilities/` + facility_id + '?' + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function facility_delete(facility_id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
    // headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({
    //   "name": facility_name,
    // })
  };

  return fetch(API_LOCATION + `/facilities/` + facility_id + '?' + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function updateFacilityPhoto(facility_id, photo, filename) {
  let formData  = new FormData();

  formData.append('file', photo, filename);

  const requestOptions = {
    method: 'POST',
    // headers: Object.assign(authHeader(), { 'Content-Type': 'multipart/form-data' }),
    headers: Object.assign(authHeader()),
    body: formData
  };

  return fetch(API_LOCATION + `/facilities/` + facility_id + `/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions)
    .then(responseHandler.handleResponse)
    .then(answerData => {
      return answerData;
    })
}

async function getFacilityPhoto(facility_id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  // return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
  return fetch(API_LOCATION + `/facilities/` + facility_id + `/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleFileResponse);
  // return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions);
}

// function handleResponse(response) {
//   return response.json().then(json => {
//     const data = json;
//
//     if (!response.ok) {
//       if (response.status === 400) {
//         // auto logout if 400 response returned from api
//         // logout();
//         location.reload(true);
//       }
//
//       const error = data.result.message;
//       return Promise.reject(error);
//     }
//
//     console.log("handleResponse: ", data);
//     // return data.result.items;
//     return data.result;
//   });
// }
