<template>
    <div>
        <!-- <navbar></navbar> -->
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="card card-main transparent">
                        <div class="card-body">
                            <div class="header-div">
                                <div class="header-upper">
                                    <div class="back-img">
                                        <a href="/"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                                    </div>
                                    Мои устройства
                                </div>
                                <div class="header-body p-3">
                                    Список устройств, привязанных к подстанциям
                                </div>
                            </div>
                            <div class="row">
                                <vue-simple-spinner v-if="this.preloading" size="large" line-fg-color="#8edc28" class="m-5"/>
                                <div class="col-6" v-for="facility in facilitylist" :key="facility.id">
                                    <div class="subject-facilities">
                                        <span class="ml-3 mt-2">{{ facility.facility.name }}</span>
                                        <hr>
                                        <div class="row">
                                            <div v-show="!facility.facility.things || !facility.facility.things.length" class="ml-3">Нет устройств</div>
                                            <div class="col-6" v-for="thing in facility.facility.things" :key="thing.id">
                                                <div v-if="thing.shadow.online === true" class="subject-devices" style="background-color: #8EDC28; color: #000000;" @click.prevent="show_in_detail(thing)">
                                                    <span style="font-size: 14px;">Тип:</span> {{ thing.type }} <br>
                                                    <span style="font-size: 14px;">Серийный номер:</span> {{ thing.serial }} <br>
                                                    <span style="font-size: 14px;">Онлайн:</span> {{ thing.shadow.online }}
                                                </div>
                                                <div v-else class="subject-devices" style="background-color: #9a1616;" @click.prevent="show_in_detail(thing)">
                                                    <span style="font-size: 14px;">Тип:</span> {{ thing.type }} <br>
                                                    <span style="font-size: 14px;">Серийный номер:</span> {{ thing.serial }} <br>
                                                    <span style="font-size: 14px;">Онлайн:</span> {{ thing.shadow.online }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="overflow-auto detail-overflow">
                        <div class="card card-second transparent">
                            <div class="card-body">
                                <div class="detailed-div">
                                    <div v-if="this.details===''">Выберите устройство для отображения информации о нем</div>
                                    <vue-json-pretty v-else
                                            :path="'res'"
                                            :data=details
                                            :showDoubleQuotes="false"
                                            :deep=2
                                            @click="handleClick">
                                    </vue-json-pretty>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  // import navbar from './Navbar.vue'
  import { facilitiesService } from '../_services';
  import { thingsService } from '../_services';
  import VueJsonPretty from 'vue-json-pretty'
  import VueSimpleSpinner from 'vue-simple-spinner'

  export default {
    name: 'DevicesPage',
    components: {
      // navbar,
      VueJsonPretty,
      VueSimpleSpinner
    },
    data () {
      return {
        facilitylist: [],
        thingList: [],
        details: '',
        preloading: false
      }
    },
    async created() {
      let user = JSON.parse(localStorage.getItem('user'));
      console.log(user);
      console.log(this.$route.params.id);
      await this.getFacilityList();
      await this.getThingList();
    },
    methods: {
      init() {
        console.log('initializing')
      },
      async getFacilityList() {
        this.preloading = true;

        let res = await facilitiesService.facilities_get();

        if (res.error) {
          console.error(res.error);

          return;
        }

        // this.facilitylist = res.result.items;
        let facilities = res.result.items;

        console.log('facilities: ', facilities);

        // this.preloading = false;
        await this.getThingList(facilities);
      },
      async getThingList(facilities) {
        for (let facility_numb in facilities) {
          let facility = facilities[facility_numb].facility;

          let res = await thingsService.things_get(facility.id);

          if (res.error) {
            console.error(res.error);

            return;
          }

          console.log(res.result.items);

          facilities[facility_numb].facility.things = res.result.items;
        }

        this.facilitylist = facilities;
        this.preloading = false;

        console.log('facilitylist:')
        console.log(this.facilitylist)
      },
      show_in_detail(thing) {
        this.details = thing;
      },
      handleClick() {
        // alert('handleClick')
      }
    }
  };
</script>

<style>
    @font-face {
        font-family: 'Snowstorm Light';
        src: url('../assets/SnowstormLight.woff2');
    }
    .card {
        margin-top: 20px;
        /*background-color: rgba(255, 255, 255, 0.9);*/
        /*color: red;*/
    }
    .card-second {
        /*background-color: rgba(0,0,0,0.4);*/
    }
    .card-title {
        font-family: "Snowstorm Light";
        font-size: 70px;
        color: #fff;
    }
    .transparent {
        border-width: 0px;
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
        background-color: rgba(0,0,0,0.0);
        background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
        background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
        background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    }
    /*.circle {*/
    /*    background: #000;*/
    /*    border-radius: 50%;*/
    /*    height: 40px;*/
    /*    width: 40px;*/
    /*    display: inline-block;*/
    /*    !*display:table-cell;*!*/
    /*    !*vertical-align:middle;*!*/
    /*    !*text-align:center;*!*/
    /*    align-items: center;*/
    /*    justify-content: center*/
    /*}*/
    /*.circle:hover {*/
    /*    background-color: rgba(0,0,0,0.3);*/
    /*}*/
    .manage-block {
        border: 1px solid black;
        height: 200px;
    }
    .input-block {
        height: 100px;
        display: inline-block;
        background: red;
        width: 50%;
    }
    .button-block {
        height: 100px;
        display: inline-block;
        background: red;
        width: 10%;
    }
    .form-control {
        border-radius: 50px;
    }
    .col-sm-6 {
        margin: 0px;
        padding: 0px;
    }
    .subject-facilities {
        background: #202020 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        /*height: 90px;*/
        /* width: 180px; */
        margin: 10px;
        font-family: "Segoe UI Light";
        font-size: 18px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        padding: 5px;
        /*padding: 10px;*/
        /*display: flex;*/
        /*flex-flow: row nowrap;*/
    }
    .subject-devices {
        background: #2E2E2E 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        /*height: 90px;*/
        /* width: 180px; */
        /*margin: 10px;*/
        margin-bottom: 10px;
        font-family: "Segoe UI Light";
        font-size: 18px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        padding: 5px;
        cursor: pointer;
    }
    .subject-devices:hover {
        opacity: 0.7;
    }
    .subject-div1:hover {
        background: #2E2E2E 0% 0% no-repeat padding-box;
    }
    .header-div {
        background: #202020 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        height: 170px;
        /* width: 180px; */
        margin: 10px;
        font-family: "Segoe UI Light";
        font-size: 30px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        /*padding: 10px;*/
    }
    .header-upper {
        border-bottom: 1px solid black;
        padding: 10px
    }
    .subject-data {
        border-left: 2px solid black;
        margin-left: 10px;
    }
    .card-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        opacity: 1;
    }
    .second-body {
        background: #606060 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        /*opacity: 1;*/
        /*backdrop-filter: blur(10px);*/
        /*-webkit-backdrop-filter: blur(10px);*/
        border-color: #606060;
    }
    .card-info {
        border-radius: 10px;
        font-family: "Segoe UI Light";
        font-size: 30px;
        color: #fff;
        letter-spacing: 0px;
        opacity: 1;
    }
    .back-img {
        width: 45px;
        height: 45px;
        display: inline-block;
        margin: 5px;
    }
    .back-img img{
        max-width:100%;
        height:auto;
    }
    .header-body {
        font-family: "Segoe UI Light";
        font-size: 16px;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding: 20px;
    }
    .subject-logo {
        /*display: inline-block;*/
        flex-grow: 3;
        /*text-align: center;*/
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .subject-info {
        /*display: inline-block;*/
        flex-grow: 7;
        font-size: 16px;
        padding: 0px;
        padding-top: 15px;
    }
    .small {
        max-width: 600px;
        margin:  150px auto;
    }
    .my-bar {
        max-width: 950px;
        max-height: 220px;
        background-color: #202020;
    }
    .detailed-div {
        background: #202020 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        /*height: 170px;*/
        /* width: 180px; */
        margin: 10px;
        font-family: "Segoe UI Light";
        font-size: 16px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        /*padding: 10px;*/
        margin-bottom: 30px;
        padding: 10px;
    }
    .detail-overflow {
        position: relative;
        max-height: 800px;
    }
    .container {
        max-width: 1920px;
        width: 100%;
    }
</style>
