<template>
	<vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
	<div v-else>
		<div class="row">

			<div class="col-sm-6">
				<div class="card card-main transparent">
					<div class="card-body">

						<div class="header-div m-0 desktop-laurel-header">
							<div class="header-upper">
								<div class="d-flex bd-highlight">
									<div class="back-img interact-img" style="flex-shrink: 0;">
										<!-- <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a> -->
										<a href="#" v-on:click="goBack()"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a>
									</div>
									<div class="back-img interact-img" style="flex-shrink: 0;">
										<div v-if="thing.shadow.online">
											<img v-if="thing.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../../assets/yellow_circle.svg" style="cursor: pointer;" @click.prevent="toggleSignallingInfo">
											<img v-else alt="laurel_online" src="../../assets/green_circle.svg">
										</div>
										<div v-else>
											<img alt="laurel_offline" src="../../assets/gray_circle.svg" style="cursor: pointer;" @click.prevent="toggleSignallingInfo">
										</div>

										<!-- <div v-if="signalling_info_toggle" class="akb-info-div">
										<div v-if="signalization.length !== 0">
											<p style="color: #EBEB14">Сигнализация:</p>
											<div v-for="warning in signalization" :key="warning">
											{{ warning }}
											</div>
										</div>
										</div> -->

									</div>
									<span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
										{{ facility.name }}
										<span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
										<span v-else>
											<span v-if="thing.type.id == 'Laurel'">, LAUREL-12 <span class="serial-small">(№ {{ thing.number }})</span></span>
											<span v-else>
												, {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
											</span>
										</span>
									</span>
									<div class="p-2 bd-highlight">
										<div class="circle-35 sort-linear" v-on:click="active_button = 'chart'" v-bind:class="{ active: active_button == 'chart' }"></div>
									</div>
									<div class="p-2 bd-highlight">
										<div class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; get_event_logs(); get_system_logs();" v-bind:class="{ active: active_button == 'current-parameters' }"></div>
									</div>
									<div class="p-2 bd-highlight">
										<div class="circle-35 files" v-on:click="active_button = 'files'; getThingFiles('configuration_file'); getThingOscillogramFiles();" v-bind:class="{ active: active_button == 'files' }"></div>
									</div>
								</div>
							</div>
							<div class="header-body" style="padding: 0px;">
								<div class="row">
									<div class="col-6">

										<div class="d-flex justify-content-around mt-3">
											<div class="d-flex flex-column align-items-center justify-content-center">
												<div class="background-text unselectable pl-4">U</div>
												<span v-if="thing.shadow.online && thing.shadow.analog_values.U_inp_0x0230_0x0231 !== null" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.U_inp_0x0230_0x0231).toFixed(1) }} В</span>
												<span v-else style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.- В</span>
												<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Uвход</span>
											</div>

											<div class="d-flex flex-column align-items-center justify-content-center">
												<div class="background-text unselectable pl-4">U</div>
												<span v-if="thing.shadow.online && thing.shadow.analog_values.U_out_0x0234_0x0235 !== null" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.U_out_0x0234_0x0235).toFixed(1) }} В</span>
												<span v-else style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.- В</span>
												<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Uвыход</span>
											</div>

											<div class="d-flex flex-column align-items-center justify-content-center">
												<div class="background-text unselectable pl-4">I</div>
												<span v-if="thing.shadow.online && thing.shadow.analog_values.I_vzu_group_0x0268_0x0269 !== null" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.I_vzu_group_0x0268_0x0269).toFixed(2) }} А</span>
												<span v-else style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.-- А</span>
												<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Iзпу групп</span>
											</div>

											<div class="d-flex flex-column align-items-center justify-content-center">
												<div class="background-text unselectable pl-4">I</div>
												<span v-if="thing.shadow.online && thing.shadow.analog_values.I_nagr_0x026C_0x026D !== null" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.I_nagr_0x026C_0x026D).toFixed(2) }} А</span>
												<span v-else style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.-- А</span>
												<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Iнагрузки</span>
											</div>
										</div>

										<div class="d-flex justify-content-center m-3" style="white-space: nowrap;">
											<span v-if="!thing.shadow.online" style="letter-spacing: 0.8px; opacity: 0.5; font-size: 16px; font-family: 'Segoe UI Regular';" class="mr-1">⚠ {{ $t('devices.laurelpage.offline') }} | </span>
											<span style="letter-spacing: 0.8px; opacity: 0.5; font-size: 16px; font-family: 'Segoe UI Regular';" class="mr-3">{{ $t('devices.laurelpage.updated') }}</span>
											<span style="letter-spacing: 0.8px; opacity: 0.5; font-size: 16px; font-family: 'Segoe UI Regular';">{{ date }} | {{ time }}</span>
										</div>

									</div>
									<div class="col-6 d-flex align-items-center justify-content-around" style="border-left-color: black; border-left: 1px solid black;">
										<!-- <img alt="back" src="../../assets/battery_scheme_normal.svg"> -->
											<div class="m-2">
												<img v-if="ab_mode == 'Обрыв цепи АБ'" alt="scheme_ab_circuit_break" src="../../assets/laurel/scheme_ab_circuit_break.svg">
												<img v-if="ab_mode == 'Подзаряд'" alt="scheme_ab_recharge" src="../../assets/laurel/scheme_ab_recharge.svg">
												<img v-if="ab_mode == 'Заряд'" alt="scheme_ab_charge" src="../../assets/laurel/scheme_ab_charge.svg">
												<img v-if="ab_mode == 'Выравнивающий заряд'" alt="scheme_ab_equalizing_charge" src="../../assets/laurel/scheme_ab_equalizing_charge.svg">
												<img v-if="ab_mode == 'Контрольный разряд'" alt="scheme_ab_control_discharge" src="../../assets/laurel/scheme_ab_control_discharge.svg">
												<img v-if="ab_mode == 'Контроль цепи АБ'" alt="scheme_ab_circuit_control" src="../../assets/laurel/scheme_ab_circuit_control.svg">
												<img v-if="ab_mode == 'Разряд'" alt="scheme_ab_discharge" src="../../assets/laurel/scheme_ab_discharge.svg">
											</div>
											<div>
												<div class="ml-3 d-flex">
													<img v-if="ab_mode == 'Обрыв цепи АБ'" alt="ab_circuit_break" src="../../assets/laurel/ab_circuit_break.svg" class="mr-2">
													<img v-if="ab_mode == 'Подзаряд'" alt="ab_recharge" src="../../assets/laurel/ab_recharge.svg" class="mr-2">
													<img v-if="ab_mode == 'Заряд'" alt="ab_charge" src="../../assets/laurel/ab_charge.svg" class="mr-2">
													<img v-if="ab_mode == 'Выравнивающий заряд'" alt="ab_equalizing_charge" src="../../assets/laurel/ab_equalizing_charge.svg" class="mr-2">
													<img v-if="ab_mode == 'Контрольный разряд'" alt="ab_control_discharge" src="../../assets/laurel/ab_control_discharge.svg" class="mr-2">
													<img v-if="ab_mode == 'Контроль цепи АБ'" alt="ab_circuit_control" src="../../assets/laurel/ab_circuit_control.svg" class="mr-2">
													<img v-if="ab_mode == 'Разряд'" alt="ab_discharge" src="../../assets/laurel/ab_discharge.svg" class="mr-2">
													<span class="status-text mt-1">{{ ab_mode }}</span>
												</div>
												<div class="row mt-4">
													<div class="col" v-if="thing.shadow.online && thing.shadow.analog_values.I_out_average_0x0264_0x0265 !== null" style="white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Iаб: {{ (thing.shadow.analog_values.I_out_average_0x0264_0x0265).toFixed(2) }} A</div>
													<div class="col" v-else style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Iаб: <span style="color: #606060;">-.-- A</span></div>
													<div class="col" v-if="thing.shadow.online && thing.shadow.analog_values.ADC_T_ab_0x0216_0x0217 !== null" style="white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Tаб: {{ (thing.shadow.analog_values.ADC_T_ab_0x0216_0x0217).toFixed(1) }} °C</div>
													<div class="col" v-else style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Tаб: <span style="color: #606060;">-.- °C</span></div>
													<div class="w-100"></div>
													<div class="col"></div>
													<div class="col"></div>
												</div>
											</div>
									</div>
								</div>
							</div>
						</div>

<!-- MOBILE ********************************************************************** -->
						<div class="header-div m-0 mobile-header" style="height: auto !important;">
							<div class="header-upper pb-3">
								<div class="d-flex bd-highlight">
									<div class="back-img interact-img" style="margin-top: 0px !important;">
										<a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
									</div>
									<div class="back-img interact-img" style="margin-top: 0px !important;">
										<div v-if="thing.shadow.online">
											<img v-if="thing.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../../assets/yellow_circle.svg" style="cursor: pointer;" @click.prevent="toggleSignallingInfo">
											<img v-else alt="laurel_online" src="../../assets/green_circle.svg">
										</div>
										<div v-else>
											<img alt="laurel_offline" src="../../assets/gray_circle.svg" style="cursor: pointer;" @click.prevent="toggleSignallingInfo">
										</div>

										<div v-if="signalling_info_toggle" class="akb-info-div">
										<div v-if="signalization.length !== 0">
											<p style="color: #EBEB14">{{ $t('devices.laurelpage.signaling') }}:</p>
											<div v-for="warning in signalization" :key="warning">
											{{ warning }}
											</div>
										</div>
										</div>

									</div>
									<span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
										{{ facility.name }}
										<span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
										<span v-else>
										<span v-if="thing.type.id == 'Laurel'">, LAUREL-12 <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
										<span v-else>
											, {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
										</span>
										</span>
									</span>
								</div>
							</div>

							<div class="header-body" style="padding: 0px;">
								<div class="d-flex">
									<div class="d-flex align-items-center">
										<ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
										<li style="color: #ff0000"><span class="nowrap-span">⚠ Лаурель оффлайн</span></li>
										<li style="font-size: 14px;">Последнее обновление:</li>
										<li>{{ date }} {{ time }}</li>
										</ul>
										<ul v-else style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
										<li style="font-size: 14px;">Обновлено:</li>
										<li>{{ date }}</li>
										<li>{{ time }}</li>
										</ul>
									</div>
									<div class="ml-auto d-flex align-items-center justify-content-center">
										<div class="p-2 bd-highlight">
											<div class="circle-35 sort-linear" v-on:click="active_button = 'chart'" v-bind:class="{ active: active_button == 'chart' }"></div>
										</div>
										<div class="p-2 bd-highlight">
											<div class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; get_event_logs(); get_system_logs();" v-bind:class="{ active: active_button == 'current-parameters' }"></div>
										</div>
										<div class="p-2 bd-highlight">
											<div class="circle-35 files" v-on:click="active_button = 'files'; getThingFiles('configuration_file'); getThingOscillogramFiles();" v-bind:class="{ active: active_button == 'files' }"></div>
										</div>
									</div>
								</div>
							</div>

							<div class="header-body" style="padding: 0px; border-top: 1px solid black;">
								<div class="d-flex align-items-center justify-content-around">
									<!-- <img alt="back" src="../../assets/battery_scheme_normal.svg"> -->
										<div class="m-2">
											<img v-if="ab_mode == 'Обрыв цепи АБ'" alt="scheme_ab_circuit_break" src="../../assets/laurel/scheme_ab_circuit_break.svg">
											<img v-if="ab_mode == 'Подзаряд'" alt="scheme_ab_recharge" src="../../assets/laurel/scheme_ab_recharge.svg">
											<img v-if="ab_mode == 'Заряд'" alt="scheme_ab_charge" src="../../assets/laurel/scheme_ab_charge.svg">
											<img v-if="ab_mode == 'Выравнивающий заряд'" alt="scheme_ab_equalizing_charge" src="../../assets/laurel/scheme_ab_equalizing_charge.svg">
											<img v-if="ab_mode == 'Контрольный разряд'" alt="scheme_ab_control_discharge" src="../../assets/laurel/scheme_ab_control_discharge.svg">
											<img v-if="ab_mode == 'Контроль цепи АБ'" alt="scheme_ab_circuit_control" src="../../assets/laurel/scheme_ab_circuit_control.svg">
											<img v-if="ab_mode == 'Разряд'" alt="scheme_ab_discharge" src="../../assets/laurel/scheme_ab_discharge.svg">
										</div>
										<div>
											<div class="ml-3 d-flex">
												<img v-if="ab_mode == 'Обрыв цепи АБ'" alt="ab_circuit_break" src="../../assets/laurel/ab_circuit_break.svg" class="mr-2">
												<img v-if="ab_mode == 'Подзаряд'" alt="ab_recharge" src="../../assets/laurel/ab_recharge.svg" class="mr-2">
												<img v-if="ab_mode == 'Заряд'" alt="ab_charge" src="../../assets/laurel/ab_charge.svg" class="mr-2">
												<img v-if="ab_mode == 'Выравнивающий заряд'" alt="ab_equalizing_charge" src="../../assets/laurel/ab_equalizing_charge.svg" class="mr-2">
												<img v-if="ab_mode == 'Контрольный разряд'" alt="ab_control_discharge" src="../../assets/laurel/ab_control_discharge.svg" class="mr-2">
												<img v-if="ab_mode == 'Контроль цепи АБ'" alt="ab_circuit_control" src="../../assets/laurel/ab_circuit_control.svg" class="mr-2">
												<img v-if="ab_mode == 'Разряд'" alt="ab_discharge" src="../../assets/laurel/ab_discharge.svg" class="mr-2">
												<span class="status-text mt-1">{{ ab_mode }}</span>
											</div>
											<div class="row mt-4">
												<div class="col" v-if="thing.shadow.online && thing.shadow.analog_values.I_out_average_0x0264_0x0265 !== null" style="white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Iаб: {{ (thing.shadow.analog_values.I_out_average_0x0264_0x0265).toFixed(2) }} A</div>
												<div class="col" v-else style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Iаб: <span style="color: #606060;">-.-- A</span></div>
												<div class="col" v-if="thing.shadow.online && thing.shadow.analog_values.ADC_T_ab_0x0216_0x0217 !== null" style="white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Tаб: {{ (thing.shadow.analog_values.ADC_T_ab_0x0216_0x0217).toFixed(1) }} °C</div>
												<div class="col" v-else style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 140px;">Tаб: <span style="color: #606060;">-.- °C</span></div>
												<div class="w-100"></div>
												<div class="col"></div>
												<div class="col"></div>
											</div>
										</div>
								</div>
							</div>
							<div class="header-body" style="border-top: 1px solid black;">
								<div class="d-flex justify-content-around" style="font-size: 14px;">
									<div class="d-flex flex-column align-items-center justify-content-center ml-2" style="height: 88px;"><div class="background-text unselectable pl-5 ml-5">U</div>
										<span v-if="thing.shadow.online && thing.shadow.analog_values.U_inp_0x0230_0x0231 !== null" style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.U_inp_0x0230_0x0231).toFixed(1) }} В</span>
										<span v-else style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.- В</span>
										<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Uвход</span>
									</div>
									<div class="d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5 ml-5">U</div>
										<span v-if="thing.shadow.online && thing.shadow.analog_values.U_out_0x0234_0x0235 !== null" style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.U_out_0x0234_0x0235).toFixed(1) }} В</span>
										<span v-else style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.- В</span>
										<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Uвыход</span>
									</div>
									<div class="d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5 ml-5">I</div>
										<span v-if="thing.shadow.online && thing.shadow.analog_values.I_vzu_group_0x0268_0x0269 !== null" style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.I_vzu_group_0x0268_0x0269).toFixed(2) }} А</span>
										<span v-else style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.-- А</span>
										<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Iзпу групп</span>
									</div>
									<div class="d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5 ml-5">I</div>
										<span v-if="thing.shadow.online && thing.shadow.analog_values.I_nagr_0x026C_0x026D !== null" style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #FFFFFF; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">{{ (thing.shadow.analog_values.I_nagr_0x026C_0x026D).toFixed(2) }} А</span>
										<span v-else style="z-index: 99999; font-size: 20px; position: relative; white-space: nowrap; color: #606060; overflow: hidden; text-overflow: ellipsis; max-width: 100px;">-.-- А</span>
										<span style="z-index: 99999; position: relative; white-space: nowrap; color: #FFFFFF;">Iнагрузки</span>
									</div>
								</div>
							</div>
						</div>
<!-- MOBILE ********************************************************************** -->
						<div class="row mt-3">

							<div class="col-xl-3 col-lg-4 col-md-6 p-0 mt-2 mb-3" v-if="group_1.length > 0 || group_2.length > 0">
								<div class="row">
									<div v-if="group_1.length > 0 || group_2.length > 0" class="group-background"></div>
									<div class="col-12 pl-0 pr-0" v-for="(param, key) in group_1" v-bind:key="key">
										<div class="laurel-mini-div" v-if="param.group.id == 'group_1'" v-on:click="goToThing(param.id)">

											<div class="d-flex">
												<div class="d-flex flex-column subject-info p-3" style="align-items: flex-end; justify-content: space-between; margin-top: 10px !important;">
													<div v-if="param.shadow.online">
														<!-- <img alt="laurel" src="../../assets/laurel/laurel.svg"> -->
														<div v-if="param.state === 'Блокировка'">
															<img alt="laurel" src="../../assets/laurel/laurel_red.svg">
														</div>
														<div v-else-if="param.state === 'Выдача приостановлена'">
															<img alt="laurel" src="../../assets/laurel/laurel_yellow.svg">
														</div>
														<div v-else-if="param.state === 'Работа'">
															<img alt="laurel" src="../../assets/laurel/laurel.svg">
														</div>
														<div v-else>
															<img alt="laurel" src="../../assets/laurel/laurel_gray.svg">
														</div>
													</div>
													<div v-else>
														<img alt="laurel_offline" src="../../assets/laurel/laurel_gray.svg">
													</div>
													<!-- <span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
													<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
													<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span> -->
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span>
												</div>
												<!-- <div class="accumulator-tag">{{ param.number }}</div> -->

												<div v-if="param.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" class="laurel-signalling" style="color: #EBEB14">⚠</div>

												<div class="d-flex flex-column subject-info" v-if="param.shadow.online" style="margin-top: 2px;">
													<span v-if="param.shadow.analog_values.U_out_0x0234_0x0235 !== null" style="color: #FFFFFF;" class="subject-info-values mt-1">{{ (param.shadow.analog_values.U_out_0x0234_0x0235).toFixed(1) }} В</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.I_vzu_0x0260_0x0261 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.I_vzu_0x0260_0x0261).toFixed(2) }} A</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- A</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.P_out_0x0262_0x0263 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.P_out_0x0262_0x0263).toFixed(1) }} Вт</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- Вт</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.U_inp_0x0230_0x0231 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.U_inp_0x0230_0x0231).toFixed(1) }} В</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.ADC_F_fan_0x021E_0x021F !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.ADC_F_fan_0x021E_0x021F).toFixed(0) }} об/мин</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- об/мин</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.T_max_0x0266_0x0267 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.T_max_0x0266_0x0267).toFixed(1) }} °C</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- °C</span>
												</div>
												<div class="d-flex flex-column subject-info" v-else>
													<span style="color: #606060;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.-- A</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- Вт</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- В</span>										
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.-- об/мин</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- °C</span>
												</div>
											</div>

											<div v-if="thing.number == param.number" class="laurel-active"></div>
											<div v-if="thing.number == param.number" class="laurel-info">
												<div id="bottom-laurel" class="ml-3 mr-3">
													<div class="d-flex justify-content-between">
														<span>LAUREL {{ param.number }}</span>
														<span v-if="param.shadow.online">{{ laurel_state }}</span>
														<span v-else>Нет связи</span>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div class="col-xl-3 col-lg-4 col-md-6 p-0 mt-2 mb-3" v-if="group_2.length > 0 || group_1.length > 0">
								<div class="row">
									<div v-if="group_2.length > 0 || group_1.length > 0" class="group-background"></div>
									<div class="col-12 col-md-6 pl-0 pr-0" v-for="(param, key) in group_2" v-bind:key="key">
										<div class="laurel-mini-div" v-if="param.group.id == 'group_2'" v-on:click="goToThing(param.id)">

											<div class="d-flex">
												<div class="d-flex flex-column subject-info p-3 mt-2" style="align-items: flex-end; justify-content: space-between; margin-top: 10px !important;">
													<div v-if="param.shadow.online">
														<!-- <img alt="laurel" src="../../assets/laurel/laurel.svg"> -->
														<div v-if="param.state === 'Блокировка'">
															<img alt="laurel" src="../../assets/laurel/laurel_red.svg">
														</div>
														<div v-else-if="param.state === 'Выдача приостановлена'">
															<img alt="laurel" src="../../assets/laurel/laurel_yellow.svg">
														</div>
														<div v-else-if="param.state === 'Работа'">
															<img alt="laurel" src="../../assets/laurel/laurel.svg">
														</div>
														<div v-else>
															<img alt="laurel" src="../../assets/laurel/laurel_gray.svg">
														</div>
													</div>
													<div v-else>
														<img alt="laurel_offline" src="../../assets/laurel/laurel_gray.svg">
													</div>
													<!-- <span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
													<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
													<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span> -->
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
													<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span>
												</div>
												<!-- <div class="accumulator-tag">{{ param.number }}</div> -->

												<div v-if="param.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" class="laurel-signalling" style="color: #EBEB14">⚠</div>

												<div class="d-flex flex-column subject-info" v-if="param.shadow.online" style="margin-top: 2px;">
													<span v-if="param.shadow.analog_values.U_out_0x0234_0x0235 !== null" style="color: #FFFFFF;" class="subject-info-values mt-1">{{ (param.shadow.analog_values.U_out_0x0234_0x0235).toFixed(1) }} В</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.I_vzu_0x0260_0x0261 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.I_vzu_0x0260_0x0261).toFixed(2) }} A</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- A</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.P_out_0x0262_0x0263 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.P_out_0x0262_0x0263).toFixed(1) }} Вт</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- Вт</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.U_inp_0x0230_0x0231 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.U_inp_0x0230_0x0231).toFixed(1) }} В</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.ADC_F_fan_0x021E_0x021F !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.ADC_F_fan_0x021E_0x021F).toFixed(0) }} об/мин</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- об/мин</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span v-if="param.shadow.analog_values.T_max_0x0266_0x0267 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.T_max_0x0266_0x0267).toFixed(1) }} °C</span>
													<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- °C</span>
												</div>
												<div class="d-flex flex-column subject-info" v-else>
													<span style="color: #606060;" class="subject-info-values mt-1">-.- В</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.-- A</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- Вт</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- В</span>										
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.-- об/мин</span>
													<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
													<span style="color: #606060" class="subject-info-values">-.- °C</span>
												</div>
											</div>

											<div v-if="thing.number == param.number" class="laurel-active"></div>
											<div v-if="thing.number == param.number" class="laurel-info">
												<div id="bottom-laurel" class="ml-3 mr-3">
													<div class="d-flex justify-content-between">
														<span>LAUREL {{ param.number }}</span>
														<span v-if="param.shadow.online">{{ laurel_state }}</span>
														<span v-else>Нет связи</span>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>

							<div class="col-xl-3 col-lg-4 col-md-6 pl-0 pr-0" v-for="(param, key) in ungroupped" v-bind:key="key">
								<div class="laurel-mini-div" v-on:click="goToThing(param.id)">
									<div class="d-flex">
										<div class="d-flex flex-column subject-info p-3 mt-2" style="align-items: flex-end; justify-content: space-between; margin-top: 10px !important;">
											<div v-if="param.shadow.online">
												<!-- <img alt="laurel" src="../../assets/laurel/laurel.svg"> -->
												<div v-if="param.state === 'Блокировка'">
													<img alt="laurel" src="../../assets/laurel/laurel_red.svg">
												</div>
												<div v-else-if="param.state === 'Выдача приостановлена'">
													<img alt="laurel" src="../../assets/laurel/laurel_yellow.svg">
												</div>
												<div v-else-if="param.state === 'Работа'">
													<img alt="laurel" src="../../assets/laurel/laurel.svg">
												</div>
												<div v-else>
													<img alt="laurel" src="../../assets/laurel/laurel_gray.svg">
												</div>
											</div>
											<div v-else>
												<img alt="laurel_offline" src="../../assets/laurel/laurel_gray.svg">
											</div>
											<!-- <span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
											<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
											<span style="color: #535353; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span> -->
											<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Uвход</span>
											<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Fвент</span>
											<span style="color: #ffffff; letter-spacing: 0.8px; margin-top: 4px; margin-bottom: 2px;">Тзпу</span>
										</div>
										<!-- <div class="accumulator-tag">{{ param.number }}</div> -->

										<div v-if="param.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" class="laurel-signalling" style="color: #EBEB14">⚠</div>

										<div class="d-flex flex-column subject-info" v-if="param.shadow.online" style="margin-top: 2px;">
											<span v-if="param.shadow.analog_values.U_out_0x0234_0x0235 !== null" style="color: #FFFFFF;" class="subject-info-values mt-1">{{ (param.shadow.analog_values.U_out_0x0234_0x0235).toFixed(1) }} В</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span v-if="param.shadow.analog_values.I_vzu_0x0260_0x0261 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.I_vzu_0x0260_0x0261).toFixed(2) }} A</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- A</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span v-if="param.shadow.analog_values.P_out_0x0262_0x0263 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.P_out_0x0262_0x0263).toFixed(1) }} Вт</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- Вт</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span v-if="param.shadow.analog_values.U_inp_0x0230_0x0231 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.U_inp_0x0230_0x0231).toFixed(1) }} В</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- В</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span v-if="param.shadow.analog_values.ADC_F_fan_0x021E_0x021F !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.ADC_F_fan_0x021E_0x021F).toFixed(0) }} об/мин</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.-- об/мин</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span v-if="param.shadow.analog_values.T_max_0x0266_0x0267 !== null" style="color: #FFFFFF" class="subject-info-values">{{ (param.shadow.analog_values.T_max_0x0266_0x0267).toFixed(1) }} °C</span>
											<span v-else style="color: #FFFFFF;" class="subject-info-values mt-1">-.- °C</span>
										</div>
										<div class="d-flex flex-column subject-info" v-else>
											<span style="color: #606060;" class="subject-info-values mt-1">-.- В</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span style="color: #606060" class="subject-info-values">-.-- A</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span style="color: #606060" class="subject-info-values">-.- Вт</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span style="color: #606060" class="subject-info-values">-.- В</span>										
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span style="color: #606060" class="subject-info-values">-.-- об/мин</span>
											<hr style="margin: 0px; margin-top: 3px; margin-bottom: 3px;">
											<span style="color: #606060" class="subject-info-values">-.- °C</span>
										</div>
									</div>

									<div v-if="thing.number == param.number" class="laurel-active"></div>
									<div v-if="thing.number == param.number" class="laurel-info">
										<div id="bottom-laurel" class="ml-3 mr-3">
											<div class="d-flex justify-content-between">
												<span>LAUREL {{ param.number }}</span>
												<span v-if="param.shadow.online">{{ laurel_state }}</span>
												<span v-else>Нет связи</span>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						
					</div>
				</div>
				<!-- <date-picker v-model="range" is-range color="green" is-dark/> -->
			</div>

			<div class="col-sm-6">
				<div class="card card-main transparent">
					<div class="card-body">
						<div v-if="active_button == 'chart'">
							<div class="chart-div mb-1">
								<LineChart :chart-data="first_chart_data" :options="options" :width=900 :height=260 />
							</div>
							
							<div class="d-flex justify-content-between chart-button-div">
								<div v-on:click="first_chart_table = 'laurel_voltage_input'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_voltage_input' }">Uвход</div>
								<div v-on:click="first_chart_table = 'laurel_amperage_nagr'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_amperage_nagr' }">Iнагрузки</div>
								<div v-on:click="first_chart_table = 'laurel_amperage_ab'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_amperage_ab' }">Iаб</div>
								<div v-on:click="first_chart_table = 'laurel_temperature_ab'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_temperature_ab' }">Tаб</div>
								<div v-on:click="first_chart_table = 'laurel_ab_state'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_ab_state' }">Состояние АБ</div>
							</div>

							<div class="d-flex justify-content-between chart-button-div">
								<div v-on:click="first_chart_table = 'laurel_voltage_out'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_voltage_out' }">Uвыход</div>
								<div v-on:click="first_chart_table = 'laurel_amperage_out'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_amperage_out' }">Iвыход</div>
								<div v-on:click="first_chart_table = 'laurel_amperage_vzu_group'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_amperage_vzu_group' }">Iзпу групп</div>
								<div v-on:click="first_chart_table = 'laurel_temperature_max'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_temperature_max' }">Tзпу</div>
								<div v-on:click="first_chart_table = 'laurel_zpu_state'; fillData()" class="chart-button" v-bind:class="{ active: first_chart_table == 'laurel_zpu_state' }">Состояние ЗПУ</div>
							</div>

							<div class="chart-div mt-5 mb-1">
								<LineChart :chart-data="second_chart_data" :options="options" :width=900 :height=260 />
							</div>

							<div class="d-flex justify-content-between chart-button-div">
								<div v-on:click="second_chart_table = 'laurel_voltage_input'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_voltage_input' }">Uвход</div>
								<div v-on:click="second_chart_table = 'laurel_amperage_nagr'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_amperage_nagr' }">Iнагрузки</div>
								<div v-on:click="second_chart_table = 'laurel_amperage_ab'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_amperage_ab' }">Iаб</div>
								<div v-on:click="second_chart_table = 'laurel_temperature_ab'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_temperature_ab' }">Tаб</div>
								<div v-on:click="second_chart_table = 'laurel_ab_state'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_ab_state' }">Состояние АБ</div>
							</div>

							<div class="d-flex justify-content-between chart-button-div">
								<div v-on:click="second_chart_table = 'laurel_voltage_out'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_voltage_out' }">Uвыход</div>
								<div v-on:click="second_chart_table = 'laurel_amperage_out'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_amperage_out' }">Iвыход</div>
								<div v-on:click="second_chart_table = 'laurel_amperage_vzu_group'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_amperage_vzu_group' }">Iзпу групп</div>
								<div v-on:click="second_chart_table = 'laurel_temperature_max'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_temperature_max' }">Tзпу</div>
								<div v-on:click="second_chart_table = 'laurel_zpu_state'; fillData()" class="chart-button" v-bind:class="{ active: second_chart_table == 'laurel_zpu_state' }">Состояние ЗПУ</div>
							</div>

						</div>
						<div v-else-if="active_button == 'current-parameters'">
							<div class="parameters-div">
								<div class="row">
									<div class="col-6">
										<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.signaling') }}</span>
										<hr style="background: #aaaaaa;">

										<div class="journals-div overflow-auto scroller">
											<span v-if="!signalization.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список сигнализаций пуст</span>
											<div v-for="elem in signalization" v-bind:key="elem">
												<span class="red-dot"></span><span style="color: #FFFFFF; font-size: 14px; letter-spacing: 0.7px;">{{ elem }}</span>
											</div>
										</div>

									</div>
									<div class="col-6">
										<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.diagnostics') }}</span>
										<hr style="background: #aaaaaa;">

										<div class="journals-div overflow-auto scroller">
											<span v-if="!diagnostic_statuses.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список сигналов пуст</span>
											<div v-for="elem in diagnostic_statuses" v-bind:key="elem">
												<span class="yellow-dot"></span><span style="color: #FFFFFF; font-size: 14px; letter-spacing: 0.7px;">{{ elem }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="parameters-div mt-3">
								<div class="row">
									<div class="col-6">
										<div class="d-flex justify-content-between">
											<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.eventjournal') }}</span>
											<div class="circle-35 files journal-download" v-on:click="event_journal_datepicker_show = !event_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
											<div v-if="event_journal_datepicker_show" class="journal-datepicker-div">
												<div class="d-flex flex-column">
													<span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать журнал событий</span>
													<date-picker v-model="event_range" :model-config="modelConfig" is-range color="green" is-dark/>
													<div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('event_log')">Скачать</div></div>
												</div>
											</div>
										</div>
										<hr style="background: #aaaaaa;">
										<div class="journals-div overflow-auto scroller">
											<span v-if="!event_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
											<div v-for="record in event_logs" :key="record.id">
												<div class="row">
													<div class="col-5 p-0">
														<span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
													</div>
													<div class="col-7">
														<p v-for="elem in getEventDescription(record.data.events)" :key="elem" style="font-size: 14px;">{{ elem }}</p>
													</div>
												</div>
												<hr class="mt-0 mb-0 mr-2" style="background: #333;">
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="d-flex justify-content-between">
											<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.messagejournal') }}</span>
											<div class="circle-35 files journal-download" v-on:click="message_journal_datepicker_show = !message_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
											<div v-if="message_journal_datepicker_show" class="journal-datepicker-div">
												<div class="d-flex flex-column">
													<span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать системный журнал</span>
													<date-picker v-model="message_range" :model-config="modelConfig" is-range color="green" is-dark/>
													<div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('message_log')">Скачать</div></div>
												</div>
											</div>
										</div>										
										<hr style="background: #aaaaaa;">
										<div class="journals-div overflow-auto scroller">
											<span v-if="!message_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
											<div v-for="record in message_logs" :key="record.id">
												<div class="row">
													<div class="col-5 p-0">
														<span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
													</div>
													<div class="col-7">
														<span style="font-size: 14px;">{{ getMessageDescription(record.data.MsgID, record.data.MsgData) }}</span>
													</div>
												</div>
												<hr class="mt-0 mb-0 mr-2" style="background: #333;">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="date-picker" v-if="active_button == 'chart'">
							<div class="row">
								<div v-if="active_date == '24h'">
									<div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">Д</div>
								</div>
								<div v-else>
									<div class="circle-50 mb-2" v-on:click="active_date = '24h'; fillData()">Д</div>
								</div>
							</div>
							<div class="row">
								<div v-if="active_date == '31d'">
									<div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">М</div>
								</div>
								<div v-else>
									<div class="circle-50 mb-2" v-on:click="active_date = '31d'; fillData()">М</div>
								</div>
							</div>
							<div class="row">
								<div v-if="active_date == '365d'">
									<div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">Г</div>
								</div>
								<div v-else>
									<div class="circle-50 mb-2" v-on:click="active_date = '365d'; fillData()">Г</div>
								</div>
							</div>
						</div>

						<div v-else-if="active_button == 'files'">
							<div class="parameters-div" style="height: 208px;">
								<div class="d-flex justify-content-between">
									<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.configfile') }}</span>
									<div v-if="this.file_update_state == 'UNRESOLVED'">
										<vue-simple-spinner size="30" :line-size="4" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5"/>
									</div>
									<div v-else class="d-flex">
										<div class="circle-35 files journal-download" v-on:click="updateFiles(); thing_update_files_status();"></div><div class="journal-download-div" style="letter-spacing: 0.9px; position: absolute; top: 43px; right: 40px;">Обновить</div>
									</div>
								</div>
								<hr style="background: #aaaaaa;">
								<span v-if="!files.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список файлов пуст</span>
								<div v-for="file in files" :key="file.id">
									<div class="d-flex justify-content-between">
										<span>{{ file.filename }}</span>
										<div class="row">
											<span>{{ toDate(file.updated) }}</span>
											<div class="download-journal-button ml-3" v-on:click="downloadFile(file)">Скачать</div>
										</div>
									</div>
								</div>
								<!-- <hr style="background: #aaaaaa;"> -->
								<div class="row mt-3">
									<span class="mt-1" style="letter-spacing: 0.8px;">Для обновления файла конфигурации, хранящегося на сервере, нажмите на кнопку </span><div class="circle-35 files journal-download ml-2" style="pointer-events: none;"></div>
								</div>
								<div class="row">
									<span style="letter-spacing: 0.8px;">Дождитесь обновления, затем нажмите </span><div class="download-journal-button ml-3" style="pointer-events: none;">Скачать</div>
								</div>
							</div>
							<div class="parameters-div mt-3">
								<div class="d-flex justify-content-between">
									<span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Осциллограммы</span>
								</div>
								<hr style="background: #aaaaaa;">
								<div class="journals-div overflow-auto scroller">
									<span v-if="!oscillogram_files.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список файлов пуст</span>
									<div v-for="file in oscillogram_files" :key="file.id">
										<div class="d-flex justify-content-between mt-1">
											<span>{{ file.filename }}</span>
											<div class="row">
												<span data-toggle="tooltip" data-placement="top" v-bind:title="'Загружено: ' + toDate(file.updated)">{{ formatDate(parseInt(file.timestamp)) }}</span>
												<!-- <span>{{ toDate(file.updated) }}</span> -->
												<div class="download-journal-button ml-3" v-on:click="downloadFile(file)">Скачать</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import VueSimpleSpinner from 'vue-simple-spinner';
	import {facilitiesService, thingsService, logsService, journalsService, filesService} from "../../_services";
	import LineChart from "../charts/LineChart.vue";
	import Vue from "vue";
	import moment from 'moment';
	// import Calendar from 'v-calendar/lib/components/calendar.umd';
	import DatePicker from 'v-calendar/lib/components/date-picker.umd';
	import download from 'downloadjs';


	moment.locale('ru');

	// Vue.component('calendar', Calendar)
	Vue.component('date-picker', DatePicker)

	export default {
		name: 'Laurel',
		props: ['facilityId', 'thingId'],
		components: {
			VueSimpleSpinner,
			LineChart,
			// Calendar,
			DatePicker
		},
		data() {
			return {
				preloading: false,
				facility: '',
				thing: '',
				equipment: '',
				active_button: 'chart',
				date: "",
				time: "",
				laurels: [],
				first_chart_data: {},
				second_chart_data: {},
				active_date: '24h',
				options: {
					responsive: true,
					scales: {
						xAxes: [{
						}],
					},
					tooltips: {
						enabled: false
					},
					legend: {
						display: true,
						position: 'top',
						align: 'start',
						labels: {
							boxWidth: 0,
						}
					},
				},
				first_chart_table: 'laurel_voltage_input',
				second_chart_table: 'laurel_voltage_out',
				ab_mode: '',
				laurel_state: '',
				signalization: [],
				diagnostic_statuses: [],
				event_logs: [],
				message_logs: [],
				event_range: {
					start: new Date(new Date().setHours(0,0,0,0)),
					end: new Date(new Date().setHours(23,59,59,0)),
				},
				message_range: {
					start: new Date(new Date().setHours(0,0,0,0)),
					end: new Date(new Date().setHours(23,59,59,0)),
				},
				modelConfig: {
					start: {
						timeAdjust: '00:00:00',
					},
					end: {
						timeAdjust: '23:59:59',
					},
				},
				event_journal_datepicker_show: false,
				message_journal_datepicker_show: false,
				files: [],
				file_update_state: '',
				group_1: [],
				group_2: [],
				ungroupped: [],
				signalling_info_toggle: false,
				oscillogram_files: [],
			}
		},
		async created() {
			this.preloading = true;
			await this.init();
			this.preloading = false;
			this.getNow();

			setInterval(function () {
				this.getNow();
				this.getThing();
				this.getFacility();
				this.getLaurels();
				this.formGroups();
				this.getABMode();
				this.laurel_state = this.getLaurelState(this.thing);
				this.setLaurelsStates();
				this.getSignalizations();
				this.getDiagnostics();
				// this.fillData();
				// console.log('updated');
			}.bind(this), 10000);
		},
		methods: {
			async init() {
				console.log('Rendering "Laurel" component');
				await this.getThing();
				await this.getFacility();
				await this.getLaurels();
				await this.getABMode();
				this.laurel_state = this.getLaurelState(this.thing);
				await this.setLaurelsStates();
				this.getSignalizations();
				this.getDiagnostics();
				await this.fillData();
				this.formGroups();
			},
			async getFacility() {
				let res = await facilitiesService.facility_get(this.facilityId);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.facility = res.result.facility;
				},
			async getThing() {
				let res = await thingsService.thing_get(this.facilityId, this.thingId);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.thing = res.result;

				if (this.thing.equipment) {
					this.equipment = this.thing.equipment;
				}
			},
			getNow: function() {
				let today = new Date(this.thing.updated + 'Z');
				this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
				this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
			},
			async getABMode() {
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[11] == "1") {
					this.ab_mode = "Обрыв цепи АБ"
					return
				}

				if (this.thing.shadow.analog_values.mode_0x0274_0x0275 == "0" || this.thing.shadow.analog_values.mode_0x0274_0x0275 == "1" || this.thing.shadow.analog_values.mode_0x0274_0x0275 == "2" || this.thing.shadow.analog_values.mode_0x0274_0x0275 == "3") {
					// this.ab_mode = "Заряд"
					if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0143[13] == "1") {
						this.ab_mode = "Подзаряд"
					} else {
						this.ab_mode = "Заряд"
					}
				}

				if (this.thing.shadow.analog_values.mode_0x0274_0x0275 == "4") {
					this.ab_mode = "Выравнивающий заряд"
				}

				if (this.thing.shadow.analog_values.mode_0x0274_0x0275 == "5") {
					this.ab_mode = "Контрольный разряд"
				}

				if (this.thing.shadow.analog_values.mode_0x0274_0x0275 == "6") {
					this.ab_mode = "Контроль цепи АБ"
				}

				if (this.thing.shadow.analog_values.mode_0x0274_0x0275 == "7") {
					this.ab_mode = "Разряд"
				}
			},
			setLaurelsStates() {
				// console.log(this.laurels)

				for (let number in this.laurels) {
					// console.log(this.laurels[number]);
					// console.log(await this.getLaurelState(this.laurels[number]));

					Vue.set(this.laurels[number], 'state', this.getLaurelState(this.laurels[number]));
				}

				if (this.ungroupped.length >= 1) {
					for (let number in this.ungroupped) {
						Vue.set(this.ungroupped[number], 'state', this.getLaurelState(this.ungroupped[number]));
					}
				}

				if (this.group_1.length >= 1) {
					for (let number in this.group_1) {
						Vue.set(this.group_1[number], 'state', this.getLaurelState(this.group_1[number]));
					}
				}

				if (this.group_2.length >= 1) {
					for (let number in this.group_2) {
						Vue.set(this.group_2[number], 'state', this.getLaurelState(this.group_2[number]));
					}
				}

				// Vue.set(this.accumulators[key], 'toggleSignallingInfo', !this.accumulators[key].toggleSignallingInfo);
			},
			getLaurelState(thing) {
				// console.log('Блокировка: ' + this.thing.shadow.logic_output_signals.logic_output_signal_0x0142[12])
				// console.log('Выдача приостановлена: ' + this.thing.shadow.logic_output_signals.logic_output_signal_0x0142[13])
				// console.log('Работа: ' + this.thing.shadow.logic_output_signals.logic_output_signal_0x0143[0])
				// console.log('Готовность: ' + this.thing.shadow.logic_output_signals.logic_output_signal_0x0142[11])

				if (thing.shadow && thing.shadow.logic_output_signals) {
					if (thing.shadow.logic_output_signals.logic_output_signal_0x0142[12] == "1") {
						// console.log('Блокировка')
						// this.laurel_state = 'Блокировка'
						return 'Блокировка'
					}

					if (thing.shadow.logic_output_signals.logic_output_signal_0x0142[13] == "1") {
						// console.log('Выдача приостановлена')
						// this.laurel_state = 'Выдача приостановлена'
						return 'Выдача приостановлена'
					}
					
					if (thing.shadow.logic_output_signals.logic_output_signal_0x0143[0] == "1") {
						// console.log('Работа')
						// this.laurel_state = 'Работа'
						return 'Работа'
					}

					if (thing.shadow.logic_output_signals.logic_output_signal_0x0142[11] == "1") {
						// console.log('Готовность')
						// this.laurel_state = 'Готовность'
						return 'Готовность'
					}

					if (thing.shadow.logic_output_signals.logic_output_signal_0x0142[12] == "0" && this.thing.shadow.logic_output_signals.logic_output_signal_0x0142[13] == "0" && this.thing.shadow.logic_output_signals.logic_output_signal_0x0143[0] == "0" && this.thing.shadow.logic_output_signals.logic_output_signal_0x0142[11] == "0") {
						// console.log('Включение')
						// this.laurel_state = 'Включение'
						return 'Включение'
					}
				}

				return 'Статус неизвестен'

			},
			goToThing(id) {
				window.location.href = '/facility/' + this.facilityId + '/things/' + id;
			},
			goBack() {
				// window.location.href = '/facility/' + this.facilityId + '/things';
				this.$router.go(-1);
			},
			getSignalizations() {
				this.signalization = [];

				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[9] == "1") {
					this.signalization.push("Срабатывание сигнализации")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[10] == "1") {
					this.signalization.push("АБ перенапряжение")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[11] == "1") {
					this.signalization.push("АБ разряжена")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[12] == "1") {
					this.signalization.push("АБ замерзла")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[13] == "1") {
					this.signalization.push("АБ перегрета")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014A[10] == "1") {
					this.signalization.push("АБ останов заряда")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[14] == "1") {
					this.signalization.push("Повышенное напр. питания")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0144[15] == "1") {
					this.signalization.push("Низкое напряжение питания")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[1] == "1") {
					this.signalization.push("Высокое напр. на выходе ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[2] == "1") {
					this.signalization.push("ЗПУ заблокирован")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[6] == "1") {
					this.signalization.push("Недостаточная мощность сети")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[7] == "1") {
					this.signalization.push("Перегрузка по мощности")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[8] == "1") {
					this.signalization.push("Блок. ЗПУ по мощности")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[11] == "1") {
					this.signalization.push("Обрыв цепи АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[12] == "1") {
					this.signalization.push("Отказ вентилятора ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[13] == "1") {
					this.signalization.push("Перегрев ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[14] == "1") {
					this.signalization.push("Земля в сети предупр сигн.")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0145[15] == "1") {
					this.signalization.push("Земля в сети авар. сигн.")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014A[13] == "1") {
					this.signalization.push("Авар. откл. ЗА ввод 1")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014A[14] == "1") {
					this.signalization.push("Авар. откл. ЗА ввод 2")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[0] == "1") {
					this.signalization.push("Аварийное отключение ЗА")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[1] == "1") {
					this.signalization.push("Аварийное отключение ЗА АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[2] == "1") {
					this.signalization.push("Аварийное отключение ЗА 1 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[3] == "1") {
					this.signalization.push("Аварийное отключение ЗА 2 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[4] == "1") {
					this.signalization.push("Аварийное отключение ЗА отходящего присоединения")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[5] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 1")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[6] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 2")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[7] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 3")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[8] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 4")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[9] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 5")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[10] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 6")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[11] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 7")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[12] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 8")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[13] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 9")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[14] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 10")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0146[15] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 11")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[0] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 12")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[1] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 13")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[2] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 14")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[3] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 15")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[4] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 16")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[5] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 17")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[6] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 18")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[7] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 19")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[8] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 20")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014A[15] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 21")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[0] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 22")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[1] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 23")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[2] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 24")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[3] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 25")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[4] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 26")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[5] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 27")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[6] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 28")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[7] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 29")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[8] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 30")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[9] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 31")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[10] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 32")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[11] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 33")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[12] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 34")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[13] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 35")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[14] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 36")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014B[15] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 37")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[0] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 38")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[1] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 39")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[2] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 40")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[3] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 41")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[4] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 42")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[5] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 43")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[6] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 44")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[7] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 45")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[8] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 46")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[9] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 47")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[10] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 48")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[11] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 49")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[12] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 50")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[13] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 51")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[14] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 52")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014C[15] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 53")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[0] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 54")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[1] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 55")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[2] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 56")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[3] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 57")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[4] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 58")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[5] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 59")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[6] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 60")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[7] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 61")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[8] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 62")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[9] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 63")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[10] == "1") {
					this.signalization.push("Аварийное отключение ЗА ОП 64")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[9] == "1") {
					this.signalization.push("Аварийное отключение ЗА 1 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[10] == "1") {
					this.signalization.push("Аварийное отключение ЗА 2 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[11] == "1") {
					this.signalization.push("Аварийное отключение ЗА 1 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[12] == "1") {
					this.signalization.push("Аварийное отключение ЗА 2 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[13] == "1") {
					this.signalization.push("Аварийное отключение ЗА 3 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[14] == "1") {
					this.signalization.push("Аварийное отключение ЗА 4 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014D[15] == "1") {
					this.signalization.push("Аварийное отключение ЗА 5 группы ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[11] == "1") {
					this.signalization.push("Сигнализация перекоса напряжений полюсов")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[12] == "1") {
					this.signalization.push("Повышенное напряжение на 1 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[13] == "1") {
					this.signalization.push("Повышенное напряжение на 2 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[14] == "1") {
					this.signalization.push("Пониженное напряжение на 1 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0147[15] == "1") {
					this.signalization.push("Пониженное напряжение на 2 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[0] == "1") {
					this.signalization.push("Пульсации на 1 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[1] == "1") {
					this.signalization.push("Пульсации на 2 секции")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[2] == "1") {
					this.signalization.push("Сигнализация снижения заряда АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[3] == "1") {
					this.signalization.push("Сигнализация снижения емкости АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[4] == "1") {
					this.signalization.push("Высокие пульсации напряжения на АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[5] == "1") {
					this.signalization.push("Срабатывание АВР на стороне питающей сети")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[6] == "1") {
					this.signalization.push("Предупредительная сигнализация системы мониторинга АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[7] == "1") {
					this.signalization.push("Аварийная сигнализация системы мониторинга АБ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0149[10] == "1") {
					this.signalization.push("Неисправность цепи готовности")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0149[12] == "1") {
					this.signalization.push("Неисправности цепи работы")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0149[13] == "1") {
					this.signalization.push("Неисправность НЕРВ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[8] == "1") {
					this.signalization.push("Сигнализация неисправности ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x0148[9] == "1") {
					this.signalization.push("Сигнализация отказа ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014E && this.thing.shadow.logic_output_signals.logic_output_signal_0x014E[4] == "1") {
					this.signalization.push("Сигнализация низкого напряжения на выходе ЗПУ")
				}
				if (this.thing.shadow.logic_output_signals.logic_output_signal_0x014E && this.thing.shadow.logic_output_signals.logic_output_signal_0x014E[5] == "1") {
					this.signalization.push("Сигнализация КЗ в СОПТ")
				}
			},
			getDiagnostics() {
				this.diagnostic_statuses = [];

				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[1] == "1") {
					this.diagnostic_statuses.push("Неисправность АЦП внутри МК")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[4] == "1") {
					this.diagnostic_statuses.push("Неисправность кварцевого резонатора")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[5] == "1") {
					this.diagnostic_statuses.push("Неисправность Flash памяти NAND")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[6] == "1") {
					this.diagnostic_statuses.push("Неисправность часов реального времени")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[7] == "1") {
					this.diagnostic_statuses.push("Структура параметров калибровки не соответствует версии микропрограммы")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[8] == "1") {
					this.diagnostic_statuses.push("Параметры калибровки не заданы")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[13] == "1") {
					this.diagnostic_statuses.push("Отказ НЕРВ")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0390[14] == "1") {
					this.diagnostic_statuses.push("Дата/время невалидны")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[0] == "1") {
					this.diagnostic_statuses.push("Структура хранилища уставок или файловой системы не соответствует версии микропрограммы")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[1] == "1") {
					this.diagnostic_statuses.push("Датчик температуры АБ поврежден или температура вне диапазона")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[2] == "1") {
					this.diagnostic_statuses.push("Заводские настройки не заданы пользователем, либо ошибка ФК")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[3] == "1") {
					this.diagnostic_statuses.push("Ионистр поврежден, или емкость не соответствует допустимой")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[4] == "1") {
					this.diagnostic_statuses.push("Датчик температуры АБ поврежден или температура вне диапазона")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[5] == "1") {
					this.diagnostic_statuses.push("Датчик температуры МК поврежден или температура вне диапазона")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[6] == "1") {
					this.diagnostic_statuses.push("Датчик температуры транзисторов поврежден или температура вне диапазона")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[7] == "1") {
					this.diagnostic_statuses.push("Датчик температуры диода поврежден или температура вне диапазона")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[9] == "1") {
					this.diagnostic_statuses.push("Неисправность аналогового входа Uвход выпр")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[10] == "1") {
					this.diagnostic_statuses.push("Неисправность аналогового входа Uвых")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[11] == "1") {
					this.diagnostic_statuses.push("Неисправность аналогового входа Uзпу")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[12] == "1") {
					this.diagnostic_statuses.push("Неисправность аналогового входа Iзпу груб")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[13] == "1") {
					this.diagnostic_statuses.push("Неисправность аналогового входа Iзпу точн")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0391[14] == "1") {
					this.diagnostic_statuses.push("Неисправность внутреннего источника 12 В")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[0] == "1") {
					this.diagnostic_statuses.push("Неисправность внутреннего источника 5 В")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[1] == "1") {
					this.diagnostic_statuses.push("Неисправность напряжения на линиях 5 В")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[2] == "1") {
					this.diagnostic_statuses.push("Неисправность напряжения на линиях 3.3 В")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[3] == "1") {
					this.diagnostic_statuses.push("Неисправность напряжения на линиях 24 В")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[6] == "1") {
					this.diagnostic_statuses.push("Неисправность диодного моста на входе")
				}
				if (this.thing.shadow.device_diagnostic_status.device_diagnostic_status_0x0392[9] == "1") {
					this.diagnostic_statuses.push("Вентилятор блокирован")
				}
			},
			async getLaurels() {
				let data = {
					'typeId': 'Laurel',
					'equipmentId': this.equipment.id
				}

				let res = await thingsService.things_get_new(this.facilityId, data);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.laurels = res.result.items;
			},
			formGroups() {
				this.group_1 = []
				this.group_2 = []
				this.ungroupped = []

				for (let number in this.laurels) {

					// console.log(this.laurels[number]);

					if (this.laurels[number].group.id) {
						if (this.laurels[number].group.id == '') {
							this.ungroupped.push(this.laurels[number])
						} else if (this.laurels[number].group.id == 'group_1') {
							this.group_1.push(this.laurels[number])
						} else if (this.laurels[number].group.id == 'group_2') {
							this.group_2.push(this.laurels[number])
						}
					} else {
						this.ungroupped.push(this.laurels[number])
					}
				}

				// console.log(this.ungroupped);
			},
			// thing_files_get
			async getThingFiles(type) {
				// if (type == 'configuration_file') {
				// 	start_time = this.event_range.start.getTime() * 1000;
				// 	end_time = this.event_range.end.getTime() * 1000;
				// } else if (type == 'oscillogram_file') {
				// 	start_time = this.message_range.start.getTime() * 1000;
				// 	end_time = this.message_range.end.getTime() * 1000;
				// }

				let args = {
					types: type,
					// start:,
					// limit:,
				}

				let res = await filesService.thing_files_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.files = res.result.items;

				// console.log(this.files);
			},
			async getThingOscillogramFiles() {
				let args = {
					types: 'oscillogram_file',
					start: 0,
					limit: 50,
				}

				let res = await filesService.thing_files_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.oscillogram_files = res.result.items;

				console.log(this.oscillogram_files)
			},
			toDate(updated_date) {
				let today = new Date(updated_date + 'Z');
				let date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
				let time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);

				return time + " | " + date
			},
			async get_event_logs() {
				let args = {
					types: 'event_log',
					start: 0,
					limit: 50,
				}

				let res = await journalsService.journals_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.event_logs = res.result.items;
			},
			async get_system_logs() {
				let args = {
					types: 'message_log',
					start: 0,
					limit: 50,
				}

				let res = await journalsService.journals_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.message_logs = res.result.items;
			},
			formatDate(value) {
				if (value) {
					return moment(value/1000).format("llLTS")
				}

				return value;
			},
			getEventDescription(events) {
				let answers = []

				for (var i = 0; i < events.length; i++) {

					if (events[i] == 1) {
						answers.push(i);
					}

				}

				let full_answers = answers.map(function(id) {
					if (id == 0) {
						return 'Повышенное напряжение АБ'
					}
					if (id == 1) {
						return 'АБ разряжена'
					}
					if (id == 2) {
						return 'Замерзание АБ'
					}
					if (id == 3) {
						return 'Перегрев АБ'
					}
					if (id == 4) {
						return 'Старт вентиляции шкафа'
					}
					if (id == 5) {
						return 'Старт обогрева шкафа'
					}
					if (id == 6) {
						return 'Повышенное напряжение питания'
					}
					if (id == 7) {
						return 'Пониженное напряжение питания'
					}
					if (id == 8) {
						return 'Низкое напряжение в цепи инвертора'
					}
					if (id == 9) {
						return 'Высокое напряжение на выходе ЗПУ'
					}
					if (id == 10) {
						return 'ЗПУ готов к работе'
					}
					if (id == 11) {
						return 'Выдача мощности заблокирована'
					}
					if (id == 12) {
						return 'ЗПУ выдача мощности'
					}
					if (id == 13) {
						return 'Смена группы ЗПУ'
					}
					if (id == 14) {
						return 'Параллельный режим по внешнему сигналу'
					}
					if (id == 15) {
						return 'Недостаточная мощность питающей сети'
					}
					if (id == 16) {
						return 'Перегрузка'
					}
					if (id == 17) {
						return 'Блокирование ЗПУ по мощности'
					}
					if (id == 18) {
						return 'Неисправность канала напряжения'
					}
					if (id == 19) {
						return 'Неисправность канала тока'
					}
					if (id == 20) {
						return 'Профилактический пуск вентилятора'
					}
					if (id == 21) {
						return 'Включен режим выравнивающего заряда'
					}
					if (id == 22) {
						return 'Включен режим контрольного разряда'
					}
					if (id == 23) {
						return 'Включен режим контроля целостности цепи АБ'
					}
					if (id == 24) {
						return 'Нарушение целостности цепи АБ'
					}
					if (id == 25) {
						return 'Замедленное вращение вентилятора'
					}
					if (id == 26) {
						return 'Силовой пуск'
					}
					if (id == 27) {
						return 'Силовой пуск неуспешен'
					}
					if (id == 28) {
						return 'ЗПУ блокирован по перегреву'
					}
					if (id == 29) {
						return 'Срабатывание сигнализации'
					}
					if (id == 30) {
						return 'Съем сигнализации'
					}
					if (id == 31) {
						return 'Bluetooth включен'
					}
					if (id == 32) {
						return 'Автоматический пуск теста цепи АБ'
					}
					if (id == 33) {
						return 'Режим работы ЗПУ по группам'
					}
					if (id == 34) {
						return 'Режим параллельной аварийной работы ЗПУ'
					}
					if (id == 35) {
						return 'Резерв готов'
					}
					if (id == 36) {
						return 'ЗПУ выдача мощности в параллельном режиме'
					}
					if (id == 37) {
						return 'Группа готова'
					}
					if (id == 38) {
						return 'Резерв в работе'
					}
					if (id == 39) {
						return 'Смена группы'
					}
					if (id == 40) {
						return 'Смена группы при неисправности шины НЕРВ в другой группе'
					}
					if (id == 41) {
						return 'Выдача приостановлена'
					}
					if (id == 42) {
						return 'Приостановка выдачи мощности по внешнему сигналу'
					}
					if (id == 43) {
						return 'Останов заряда АБ'
					}
					if (id == 44) {
						return 'Смена группы при неисправности шины НЕРВ'
					}
					if (id == 45) {
						return 'Работа по ДВ'
					}
					if (id == 46) {
						return 'Низкое напряжение на выходе ЗПУ'
					}
					if (id == 47) {
						return 'КЗ в СОПТ'
					}
					if (id == 48) {
						return 'Цепь АБ исправна'
					}

					return id;
				});

				return full_answers;
			},
			getMessageDescription(id, data) {

				if (id == 0) {
					return 'Включение «Laurel»'
				}
				if (id == 1) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Режим питания «от сети»'
					}
					if (x == 1) {
						return 'Режим питания «от USB»'
					}

					return 'Режим питания «X»'
				}
				if (id == 2) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);					

					if (x == 0) {
						return 'Восстановление хранилища MCU FLASH после сбоя'
					}
					if (x == 1) {
						return 'Восстановление хранилища NAND FLASH после сбоя'
					}

					return 'Восстановление хранилища (X) после сбоя'
				}
				if (id == 3) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					// let y = parseInt(Number(data.slice(8, 16)), 16)

					let x = parseInt(data.slice(0, 8), 16);
					let y = parseInt(data.slice(8, 16), 16);

					if (y < 32) {
						if (x == 0) {
							return 'НЕРВ - УРОВ сраб. [' + y + ']'
						}
						if (x == 1) {
							return 'НЕРВ - Пуск ЗДЗ по I [' + y + ']'
						}
						if (x == 2) {
							return 'НЕРВ - ЛЗШ датчик [' + y + ']'
						}
						if (x == 3) {
							return 'НЕРВ - АВР на вкл. СВ [' + y + ']'
						}
						if (x == 4) {
							return 'НЕРВ - ВНР на откл. СВ [' + y + ']'
						}
					}

					return id
				}
				if (id == 4) {
					return '«Laurel» готов'
				}
				if (id == 5) {
					return 'USB кабель подключен'
				}
				if (id == 6) {
					return 'USB кабель отключен'
				}
				if (id == 7) {
					return 'Сбой тактового генератора'
				}
				if (id == 8) {
					//let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Уровень доступа "полный доступ" активирован'
					}
					if (x == 1) {
						return 'Уровень доступа "наладчик РЗА" активирован'
					}
					if (x == 2) {
						return 'Уровень доступа "инженер РЗА" активирован'
					}
					if (x == 3) {
						return 'Уровень доступа "оперативный персонал" активирован'
					}
					if (x == 4) {
						return 'Уровень доступа "только чтение" активирован'
					}
					return id
				}
				if (id == 9) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Уровень доступа "полный доступ" деактивирован'
					}
					if (x == 1) {
						return 'Уровень доступа "наладчик РЗА" деактивирован'
					}
					if (x == 2) {
						return 'Уровень доступа "инженер РЗА" деактивирован'
					}
					if (x == 3) {
						return 'Уровень доступа "оперативный персонал" деактивирован'
					}
					if (x == 4) {
						return 'Уровень доступа "только чтение" деактивирован'
					}
					return id
				}
				if (id == 12) {
					return 'Изменение уставок из KIWI'
				}
				if (id == 13) {
					return 'Изменение уставок из АСУ'
				}
				if (id == 14) {
					// let x = parseFloat(Number(data.slice(0, 8)), 16)
					let x = parseFloat(data.slice(0, 8), 16);

					return 'Неверное напряжение питания ' + x + ' B'
				}
				if (id == 15) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 1) {
						return 'Неисправность «Laurel» (Неисправность АЦП МК)'
					}
					if (x == 3) {
						return 'Неисправность «Laurel» (Неисправность CAN)'
					}
					if (x == 4) {
						return 'Неисправность «Laurel» (Неисправность кварцевого резонатора)'
					}
					if (x == 5) {
						return 'Неисправность «Laurel» (Неисправность чипа памяти NAND)'
					}
					if (x == 6) {
						return 'Неисправность «Laurel» (6-Неисправность часов реального времени)'
					}
					if (x == 7) {
						return 'Неисправность «Laurel» (Структура параметров калибровки не соответствует версии микропрограммы)'
					}
					if (x == 8) {
						return 'Неисправность «Laurel» (Параметры калибровки не заданы)'
					}
					if (x == 13) {
						return 'Неисправность «Laurel» (Неисправность НЕРВ)'
					}
					if (x == 14) {
						return 'Неисправность «Laurel» (Дата/время некорректные)'
					}
					if (x == 15) {
						return 'Неисправность «Laurel» (Ошибка контрольной суммы хранилища уставок)'
					}
					if (x == 16) {
						return 'Неисправность «Laurel» (Структура хранилища уставок или файловой системы не соответствует версии микропрограммы)'
					}
					if (x == 17) {
						return 'Неисправность «Laurel» (Неисправность системы хранения счетчиков)'
					}
					if (x == 18) {
						return 'Неисправность «Laurel» (Заводские настройки не заданы пользователем)'
					}
					if (x == 19) {
						return 'Неисправность «Laurel» (Ионистр поврежден, или емкость не соответствует допустимой)'
					}
					if (x == 20) {
						return 'Неисправность «Laurel» (Неисправность датчика температуры АБ)'
					}
					if (x == 21) {
						return 'Неисправность «Laurel» (Неисправность датчика температуры МК)'
					}
					if (x == 22) {
						return 'Неисправность «Laurel» (Неисправность датчика температуры транзисторов)'
					}
					if (x == 23) {
						return 'Неисправность «Laurel» (Неисправность датчика температуры диодов)'
					}
					if (x == 24) {
						return 'Неисправность «Laurel» (Неисправность Uвход)'
					}
					if (x == 25) {
						return 'Неисправность «Laurel» (Неисправность Uвход_выпр)'
					}
					if (x == 26) {
						return 'Неисправность «Laurel» (Неисправность Uаб)'
					}
					if (x == 27) {
						return 'Неисправность «Laurel» (Неисправность Uвзу)'
					}
					if (x == 28) {
						return 'Неисправность «Laurel» (Неисправность Iвзу.груб)'
					}
					if (x == 30) {
						return 'Неисправность «Laurel» (Неисправность БП 12В)'
					}
					if (x == 32) {
						return 'Неисправность «Laurel» (Неисправность БП 5В)'
					}
					if (x == 33) {
						return 'Неисправность «Laurel» (Неисправность питания 5В)'
					}
					if (x == 34) {
						return 'Неисправность «Laurel» (Неисправность питания 3.3В)'
					}
					if (x == 35) {
						return 'Неисправность «Laurel» (Неисправность питания 24В)'
					}
					if (x == 36) {
						return 'Неисправность «Laurel» (Неисправность ООС по U)'
					}
					if (x == 38) {
						return 'Неисправность «Laurel» (Неисправность диодного моста)'
					}
					if (x == 39) {
						return 'Неисправность «Laurel» (Неисправность АЦП/ЦАП U)'
					}
					if (x == 40) {
						return 'Неисправность «Laurel» (Неисправность АЦП/ЦАП I)'
					}
					if (x == 41) {
						return 'Неисправность «Laurel» (Неисправность вентилятора)'
					}

					return id
				}
				if (id == 16) {
					return 'Ошибка записи уставок'
				}
				if (id == 17) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 1) {
						return 'Неисправность НЕРВ (НЕРВ - неизвестное устройство в сети)'
					}
					if (x == 2) {
						return 'Неисправность НЕРВ (НЕРВ - несконфигурированное устройство в сети)'
					}
					if (x == 3) {
						return 'Неисправность НЕРВ (НЕРВ - потеря устройства в сети)'
					}
					if (x == 4) {
						return 'Неисправность НЕРВ (НЕРВ - в сети отсутствуют все подписчики типа ВВ (Ввод))'
					}
					if (x == 5) {
						return 'Неисправность НЕРВ (НЕРВ - в сети отсутствуют все издатели)'
					}
					if (x == 6) {
						return 'Неисправность НЕРВ (НЕРВ - нарушение обмена в сети)'
					}
					if (x == 7) {
						return 'Неисправность НЕРВ (НЕРВ - отсутствует конфигурация)'
					}

					return id
				}
				if (id == 18) {
					return 'Потеря питания 5 В'
				}
				if (id == 19) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Выполнена заводская активация устройства'
					}
					if (x == 1) {
						return 'Выполнена активация устройства'
					}
					if (x == 2) {
						return 'Выполнен сброс активации устройства'
					}
				}
				if (id == 21) {
					return 'Кнопка Bluetooth нажата'
				}
				if (id == 22) {
					return 'Кнопка ВЫКЛ нажата'
				}
				if (id == 23) {
					return 'Кнопка СБРОС нажата'
				}
				if (id == 25) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					// let y = parseInt(Number(data.slice(8, 16)), 16)
					let x = parseInt(data.slice(0, 8), 16);
					let y = parseInt(data.slice(8, 16), 16);

					if (y < 32) {
						if (x == 0) {
							return 'НЕРВ - ОПРОБОВАНИЕ - УРОВ сраб. [' + y + ']'
						}
						if (x == 1) {
							return 'НЕРВ - ОПРОБОВАНИЕ - Пуск ЗДЗ по I [' + y + ']'
						}
						if (x == 2) {
							return 'НЕРВ - ОПРОБОВАНИЕ - ЛЗШ датчик [' + y + ']'
						}
						if (x == 3) {
							return 'НЕРВ - ОПРОБОВАНИЕ - АВР на вкл. СВ [' + y + ']'
						}
						if (x == 4) {
							return 'НЕРВ - ОПРОБОВАНИЕ - ВНР на откл. СВ [' + y + ']'
						}
					}

					return id
				}
				if (id == 29) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Устройство вне конфигурации [' + x + ']'
				}
				if (id == 30) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Остановка диагностики НЕРВ устройства [' + x + ']'
				}
				if (id == 33) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Дата/время изменено пользователем по сети НЕРВ'
					}
					if (x == 1) {
						return 'Дата/время изменено пользователем'
					}

					return 'Дата/время изменено'
				}
				if (id == 34) {
					return 'Ошибка CRC NAND'
				}
				if (id == 36) {
					return 'Ошибка CRC NVMEM'
				}
				if (id == 37) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Перезапуск (Неизвестная причина)'
					}
					if (x == 1) {
						return 'Перезапуск (Включение питания)'
					}
					if (x == 2) {
						return 'Перезапуск (Внешний сброс)'
					}
					if (x == 3) {
						return 'Перезапуск (Срабатывание сторожевого таймера)'
					}
					if (x == 4) {
						return 'Перезапуск (Программный рестарт)'
					}

					return 'Перезапуск'
				}
				if (id == 38) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					// let y = parseInt(Number(data.slice(8, 16)), 16)
					let x = parseInt(data.slice(0, 8), 16);
					let y = parseInt(data.slice(8, 16), 16);

					return 'Постоянная составляющая канала №' + x + ' выше ' + y
				}
				if (id == 39) {
					// let y = parseFloat(Number(data.slice(0, 8)), 16)
					// let xmin = parseFloat(Number(data.slice(8, 16)), 16)
					// let xmax = parseFloat(Number(data.slice(16, 32)), 16)
					let y = parseFloat(data.slice(0, 8), 16);
					let xmin = parseInt(data.slice(8, 16), 16);
					let xmax = parseInt(data.slice(16, 32), 16);

					return 'Опорное напряжение АЦП1 ' + y + ' вне допустимых пределов [' + xmin + '..' + xmax + ']'
				}
				if (id == 40) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					// let y = parseInt(Number(data.slice(8, 16)), 16)
					let x = parseInt(data.slice(0, 8), 16);
					let y = parseInt(data.slice(8, 16), 16);

					return 'Ресурс блинкера ' + x + ' исчерпан (' + y + ' переключений)'
				}
				if (id == 41) {
					return 'Неисправность датчика температуры'
				}
				if (id == 42) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Ошибка CRC при инициализации региона ' + x + ' NVMEM'
				}
				if (id == 44) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					// let y = parseFloat(Number(data.slice(8, 16)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Неисправность ионистра (Снижение емкости)'
					}
					if (x == 1) {
						return 'Неисправность ионистра (Обрыв цепи заряда, отсутствие)'
					}
				}
				if (id == 45) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Время восстановлено (RTC)'
					}
					if (x == 1) {
						return 'Время восстановлено (Дата производства блока)'
					}
					if (x == 2) {
						return 'Время восстановлено (Дата производства микропрограммы)'
					}
					if (x == 3) {
						return 'Время восстановлено (Энергонезависимая память)'
					}
					if (x == 4) {
						return 'Время восстановлено (Журнлы)'
					}
					if (x == 5) {
						return 'Время восстановлено (Копия энергонезависимой памяти в NAND)'
					}
					if (x == 6) {
						return 'Время восстановлено (Время зашитое в код ПО)'
					}
				}
				if (id == 46) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					if (x == 0) {
						return 'Оперативное включение из АСУ'
					}
					if (x == 1) {
						return 'Оперативное включение из KIWI'
					}
					if (x == 2) {
						return 'Оперативное отключение из АСУ'
					}
					if (x == 3) {
						return 'Оперативное отключение из KIWI'
					}
					if (x == 4) {
						return 'Съем сигнализации из АСУ'
					}
					if (x == 5) {
						return 'Съем сигнализации из KIWI'
					}
					if (x == 6) {
						return 'Режим функционального контроля включен'
					}
					if (x == 7) {
						return 'Режим функционального контроля выключен'
					}
					if (x == 8) {
						return 'Режим конфигурирования НЕРВ включен'
					}
					if (x == 9) {
						return 'Режим конфигурирования НЕРВ выключен'
					}
					if (x == 10) {
						return 'ФК Laurel обновлен'
					}
					if (x == 11) {
						return 'ФК Laurel - ошибка обновления'
					}
					if (x == 12) {
						return 'НЕРВ - конфигурация обновлена'
					}
					if (x == 13) {
						return 'НЕРВ - временная конфигурация обновлена'
					}
					if (x == 14) {
						return 'НЕРВ - сброс конфигурации'
					}
					if (x == 15) {
						return 'НЕРВ - отмена обновления конфигурации'
					}
					if (x == 16) {
						return 'НЕРВ - ошибка обновления конфигурации'
					}
					if (x == 17) {
						return 'Пуск осциллографа АСУ'
					}
					if (x == 18) {
						return 'Пуск осциллографа KIWI'
					}
					if (x == 19) {
						return 'ВЗ пуск АСУ'
					}
					if (x == 20) {
						return 'ВЗ пуск KIWI'
					}
					if (x == 21) {
						return 'ВЗ стоп АСУ'
					}
					if (x == 22) {
						return 'ВЗ стоп KIWI'
					}
					if (x == 23) {
						return 'Тест АБ пуск АСУ'
					}
					if (x == 24) {
						return 'Тест АБ пуск KIWI'
					}
					if (x == 25) {
						return 'Тест АБ стоп АСУ'
					}
					if (x == 26) {
						return 'Тест АБ стоп KIWI'
					}
					if (x == 27) {
						return 'Тест цепей АСУ'
					}
					if (x == 28) {
						return 'Тест цепей KIWI'
					}
					if (x == 29) {
						return 'Останов выдачи мощности АСУ'
					}
					if (x == 30) {
						return 'Останов выдачи мощности KIWI'
					}
					if (x == 31) {
						return 'Пуск выдачи мощности АСУ'
					}
					if (x == 32) {
						return 'Пуск выдачи мощности KIWI'
					}
				}
				if (id == 48) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Срабатывание дискретного входа №' + x + 1
				}
				if (id == 49) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Возврат дискретного входа №' + x + 1
				}
				if (id == 50) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Срабатывание реле №' + x + 1
				}
				if (id == 51) {
					// let x = parseInt(Number(data.slice(0, 8)), 16)
					let x = parseInt(data.slice(0, 8), 16);

					return 'Возврат реле №' + x + 1
				}
				if (id == 52) {
					return 'Обновлено ПО устройства'
				}

				return id
			},
			async download_log(type) {
				let start_time = 0;
				let end_time = 0;

				if (type == 'event_log') {
					start_time = this.event_range.start.getTime() * 1000;
					end_time = this.event_range.end.getTime() * 1000;
				} else if (type == 'message_log') {
					start_time = this.message_range.start.getTime() * 1000;
					end_time = this.message_range.end.getTime() * 1000;
				}

				let args = {
					types: type,
					timestampGte: start_time,
					timestampLte: end_time,
					// start:,
					// limit:,
					// limit: 1000,
					limit: 10000
				}

				let res = await journalsService.journals_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				let temp_log = res.result.items;

				if (temp_log.length > 0){
					if (type == 'event_log') {
						await this.csvExportEvent(temp_log, res.result.filters);
					} else if ( type == 'message_log') {
						await this.csvExportMessage(temp_log, res.result.filters);
					}
				} else {
					alert('Журнал за указанный период пуст!');
				}
			},
			getTitles(data) {

				let titles = {
					// 'AnalogOutputSignals': 'Аналоговые выходные сигналы',
					// 'BinaryOutputs': 'Состояние двоичных выходов',
					// 'binaryInputs': 'Состояние двоичных входов',
					'events': 'События',
					// 'record_number': 'Номер записи',
					'timestamp': 'Время',
					// 'binaryInputSignals': 'Состояние двоичных входных сигналов',
					// 'binaryOutputSignals': 'Состояние двоичных выходных сигналов',
					// 'KIWIAccessLevelID': 'Идентификатор уровня доступа от Киви',
					'MsgID': 'События',
					// 'MsgData': 'Данные события',
					// 'SCADAAccessLevelID': 'Идентификатор уровня доступа от АСУ',
					// 'reserve': 'Резерв',
					// 'timeoffset': 'Смещение времени'
				}

				let answ = ''

				for (let key in data) {
					if (titles[key]) {
						// answ += titles[key] + ';'
						// reversing
						answ = titles[key] + ';' + answ
					} else {
						// answ += key + ';'
					}
				}

				return answ
			},
			async csvExportEvent(arrData, filters) {
				let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
				// let csvContent = "data:text/csv;charset=windows-1251,";

				// console.log(arrData);
				// console.log(arrData[0].data);

				// console.log(arrData)
				// console.log(arrData[0])

				csvContent += [
					// Object.keys(arrData[0].data).join(";"),
					this.getTitles(arrData[0].data),
					...arrData.map((item) => {
						let data_value = ''

						// // data_value += item.data.AnalogOutputSignals + ";"
						// // data_value += item.data.BinaryOutputs + ";"
						// // data_value += item.data.binaryInputSignals + ";"
						// // data_value += item.data.binaryInputs + ";"
						// // data_value += item.data.binaryOutputSignals + ";"
						// data_value += this.getEventDescription(item.data.events) + ";"
						// // data_value += item.data.record_number + ";"
						// data_value += this.formatDate(item.data.timestamp)

						data_value += this.formatDate(item.data.timestamp) + ";"
						data_value += this.getEventDescription(item.data.events) + ";"

						return data_value
					})  // Object.values(item.data).join(";"))
				]
					.join("\n")
					.replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(csvContent);
				const link = document.createElement("a");
				link.setAttribute("href", data);
				// link.setAttribute("download", "events_journal.csv");

				let start_date = new Date(filters.timestampGte / 1000).toLocaleDateString("ru-Ru");
				let end_date = new Date(filters.timestampLte / 1000).toLocaleDateString("ru-Ru");

				let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

				link.setAttribute("download", "LAUREL-12_" + this.thing.number + "_Журнал_событий_" + start_end_date + ".csv");
				link.click();
			},
			async csvExportMessage(arrData, filters) {
				let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
				// let csvContent = "data:text/csv;charset=utf-8,";
				// console.log(arrData[0].data);

				csvContent += [
					// Object.keys(arrData[0].data).join(";"),
					this.getTitles(arrData[0].data),
					...arrData.map((item) => {
						let data_value = ''

						// // data_value += item.data.KIWIAccessLevelID + ";"
						// // data_value += item.data.MsgData + ";"
						// data_value += this.getMessageDescription(item.data.MsgID, item.data.MsgData) + ";"
						// // data_value += item.data.SCADAAccessLevelID + ";"
						// // data_value += item.data.record_number + ";"
						// // data_value += item.data.reserve + ";"
						// // data_value += item.data.timeoffset + ";"
						// data_value += this.formatDate(item.data.timestamp)

						data_value += this.formatDate(item.data.timestamp) + ";"
						data_value += this.getMessageDescription(item.data.MsgID, item.data.MsgData) + ";"

						return data_value
					}) // Object.values(item.data).join(";"))
				]
					.join("\n")
					.replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(csvContent);
				const link = document.createElement("a");
				link.setAttribute("href", data);
				// link.setAttribute("download", "events_journal.csv");

				let start_date = new Date(filters.timestampGte / 1000).toLocaleDateString("ru-Ru");
				let end_date = new Date(filters.timestampLte / 1000).toLocaleDateString("ru-Ru");

				let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

				link.setAttribute("download", "LAUREL-12_" + this.thing.number + "_Системный_журнал_" + start_end_date + ".csv");

				link.click();
			},
			async fillData() {
				if (this.active_button === 'chart') {
					this.linear_data_preloader = true;
					
					if (this.first_chart_table == 'laurel_zpu_state' || this.first_chart_table == 'laurel_ab_state') {
						await this.fill_first_states_chart();
					} else {
						await this.fill_first_chart();
					}

					if (this.second_chart_table == 'laurel_zpu_state' || this.second_chart_table == 'laurel_ab_state') {
						await this.fill_second_states_chart();
					} else {
						await this.fill_second_chart();
					}

					this.linear_data_preloader = false;
				} else {
					console.log('Unknown button state');
				}
			},
			async fill_first_chart() {
				this.options.scales.yAxes = [{
					ticks: {
						// min: 0,
					}
				}]

				this.options.scales.xAxes = [{
					ticks: {
						// min: 0,
						autoSkip: true,
						autoSkipPadding: 20,
						maxRotation: 0,
						callback: (value) => {
							let datetime = new Date(value);

							var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
							"Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

							if (this.active_date === '24h') {
								return datetime.getHours()
							} else if (this.active_date === '31d') {
								return datetime.getDate()
							} else if (this.active_date === '365d') {
								return months[datetime.getMonth()]
							} else {
								return datetime.getHours()
							}
						},
					}
				}]

				this.options.tooltips = {
					enabled: true,
					mode: 'label',
					callbacks: {
						title: function(tooltipItems, data) {
							var idx = tooltipItems[0].index;
							let datetime = new Date(data.labels[idx]);

							var options = {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								weekday: 'long',
								timezone: 'UTC',
							};

							return datetime.toLocaleString("ru", options);
						},
					}
				}

				let group_by = '1h';

				if (this.active_date == null) {
					this.active_date = '24h';
				}

				if (this.active_date === '24h') {
					// group_by = '1h';
					group_by = '5m';
				}
				if (this.active_date === '31d') {
					// group_by = '1d';
					group_by = '2h';
				}
				if (this.active_date === '365d') {
					// group_by = '31d';
					group_by = '7d';
				}

				let params = {
					"table": this.first_chart_table,
					"period": this.active_date,
					"group_by": group_by
				}

				if (this.thing.equipment && this.thing.equipment.id) {
					params.equipment_id = this.thing.equipment.id;
				}

				let first_chart_logs = await logsService.logs_laurel(this.facilityId, this.thing.id, params);

				if (first_chart_logs.error) {
					console.error(first_chart_logs.error);

					return;
				}

				let response = first_chart_logs.result.logs;

				// console.log(response);

				response = response.replace(/'/g, '"');
				response = response.replace(/None/g, 'null');

				var obj_reponse = JSON.parse(response);

				const timeArray= obj_reponse.map(element => element.time);

				const meanArray= obj_reponse.map(element => {
					element = element.mean;

					if (element) {
						element = element.toFixed(2);
					}

					return element
				});

				this.first_chart_data = {
					labels: timeArray,
					datasets: [
						{
							label: this.getLabelName(this.first_chart_table),
							data: meanArray,
							borderColor: 'rgba(142,220,40,0.9)',
							borderWidth: 1,
							pointRadius: 0,
							pointHoverRadius: 1,
							fill: false
						},
					]
				}
			},
			async fill_second_chart() {
				this.options.scales.yAxes = [{
					ticks: {
						// min: 0,
					}
				}]

				this.options.scales.xAxes = [{
					ticks: {
						// min: 0,
						autoSkip: true,
						autoSkipPadding: 20,
						maxRotation: 0,
						callback: (value) => {
							let datetime = new Date(value);

							var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
							"Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

							if (this.active_date === '24h') {
								return datetime.getHours()
							} else if (this.active_date === '31d') {
								return datetime.getDate()
							} else if (this.active_date === '365d') {
								return months[datetime.getMonth()]
							} else {
								return datetime.getHours()
							}
						},
					}
				}]

				this.options.tooltips = {
					enabled: true,
					mode: 'label',
					callbacks: {
						title: function(tooltipItems, data) {
							var idx = tooltipItems[0].index;
							let datetime = new Date(data.labels[idx]);

							var options = {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								weekday: 'long',
								timezone: 'UTC',
							};

							return datetime.toLocaleString("ru", options);
						},
					}
				}

				let group_by = '1h';

				if (this.active_date == null) {
					this.active_date = '24h';
				}

				if (this.active_date === '24h') {
					// group_by = '1h';
					group_by = '5m';
				}
				if (this.active_date === '31d') {
					// group_by = '1d';
					group_by = '2h';
				}
				if (this.active_date === '365d') {
					// group_by = '31d';
					group_by = '7d';
				}

				let params = {
					"table": this.second_chart_table,
					"period": this.active_date,
					"group_by": group_by
				}

				if (this.thing.equipment && this.thing.equipment.id) {
					params.equipment_id = this.thing.equipment.id;
				}

				let second_chart_logs = await logsService.logs_laurel(this.facilityId, this.thing.id, params);

				if (second_chart_logs.error) {
					console.error(second_chart_logs.error);

					return;
				}

				let response = second_chart_logs.result.logs;

				// console.log(response);

				response = response.replace(/'/g, '"');
				response = response.replace(/None/g, 'null');

				var obj_reponse = JSON.parse(response);

				const timeArray= obj_reponse.map(element => element.time);

				const meanArray= obj_reponse.map(element => {
					element = element.mean;

					// if (element) {
					// 	element = element;
					// }

					return element
				});

				this.second_chart_data = {
					labels: timeArray,
					datasets: [
						{
							label: this.getLabelName(this.second_chart_table),
							data: meanArray,
							borderColor: 'rgba(142,220,40,0.9)',
							borderWidth: 1,
							pointRadius: 0,
							pointHoverRadius: 1,
							fill: false
						},
					]
				}				
			},
			async fill_first_states_chart() {
				if (this.first_chart_table == 'laurel_ab_state') {
					this.options.scales.yAxes = [{
						ticks: {
							min: 1,
							max: 7,
							stepSize: 1,
							callback: (value) => {
								if (parseInt(value) == 1) {
									return 'Обрыв цепи АБ'
								} else if (parseInt(value) == 2) {
									return 'Подзаряд'
								} else if (parseInt(value) == 3) {
									return 'Заряд'
								} else if (parseInt(value) == 4) {
									return 'Выравнивающий заряд'
								} else if (parseInt(value) == 5) {
									return 'Контрольный разряд'
								} else if (parseInt(value) == 6) {
									return 'Контроль цепи АБ'
								} else if (parseInt(value) == 7) {
									return 'Разряд'
								}

								return parseInt(value)
							}
						}
					}]
				} else if (this.first_chart_table == 'laurel_zpu_state') {
					this.options.scales.yAxes = [{
						ticks: {
							min: 1,
							max: 5,
							stepSize: 1,
							callback: (value) => {
								if (parseInt(value) === 1) {
									return 'Блокировка'
								} else if (parseInt(value) === 2) {
									return 'Включение'
								} else if (parseInt(value) === 3) {
									return 'Готовность'
								} else if (parseInt(value) === 4) {
									return 'Работа'
								} else if (parseInt(value) === 5) {
									return 'Выдача приостановлена'
								}

								return parseInt(value)
							}
						}
					}]
				}

				this.options.scales.xAxes = [{
					ticks: {
						// min: 0,
						autoSkip: true,
						autoSkipPadding: 20,
						maxRotation: 0,
						callback: (value) => {
							let datetime = new Date(value);

							var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
							"Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

							if (this.active_date === '24h') {
								return datetime.getHours()
							} else if (this.active_date === '31d') {
								return datetime.getDate()
							} else if (this.active_date === '365d') {
								return months[datetime.getMonth()]
							} else {
								return datetime.getHours()
							}
						},
					}
				}]

				this.options.tooltips = {
					enabled: true,
					mode: 'label',
					callbacks: {
						title: function(tooltipItems, data) {
							var idx = tooltipItems[0].index;
							let datetime = new Date(data.labels[idx]);

							var options = {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								weekday: 'long',
								timezone: 'UTC',
							};

							return datetime.toLocaleString("ru", options);
						},
					}
				}

				let group_by = '1h';

				if (this.active_date == null) {
					this.active_date = '24h';
				}

				if (this.active_date === '24h') {
					// group_by = '1h';
					group_by = '5m';
				}
				if (this.active_date === '31d') {
					group_by = '1d';
				}
				if (this.active_date === '365d') {
					group_by = '31d';
				}

				let params = {
					"table": this.first_chart_table,
					"period": this.active_date,
					"group_by": group_by
				}

				if (this.thing.equipment && this.thing.equipment.id) {
					params.equipment_id = this.thing.equipment.id;
				}

				let first_chart_logs = await logsService.logs_laurel_state(this.facilityId, this.thing.id, params);

				if (first_chart_logs.error) {
					console.error(first_chart_logs.error);

					return;
				}

				let response = first_chart_logs.result.logs;

				// console.log(response);

				response = response.replace(/'/g, '"');
				response = response.replace(/None/g, 'null');

				var obj_reponse = JSON.parse(response);

				const timeArray= obj_reponse.map(element => element.time);

				const meanArray= obj_reponse.map(element => {
					element = element.mode;

					// if (element) {
					// 	element = element;
					// }

					return element
				});

				this.first_chart_data = {
					labels: timeArray,
					datasets: [
						{
							label: this.getLabelName(this.first_chart_table),
							data: meanArray,
							borderColor: 'rgba(142,220,40,0.9)',
							borderWidth: 2,
							pointRadius: 1,
							pointHoverRadius: 1,
							fill: false
						},
					]
				}				
			},
			async fill_second_states_chart() {
				if (this.second_chart_table == 'laurel_ab_state') {
					this.options.scales.yAxes = [{
						ticks: {
							min: 1,
							max: 7,
							stepSize: 1,
							callback: (value) => {
								if (parseInt(value) == 1) {
									return 'Обрыв цепи АБ'
								} else if (parseInt(value) == 2) {
									return 'Подзаряд'
								} else if (parseInt(value) == 3) {
									return 'Заряд'
								} else if (parseInt(value) == 4) {
									return 'Выравнивающий заряд'
								} else if (parseInt(value) == 5) {
									return 'Контрольный разряд'
								} else if (parseInt(value) == 6) {
									return 'Контроль цепи АБ'
								} else if (parseInt(value) == 7) {
									return 'Разряд'
								}

								return parseInt(value)
							}
						}
					}]
				} else if (this.second_chart_table == 'laurel_zpu_state') {
					this.options.scales.yAxes = [{
						ticks: {
							min: 1,
							max: 5,
							stepSize: 1,
							callback: (value) => {
								if (parseInt(value) === 1) {
									return 'Блокировка'
								} else if (parseInt(value) === 2) {
									return 'Включение'
								} else if (parseInt(value) === 3) {
									return 'Готовность'
								} else if (parseInt(value) === 4) {
									return 'Работа'
								} else if (parseInt(value) === 5) {
									return 'Выдача приостановлена'
								}

								return parseInt(value)
							}
						}
					}]
				}

				this.options.scales.xAxes = [{
					ticks: {
						// min: 0,
						autoSkip: true,
						autoSkipPadding: 20,
						maxRotation: 0,
						callback: (value) => {
							let datetime = new Date(value);

							var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
							"Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

							if (this.active_date === '24h') {
								return datetime.getHours()
							} else if (this.active_date === '31d') {
								return datetime.getDate()
							} else if (this.active_date === '365d') {
								return months[datetime.getMonth()]
							} else {
								return datetime.getHours()
							}
						},
					}
				}]

				this.options.tooltips = {
					enabled: true,
					mode: 'label',
					callbacks: {
						title: function(tooltipItems, data) {
							var idx = tooltipItems[0].index;
							let datetime = new Date(data.labels[idx]);

							var options = {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								weekday: 'long',
								timezone: 'UTC',
							};

							return datetime.toLocaleString("ru", options);
						},
					}
				}

				let group_by = '1h';

				if (this.active_date == null) {
					this.active_date = '24h';
				}

				if (this.active_date === '24h') {
					// group_by = '1h';
					group_by = '5m';
				}
				if (this.active_date === '31d') {
					group_by = '1d';
				}
				if (this.active_date === '365d') {
					group_by = '31d';
				}

				let params = {
					"table": this.second_chart_table,
					"period": this.active_date,
					"group_by": group_by
				}

				if (this.thing.equipment && this.thing.equipment.id) {
					params.equipment_id = this.thing.equipment.id;
				}

				let second_chart_logs = await logsService.logs_laurel_state(this.facilityId, this.thing.id, params);

				if (second_chart_logs.error) {
					console.error(second_chart_logs.error);

					return;
				}

				let response = second_chart_logs.result.logs;

				// console.log(response);

				response = response.replace(/'/g, '"');
				response = response.replace(/None/g, 'null');

				var obj_reponse = JSON.parse(response);

				const timeArray= obj_reponse.map(element => element.time);

				const meanArray= obj_reponse.map(element => {
					element = element.mode;

					// if (element) {
					// 	element = element;
					// }

					return element
				});

				this.second_chart_data = {
					labels: timeArray,
					datasets: [
						{
							label: this.getLabelName(this.second_chart_table),
							data: meanArray,
							borderColor: 'rgba(142,220,40,0.9)',
							borderWidth: 2,
							pointRadius: 1,
							pointHoverRadius: 1,
							fill: false
						},
					]
				}				
			},
			getLabelName(id) {
				let name = ''

				if (id == 'laurel_voltage_input') {
					name = 'Напряжение питания ЗПУ, В'
				} else if (id == 'laurel_voltage_out') {
					name = 'Напряжение на выходе ЗПУ, В'
				} else if (id == 'laurel_amperage_out') {
					name = 'Ток на выходе ЗПУ, А'
				} else if (id == 'laurel_amperage_ab') {
					name = 'Усредненный ток АБ, А'
				} else if (id == 'laurel_amperage_vzu_group') {
					name = 'Суммарный выходной ток группы ЗПУ, А'
				} else if (id == 'laurel_amperage_nagr') {
					name = 'Ток нагрузки СОПТ, А'
				} else if (id == 'laurel_temperature_max') {
					name = 'Максимальная температура внутри ЗПУ, °C'
				} else if (id == 'laurel_temperature_ab') {
					name = 'Температура АБ, °C'
				} else if (id == 'laurel_ab_state') {
					name = 'Состояние АБ'
				} else if (id == 'laurel_zpu_state') {
					name = 'Состояние ЗПУ'
				} else {
					name = id
				}

				return name
			},
			_base64ToArrayBuffer(base64) {
				var binary_string = window.atob(base64);
				var len = binary_string.length;
				var bytes = new Uint8Array(len);

				for (var i = 0; i < len; i++) {
					bytes[i] = binary_string.charCodeAt(i);
				}

				return bytes.buffer;
			},
			async downloadFile(file) {
				let res = await filesService.file_get(file.id);

				if (res.error) {
					console.error(res.error);
				}

				let item = res.result;

				var mybytes = this._base64ToArrayBuffer(item.base64);

				download(mybytes, item.filename, "application/octet-stream");
			},
			async updateFiles() {
				let res = await thingsService.thing_update_files(this.facility.id, this.thing.id);

				if (res.error) {

					console.error(res.error);

				return;
				}

				// console.log(res);

				// alert('updateFiles handled');
			},

			async thing_update_files_status() {

				this.file_update_state = 'UNRESOLVED';

				function delay(t) {
					return new Promise(resolve => setTimeout(resolve, t));
				}

				let res;
				do {
					await delay(2000);
					let result = await thingsService.thing_update_files_status(this.facilityId, this.thingId, this.thing.serial)
					res = result.result.state;
					// console.log(res)
					this.file_update_state = res
				} while(res != 'RESOLVED');

				// this.bind_status = true;

				// await this.getThingList(this.facilityId);

				await this.getThingFiles('configuration_file');
			},
			toggleSignallingInfo() {
				this.signalling_info_toggle = !this.signalling_info_toggle;
			}
		}
	}
</script>

<style>
  .circle-35.current-parameters {
    background: url(../../assets/current_parameters.svg) no-repeat center center #000;
  }
  .circle-35.current-parameters.active {
    background: url(../../assets/current_parameters_black.svg) no-repeat center center #8EDC28;
  }
	.circle-35:hover.current-parameters {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35.files {
    background: url(../../assets/files_download.svg) no-repeat center center #000;
  }
  .circle-35.files.active {
    background: url(../../assets/files_download_black.svg) no-repeat center center #8EDC28;
  }
	.circle-35:hover.files {
    opacity: 0.5;
    cursor: pointer;
  }
	.chart-button {
		color: #ffffff;
		font-family: "Segoe UI Regular";
		font-size: 14px;
		letter-spacing: 0.7px;
		width: 162px;
		height: 36px;
		background: #131416 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 18px;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.chart-button.active {
		background: #8EDC28 0% 0% no-repeat padding-box;
		color: #000000;
	}
	.chart-button:hover {
		opacity: 0.8;
		cursor: pointer;
	}
	.chart-button-div {
		margin: 10px;
		margin-right: 40px;
  }
	.desktop-laurel-header {
		height: 208px !important;
	}
	.status-text {
		font-family: "Segoe UI";
		font-size: 17px;
		letter-spacing: 0.9px;
		color: #FFFFFF;
		white-space: nowrap;
		overflow: hidden; 
		text-overflow: ellipsis;
	}
	.parameters-div {
		/* background: #151515 0% 0% no-repeat padding-box; */
		background: rgba(21, 21, 21, 0.6);
		/* opacity: 0.7; */
		height: 362px;
		border-radius: 10px;
		color: #ffffff;
		padding: 20px;
	}
	.laurel-mini-div {
		background: #202020 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;
		opacity: 1;
		height: 207px;
		margin: 12px;
		min-width: 184px;
		font-family: "Segoe UI Light";
		font-size: 10px;
		letter-spacing: 0px;
		color: #FFFFFF;
		/*padding: 10px;*/
		display: flex;
		flex-flow: row nowrap;
		cursor: pointer;
		position: relative;
		z-index: 99;
	}
	.laurel-mini-div:hover {
		background: #2E2E2E 0% 0% no-repeat padding-box;
	}
	.laurel-active {
		position: absolute;
		left: 0px;
		right: 0px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;
		height: 207px;
		font-family: "Segoe UI Light";
		font-size: 10px;
		letter-spacing: 0px;
		color: #FFFFFF;
		cursor: pointer;
		z-index: -4;
		width: auto;
		border: 1px solid gray;
		padding-bottom: 30px;
	}
	.laurel-info {
    position: absolute;
    left: 0px;
    right: 0px;
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 224px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: -5;
    width: auto;
    padding-bottom: 30px;
  }
  #bottom-laurel {
    position:relative;
    top: 207px;
  }
  .red-dot {
	height: 10px;
	width: 10px;
	background-color: #C40D0D ;
	border-radius: 50%;
	display: inline-block;
	margin-right: 1rem;
  }
  .yellow-dot {
	height: 10px;
	width: 10px;
	background-color: #EBEB14 ;
	border-radius: 50%;
	display: inline-block;
	margin-right: 1rem;
  }
	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		width: 1px;
		/*background-color: rgba(180,180,180,0.4);*/
		background-color:#000;
		/*background-color: rgba(180,180,180,0.4);*/
		/*-webkit-box-shadow: inset 0 0 4px rgba(200,200,200,1);*/
		/*-webkit-box-shadow: inset 0 0 5px rgba(200,200,200,0.2);*/
		/*border-radius: 10px;*/
	}

	::-webkit-scrollbar-thumb {
		/*border-radius: 10px;*/
		/*background-color:#000;*/
		background-color: rgba(180,180,180,0.4);
		/*-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,1);*/
	}
	.scroller {
		scrollbar-color: rgba(180,180,180,0.4) #000; /* thumb and track color */
		scrollbar-width: thin;
	}
	.journals-div {
		height: 250px;
		min-height: 180px;
	}
	.journals-div.row {
		display: flex;
		align-items: center;
	}
	.journal-download-div {
		display: none;
	}
	.journal-download {
		z-index: 99;
	}
	.journal-download:hover {
		transform: scale(1.1);
	}
	.journal-download:hover + .journal-download-div {
		display: block;
		position: absolute;
		right: 15px;
		top: 3px;
		padding: 2px;
		padding-right: 40px;
		padding-left: 10px;
		z-index: 1;
		border-radius: 12px;
		background: #131416 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #000000E0;
	}
	.vc-container.vc-is-dark {
		background-color: #111111 !important;
	}
	.vc-container {
		--green-500: #8EDC28 !important;
		--green-800: #535353 !important;
	}
	.journal-datepicker-div {
		background: #131416 0% 0% no-repeat padding-box;
		width: 290px;
		height: 352px;
		position: absolute;
		right: 15px;
		top: 0px;
		z-index: 2;
		border-radius: 10px;
		padding: 20px;
		padding-top: 10px;
		box-shadow: 0px 3px 8px #000000E0;
	}
	.download-journal-button {
		/* font-family: "Segoe UI Regular"; */
		font-family: "Comfortaa normal";
		font-size: 12px;
		letter-spacing: 0.6px;
		width: 101px;
		height: 27px;
		background: #8EDC28 0% 0% no-repeat padding-box;
		color: #000000;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 18px;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 999;
		letter-spacing: 0.6px;
		color: #131416;
		text-transform: uppercase;
		opacity: 1;
		font-weight: bold;
	}
	.download-journal-button:hover {
		cursor: pointer;
		opacity: 0.8;
	}
	.group-background {
		background: rgba(99, 99, 99, 0.6);
		width: 75%;
		height: calc(100% + 22px);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;
	}
	.laurel-signalling {
		/* float: left;
		position: absolute;
		left: 17px; */
		float: left;
		position: absolute;
		left: 96px;
		height: 15px;
		font-size: 18px;
		font-family: "Segoe UI";
		color: #FFFFFF;
		letter-spacing: 0.7px;
		opacity: 0.8;
	}
	/*.laurel-signalling:hover {
		font-size: 20px;
		margin-top: -2px;
		opacity: 1;
	} */
	.subject-info-values {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
	.subject-info {
      flex-grow: 7;
      font-size: 16px;
    }
	.mobile-header {
		display: none;
	}
	@media (max-width: 640px) {
		.desktop-laurel-header {
			display: none;
		}
		.mobile-header {
			display: block;
		}
		.header-div {
			height: 220px !important;
			margin: 10px;
			font-family: "Segoe UI Light";
			font-size: 30px;
			color: #FFFFFF;
		}
		.small-info {
			font-size: 12px !important;
		}
		.date-picker {
			top: 18%;
			/*position: absolute;*/
			float: right;
			right: 0px;
			/*top: 50%;*/
			width: 50px;
			height: 166px;
			border-radius: 50px;
			background-color: #000000;
			color: #FFFFFF;
		}
		.chart-button {
			font-size: 10px !important;
		}
	}
</style>
