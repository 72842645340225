export * from './user.service';
<template>
  <!-- <div id="app" class="bg"> -->
  <div id="app">
      <navbar></navbar>
      <section class="main-content-section">
        <router-view></router-view>
      </section>
      <!-- <footer class="footer"><a href="http://i-mt.net/"><label class="ml-5 mt-2" style="color: #FFFFFF; cursor: pointer;">© Микропроцессорные технологии</label></a></footer> -->
  <!--    <footer class="footer">-->
  <!--      <div class="container">-->
  <!--        <a href="http://i-mt.net/">-->
  <!--          <label class="ml-5 mt-2" style="color: #FFFFFF; cursor: pointer;">© Микропроцессорные технологии</label>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--    </footer>-->

      <footer class="footer">
        <div class="container">
          <span class="text-muted ml-4">
            <a href="http://i-mt.net/" style="color: #FFFFFF; cursor: pointer;">© Микропроцессорные технологии</a>
          </span>
        </div>
      </footer>
  </div>
</template>

<script>
  // TODO: navbar like component
  import navbar from './components/Navbar.vue'

  export default {
    name: 'App',
    components: {
      navbar
    },
    data () {
      return {
        username: '',
      }
    },
    methods: {
      // setUser() {
      //   let userdata = JSON.parse(localStorage.getItem('user'));
      //   this.username = userdata.profile.name;
      // }
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Mont Demo';
    src: url('./assets/Montextralightdemo.woff2');
  }
  .footer {
    background-color: #000;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px; /* Set the fixed height of the footer here */
    line-height: 50px; /* Vertically center the text there */
  }
  /*body, html {*/
  /*  height: 100%;*/
  /*}*/
  html {
    /*background: url("./assets/background1.png") no-repeat center center fixed;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/
  }
  html {
    position: relative;
    min-height: 100%;
  }
  body {
    margin-bottom: 50px;
    overflow-x: hidden;
  }
  body {
      background: url("./assets/background1.png") no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }
  /*.bg {*/
  /*  !*position: relative;*!*/
  /*  !*min-height: 100vh;*!*/
  /*  !*!* The image used *!*!*/
  /*  background-image: url("./assets/background1.png");*/

  /*  !*!* Full height *!*!*/
  /*  height: calc(100% - 60px);*/

  /*  !*!* Center and scale the image nicely *!*!*/
  /*  background-position: center;*/
  /*  background-repeat: no-repeat;*/
  /*  background-size: cover;*/
  /*}*/
  .container {
    /*width: auto;*/
    /*max-width: 680px;*/
    /*padding: 0 15px;*/
    max-width: 1920px;
    width: 100%;
  }
</style>
