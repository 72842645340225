<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body">
              <h5 class="card-title" style="margin-left: 10px;">{{ $t('invitespage.title') }}</h5>
              <div class="card-body header-div d-flex" style="height: auto !important;">
                <!-- <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="hover"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                </div> -->
                <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
                </div>
                <div class="mt-2 ml-3 invite-helper">{{ $t('invitespage.info') }}:</div>
              </div>
              <div class="invitesform">
                <div v-if="invites.length === 0" class="small-text">
                  {{ $t('invitespage.empty') }}
                </div>
                <div v-else v-for="(invite) in invites" v-bind:key="invite">
                  <div class="d-flex bd-highlight" style="border-bottom: 1px solid #2E2E2E;">
                    <div class="mr-auto p-2 bd-highlight facilityname">{{ invite.facility.name }}</div>
                    <div class="p-2 bd-highlight">{{ get_role_translate(invite.role.id) }}</div>
                    <div class="p-2 bd-highlight"><a href="#" @click.prevent="acceptInvite(invite.id)">Принять</a></div>
                    <div class="p-2 bd-highlight"><a href="#" @click.prevent="declineInvite(invite.id)">Отклонить</a></div>
                  </div>

<!--                  <div class="row" style="border-bottom: 1px solid #2E2E2E;">-->
<!--                    <div class="col-sm-6 facilityname">{{ invite.facility.name }}</div>-->
<!--                    <div class="col-sm-2">{{ invite.role.id }}</div>-->
<!--                    <div class="col-sm-2"><a href="#" @click.prevent="acceptInvite(invite.id)">Принять</a></div>-->
<!--                    <div class="col-sm-2"><a href="#" @click.prevent="declineInvite(invite.id)">Отклонить</a></div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import navbar from './Navbar.vue'
  import {invitesService} from "../_services";

  export default {
    name: 'InvitesPage',
    components: {
      // navbar,
    },
    data () {
      return {
        invites: []
      }
    },
    created () {
      this.init();
      this.getInvites();
    },
    methods: {
      init() {
        console.log('initializing');
      },
      async getInvites() {
        let res = await invitesService.invites_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.invites = res.result.items;

        console.log('this invites: ' + this.invites)
      },
      async acceptInvite(inviteId) {
        let res = await invitesService.invite_accept(inviteId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        await this.getInvites()
      },
      async declineInvite(inviteId) {
        let res = await invitesService.invite_decline(inviteId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        await this.getInvites()
      },
      get_role_translate(roleText) {
        if (roleText === 'Admin') {
          return 'Адинистратор'
        } else if (roleText === 'Employee') {
          return 'Сотрудник'
        } else if (roleText === 'Owner') {
          return 'Владелец'
        } else {
          return roleText
        }
      },
    }
  }
</script>
<style>
  .invitesform {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    font-family: "Segoe UI Light";
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 10px;
    padding: 20px;
  }
  .facilityname {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .container {
    max-width: 1920px;
    width: 100%;
  }
  @media (max-width: 640px) {
    .invite-helper { font-size: 26px !important; }
    .card-body.header-div { margin: 0px !important; }
    .invitesform { margin: 0px !important; margin-top: 10px !important; font-size: 16px !important; padding: 5px !important; }
  }
</style>