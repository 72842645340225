<template>
    <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
    <div v-else>
    <div class="row">
      <div class="col-sm-6">
        <div class="card card-main transparent">
          <div class="card-body">
            <div class="header-div m-0 desktop-header">
              <div class="header-upper">
                <div class="d-flex bd-highlight">
                  <div class="back-img interact-img" style="flex-shrink: 0;">
                    <!-- <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back_old.png" style="width: 80%; height: 80%;"></a> -->
                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a>
                  </div>
                  <div class="back-img interact-img" style="flex-shrink: 0;">
                    <div v-if="battery_status === 'normal'">
                      <img alt="repeyBase_online" src="../../assets/green_circle.svg">
                    </div>

                    <!-- <div v-else-if="battery_status === 'warning'" v-bind:title="(JSON.stringify(battery_status_reason, null, 1)).slice(1,-1)" style="cursor: pointer;" @click.prevent="toggleAkbInfo"> -->
                    <div v-else-if="battery_status === 'warning'" style="cursor: pointer;" @click.prevent="toggleAkbInfo" v-bind:title="'Предупредительная сигнализация: нажмите, чтобы увидеть подробнее'">
                      <img alt="repeyBase_online" src="../../assets/yellow_circle.svg">
                    </div>

                    <!-- <div v-else-if="battery_status === 'critical'" v-bind:title="(JSON.stringify(battery_status_reason, null, 1)).slice(1,-1)" style="cursor: pointer;" @click.prevent="toggleAkbInfo"> -->
                    <div v-else-if="battery_status === 'critical'" style="cursor: pointer;" @click.prevent="toggleAkbInfo" v-bind:title="'Аварийная сигнализация: нажмите, чтобы увидеть подробнее'">
                      <img alt="repeyBase_online" src="../../assets/red_circle.svg">
                    </div>

                    <!-- <div v-else-if="battery_status === 'offline'" v-bind:title="(JSON.stringify(battery_status_reason, null, 1)).slice(1,-1)" style="cursor: pointer;" @click.prevent="toggleAkbInfo"> -->
                    <div v-else-if="battery_status === 'offline'" style="cursor: pointer;" @click.prevent="toggleAkbInfo">
                      <img alt="repeyBase_online" src="../../assets/gray_circle.svg">
                    </div>

                    <div v-if="akb_info_toggle" class="akb-info-div">
                      <div v-if="critical_list.length !== 0" class="mb-3">
                        <p style="color: #FF0D0D">{{ $t('devices.repeypage.alert') }}:</p>
                        <div v-for="critical in critical_list" :key="critical">
                          {{ critical }}
                        </div>
                      </div>
                      <div v-if="warning_list.length !== 0">
                        <p style="color: #EBEB14">{{ $t('devices.repeypage.warning') }}:</p>
                        <div v-for="warning in warning_list" :key="warning">
                          {{ warning }}
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">{{ facility.name }}, {{ thing.equipment.name }}</span> -->
                  <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
                    {{ facility.name }}
                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                    <span v-else>
                      <span v-if="thing.type.id == 'RepeyBase'">, Репей <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
                      <span v-else>
                        , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                      </span>
                    </span>
                  </span>
                  <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                    <div v-if="active_button == 'sort-linear'" class="circle-35 active sort-linear" v-on:click="active_button = 'sort-linear'; selected_element = 'battery'; fillData()"></div>
                    <div v-else class="circle-35 sort-linear" v-on:click="active_button = 'sort-linear'; selected_element = 'battery'; fillData()"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-else>
                    <!-- <div class="circle-35 sort-linear" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                    <div v-if="active_button == 'sort-linear'" class="circle-35 active sort-linear"></div>
                    <div v-else class="circle-35 sort-linear"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                    <div v-if="active_button == 'sort-bars'" class="circle-35 active sort-bars" v-on:click="active_button = 'sort-bars'; selected_element = ''; active_date = null; fillData()"></div>
                    <div v-else class="circle-35 sort-bars" v-on:click="active_button = 'sort-bars'; active_date = null; fillData()"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-else>
                    <!-- <div class="circle-35 sort-bars" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                    <div v-if="active_button == 'sort-bars'" class="circle-35 active sort-bars"></div>
                    <div v-else class="circle-35 sort-bars"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                    <div v-if="active_button == 'sort-cols'" class="circle-35 active sort-cols" v-on:click="active_button = 'sort-cols'; selected_element = 'battery_0x9c0'; fillData()"></div>
                    <div v-else class="circle-35 sort-cols" v-on:click="active_button = 'sort-cols'; selected_element = 'battery_0x9c0'; fillData()"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-else>
                    <!-- <div class="circle-35 sort-cols" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                    <div v-if="active_button == 'sort-cols'" class="circle-35 active sort-cols"></div>
                    <div v-else class="circle-35 sort-cols"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                    <div v-if="active_button == 'current-parameters'" class="circle-35 active current-parameters" v-on:click="active_button = 'current-parameters'; selected_element = ''; active_date = null; get_event_logs(); get_system_logs();"></div>
                    <div v-else class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; active_date = null; get_event_logs(); get_system_logs();"></div>
                  </div>
                  <div class="p-2 bd-highlight" v-else>
                    <div v-if="active_button == 'current-parameters'" class="circle-35 active current-parameters"></div>
                    <div v-else class="circle-35 current-parameters"></div>
                  </div>
                </div>
              </div>
              <div class="header-body" style="padding: 0px;">
                <div class="row">
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <div class="back-img-battery">
                      <img v-if="thing.shadow.online" alt="battery_logo" src="../../assets/battery_filled.svg" style="position: absolute; z-index: 4;">
                      <div v-if="thing.shadow.online" class="fill-battery" id="batteryFill" :style="{height: batteryFillHeight + 'px' }"></div>
                      <div v-else class="fill-battery-offline">?</div>
                      <img alt="battery_logo" src="../../assets/battery.svg" style="position: relative; z-index: 6;">
                    </div>

                  </div>
                  <div class="col-3 d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="color: #ff0000"><span class="nowrap-span">⚠ {{ $t('devices.repeypage.offline') }}</span></li>
                      <li style="font-size: 14px;">{{ $t('devices.repeypage.last_update') }}:</li>
                      <li>{{ date }} {{ time }}</li>
                    </ul>
                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;">
                      <li style="font-size: 14px;">{{ $t('devices.repeypage.updated') }}:</li>
                      <li>{{ date }}</li>
                      <li>{{ time }}</li>
                    </ul>
                  </div>
                  <div class="col-1">
                  </div>
                  <div class="col-3 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black; border-left: 1px solid black; height: 88px;"><div class="background-text unselectable pl-5">t</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D !== null">
<!--                        <span v-if="thing.shadow.online" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap;">{{ (thing.shadow.values_in_physical_units.T_enclosure_temperature_0x09A2_0x09A3).toFixed(2) }} °C</span>-->
                        <span v-if="main_battery_status.ab_temperature_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                        <span v-else-if="main_battery_status.ab_temperature_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                        <span v-else-if="main_battery_status.ab_temperature_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                    </div>
                    <span v-else style="z-index: 99999; font-size: 24px; position: relative; color: #606060;">-.-- °C</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5 !== null">
<!--                        <span v-if="thing.shadow.online" class="small-info">Вне шкафа {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }}°C</span>-->
                        <span v-if="main_battery_status.ab_outer_temperature_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                        <span v-else-if="main_battery_status.ab_outer_temperature_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                        <span v-else-if="main_battery_status.ab_outer_temperature_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.outside') }} -.-- °C</span>
                  </div>
                  <div class="col-2 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5">U</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 !== null">
                      <span v-if="main_battery_status.ab_voltage_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                      <span v-else-if="main_battery_status.ab_voltage_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                      <span v-else-if="main_battery_status.ab_voltage_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                    </div>
                    <span v-else style="z-index: 99999; font-size: 24px; position: relative; color: #606060;">-.-- V</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D !== null">
<!--                        <span v-if="thing.shadow.online" class="small-info">пульсации {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>-->
                        <span v-if="main_battery_status.ab_voltage_pulsation_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                        <span v-else-if="main_battery_status.ab_voltage_pulsation_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                        <span v-else-if="main_battery_status.ab_voltage_pulsation_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.pulsation') }} -.- %</span>
                  </div>
                  <div class="col-2 d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5">I</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983 !== null">
<!--                        <span v-if="thing.shadow.online" style="z-index: 9999; font-size: 24px; position: relative; white-space: nowrap;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>-->
                        <span v-if="main_battery_status.ab_amperage_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                        <span v-else-if="main_battery_status.ab_amperage_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                        <span v-else-if="main_battery_status.ab_amperage_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                    </div>
                    <span v-else style="z-index: 9999; font-size: 24px; position: relative; color: #606060;">-.-- A</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F !== null">
<!--                        <span v-if="thing.shadow.online" class="small-info" >пульсации {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>-->
                        <span v-if="main_battery_status.ab_amperage_pulsation_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                        <span v-else-if="main_battery_status.ab_amperage_pulsation_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                        <span v-else-if="main_battery_status.ab_amperage_pulsation_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.pulsation') }} -.- %</span>

                  </div>
                </div>
              </div>
            </div>
<!-- MOBILE ********************************************************************** -->
            <div class="header-div m-0 mobile-header">
              <div class="header-upper pb-3">
                <div class="d-flex bd-highlight">
                  <div class="back-img interact-img" style="margin-top: 0px !important;">
                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
                  </div>
                  <div class="back-img interact-img" style="margin-top: 0px !important;">

                    <div v-if="battery_status === 'normal'">
                      <img alt="repeyBase_online" src="../../assets/green_circle.svg" style="width: 100%; height: 100%; flex-shrink: 0;">
                    </div>

                    <div v-else-if="battery_status === 'warning'" style="cursor: pointer; width: 100%; height: 100%; flex-shrink: 0;" @click.prevent="toggleAkbInfo">
                      <img alt="repeyBase_online" src="../../assets/yellow_circle.svg">
                    </div>

                    <div v-else-if="battery_status === 'critical'">
                      <img alt="repeyBase_online" src="../../assets/red_circle.svg" style="width: 100%; height: 100%; flex-shrink: 0;" @click.prevent="toggleAkbInfo">
                    </div>

                    <div v-else-if="battery_status === 'offline'" style="cursor: pointer; width: 100%; height: 100%; flex-shrink: 0;" @click.prevent="toggleAkbInfo">
                      <img alt="repeyBase_online" src="../../assets/gray_circle.svg">
                    </div>

                    <div v-if="akb_info_toggle" class="akb-info-div">
                      <div v-if="critical_list.length !== 0" class="mb-3">
                        <p style="color: #FF0D0D">{{ $t('devices.repeypage.alert') }}:</p>
                        <div v-for="critical in critical_list" :key="critical">
                          {{ critical }}
                        </div>
                      </div>
                      <div v-if="warning_list.length !== 0">
                        <p style="color: #EBEB14">{{ $t('devices.repeypage.warning') }}:</p>
                        <div v-for="warning in warning_list" :key="warning">
                          {{ warning }}
                        </div>
                      </div>
                    </div>

                  </div>

                  <span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
                    {{ facility.name }}
                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                    <span v-else>
                      <span v-if="thing.type.id == 'RepeyBase'">, Репей <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
                      <span v-else>
                        , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="header-body" style="padding: 0px;">
                <div class="d-flex">
                  <div class="back-img-battery m-2 ml-3 mr-3">
                    <img v-if="thing.shadow.online" alt="battery_logo" src="../../assets/battery_filled.svg" style="position: absolute; z-index: 4;">
                    <div v-if="thing.shadow.online" class="fill-battery" id="batteryFill" :style="{height: batteryFillHeight + 'px' }"></div>
                    <div v-else class="fill-battery-offline">?</div>
                    <img alt="battery_logo" src="../../assets/battery.svg" style="position: relative; z-index: 6;">
                  </div>
                  <div class="d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                      <li style="color: #ff0000"><span class="nowrap-span">⚠ Репей оффлайн</span></li>
                      <li style="font-size: 14px;">Последнее обновление:</li>
                      <li>{{ date }} {{ time }}</li>
                    </ul>
                    <ul v-else style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                      <li style="font-size: 14px;">Обновлено:</li>
                      <li>{{ date }}</li>
                      <li>{{ time }}</li>
                    </ul>
                  </div>
                  <div class="ml-auto d-flex align-items-center justify-content-center">

                    <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                      <div v-if="active_button == 'sort-linear'" class="circle-35 active sort-linear" v-on:click="active_button = 'sort-linear'; selected_element = 'battery'; fillData()"></div>
                      <div v-else class="circle-35 sort-linear" v-on:click="active_button = 'sort-linear'; selected_element = 'battery'; fillData()"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-else>
                      <!-- <div class="circle-35 sort-linear" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                      <div v-if="active_button == 'sort-linear'" class="circle-35 active sort-linear"></div>
                      <div v-else class="circle-35 sort-linear"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                      <div v-if="active_button == 'sort-bars'" class="circle-35 active sort-bars" v-on:click="active_button = 'sort-bars'; selected_element = ''; active_date = null; fillData()"></div>
                      <div v-else class="circle-35 sort-bars" v-on:click="active_button = 'sort-bars'; active_date = null; fillData()"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-else>
                      <!-- <div class="circle-35 sort-bars" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                      <div v-if="active_button == 'sort-bars'" class="circle-35 active sort-bars"></div>
                      <div v-else class="circle-35 sort-bars"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                      <div v-if="active_button == 'sort-cols'" class="circle-35 active sort-cols" v-on:click="active_button = 'sort-cols'; selected_element = 'battery_0x9c0'; fillData()"></div>
                      <div v-else class="circle-35 sort-cols" v-on:click="active_button = 'sort-cols'; selected_element = 'battery_0x9c0'; fillData()"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-else>
                      <!-- <div class="circle-35 sort-cols" style="-webkit-filter: grayscale(100%); filter: grayscale(100%); opacity: 0.5;"></div> -->
                      <div v-if="active_button == 'sort-cols'" class="circle-35 active sort-cols"></div>
                      <div v-else class="circle-35 sort-cols"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-if="!linear_data_preloader && !bars_data_preloader">
                      <div v-if="active_button == 'current-parameters'" class="circle-35 active current-parameters" v-on:click="active_button = 'current-parameters'; selected_element = ''; active_date = null; fillData()"></div>
                      <div v-else class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; active_date = null; fillData()"></div>
                    </div>
                    <div class="p-2 bd-highlight" v-else>
                      <div v-if="active_button == 'current-parameters'" class="circle-35 active current-parameters"></div>
                      <div v-else class="circle-35 current-parameters"></div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="header-body" style="border-top: 1px solid black;">
                <div class="d-flex justify-content-between">

                  <div class="d-flex flex-column align-items-center justify-content-center ml-2" style="height: 88px;"><div class="background-text unselectable pl-5 ml-5">t</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D !== null" style="width: auto;">
                      <span v-if="main_battery_status.ab_temperature_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                      <span v-else-if="main_battery_status.ab_temperature_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                      <span v-else-if="main_battery_status.ab_temperature_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.Ta_average_temperature_0x099C_0x099D).toFixed(2) }} °C</span>
                    </div>
                    <span v-else style="z-index: 99999; font-size: 24px; position: relative; color: #606060;">-.-- °C</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5 !== null">
                      <span v-if="main_battery_status.ab_outer_temperature_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                      <span v-else-if="main_battery_status.ab_outer_temperature_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                      <span v-else-if="main_battery_status.ab_outer_temperature_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.outside') }} {{ (thing.shadow.values_in_physical_units.T_room_temperature_0x09A4_0x09A5).toFixed(2) }} °C</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.outside') }} -.-- °C</span>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center" style="border-left-color: black;"><div class="background-text unselectable pl-5 ml-5">U</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 !== null">
                      <span v-if="main_battery_status.ab_voltage_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                      <span v-else-if="main_battery_status.ab_voltage_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                      <span v-else-if="main_battery_status.ab_voltage_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                    </div>
                    <span v-else style="z-index: 99999; font-size: 24px; position: relative; color: #606060;">-.-- V</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D !== null">
                      <span v-if="main_battery_status.ab_voltage_pulsation_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                      <span v-else-if="main_battery_status.ab_voltage_pulsation_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                      <span v-else-if="main_battery_status.ab_voltage_pulsation_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kU_voltage_pulse_coeff_0x098C_0x098D).toFixed(1) }} %</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.pulsation') }} -.- %</span>
                  </div>
                  <div class="d-flex flex-column align-items-center justify-content-center mr-2" style="border-left-color: black;"><div class="background-text unselectable pl-5 ml-5">I</div>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983 !== null">
                      <span v-if="main_battery_status.ab_amperage_status === 'normal'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #FFFFFF;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                      <span v-else-if="main_battery_status.ab_amperage_status === 'warning'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #EBEB14;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                      <span v-else-if="main_battery_status.ab_amperage_status === 'critical'" style="z-index: 99999; font-size: 24px; position: relative; white-space: nowrap; color: #C40D0D;">{{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                    </div>
                    <span v-else style="z-index: 9999; font-size: 24px; position: relative; color: #606060;">-.-- A</span>
                    <div v-if="thing.shadow.online && thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F !== null">
                      <span v-if="main_battery_status.ab_amperage_pulsation_status === 'normal'" class="small-info" style="color: #FFFFFF;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                      <span v-else-if="main_battery_status.ab_amperage_pulsation_status === 'warning'" class="small-info" style="color: #EBEB14;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                      <span v-else-if="main_battery_status.ab_amperage_pulsation_status === 'critical'" class="small-info" style="color: #C40D0D;">{{ $t('devices.repeypage.pulsation') }} {{ (thing.shadow.values_in_physical_units.kI_amperage_pulse_coeff_0x098E_0x098F).toFixed(1) }} %</span>
                    </div>
                    <span v-else class="small-info" style="color: #606060;">{{ $t('devices.repeypage.pulsation') }} -.- %</span>
                  </div>

                </div>
              </div>
            </div>

<!-- MOBILE ********************************************************************** -->

            <div class="row mt-3">
              <div class="col-12 col-md-3 pl-0 pr-0" v-for="(param, key) in accumulators" v-bind:key="key">
                <div class="subject-div1" v-on:click="(selected_element === key) ? selected_element = 'battery' : selected_element = key; fillData()">
                  <!-- <div class="subject-logo" v-if="thing.shadow.online && param.status.status === 'warning'" v-bind:title="JSON.stringify(param.status, null, 4)"> -->
                  <div class="subject-logo" v-if="thing.shadow.online && param.status.akb_logo_status === 'warning'">
                    <img alt="accumulator" src="../../assets/accumulator_mini_yellow.svg">
                  </div>
                  <!-- <div class="subject-logo" v-else-if="thing.shadow.online && param.status.status === 'critical'" v-bind:title="JSON.stringify(param.status, null, 4)"> -->
                  <div class="subject-logo" v-else-if="thing.shadow.online && param.status.akb_logo_status === 'critical'">
                    <img alt="accumulator" src="../../assets/accumulator_mini_red.svg">
                  </div>
                  <!-- <div class="subject-logo" v-else-if="thing.shadow.online && param.status.status === 'offline'" v-bind:title="JSON.stringify(param.status, null, 4)"> -->
                  <div class="subject-logo" v-else-if="thing.shadow.online && param.status.akb_logo_status === 'offline'">
                    <img alt="accumulator" src="../../assets/accumulator_mini_gray.svg">
                  </div>
                  <!-- <div class="subject-logo" v-else-if="!thing.shadow.online" v-bind:title="JSON.stringify(param.status, null, 4)"> -->
                  <div class="subject-logo" v-else-if="!thing.shadow.online">
                    <img alt="accumulator" src="../../assets/accumulator_mini_gray.svg">
                  </div>
                  <!-- <div class="subject-logo" v-else v-bind:title="JSON.stringify(param.status, null, 4)" :data-tooltip="makeStatusTitle(param.status)"> -->
                  <div class="subject-logo" v-else>
                    <img alt="accumulator" src="../../assets/accumulator_mini.svg">
                  </div>
                  <div class="accumulator-tag">{{ getAccumulatorNumber(key) }}</div>

<!--                  <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'warning'" class="accumulator-signalling" v-bind:title="(JSON.stringify(param.status.signalling_indicator_reason, null, 1)).slice(1,-1)" style="color: #EBEB14">⚠</div>-->
<!--                  <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'critical'" class="accumulator-signalling" v-bind:title="(JSON.stringify(param.status.signalling_indicator_reason, null, 1)).slice(1,-1)" style="color: #C40D0D">⚠</div>-->

                  <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'warning'" class="accumulator-signalling" style="color: #EBEB14" @click.prevent="showAccumSignallingInfo(key)">⚠</div>
                  <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'critical'" class="accumulator-signalling" style="color: #C40D0D" @click.prevent="showAccumSignallingInfo(key)">⚠</div>

                  <div v-if="param.toggleSignallingInfo" class="battery-info-div-repey">
                    <div>
                      <p v-if="param.status.signalling_indicator_reason['Ускоренный износ']">
                        Ускоренный износ: {{ param.status.signalling_indicator_reason['Ускоренный износ'] }}
                      </p>

                      <p v-if="param.status.signalling_indicator_reason['Деградация']">
                        Деградация: {{ param.status.signalling_indicator_reason['Деградация'] }}
                      </p>

                      <p v-if="param.status.signalling_indicator_reason['Короткое замыкание']">
                        Короткое замыкание: {{ param.status.signalling_indicator_reason['Короткое замыкание'] }}
                      </p>

                      <p v-if="param.status.signalling_indicator_reason['Тепловой разгон']">
                        Тепловой разгон: {{ param.status.signalling_indicator_reason['Тепловой разгон'] }}
                      </p>

                      <p v-if="param.status.signalling_indicator_reason['Неисправность датчика']">
                        Неисправность датчика: {{ param.status.signalling_indicator_reason['Неисправность датчика'] }}
                      </p>

                      <p v-if="param.status.signalling_indicator_reason['Потеря связи']">
                        Потеря связи: {{ param.status.signalling_indicator_reason['Потеря связи'] }}
                      </p>
                    </div>
                  </div>

                  <!-- <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'warning'" class="accumulator-signalling" style="color: #EBEB14" @click.prevent="toggleBatInfo">
                    ⚠
                    <div v-if="bat_info_toggle && selected_element === key" class="bat-info-div">
                      <div>
                        {{ JSON.stringify(param.status.signalling_indicator_reason, null, 4) }}
                      </div>
                    </div>
                  </div>
                  <div v-if="thing.shadow.online && param.status.signalling_indicator_status === 'critical'" class="accumulator-signalling" style="color: #C40D0D" @click.prevent="toggleBatInfo">⚠</div> -->

                  <div class="subject-info ml-1 mt-1" v-if="thing.shadow.online">
                    
                    <div v-if="param.u !== null">
                      <span v-if="param.status.voltage_status === 'normal'" style="color: #FFFFFF" class="subject-info-values">{{ (param.u).toFixed(2) }} V</span>
                      <span v-else-if="param.status.voltage_status === 'warning'" style="color: #EBEB14" class="subject-info-values">{{ (param.u).toFixed(2) }} V</span>
                      <span v-else-if="param.status.voltage_status === 'critical'" style="color: #C40D0D" class="subject-info-values">{{ (param.u).toFixed(2) }} V</span>
                      <span v-else style="color: #FFFFFF" class="subject-info-values">{{ (param.u).toFixed(2) }} V</span>
                    </div>
                    <div v-else>
                      <span style="color: #FFFFFF" class="subject-info-values">--.- V</span>
                    </div>

                    <hr style="margin: 0px; margin-top: 4px; margin-bottom: 4px;">

                  <div v-if="param.t !== null">
                    <span v-if="param.status.temperature_status === 'normal'" style="color: #FFFFFF" class="subject-info-values">{{ (param.t).toFixed(2) }} °C</span>
                    <span v-else-if="param.status.temperature_status === 'warning'" style="color: #EBEB14" class="subject-info-values">{{ (param.t).toFixed(2) }} °C</span>
                    <span v-else-if="param.status.temperature_status === 'critical'" style="color: #C40D0D" class="subject-info-values">{{ (param.t).toFixed(2) }} °C</span>
                    <span v-else style="color: #FFFFFF" class="subject-info-values">{{ (param.t).toFixed(2) }} °C</span>
                  </div>
                  <div v-else>
                    <span style="color: #FFFFFF" class="subject-info-values">-.-- °C</span>
                  </div>

                  </div>
                  <div class="subject-info" v-else>
                    <span style="color: #606060;" class="subject-info-values">-.-- V</span>
                    <hr style="margin: 0px; margin-top: 7px; margin-bottom: 7px;">
                    <span style="color: #606060" class="subject-info-values">-.-- °C</span>
                  </div>
                  <div v-show="selected_element === key" class="accumulator-active"></div>
                  <div v-if="selected_element === key" class="accumulator-info">
                    <div id="bottom" class="ml-3 mr-3">
                      <div class="d-flex justify-content-between">
                        <!-- <span v-if="param.name !== null">{{ param.name }}</span> -->
                        <!-- <span v-if="param.name !== null">{{ getMessageDescription(param.name) }}</span> -->
                        <span v-if="hub.shadow.software_version[0] <= 1 && hub.shadow.software_version[2] <= 4 && hub.shadow.software_version[4] <= 0 && param.name !== null">{{ param.name }}</span>
                        <span v-else-if="param.name !== null">{{ getMessageDescription(param.name) }}</span>
                        <!-- <span v-else-if="param.name !== null">{{ param.name }}</span> -->
                        <span v-if="param.date !== null">{{ param.date }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="col-sm-6">
        <div class="card card-second transparent">
          <div class="card-body" style="position: relative;">
            <div v-if="active_button == null">
              <vue-simple-spinner v-if="this.bars_data_preloader" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
              <div v-else>
                <div class="chart-div">
                  <div>{{ this.U_max }}</div>
                  <BarChart :chart-data="voltage_data" :options="options" :width=900 :height=360 />
                </div>
                <div class="chart-div">
                  <BarChart :chart-data="temperature_data" :options="options" :width=900 :height=360 />
                </div>
              </div>
            </div>
            <div v-else-if="active_button == 'sort-linear'">
              <!-- <vue-simple-spinner v-if="this.linear_data_preloader" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
              <div v-else> -->
              <div class="chart-div">
                <div class="chart-minmax mt-1"><span>Umax: {{ this.U_max }}</span><span class="ml-3">Umin: {{ this.U_min }}</span></div>
                <LineChart :chart-data="voltage_data" :options="options" :width=900 :height=360 />
              </div>
              <div class="chart-div">
                <div class="chart-minmax mt-1"><span>Tmax: {{ this.T_max }}</span><span class="ml-3">Tmin: {{ this.T_min }}</span></div>
                <LineChart :chart-data="temperature_data" :options="options" :width=900 :height=360 />
              </div>
              <!-- </div> -->
            </div>
            <div v-else-if="active_button == 'sort-bars'">
              <!-- <vue-simple-spinner v-if="this.bars_data_preloader" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
              <div v-else> -->
              <div class="chart-div">
                <div class="chart-minmax mt-1"><span>Umax: {{ this.U_max }}</span><span class="ml-3">Umin: {{ this.U_min }}</span></div>
                <BarChart :chart-data="voltage_data" :options="options" :width=900 :height=360></BarChart>
              </div>

              <div class="chart-div">
                <div class="chart-minmax mt-1"><span>Tmax: {{ this.T_max }}</span><span class="ml-3">Tmin: {{ this.T_min }}</span></div>
                <BarChart :chart-data="temperature_data" :options="options" :width=900 :height=360></BarChart>
              </div>
              <!-- </div> -->
            </div>
            <div v-else-if="active_button == 'sort-cols'">
              <div class="chart-div">
                <BarChart :chart-data="voltage_data" :options="options" :width=900 :height=360 />
              </div>
              <div class="chart-div">
                <BarChart :chart-data="temperature_data" :options="options" :width=900 :height=360 />
              </div>
            </div>
            <div v-else-if="active_button == 'current-parameters'">
              <div class="parameters-div">
                <div class="row">
                  <div class="col-6">
                    <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.signaling') }}</span>
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!warning_list.length && !critical_list.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список сигнализаций пуст</span>
                      <div v-for="elem in critical_list" v-bind:key="elem">
                        <span class="red-dot"></span><span style="color: #FFFFFF; font-size: 14px; letter-spacing: 0.7px;">{{ elem }}</span>
                      </div>
                      <div v-for="elem in warning_list" v-bind:key="elem">
                        <span class="yellow-dot"></span><span style="color: #FFFFFF; font-size: 14px; letter-spacing: 0.7px;">{{ elem }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.diagnostics') }}</span>
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!diagnostic_statuses.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список сигналов пуст</span>
                      <div v-for="elem in diagnostic_statuses" v-bind:key="elem">
                        <span class="yellow-dot"></span><span style="color: #FFFFFF; font-size: 14px; letter-spacing: 0.7px;">{{ elem }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="parameters-div mt-3">
                <div class="row">
                  <div class="col-6">
                    <div class="d-flex justify-content-between">
                      <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.eventjournal') }}</span>
                      <div class="circle-35 files journal-download" v-on:click="event_journal_datepicker_show = !event_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
											<div v-if="event_journal_datepicker_show" class="journal-datepicker-div">
												<div class="d-flex flex-column">
													<span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать журнал событий</span>
													<date-picker v-model="event_range" :model-config="modelConfig" is-range color="green" is-dark/>
													<div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('event_log')">Скачать</div></div>
												</div>
											</div>
                    </div>
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!event_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                      <div v-for="record in event_logs" :key="record.id">
                        <div class="row">
                          <!-- {{ record }} -->
                          <div class="col-5 p-0">
														<span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
													</div>
													<div class="col-7">
														<!-- <p v-for="elem in getEventDescription(record.data.Parameters)" :key="elem" style="font-size: 14px;">{{ elem }}</p> -->
                            <!-- <p style="font-size: 14px;">{{ getEventDescription(record.data.Parameters) }}</p> -->
                            <!-- <p style="font-size: 14px;">{{ getEventDescription(record.data) }}</p> -->
                            <div v-for="event in getEventDescription(record.data)" :key="event">
                              <p style="font-size: 14px;">{{ event }}</p>
                            </div>
													</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="d-flex justify-content-between">
                      <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.messagejournal') }}</span>
                      <div class="circle-35 files journal-download" v-on:click="message_journal_datepicker_show = !message_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
                      <div v-if="message_journal_datepicker_show" class="journal-datepicker-div">
												<div class="d-flex flex-column">
													<span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать системный журнал</span>
													<date-picker v-model="message_range" :model-config="modelConfig" is-range color="green" is-dark/>
													<div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('message_log')">Скачать</div></div>
												</div>
											</div>
                    </div>										
                    <hr style="background: #aaaaaa;">
                    <div class="journals-div overflow-auto scroller">
                      <span v-if="!message_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                      <div v-for="record in message_logs" :key="record.id">
                        <div class="row">
                          <!-- {{ record }} -->
                          <div class="col-5 p-0">
														<span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
													</div>
													<div class="col-7">
														<!-- <p v-for="elem in getEventDescription(record.data.Parameters)" :key="elem" style="font-size: 14px;">{{ elem }}</p> -->
                            <p style="font-size: 14px;">{{ getMessageDescription(record.data.Text) }}</p>
													</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="date-picker" v-if="active_button == 'sort-linear' || active_button == 'sort-bars'">
              <div class="row">
                <div v-if="active_date == '24h'">
                  <div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">Д</div>
                </div>
                <div v-else>
                  <div class="circle-50 mb-2" v-on:click="active_date = '24h'; fillData()">Д</div>
                </div>
              </div>
              <div class="row">
                <div v-if="active_date == '31d'">
                  <div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">М</div>
                </div>
                <div v-else>
                  <div class="circle-50 mb-2" v-on:click="active_date = '31d'; fillData()">М</div>
                </div>
              </div>
              <div class="row">
                <div v-if="active_date == '365d'">
                  <div class="circle-50 active mb-2" v-on:click="active_date = null; fillData()">Г</div>
                </div>
                <div v-else>
                  <div class="circle-50 mb-2" v-on:click="active_date = '365d'; fillData()">Г</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {facilitiesService, logsService, thingsService, journalsService} from "../../_services";
  import BarChart from "../charts/BarChart.vue";
  import LineChart from "../charts/LineChart.vue";
  import VueSimpleSpinner from 'vue-simple-spinner'
  import Vue from "vue";
  import moment from 'moment';


  moment.locale('ru');

  export default {
    name: 'RepeyBase',
    props: ['facilityId', 'thingId'],
    components: {
      VueSimpleSpinner,
      BarChart,
      LineChart
    },
    data () {
      return {
        // facilityId: this.$route.params.facility_id,
        // thingId: this.$route.params.thing_id,
        preloading: false,
        thing: '',
        facility: '',
        accumulators: {},
        battery_status: 'normal',
        main_battery_status: [],
        selected_element: 'battery',    // possible values: 'battery', 'battery_0xa94'
        date: "",
        time: "",
        batteryFillHeight: null,
        // -- bars
        active_button: 'sort-bars',    // possible values: "sort-linear, sort-bars, sort-cols"
        active_date: null,       // possible values: "24h, 31d, 365d"
        voltage_data: {},
        temperature_data: {},
        U_max: null,
        U_min: null,
        T_max: null,
        T_min: null,
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              // display: false, //this will remove all the x-axis grid lines
              // ticks: {
              //   display: false //this will remove only the label
              // }
            }],
          },
          tooltips: {
            enabled: false
          },
          legend: {
            display: true,
            position: 'top',
            align: 'start',
            // fullWidth: true,
            labels: {
              // fontColor: 'rgb(255, 255, 255)',
              boxWidth: 0,
              // generateLabels: {
              //   text: 'string',
              //   fillStyle: 'rgb(255, 255, 255)',
              //   hidden: false,
              // }
            }
          },
        },
        akb_info_toggle: false,
        bat_info_toggle: false,
        battery_status_reason: {},
        warning_list: [],
        critical_list: [],
        diagnostic_statuses: [],
        linear_data_preloader: false,
        bars_data_preloader: false,
				event_logs: [],
				message_logs: [],
				event_range: {
					start: new Date(new Date().setHours(0,0,0,0)),
					end: new Date(new Date().setHours(23,59,59,0)),
				},
				message_range: {
					start: new Date(new Date().setHours(0,0,0,0)),
					end: new Date(new Date().setHours(23,59,59,0)),
				},
				modelConfig: {
					start: {
						timeAdjust: '00:00:00',
					},
					end: {
						timeAdjust: '23:59:59',
					},
				},
				event_journal_datepicker_show: false,
				message_journal_datepicker_show: false,
      }
    },
    async created() {
      this.preloading = true;
      await this.init();
      this.preloading = false;
      this.getNow();
      this.setHeight();
      this.fillData();
      this.getMainBatteryCurrentStatus();
      // this.getBatteryStatus();
      this.getBatterySignallingStatuses();
      this.getDiagnosticStatuses();
      // this.getBatteryStatusInfo();

      // setInterval(async () => {
      //   this.warning_list = [];
      //   this.critical_list = [];
      //   await this.getThing();
      //   await this.getFacility();
      //   await this.getAccumulators();
      //   await this.getNow();
      //   await this.setHeight();
      //   await this.getMainBatteryCurrentStatus();
      //   await this.fillData();
      //   await this.getBatterySignallingStatuses();
      // }, 1500);

      setInterval(function () {
        this.warning_list = [];
        this.critical_list = [];
        this.getThing();
        this.getFacility();
        this.getAccumulators();
        this.getNow();
        this.setHeight();
        this.getMainBatteryCurrentStatus();
        this.fillData();
        this.getBatterySignallingStatuses();
        this.getDiagnosticStatuses();
      }.bind(this), 15000);
    },
    methods: {
      async init() {
        console.log('Rendering "RepeyBase" component');
        await this.getThing();
        await this.getFacility();
        // await this.getHub();
        await this.getAccumulators();
      },
      async getThing() {
        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        // console.log(this.thing);

        // console.log(this.thing.hub.id);

        this.hub = await this.getHub(this.thing.hub.id);

        // console.log(this.hub);

        // console.log(this.hub.shadow.software_version);
      },
      async getHub(id) {
        let res = await thingsService.thing_get(this.facilityId, id);

        if (res.error) {
          console.error(res.error);

          return;
        }

        return res.result;
      },
      async getFacility() {
        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facility = res.result.facility;
      },
      async get_event_logs() {
				let args = {
					types: 'event_log',
					start: 0,
					limit: 50,
				}

				let res = await journalsService.journals_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.event_logs = res.result.items;
			},
			async get_system_logs() {
        // console.log('get_system_logs worked');
				let args = {
					types: 'message_log',
					start: 0,
					limit: 50,
				}

				let res = await journalsService.journals_get(this.thingId, args);

				if (res.error) {
					console.error(res.error);

					return;
				}

				this.message_logs = res.result.items;
			},
      async getAccumulators() {
        this.accumulators = this.thing.shadow.battery_parameters;

        if (this.thing.shadow && this.thing.shadow.device_info && this.thing.shadow.device_info.first_version_number_0x0122_0x0123 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125) {
          if (this.thing.shadow.device_info.first_version_number_0x0122_0x0123 === 1 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125 === 0) {
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 20) {
              delete this.accumulators.battery_0xa7e
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 19) {
              delete this.accumulators.battery_0xa74
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 18) {
              delete this.accumulators.battery_0xa6a
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 17) {
              delete this.accumulators.battery_0xa60
            }
          } else if (this.thing.shadow.device_info.first_version_number_0x0122_0x0123 === 1 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125 === 1) {
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 20) {
              delete this.accumulators.battery_0xaa4
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 19) {
              delete this.accumulators.battery_0xa98
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 18) {
              delete this.accumulators.battery_0xa8c
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 17) {
              delete this.accumulators.battery_0xa80
            }
          } else {
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 20) {
              delete this.accumulators.battery_0xaa4
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 19) {
              delete this.accumulators.battery_0xa98
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 18) {
              delete this.accumulators.battery_0xa8c
            }
            if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 17) {
              delete this.accumulators.battery_0xa80
            }
          }
        } else {
          if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 20) {
            delete this.accumulators.battery_0xa7e
          }
          if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 19) {
            delete this.accumulators.battery_0xa74
          }
          if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 18) {
            delete this.accumulators.battery_0xa6a
          }
          if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 17) {
            delete this.accumulators.battery_0xa60
          }
        }

        // if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 20) {
        //   delete this.accumulators.battery_0xa7e
        // }
        // if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 19) {
        //   delete this.accumulators.battery_0xa74
        // }
        // if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 18) {
        //   delete this.accumulators.battery_0xa6a
        // }
        // if (this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207 < 17) {
        //   delete this.accumulators.battery_0xa60
        // }
        // TODO: count batteries

        for (let accumulator in this.accumulators) {
          // this.accumulators[accumulator].name = 'dummy';  // this.thing.shadow.general_setpoints.accum_1_name_0x02CB:

          // this.accumulators[accumulator].name = eval('this.thing.shadow.general_setpoints.' + this.getAccumulatorName(accumulator));

          this.accumulators[accumulator].name = this.getAccumulatorName(this.getAccumulatorNumber(accumulator));

          if (this.accumulators[accumulator].name === '') {
            this.accumulators[accumulator].name = '---'
          }

          let AccumulatorManufacturingDate = this.getAccumulatorManufacturingDate(this.getAccumulatorNumber(accumulator));

          this.accumulators[accumulator].date = AccumulatorManufacturingDate;

          this.accumulators[accumulator].status = this.getAccumulatorStatus(this.getAccumulatorNumber(accumulator) - 1);
        }
      },
      getBatterySignallingStatuses() {
        // Цвет кругляша
        // ------------------------------------------------------------------------------- logic_out_signal_state_0x0154
        let battery_status = 'normal';

        let critical_signalisation = 'normal';
        let warning_signalisation = 'normal';

        let deep_discharge_status = 'normal';
        let high_voltage_status = 'normal';
        let high_pulsation_U = 'normal';
        let none_thermocompensation = 'normal';
        let high_charge_current = 'normal';

        let high_pulsation_I = 'normal';
        let equip_low_temperature = 'normal';
        let equip_high_temperature_1 = 'normal';
        let equip_high_temperature_2 = 'normal';
        let room_low_temperature = 'normal';
        let room_high_temperature = 'normal';
        let REPEY_malfunction = 'normal';
        // let remove_alarm = 'normal';

        let equip_sensor_malfunction = 'normal';
        let room_sensor_malfunction = 'normal';
        let equip_lost_connection = 'normal';
        let room_lost_connection = 'normal';
        // let no_connection_equip_sensor = 'normal';
        // let no_connection_room_sensor = 'normal';

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1') {
            battery_status = 'warning';
            warning_signalisation = 'warning';
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1') {
            battery_status = 'critical';
            critical_signalisation = 'critical';
          }
        } catch (e) {
          console.log(e);
        }

        if (!this.thing.shadow.online) {
          battery_status = 'offline';
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[2] === '1') {
            deep_discharge_status = 'warning';
            this.warning_list.push("АБ: глубокий разряд");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[5] === '1') {
              deep_discharge_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: глубокий разряд")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[3] === '1') {
            high_voltage_status = 'warning';
            this.warning_list.push("АБ: высокое напряжение");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[6] === '1') {
              high_voltage_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: высокое напряжение")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[4] === '1') {
            high_pulsation_U = 'warning';
            this.warning_list.push("АБ: высокие пульсации U");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[7] === '1') {
              high_pulsation_U = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: высокие пульсации U")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[5] === '1') {
            none_thermocompensation = 'warning';
            this.warning_list.push("АБ: отсутствие термокомпенсации");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[8] === '1') {
              none_thermocompensation = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: отсутствие термокомпенсации")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[6] === '1') {
            high_charge_current = 'warning';
            this.warning_list.push("АБ: высокий ток заряда");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[9] === '1') {
              high_charge_current = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: высокий ток заряда")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[8] === '1') {
            high_pulsation_I = 'warning';
            this.warning_list.push("АБ: высокие пульсации I");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[11] === '1') {
              high_pulsation_I = 'critical';
              this.warning_list.pop();
              this.critical_list.push("АБ: высокие пульсации I")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[9] === '1') {
            equip_low_temperature = 'warning';
            this.warning_list.push("Шкаф: низкая температура");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[12] === '1') {
              equip_low_temperature = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Шкаф: низкая температура")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[10] === '1') {
            equip_high_temperature_1 = 'warning';
            this.warning_list.push("Шкаф: высокая температура 1 ст.");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[13] === '1') {
              equip_high_temperature_1 = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Шкаф: высокая температура 1 ст.")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[11] === '1') {
            equip_high_temperature_2 = 'warning';
            this.warning_list.push("Шкаф: высокая температура 2 ст.");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[14] === '1') {
              equip_high_temperature_2 = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Шкаф: высокая температура 2 ст.")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[12] === '1') {
            room_low_temperature = 'warning';
            this.warning_list.push("Помещение: низкая температура");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[15] === '1') {
              room_low_temperature = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Помещение: низкая температура")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[13] === '1') {
            room_high_temperature = 'warning';
            this.warning_list.push("Помещение: высокая температура");
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[0] === '1') {
              room_high_temperature = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Помещение: высокая температура")
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[14] === '1') {
            REPEY_malfunction = 'warning';
            this.warning_list.push("РЕПЕЙ: неисправность");
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0155[0] === '1') {
            equip_sensor_malfunction = 'warning';
            this.warning_list.push("Шкаф: неисправность датчика");
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0155[1] === '1') {
            room_sensor_malfunction = 'warning';
            this.warning_list.push("Помещение: неисправность датчика");
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0155[2] === '1') {
            equip_lost_connection = 'warning';
            this.warning_list.push("Шкаф: потеря связи");
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0155[3] === '1') {
            room_lost_connection = 'warning';
            this.warning_list.push("Помещение: потеря связи");
          }
        } catch (e) {
          console.log(e);
        }

        let answer = {
          'critical_signalisation': critical_signalisation,
          'warning_signalisation': warning_signalisation,
          'deep_discharge_status': deep_discharge_status,
          'high_voltage_status': high_voltage_status,
          'high_pulsation_U': high_pulsation_U,
          'none_thermocompensation': none_thermocompensation,
          'high_charge_current': high_charge_current,
          'high_pulsation_I': high_pulsation_I,
          'equip_low_temperature': equip_low_temperature,
          'equip_high_temperature_1': equip_high_temperature_1,
          'equip_high_temperature_2': equip_high_temperature_2,
          'room_low_temperature': room_low_temperature,
          'room_high_temperature': room_high_temperature,
          'REPEY_malfunction': REPEY_malfunction,
          'equip_sensor_malfunction': equip_sensor_malfunction,
          'room_sensor_malfunction': room_sensor_malfunction,
          'equip_lost_connection': equip_lost_connection,
          'room_lost_connection': room_lost_connection,
        }

        answer['end'] = 'end';

        // console.log(answer);

        this.battery_status = battery_status;
      },
      // getBatteryStatus() {
      //   // цвет кругляша
      //
      //   let _status = 'normal';
      //   let battery_statuses = [];
      //   let battery_status = 'normal';
      //   // let battery_status_reason = {};
      //
      //   try {
      //     for (let accumulator in this.accumulators) {
      //
      //       if (this.accumulators[accumulator].status.akb_logo_status === 'warning' || this.accumulators[accumulator].status.signalling_indicator_status === 'warning' || this.accumulators[accumulator].status.voltage_status === 'warning' || this.accumulators[accumulator].status.temperature_status === 'warning') {
      //         _status = 'warning';
      //
      //         if (this.accumulators[accumulator].status.akb_logo_status === 'critical' || this.accumulators[accumulator].status.signalling_indicator_status === 'critical' || this.accumulators[accumulator].status.voltage_status === 'critical' || this.accumulators[accumulator].status.temperature_status === 'critical') {
      //           _status = 'critical';
      //         }
      //       }
      //
      //       battery_statuses.push(_status);
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   }
      //
      //   if (battery_statuses.includes('warning')) {
      //     battery_status = 'warning'
      //   }
      //
      //   if (battery_statuses.includes('critical')) {
      //     battery_status = 'critical'
      //   }
      //
      //   // this.battery_status_reason = battery_status_reason;
      //
      //   if (battery_status !== 'critical'){
      //     if (this.main_battery_status['ab_voltage_status'] === 'warning' || this.main_battery_status['ab_temperature_status'] === 'warning' || this.main_battery_status['ab_amperage_status'] === 'warning' || this.main_battery_status['ab_outer_temperature_status'] === 'warning' || this.main_battery_status['ab_voltage_pulsation_status'] === 'warning' || this.main_battery_status['ab_amperage_pulsation_status'] === 'warning') {
      //       battery_status = 'warning'
      //     }
      //
      //     if (this.main_battery_status['ab_voltage_status'] === 'critical' || this.main_battery_status['ab_temperature_status'] === 'critical' || this.main_battery_status['ab_amperage_status'] === 'critical' || this.main_battery_status['ab_outer_temperature_status'] === 'critical' || this.main_battery_status['ab_voltage_pulsation_status'] === 'critical' || this.main_battery_status['ab_amperage_pulsation_status'] === 'critical') {
      //       battery_status = 'critical'
      //     }
      //   }
      //
      //   if (!this.thing.shadow.online) {
      //     battery_status = 'offline'
      //   }
      //
      //   this.battery_status = battery_status;
      // },

      getMainBatteryCurrentStatus() {
        let ab_voltage_status = 'normal';
        let ab_temperature_status = 'normal';
        let ab_amperage_status = 'normal';
        let ab_outer_temperature_status = 'normal';
        let ab_voltage_pulsation_status = 'normal';
        let ab_amperage_pulsation_status = 'normal';

        // current_logical_outputs_signals_state_0x0140_0x0141[4] - new signal: ab voltage is None

        try {
          if (ab_voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[0] === '1') {
            ab_voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[5] === '1') {
              ab_voltage_status = 'critical';
            }
          }

          if (ab_voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[1] === '1') {
            ab_voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[6] === '1') {
              ab_voltage_status = 'critical';
            }
          }

          if (ab_voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[3] === '1') {
            ab_voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[8] === '1') {
              ab_voltage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (ab_temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[10] === '1') {
            ab_temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[12] === '1') {
              ab_temperature_status = 'critical';
            }
          }

          if (ab_temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[11] === '1') {
            ab_temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[13] === '1') {
              ab_temperature_status = 'critical';
            }
          }

          if (ab_temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[12] === '1') {
            ab_temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[14] === '1') {
              ab_temperature_status = 'critical';
            }
          }

        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[7] === '1') {
            ab_amperage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[9] === '1') {
              ab_amperage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (ab_outer_temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[13] === '1') {
            ab_outer_temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[15] === '1') {
              ab_outer_temperature_status = 'critical';
            }
          }

          if (ab_outer_temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[14] === '1') {
            ab_outer_temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[0] === '1') {
              ab_outer_temperature_status = 'critical';
            }
          }

        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[2] === '1') {
            ab_voltage_pulsation_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[7] === '1') {
              ab_voltage_pulsation_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.current_logical_outputs_signals_state_0x0140_0x0141[9] === '1') {
            ab_voltage_pulsation_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0700[11] === '1') {
              ab_voltage_pulsation_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        this.main_battery_status['ab_voltage_status'] = ab_voltage_status;
        this.main_battery_status['ab_temperature_status'] = ab_temperature_status;
        this.main_battery_status['ab_amperage_status'] = ab_amperage_status;
        this.main_battery_status['ab_outer_temperature_status'] = ab_outer_temperature_status;
        this.main_battery_status['ab_voltage_pulsation_status'] = ab_voltage_pulsation_status;
        this.main_battery_status['ab_amperage_pulsation_status'] = ab_amperage_pulsation_status;
      },

      getAccumulatorStatus(accumulator_numb) {
        let akb_logo_status = 'normal';
        let voltage_status = 'normal';
        let temperature_status = 'normal';
        let signalling_indicator_status = 'normal';
        let signalling_indicator_reason = {};

        // ============================================================================================= akb_logo_status

        try {
          if (akb_logo_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.accelerated_wear_0x0142_0x0143[accumulator_numb] === '1') {
            akb_logo_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              akb_logo_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (akb_logo_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.degradation_0x014E_0x014F[accumulator_numb] === '1') {
            akb_logo_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[2] === '1') {
              akb_logo_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (akb_logo_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.short_circuit_0x0150_0x0151[accumulator_numb] === '1') {
            akb_logo_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[3] === '1') {
              akb_logo_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (akb_logo_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.thermal_overclock_0x0152_0x0153[accumulator_numb] === '1') {
            akb_logo_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[4] === '1') {
              akb_logo_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.connection_lost_0x0162_0x0163[accumulator_numb] === '1') {
            akb_logo_status = 'offline';
          }
        } catch (e) {
          console.log(e);
        }

        // ============================================================================================== voltage_status

        try {
          if (voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.deep_discharge_0x0144_0x0145[accumulator_numb] === '1') {
            voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              voltage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.high_voltage_0x0146_0x0147[accumulator_numb] === '1') {
            voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              voltage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.degradation_0x014E_0x014F[accumulator_numb] === '1') {
            voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[2] === '1') {
              voltage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (voltage_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.short_circuit_0x0150_0x0151[accumulator_numb] === '1') {
            voltage_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[3] === '1') {
              voltage_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        // ========================================================================================== temperature_status

        try {
          if (temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.low_temperature_0x0148_0x0149[accumulator_numb] === '1') {
            temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              temperature_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.high_temperature_0x014A_0x014B[accumulator_numb] === '1') {
            temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              temperature_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (temperature_status !== 'critical' && this.thing.shadow.logical_outputs_signals_state.thermal_overclock_0x0152_0x0153[accumulator_numb] === '1') {
            temperature_status = 'warning';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[4] === '1') {
              temperature_status = 'critical';
            }
          }
        } catch (e) {
          console.log(e);
        }

        // ================================================================================= signalling_indicator_status

        let accelerated_wear_status = 'normal';
        let degradation_status = 'normal';
        let short_circuit_status = 'normal';
        let thermal_overclock_status = 'normal';
        let sensor_malfunction_status = 'normal';
        let connection_lost_status = 'normal';

        try {
          if (this.thing.shadow.logical_outputs_signals_state.accelerated_wear_0x0156_0x0157[accumulator_numb] === '1') {
            accelerated_wear_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " ускоренный износ");
            signalling_indicator_reason['Ускоренный износ'] = 'предупреждение';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[1] === '1') {
              accelerated_wear_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Акм №" + (accumulator_numb + 1) + " ускоренный износ")
              signalling_indicator_reason['Ускоренный износ'] = 'авария';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.degradation_0x0158_0x0159[accumulator_numb] === '1') {
            degradation_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " деградация");
            signalling_indicator_reason['Деградация'] = 'предупреждение';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[2] === '1') {
              degradation_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Акм №" + (accumulator_numb + 1) + " деградация")
              signalling_indicator_reason['Деградация'] = 'авария';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.short_circuit_0x015A_0x015B[accumulator_numb] === '1') {
            short_circuit_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " короткое замыкание");
            signalling_indicator_reason['Короткое замыкание'] = 'предупреждение';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[3] === '1') {
              short_circuit_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Акм №" + (accumulator_numb + 1) + " короткое замыкание")
              signalling_indicator_reason['Короткое замыкание'] = 'авария';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.thermal_overclock_0x015C_0x015D[accumulator_numb] === '1') {
            thermal_overclock_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " тепловой разгон");
            signalling_indicator_reason['Тепловой разгон'] = 'предупреждение';
            if (this.thing.shadow.algorithm_setpoints.signalling_binary_0x0701[4] === '1') {
              thermal_overclock_status = 'critical';
              this.warning_list.pop();
              this.critical_list.push("Акм №" + (accumulator_numb + 1) + " тепловой разгон")
              signalling_indicator_reason['Тепловой разгон'] = 'авария';
            }
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.connection_lost_0x015E_0x015F[accumulator_numb] === '1') {
            connection_lost_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " потеря связи");
            signalling_indicator_reason['Потеря связи'] = 'предупреждение';
          }
        } catch (e) {
          console.log(e);
        }

        try {
          if (this.thing.shadow.logical_outputs_signals_state.sensor_malfunction_0x0160_0x0161[accumulator_numb] === '1') {
            sensor_malfunction_status = 'warning';
            this.warning_list.push("Акм №" + (accumulator_numb + 1) + " неисправность датчика");
            signalling_indicator_reason['Неисправность датчика'] = 'предупреждение';


            // Vue.set(this.accumulators[accumulator_numb], 'signalling_indicator_reason', {'Неисправность датчика': 'предупреждение'});
          }
        } catch (e) {
          console.log(e);
        }

        if (signalling_indicator_status !== 'critical' && accelerated_wear_status === 'warning') {
          signalling_indicator_status = 'warning';
        }
        if (accelerated_wear_status === 'critical') {
          signalling_indicator_status = 'critical';
        }

        if (signalling_indicator_status !== 'critical' && degradation_status === 'warning') {
          signalling_indicator_status = 'warning';
        }
        if (degradation_status === 'critical') {
          signalling_indicator_status = 'critical';
        }

        if (signalling_indicator_status !== 'critical' && short_circuit_status === 'warning') {
          signalling_indicator_status = 'warning';
        }
        if (short_circuit_status === 'critical') {
          signalling_indicator_status = 'critical';
        }

        if (signalling_indicator_status !== 'critical' && thermal_overclock_status === 'warning') {
          signalling_indicator_status = 'warning';
        }
        if (thermal_overclock_status === 'critical') {
          signalling_indicator_status = 'critical';
        }

        if (signalling_indicator_status !== 'critical' && connection_lost_status === 'warning') {
          signalling_indicator_status = 'warning';
          // if (connection_lost_status === 'critical') {
          //   signalling_indicator_status = 'critical';
          // }
        }

        if (signalling_indicator_status !== 'critical' && sensor_malfunction_status === 'warning') {
          signalling_indicator_status = 'warning';
          // if (connection_lost_status === 'critical') {
          //   signalling_indicator_status = 'critical';
          // }
        }


        return {
          'akb_logo_status': akb_logo_status,
          'voltage_status': voltage_status,
          'temperature_status': temperature_status,
          'signalling_indicator_status': signalling_indicator_status,
          'signalling_indicator_reason': signalling_indicator_reason,
        }
      },
      getNow: function() {
        let today = new Date(this.thing.updated + 'Z');
        this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      },
      getAccumulatorNumber(key) {

        let values = {}

        if (this.thing.shadow.device_info.first_version_number_0x0122_0x0123 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125) {
          if (this.thing.shadow.device_info.first_version_number_0x0122_0x0123 === 1 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125 === 0) {
            values = {
              'battery': '',
              'battery_0x9c0': 1,
              'battery_0x9ca': 2,
              'battery_0x9d4': 3,
              'battery_0x9de': 4,
              'battery_0x9e8': 5,
              'battery_0x9f2': 6,
              'battery_0x9fc': 7,
              'battery_0xa06': 8,
              'battery_0xa10': 9,
              'battery_0xa1a': 10,
              'battery_0xa24': 11,
              'battery_0xa2e': 12,
              'battery_0xa38': 13,
              'battery_0xa42': 14,
              'battery_0xa4c': 15,
              'battery_0xa56': 16,
              'battery_0xa60': 17,
              'battery_0xa6a': 18,
              'battery_0xa74': 19,
              'battery_0xa7e': 20
            }
          } else if (this.thing.shadow.device_info.first_version_number_0x0122_0x0123 === 1 && this.thing.shadow.device_info.second_version_number_0x0124_0x0125 === 1) {
            values = {
              'battery': '',
              'battery_0x9c0': 1,
              'battery_0x9cc': 2,
              'battery_0x9d8': 3,
              'battery_0x9e4': 4,
              'battery_0x9f0': 5,
              'battery_0x9fc': 6,
              'battery_0xa08': 7,
              'battery_0xa14': 8,
              'battery_0xa20': 9,
              'battery_0xa2c': 10,
              'battery_0xa38': 11,
              'battery_0xa44': 12,
              'battery_0xa50': 13,
              'battery_0xa5c': 14,
              'battery_0xa68': 15,
              'battery_0xa74': 16,
              'battery_0xa80': 17,
              'battery_0xa8c': 18,
              'battery_0xa98': 19,
              'battery_0xaa4': 20
            }
          } else {
            values = {
              'battery': '',
              'battery_0x9c0': 1,
              'battery_0x9cc': 2,
              'battery_0x9d8': 3,
              'battery_0x9e4': 4,
              'battery_0x9f0': 5,
              'battery_0x9fc': 6,
              'battery_0xa08': 7,
              'battery_0xa14': 8,
              'battery_0xa20': 9,
              'battery_0xa2c': 10,
              'battery_0xa38': 11,
              'battery_0xa44': 12,
              'battery_0xa50': 13,
              'battery_0xa5c': 14,
              'battery_0xa68': 15,
              'battery_0xa74': 16,
              'battery_0xa80': 17,
              'battery_0xa8c': 18,
              'battery_0xa98': 19,
              'battery_0xaa4': 20
            }
          }
        } else {
          values = {
            'battery': '',
            'battery_0x9c0': 1,
            'battery_0x9ca': 2,
            'battery_0x9d4': 3,
            'battery_0x9de': 4,
            'battery_0x9e8': 5,
            'battery_0x9f2': 6,
            'battery_0x9fc': 7,
            'battery_0xa06': 8,
            'battery_0xa10': 9,
            'battery_0xa1a': 10,
            'battery_0xa24': 11,
            'battery_0xa2e': 12,
            'battery_0xa38': 13,
            'battery_0xa42': 14,
            'battery_0xa4c': 15,
            'battery_0xa56': 16,
            'battery_0xa60': 17,
            'battery_0xa6a': 18,
            'battery_0xa74': 19,
            'battery_0xa7e': 20
          }
        }

        return values[key];
      },
      getAccumulatorName(key) {
        let values = {

          // this.thing.shadow.general_setpoints.accum_1_name_0x02CB:

          1: this.thing.shadow.general_setpoints.accum_1_name_0x02CB,
          2: this.thing.shadow.general_setpoints.accum_2_name_0x02EB,
          3: this.thing.shadow.general_setpoints.accum_3_name_0x030B,
          4: this.thing.shadow.general_setpoints.accum_4_name_0x032B,
          5: this.thing.shadow.general_setpoints.accum_5_name_0x034B,
          6: this.thing.shadow.general_setpoints.accum_6_name_0x036B,
          7: this.thing.shadow.general_setpoints.accum_7_name_0x038B,
          8: this.thing.shadow.general_setpoints.accum_8_name_0x03AB,
          9: this.thing.shadow.general_setpoints.accum_9_name_0x03CB,
          10: this.thing.shadow.general_setpoints.accum_10_name_0x03EB,
          11: this.thing.shadow.general_setpoints.accum_11_name_0x040B,
          12: this.thing.shadow.general_setpoints.accum_12_name_0x042B,
          13: this.thing.shadow.general_setpoints.accum_13_name_0x044B,
          14: this.thing.shadow.general_setpoints.accum_14_name_0x046B,
          15: this.thing.shadow.general_setpoints.accum_15_name_0x048B,
          16: this.thing.shadow.general_setpoints.accum_16_name_0x04AB,
          17: this.thing.shadow.general_setpoints.accum_17_name_0x04CB,
          18: this.thing.shadow.general_setpoints.accum_18_name_0x04EB,
          19: this.thing.shadow.general_setpoints.accum_19_name_0x050B,
          20: this.thing.shadow.general_setpoints.accum_20_name_0x052B
        }

        return values[key];
      },

      AccumDate(timestamp) {
        if (timestamp === '') {
          return '--.----'
        }

        let month = '--'
        let year = '----'

        let date = new Date(timestamp * 1000);

        if ((new Date(date)).getTime() > 0) {
          // date.toLocaleString("en-US", {weekday: "long"}) // Monday
          month = date.toLocaleString("en-US", {month: "numeric"})
          // let day = date.toLocaleString("en-US", {day: "numeric"}) // 9
          year = date.toLocaleString("en-US", {year: "numeric"}) // 2019
          // date.toLocaleString("en-US", {hour: "numeric"}) // 10 AM
          // date.toLocaleString("en-US", {minute: "numeric"}) // 30
          // date.toLocaleString("en-US", {second: "numeric"}) // 15
          // date.toLocaleString("en-US", {timeZoneName: "short"}) // 12/9/2019, 10:30:15 AM CST
        } else {
          return '--.----';
        }

        return month + '.' + year
      },

      getAccumulatorManufacturingDate(key) {
        let values = {

          // this.thing.shadow.general_setpoints.accum_1_name_0x02CB:

          1: this.AccumDate(this.thing.shadow.general_setpoints.accum_1_manuf_date_0x054B),
          2: this.AccumDate(this.thing.shadow.general_setpoints.accum_2_manuf_date_0x054F),
          3: this.AccumDate(this.thing.shadow.general_setpoints.accum_3_manuf_date_0x0553),
          4: this.AccumDate(this.thing.shadow.general_setpoints.accum_4_manuf_date_0x0557),
          5: this.AccumDate(this.thing.shadow.general_setpoints.accum_5_manuf_date_0x055B),
          6: this.AccumDate(this.thing.shadow.general_setpoints.accum_6_manuf_date_0x055F),
          7: this.AccumDate(this.thing.shadow.general_setpoints.accum_7_manuf_date_0x0563),
          8: this.AccumDate(this.thing.shadow.general_setpoints.accum_8_manuf_date_0x0567),
          9: this.AccumDate(this.thing.shadow.general_setpoints.accum_9_manuf_date_0x056B),
          10: this.AccumDate(this.thing.shadow.general_setpoints.accum_10_manuf_date_0x056F),
          11: this.AccumDate(this.thing.shadow.general_setpoints.accum_11_manuf_date_0x0573),
          12: this.AccumDate(this.thing.shadow.general_setpoints.accum_12_manuf_date_0x0577),
          13: this.AccumDate(this.thing.shadow.general_setpoints.accum_13_manuf_date_0x057B),
          14: this.AccumDate(this.thing.shadow.general_setpoints.accum_14_manuf_date_0x057F),
          15: this.AccumDate(this.thing.shadow.general_setpoints.accum_15_manuf_date_0x0583),
          16: this.AccumDate(this.thing.shadow.general_setpoints.accum_16_manuf_date_0x0587),
          17: this.AccumDate(this.thing.shadow.general_setpoints.accum_17_manuf_date_0x058B),
          18: this.AccumDate(this.thing.shadow.general_setpoints.accum_18_manuf_date_0x058F),
          19: this.AccumDate(this.thing.shadow.general_setpoints.accum_19_manuf_date_0x0593),
          20: this.AccumDate(this.thing.shadow.general_setpoints.accum_20_manuf_date_0x0597),
        }

        return values[key];
      },
      setHeight() {
        if (this.thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 >= 220) {
          this.batteryFillHeight = 0;
        } else if (this.thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 <= 105 && this.thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 > 0) {
          this.batteryFillHeight = 55;
        } else if (this.thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 === 0) {
          this.batteryFillHeight = 60;
        } else {
          this.batteryFillHeight = 60 - ((this.thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981 - 100) / 2);
        }
      },
      async fillData() {
        if (this.active_button === 'sort-linear') {
          this.linear_data_preloader = true;
          await this.fill_linear_data();
          this.linear_data_preloader = false;
        } else if (this.active_button === 'sort-bars') {
          this.bars_data_preloader = true;
          await this.fill_bars_data();
          this.bars_data_preloader = false;
        } else if (this.active_button === 'sort-cols') {
          await this.fill_cols_data();
        } else {
          console.log('Unknown button state');
        }
      },
      async fill_linear_data() {
        // this.options.scales.xAxes = [{ ticks: { display: false } }]

        this.options.scales.yAxes = [{
          ticks: {
            // min: 0,
          }
        }]

        this.options.scales.xAxes = [{
          ticks: {
            // min: 0,
            callback: (value) => {
              let datetime = new Date(value);

              var months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

              if (this.active_date === '24h') {
                return datetime.getHours()
              } else if (this.active_date === '31d') {
                return datetime.getDate()
              } else if (this.active_date === '365d') {
                return months[datetime.getMonth()]
              } else {
                return datetime.getHours()
              }
            },
          }
        }]

        this.options.tooltips = {
          enabled: true,
          mode: 'label',
          callbacks: {
            title: function(tooltipItems, data) {
              var idx = tooltipItems[0].index;
              let datetime = new Date(data.labels[idx]);

              var options = {
                // era: 'short',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
                timezone: 'UTC',
                // hour: 'numeric',
                // minute: 'numeric',
                // second: 'numeric'
              };

              return datetime.toLocaleString("ru", options);
            },
          }
        }

        let group_by = '1h';

        if (this.active_date == null) {
          this.active_date = '24h';
        }

        if (this.active_date === '24h') {
          group_by = '1h';
        }
        if (this.active_date === '31d') {
          group_by = '1d';
        }
        if (this.active_date === '365d') {
          group_by = '31d';
        }

        // v-if="thing.equipment && thing.equipment.name">
        let params = {
          "sensor_tag": this.selected_element,
          "period": this.active_date,
          "group_by": group_by
        }

        if (this.thing.equipment && this.thing.equipment.id) {
          params.equipment_id = this.thing.equipment.id;
        }

        // console.log(params);

        let res_voltage = await logsService.logs_voltage(this.facilityId, this.thing.id, params);

        if (res_voltage.error) {
          console.error(res_voltage.error);

          return;
        }

        let response = res_voltage.result.logs;

        response = response.replace(/'/g, '"');
        response = response.replace(/None/g, 'null');

        var obj_reponse = JSON.parse(response);

        const timeArray= obj_reponse.map(element => element.time);
        const meanArray= obj_reponse.map(element => {
          element = element.mean;

          if (element) {
            element = element.toFixed(2);
          }

          return element
        });

        this.U_max = Math.max.apply(null, meanArray);
        this.U_min = Math.min.apply(null, meanArray);

        this.voltage_data = {
          labels: timeArray,
          datasets: [
            {
              label: 'Напряжение ' + this.getLabel(this.selected_element) + this.getAccumulatorNumber(this.selected_element) + ', В',
              data: meanArray,
              borderColor: 'rgba(142,220,40,0.9)',
              borderWidth: 2,
              pointRadius: 2,
              pointHoverRadius: 3,
              fill: false
            },
          ]
        }

        let res_temperature = await logsService.logs_temperature(this.facilityId, this.thing.id, params);

        if (res_temperature.error) {
          console.error(res_temperature.error);

          return;
        }

        let response_temperature = res_temperature.result.logs;

        response_temperature = response_temperature.replace(/'/g, '"');
        response_temperature = response_temperature.replace(/None/g, 'null');

        var obj_response_temperature = JSON.parse(response_temperature);

        const timeArrayT= obj_response_temperature.map(element => {
          let d = new Date(element.time);
          return d;
        });
        const meanArrayT= obj_response_temperature.map(element => {
          element = element.mean;

          if (element) {
            element = element.toFixed(2);
          }

          return element
        });

        this.T_max = Math.max.apply(null, meanArrayT);
        this.T_min = Math.min.apply(null, meanArrayT);

        this.temperature_data = {
          labels: timeArrayT,
          datasets: [
            {
              label: 'Температура ' + this.getLabel(this.selected_element) + this.getAccumulatorNumber(this.selected_element) + ', °C',
              data: meanArrayT,
              borderColor: 'rgba(142,220,40,0.9)',
              borderWidth: 2,
              pointRadius: 2,
              pointHoverRadius: 3,
              fill: false
            },
          ]
        }
      },
      async fill_bars_data() {
        this.options.scales.xAxes = [{ ticks: { display: true } }]

        this.options.scales.xAxes = [{
          ticks: {
            // min: 0,
            callback: (value) => {
              return value
            },
          }
        }]

        this.options.tooltips = {
          enabled: true,
          mode: 'label',
          callbacks: {
            // title: function(tooltipItems, data) {
            //   var idx = tooltipItems[0].index;
            //   // let label_data = data.labels[idx];
            //
            //   // console.log(label_data)
            // },
          }
        }

        if (this.active_date == null) {
          this.U_max = Math.max.apply(null, this.getCurrentVoltages());
          this.U_min = Math.min.apply(null, this.getCurrentVoltages());

          this.voltage_data = {
            // labels: [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20" ],
            labels: Array.from({length: this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207}, (_, index) => index + 1),
            datasets: [
              {
                label: this.$t('devices.repeypage.voltage'),    // 'Напряжение, В',
                data: this.getCurrentVoltages(),
                // backgroundColor: this.getColors(),
                backgroundColor: this.getVoltageColors(),
                borderColor: 'rgba(255, 255, 255, 0.4)',
                borderWidth: 2,
              },
            ]
          }

          this.T_max = Math.max.apply(null, this.getCurrentTemperatures());
          this.T_min = Math.min.apply(null, this.getCurrentTemperatures());

          this.temperature_data = {
            labels: Array.from({length: this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207}, (_, index) => index + 1),
            datasets: [
              {
                label: this.$t('devices.repeypage.temperature'),    // 'Температура, °C',
                data: this.getCurrentTemperatures(),
                // backgroundColor: this.getColors(),
                backgroundColor: this.getTemperatureColors(),
                borderColor: 'rgba(255, 255, 255, 0.4)',
                borderWidth: 2,
              }
            ]
          }
        } else {
          let params = {
            "period": this.active_date
          }

          if (this.thing.equipment && this.thing.equipment.id) {
            params.equipment_id = this.thing.equipment.id;
          }

          let res_voltage = await logsService.logs_voltage_bar(this.facilityId, this.thing.id, params);

          if (res_voltage.error) {
            console.error(res_voltage.error);

            return;
          }

          let response_voltage = res_voltage.result.logs;

          response_voltage = response_voltage.replace(/'/g, '"');
          response_voltage = response_voltage.replace(/None/g, 'null');

          var obj_response_v = JSON.parse(response_voltage);

          const meanArrayV = obj_response_v.map(element => {
            element = element.mean;

            if (element) {
              element = element.toFixed(2);
            }

            return element
          });

          this.U_max = Math.max.apply(null, meanArrayV);
          this.U_min = Math.min.apply(null, meanArrayV);

          this.voltage_data = {
            //labels: timeArrayT,
            labels: Array.from({length: this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207}, (_, index) => index + 1),
            datasets: [
              {
                label: this.$t('devices.repeypage.voltage'),    // 'Напряжение, В',
                data: meanArrayV,
                // data: [23, 22, 24, 21 ,22, 23, 22, 24, 21 ,22, 25],
                borderColor: 'rgba(255, 255, 255, 0.4)',
                backgroundColor: this.getColors(),
                borderWidth: 2,
              },
            ]
          }

          let res_temperature = await logsService.logs_temperature_bar(this.facilityId, this.thing.id, params);

          if (res_temperature.error) {
            console.error(res_temperature.error);

            return;
          }

          let response_temperature = res_temperature.result.logs;

          response_temperature = response_temperature.replace(/'/g, '"');
          response_temperature = response_temperature.replace(/None/g, 'null');

          var obj_response_t = JSON.parse(response_temperature);

          // const meanArrayT = obj_response_t.map(element => element.mean);
          const meanArrayT = obj_response_t.map(element => {
            element = element.mean;

            if (element) {
              element = element.toFixed(2);
            }

            return element
          });

          this.T_max = Math.max.apply(null, meanArrayT);
          this.T_min = Math.min.apply(null, meanArrayT);

          this.temperature_data = {
            labels: Array.from({length: this.thing.shadow.general_setpoints.Na_batteries_quantity_0x0207}, (_, index) => index + 1),
            datasets: [
              {
                label: this.$t('devices.repeypage.temperature'),    // 'Температура, °C',
                data: meanArrayT,
                borderColor: 'rgba(255, 255, 255, 0.4)',
                backgroundColor: this.getColors(),
                borderWidth: 2,
              },
            ]
          }
        }
      },
      fill_cols_data() {
        this.active_date = null;

        this.options.tooltips = {
          enabled: true,
          mode: 'label',
          callbacks: {
            // title: function(tooltipItems, data) {
            //   var idx = tooltipItems[0].index;
            //   // let label_data = data.labels[idx];
            //
            //   // console.log(label_data)
            // },
          }
        }

        this.options.scales.xAxes = [{
          ticks: {
            display: true,
            fontColor: '#555'
          },
          gridLines: {
            display: true,
            drawBorder: false,
            // color: 'rgba(255, 0, 0, 0.9)'
            color: ['#28DCDC', '#28DCDC', '#28DC9F', '#28DC93', '#BFDC28', '#84CB26', '#84CB26', '#B3DC28', '#B3DC28', '#B9AA23', '#C27D14', '#EB5117', '#EB1717', '#EB1717', '#EB1717']
          }
        }]

        this.voltage_data = {
          // labels: [ "< 8,5 В", "9 В", "9,5 В", "10 В", "10,5 В", "11 В", "11,5 В", "12 В", "12,5 В", "13 В", "13,5 В", "14 В", "14,5 В", "15 В", "> 15 В" ],
          labels: [ "< 8,5 В", "8,75 В", "9,25 В", "9,75 В", "10,25 В", "10,75 В", "11,25 В", "11,75 В", "12,25 В", "12,75 В", "13,25 В", "13,75 В", "14,25 В", "14,75 В", "> 15 В" ],
          datasets: [
            {
              label: 'Время нахождения ' + this.getLabel(this.selected_element) + this.getAccumulatorNumber(this.selected_element) + ' в диапазонах напряжений, минут ',
              data: eval('this.thing.shadow.battery_statistics.accum_' + this.getAccumulatorNumber(this.selected_element) + '_voltage_statistics'),
              backgroundColor: ['#28DCDC', '#28DCDC', '#28DC9F', '#28DC93', '#BFDC28', '#84CB26', '#84CB26', '#B3DC28', '#B3DC28', '#B9AA23', '#C27D14', '#EB5117', '#EB1717', '#EB1717', '#EB1717'],
              borderColor: 'rgba(255, 255, 255, 0.4)',
              borderWidth: 2,
            }
          ]
        }

        this.temperature_data = {
          // labels: [ "< -20°C", "-10°C", "-5°C", "0°C", "5°C", "10°C", "15°C", "20°C", "25°C", "30°C", "35°C", "40°C", "45°C", "50°C", "> 50°C" ],
          labels: [ "< -20°C", "-15°C", "-7,5°C", "-2,5°C", "2,5°C", "7,5°C", "12,5°C", "17,5°C", "22,5°C", "27,5°C", "32,5°C", "37,5°C", "42,5°C", "47,5°C", "> 50°C" ],
          datasets: [
            {
              label: 'Время нахождения ' + this.getLabel(this.selected_element) + this.getAccumulatorNumber(this.selected_element) + ' в диапазонах температур, минут ',
              data: eval('this.thing.shadow.battery_statistics.accum_' + this.getAccumulatorNumber(this.selected_element) + '_temperature_statistics'),
              backgroundColor: ['#28DCDC', '#28DCDC', '#28DC9F', '#28DC93', '#BFDC28', '#84CB26', '#84CB26', '#B3DC28', '#B3DC28', '#B9AA23', '#C27D14', '#EB5117', '#EB1717', '#EB1717', '#EB1717'],
              borderColor: 'rgba(255, 255, 255, 0.4)',
              borderWidth: 2,
            }
          ]
        }
      },
      getCurrentVoltages() {
        let voltages = []

        let battery_parameters = this.thing.shadow.battery_parameters;

        for (let param_number in battery_parameters) {
          if (this.thing.shadow.online && this.thing.shadow.battery_parameters[param_number].u) {
            let voltage = this.thing.shadow.battery_parameters[param_number].u;
            voltages.push(voltage.toFixed(2));
          } else {
            voltages.push(0);
          }
        }

        return voltages
      },
      getCurrentTemperatures() {
        let temperatures = []

        let battery_parameters = this.thing.shadow.battery_parameters;
        for (let param_number in battery_parameters) {
          if (this.thing.shadow.online && this.thing.shadow.battery_parameters[param_number].t) {
            let temperature = this.thing.shadow.battery_parameters[param_number].t;
            temperatures.push(temperature.toFixed(2));
          } else {
            temperatures.push(0);
          }
        }

        return temperatures
      },
      getVoltageColors() {
        let colors = [];

        for (let accumulator in this.accumulators) {
          // let grayColor = '#333333';
          let yellowColor = '#88881c';
          let redColor = '#771c1c';
          let whiteColor = '#929292';

          if (accumulator === this.selected_element){
            // grayColor = '#777777';
            redColor = '#ff1c1c';
            yellowColor = '#ffff00';
            whiteColor = '#eeeeee';
          }

          if (this.accumulators[accumulator].status.voltage_status === 'warning') {
            colors.push(yellowColor)
          } else if (this.accumulators[accumulator].status.voltage_status === 'critical') {
            colors.push(redColor)
          // } else if (this.accumulators[accumulator].status.status === 'broken') {
          //   colors.push(redColor)
          // } else if (this.accumulators[accumulator].status.status === 'offline') {
          //   colors.push(grayColor)
          } else {
            colors.push(whiteColor)
          }
        }

        return colors;
      },
      getTemperatureColors() {
        let colors = [];

        for (let accumulator in this.accumulators) {
          // let grayColor = '#333333';
          let yellowColor = '#88881c';
          let redColor = '#771c1c';
          let whiteColor = '#929292';

          if (accumulator === this.selected_element){
            // grayColor = '#777777';
            redColor = '#ff1c1c';
            yellowColor = '#ffff00';
            whiteColor = '#eeeeee';
          }

          if (this.accumulators[accumulator].status.temperature_status === 'warning') {
            colors.push(yellowColor)
          } else if (this.accumulators[accumulator].status.temperature_status === 'critical') {
            colors.push(redColor)
            // } else if (this.accumulators[accumulator].status.status === 'broken') {
            //   colors.push(redColor)
            // } else if (this.accumulators[accumulator].status.status === 'offline') {
            //   colors.push(grayColor)
          } else {
            colors.push(whiteColor)
          }
        }

        return colors;
      },
      getColors() {
        let colors = []

        for (let accumulator in this.accumulators) {
          let grayColor = '#333333'
          let yellowColor = '#88881c'
          let redColor = '#771c1c'
          let whiteColor = '#929292'

          if (accumulator === this.selected_element){
            grayColor = '#777777'
            redColor = '#ff1c1c'
            yellowColor = '#ffff00'
            whiteColor = '#eeeeee'
          }

          if (this.accumulators[accumulator].status.status === 'warning') {
            colors.push(yellowColor)
          } else if (this.accumulators[accumulator].status.status === 'critical') {
            colors.push(redColor)
          } else if (this.accumulators[accumulator].status.status === 'broken') {
            colors.push(redColor)
          } else if (this.accumulators[accumulator].status.status === 'offline') {
            colors.push(grayColor)
          } else {
            colors.push(whiteColor)
          }

        }

        return colors;
      },
      getLabel(key) {
        if (key === 'battery') {
          return 'батареи'
        } else {
          return 'аккумулятора № '
        }
      },
      toggleAkbInfo() {
        this.akb_info_toggle = !this.akb_info_toggle;
      },
      toggleBatInfo() {
        this.bat_info_toggle = !this.bat_info_toggle;
      },
      showAccumSignallingInfo(key) {

        Vue.set(this.accumulators[key], 'toggleSignallingInfo', !this.accumulators[key].toggleSignallingInfo);

      },
      formatDate(value) {
				if (value) {
					// return moment(value/1000).format("llLTS")
          return moment.utc(value).format("llLTS")
				}

				return value;
			},
      getEventDescription(events) {
        let answer = [];
        // let answer = 'Unknown';
        // let type = '';

        // console.log(events.EmergencySignals);    // авария
        // console.log(events.PreventiveSignals);    // предуп.
        // console.log(events.FaultSignals);    // неисправность

        // table 4 avar
        if (events.EmergencySignals[0] == 1) {
          answer.push('АБ: глубокий разряд');
          // type = 'Авария';
        } else if (events.EmergencySignals[1] == 1) {
          answer.push('АБ: высокое напряжение');
          // type = 'Авария';
        } else if (events.EmergencySignals[2] == 1) {
          answer.push('АБ: высокие пульсации U');
          // type = 'Авария';
        } else if (events.EmergencySignals[3] == 1) {
          answer.push('АБ: отсутствие термокомпенсации');
          // type = 'Авария';
        } else if (events.EmergencySignals[4] == 1) {
          answer.push('АБ: высокий ток заряда');
        } else if (events.EmergencySignals[5] == 1) {
          answer.push('АБ: низкий ток заряда');
        } else if (events.EmergencySignals[6] == 1) {
          answer.push('АБ: высокие пульсации I');
        } else if (events.EmergencySignals[7] == 1) {
          answer.push('Шкаф: низкая температура');
        } else if (events.EmergencySignals[8] == 1) {
          answer.push('Шкаф: высокая температура 1 ст.');
        } else if (events.EmergencySignals[9] == 1) {
          answer.push('Шкаф: высокая температура 2 ст.');
        } else if (events.EmergencySignals[10] == 1) {
          answer.push('Помещение: низкая температура');
        } else if (events.EmergencySignals[11] == 1) {
          answer.push('Помещение: высокая температура');
        } 

        for (let i = 12; i <= 31; i++) {
          if (events.EmergencySignals[i] == 1) {
            answer.push('Акм №' + (i-11) + ': ускоренный износ');
          }
        }

        for (let i = 32; i <= 51; i++) {
          if (events.EmergencySignals[i] == 1) {
            answer.push('Акм №' + (i-31) + ': деградация');
          }
        }

        for (let i = 52; i <= 71; i++) {
          if (events.EmergencySignals[i] == 1) {
            answer.push('Акм №' + (i-51) + ': короткое замыкание');
          }
        }

        for (let i = 72; i <= 91; i++) {
          if (events.EmergencySignals[i] == 1) {
            answer.push('Акм №' + (i-71) + ': тепловой разгон');
          }
        }

        if (events.PreventiveSignals[0] == 1) {
          answer.push('АБ: глубокий разряд');
          // type = 'Предупреждение';
        } else if (events.PreventiveSignals[1] == 1) {
          answer.push('АБ: высокое напряжение');
          // type = 'Предупреждение';
        } else if (events.PreventiveSignals[2] == 1) {
          answer.push('АБ: высокие пульсации U');
          // type = 'Предупреждение';
        } else if (events.PreventiveSignals[3] == 1) {
          answer.push('АБ: отсутствие термокомпенсации');
          // type = 'Предупреждение';
        } else if (events.PreventiveSignals[4] == 1) {
          answer.push('АБ: высокий ток заряда');
        } else if (events.PreventiveSignals[5] == 1) {
          answer.push('АБ: низкий ток заряда');
        } else if (events.PreventiveSignals[6] == 1) {
          answer.push('АБ: высокие пульсации I');
        } else if (events.PreventiveSignals[7] == 1) {
          answer.push('Шкаф: низкая температура');
        } else if (events.PreventiveSignals[8] == 1) {
          answer.push('Шкаф: высокая температура 1 ст.');
        } else if (events.PreventiveSignals[9] == 1) {
          answer.push('Шкаф: высокая температура 2 ст.');
        } else if (events.PreventiveSignals[10] == 1) {
          answer.push('Помещение: низкая температура');
        } else if (events.PreventiveSignals[11] == 1) {
          answer.push('Помещение: высокая температура');
        } 

        for (let i = 12; i <= 31; i++) {
          if (events.PreventiveSignals[i] == 1) {
            answer.push('Акм №' + (i-11) + ': ускоренный износ');
          }
        }

        for (let i = 32; i <= 51; i++) {
          if (events.PreventiveSignals[i] == 1) {
            answer.push('Акм №' + (i-31) + ': деградация');
          }
        }

        for (let i = 52; i <= 71; i++) {
          if (events.PreventiveSignals[i] == 1) {
            answer.push('Акм №' + (i-51) + ': короткое замыкание');
          }
        }

        for (let i = 72; i <= 91; i++) {
          if (events.PreventiveSignals[i] == 1) {
            answer.push('Акм №' + (i-71) + ': тепловой разгон');
          }
        }

        // table 5
        for (let i = 0; i <= 19; i++) {
          if (events.FaultSignals[i] == 1) {
            answer.push('Акм №' + (i+1) + ': потеря связи');
          }
        }

        for (let i = 20; i <= 39; i++) {
          if (events.FaultSignals[i] == 1) {
            answer.push('Акм №' + (i-19) + ': неисправность датчика');
          }
        }

        if (events.FaultSignals[40] == 1) {
          answer.push('РЕПЕЙ: неисправность');
        }

        // answer = ['Test 1', 'Тест №2']

        // console.log(type);
        return answer
      },
      uintToString(hex) {
        try {
          let _hex = hex.replace( /../g , hex2=>('%'+hex2));

          var string = decodeURIComponent(_hex);

          return string;
        } catch(e) {
          // console.log(e);

          return hex;
        }
      },
      // utf8Decode(utf8String) {
      //   if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
      //   // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
      //   const unicodeString = utf8String.replace(
      //       /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
      //       function(c) {  // (note parentheses for precedence)
      //           var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
      //           return String.fromCharCode(cc); }
      //   ).replace(
      //       /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
      //       function(c) {  // (note parentheses for precedence)
      //           var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
      //           return String.fromCharCode(cc); }
      //   );
      //   return unicodeString;
      // },
      getMessageDescription(message) {
        let utf8 = this.uintToString(message);

        return utf8
      },
      getTitles(data) {

        let titles = {
          'events': 'События',
          'timestamp': 'Время',
          'MsgID': 'События',
          // 'EmergencySignals': 'EmergencySignals',
          // 'PreventiveSignals': 'PreventiveSignals',
          // 'FaultSignals': 'FaultSignals',
          // 'Parameters': 'Parameters',
        }

        let answ = ''

        for (let key in data) {
          if (titles[key]) {
            // answ += titles[key] + ';'
            // reversing
            answ = titles[key] + ';' + answ
          } else {
            // answ += key + ';'
            answ = 'События' + ';'
          }
        }

        return answ
      },
      async download_log(type) {
        let start_time = 0;
        let end_time = 0;

        if (type == 'event_log') {
          start_time = this.event_range.start.getTime();
          end_time = this.event_range.end.getTime();
        } else if (type == 'message_log') {
          start_time = this.message_range.start.getTime();
          end_time = this.message_range.end.getTime();
        }

        let args = {
          types: type,
          timestampGte: start_time,
          timestampLte: end_time,
          limit: 10000
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        let temp_log = res.result.items;

        if (temp_log.length > 0){
          if (type == 'event_log') {
            await this.csvExportEvent(temp_log, res.result.filters);
          } else if ( type == 'message_log') {
            await this.csvExportMessage(temp_log, res.result.filters);
          }
        } else {
          alert('Журнал за указанный период пуст!');
        }
      },
			async csvExportEvent(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getEventDescription(item.data) + ";"

            return data_value
          }) 
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "РЕПЕЙ_" + this.thing.number + "_Журнал_событий_" + start_end_date + ".csv");
        link.click();
			},
			async csvExportMessage(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getMessageDescription(item.data.Text) + ";"

            return data_value
          }) 
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "РЕПЕЙ_" + this.thing.number + "_Системный_журнал_" + start_end_date + ".csv");

        link.click();
			},
      dec2bin(dec) {
        // var base2 = (dec).toString(2);
        return ("00000000" + (dec >>> 0).toString(2)).slice(-16)
      },
      getDiagnosticStatuses() { 
        this.diagnostic_statuses = [];

        let answer = this.dec2bin(this.thing.shadow.device_diagnostic_status.self_test_register_0x0906);

        if (answer[0] == 1 ) {
          this.diagnostic_statuses.push('неисправность АЦП1');
        }
        if ( answer[1] == 1 ) {
          this.diagnostic_statuses.push('неисправность АЦП2');
        }
        if ( answer[2] == 1 ) {
          this.diagnostic_statuses.push('неисправность АЦП3');
        }
        if ( answer[3] == 1 ) {
          this.diagnostic_statuses.push('неисправность канала Iаб');
        }
        if ( answer[4] == 1 ) {
          this.diagnostic_statuses.push('неисправность канала Uаб');
        }
        if ( answer[5] == 1 ) {
          this.diagnostic_statuses.push('неисправность Bluetooth');
        }
        if ( answer[6] == 1 ) {
          this.diagnostic_statuses.push('неисправность NAND-флеш');
        }
        if ( answer[7] == 1 ) {
          this.diagnostic_statuses.push('неисправность внутренней флеш-памяти');
        }
        if ( answer[8] == 1 ) {
          this.diagnostic_statuses.push('неисправность часов');
        }
        if ( answer[9] == 1 ) {
          this.diagnostic_statuses.push('некорректная дата или время');
        }
        if ( answer[10] == 1 ) {
          this.diagnostic_statuses.push('неисправность калибровки');
        }
        if ( answer[11] == 1 ) {
          this.diagnostic_statuses.push('неисправность конфигурации');
        }
        if ( answer[12] == 1 ) {
          this.diagnostic_statuses.push('неисправность кварцевого резонатора');
        }

        let answer1_v = this.dec2bin(this.thing.shadow.device_diagnostic_status.first_battery_voltage_diagnostic_0x0907);

        if (answer1_v[0] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 1-го аккумулятора');
        }
        if ( answer1_v[1] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 2-го аккумулятора');
        }
        if ( answer1_v[2] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 3-го аккумулятора');
        }
        if ( answer1_v[3] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 4-го аккумулятора');
        }
        if ( answer1_v[4] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 5-го аккумулятора');
        }
        if ( answer1_v[5] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 6-го аккумулятора');
        }
        if ( answer1_v[6] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 7-го аккумулятора');
        }
        if ( answer1_v[7] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 8-го аккумулятора');
        }
        if ( answer1_v[8] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 9-го аккумулятора');
        }
        if ( answer1_v[9] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 10-го аккумулятора');
        }
        if ( answer1_v[10] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 11-го аккумулятора');
        }
        if ( answer1_v[11] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 12-го аккумулятора');
        }
        if ( answer1_v[12] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 13-го аккумулятора');
        }
        if ( answer1_v[13] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 14-го аккумулятора');
        }
        if ( answer1_v[14] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 15-го аккумулятора');
        }
        if ( answer1_v[15] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 16-го аккумулятора');
        }

        let answer2_v = this.dec2bin(this.thing.shadow.device_diagnostic_status.first_battery_voltage_diagnostic_0x0908);

        if (answer2_v[0] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 17-го аккумулятора');
        }
        if ( answer2_v[1] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 18-го аккумулятора');
        }
        if ( answer2_v[2] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 19-го аккумулятора');
        }
        if ( answer2_v[3] == 1 ) {
          this.diagnostic_statuses.push('неисправность U 20-го аккумулятора');
        }

        let answer1_b = this.dec2bin(this.thing.shadow.device_diagnostic_status.first_battery_diagnostic_0x0909);

        if (answer1_b[0] == 1 ) {
          this.diagnostic_statuses.push('неисправность 1-го аккумулятора');
        }
        if ( answer1_b[1] == 1 ) {
          this.diagnostic_statuses.push('неисправность 2-го аккумулятора');
        }
        if ( answer1_b[2] == 1 ) {
          this.diagnostic_statuses.push('неисправность 3-го аккумулятора');
        }
        if ( answer1_b[3] == 1 ) {
          this.diagnostic_statuses.push('неисправность 4-го аккумулятора');
        }
        if ( answer1_b[4] == 1 ) {
          this.diagnostic_statuses.push('неисправность 5-го аккумулятора');
        }
        if ( answer1_b[5] == 1 ) {
          this.diagnostic_statuses.push('неисправность 6-го аккумулятора');
        }
        if ( answer1_b[6] == 1 ) {
          this.diagnostic_statuses.push('неисправность 7-го аккумулятора');
        }
        if ( answer1_b[7] == 1 ) {
          this.diagnostic_statuses.push('неисправность 8-го аккумулятора');
        }
        if ( answer1_b[8] == 1 ) {
          this.diagnostic_statuses.push('неисправность 9-го аккумулятора');
        }
        if ( answer1_b[9] == 1 ) {
          this.diagnostic_statuses.push('неисправность 10-го аккумулятора');
        }
        if ( answer1_b[10] == 1 ) {
          this.diagnostic_statuses.push('неисправность 11-го аккумулятора');
        }
        if ( answer1_b[11] == 1 ) {
          this.diagnostic_statuses.push('неисправность 12-го аккумулятора');
        }
        if ( answer1_b[12] == 1 ) {
          this.diagnostic_statuses.push('неисправность 13-го аккумулятора');
        }
        if ( answer1_b[13] == 1 ) {
          this.diagnostic_statuses.push('неисправность 14-го аккумулятора');
        }
        if ( answer1_b[14] == 1 ) {
          this.diagnostic_statuses.push('неисправность 15-го аккумулятора');
        }
        if ( answer1_b[15] == 1 ) {
          this.diagnostic_statuses.push('неисправность 16-го аккумулятора');
        }

        let answer2_b = this.dec2bin(this.thing.shadow.device_diagnostic_status.second_battery_diagnostic_0x090A);

        if (answer2_b[0] == 1 ) {
          this.diagnostic_statuses.push('неисправность 17-го аккумулятора');
        }
        if ( answer2_b[1] == 1 ) {
          this.diagnostic_statuses.push('неисправность 18-го аккумулятора');
        }
        if ( answer2_b[2] == 1 ) {
          this.diagnostic_statuses.push('неисправность 19-го аккумулятора');
        }
        if ( answer2_b[3] == 1 ) {
          this.diagnostic_statuses.push('неисправность 20-го аккумулятора');
        }

        // let answer3 = this.dec2bin(this.thing.shadow.device_diagnostic_status.sensors_diagnostic_0x090B);
      }
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../../assets/SnowstormLight.woff2');
  }
  .card {
    margin-top: 20px;
  }
  /* .card-second {
  } */
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  .subject-div1 {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 90px;
    /* width: 180px; */
    margin: 12px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 10px;*/
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    position: relative;
    z-index: 99 !important;
  }
  .subject-div1:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .header-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 165px;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .header-upper {
    border-bottom: 1px solid black;
    padding: 10px
  }
  .back-img {
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 5px;
  }
  .back-img img{
    max-width:100%;
    height:auto;
  }
  .back-img-battery img{
    width:30px;
    height:auto;
  }
  .header-body {
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 20px;*/
    padding: 0px;
  }
  .subject-logo {
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subject-info {
    flex-grow: 7;
    font-size: 16px;
    padding: 0px;
    padding-top: 15px;
  }
  .my-bar {
    min-width: 650px;
    min-height: 340px;
    max-width: 950px;
    max-height: 820px;
    background-color: #202020;
    border-radius: 10px;
  }
  .bar-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-right: 40px;
    height: 340px;
  }
  .circle-35 {
    background: #8EDC28;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .circle-35.sort-linear {
    background: url(../../assets/sort_linear.svg) no-repeat center center #000;
  }
  .circle-35.sort-linear.active {
    background: url(../../assets/sort_linear_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-bars {
    background: url(../../assets/sort_bars.svg) no-repeat center center #000;
  }
  .circle-35.sort-bars.active {
    background: url(../../assets/sort_bars_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-cols {
    background: url(../../assets/sort_cols.svg) no-repeat center center #000;
  }
  .circle-35.sort-cols.active {
    background: url(../../assets/sort_cols_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.sort-linear {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-bars {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-cols {
    opacity: 0.5;
    cursor: pointer;
  }
  .background-text {
    z-index: 1;
  }
  .col-2 {
    display: flex;
    flex-direction: row;
  }
  .small-info {
    position: relative;
    font-size: 14px;
    font-family: "Segoe UI Light";
    letter-spacing: 0px;
    /*color: #FFFFFF;*/
    opacity: 1;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    display: block;
  }
  @media screen and (max-width: 1366px) {
    .small-info {
      width: 100px;
    }
    .col-md-3 {
      padding: 0px;
    }
  }
  .subject-info-values {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date-picker {
    position: absolute;
    top: 38%;
    /*position: absolute;*/
    float: right;
    right: 0px;
    /*top: 50%;*/
    width: 50px;
    height: 166px;
    border-radius: 50px;
    background-color: #000000;
    color: #FFFFFF;
  }
  .circle-50 {
    background: #000000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
  }
  .circle-50:hover {
    background-color: rgba(142,220,40,0.1);
  }
  .circle-50.active {
    background: #8EDC28;
  }
  .accumulator-tag {
    /*border: 1px solid black;*/
    float: right;
    position: absolute;
    right: 15px;
    height: 15px;
    font-size: 14px;
    font-family: "Segoe UI Light";
    color: #FFFFFF;
    /*letter-spacing: 0.7px;*/
    margin-top: 5px;
  }
  .accumulator-signalling {
    float: left;
    position: absolute;
    right: 10px;
    height: 15px;
    font-size: 20px;
    font-family: "Segoe UI";
    color: #FFFFFF;
    letter-spacing: 0.7px;
    margin-top: 30px;
    opacity: 0.8;
  }
  .accumulator-signalling:hover {
    font-size: 23px;
    margin-top: 28px;
    right: 9px;
    opacity: 1;
  }
  .accumulator-info {
    position: absolute;
    left: 0px;
    right: 0px;
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 105px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: -5;
    width: auto;
    padding-bottom: 30px;
  }
  #bottom {
    position:relative;
    top: 90px;
  }
  .accumulator-active {
    position: absolute;
    left: 0px;
    right: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 90px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: -4;
    width: auto;
    border: 1px solid gray;
    padding-bottom: 30px;
  }
  .chart-minmax {
    position: absolute;
    right: 80px;
    /*top: 35px;*/
    font-family: "Segoe UI";
    font-size: 14px;
    color: #7e7e7e;
  }
  .fill-battery {
    background-color: #202020;
    width: 30px;
    height:30px;
    position: absolute;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 5;
  }
  .fill-battery-offline {
    background-color: #202020;
    width: 25px;
    height:35px;
    position: absolute;
    margin-top: 14px;
    margin-left: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Snowstorm Light";
    font-size: 22px;
    color: #fff;
  }
  /*.name-span {*/
  /*  overflow: hidden;*/
  /*  text-overflow: ellipsis;*/
  /*  white-space: nowrap;*/
  /*}*/
  .nowrap-span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .akb-info-div {
    position: absolute;
    width: auto;
    max-width: 300px;
    background-color: #000000;
    opacity: 0.9;
    z-index: 999999;
    color: white;
    font-family: "Segoe UI";
    font-size: 14px;
    border-radius: 10px;
    padding: 10px;
    overflow-y: scroll;
    max-height:calc(100vh - 280px);
    box-shadow: 0px 3px 6px #000000;
  }
  .battery-info-div-repey {
    position: absolute !important;
    /*top: -85px;*/
    bottom: 92px !important;
    /*left: -45px;*/
    right: -50px !important;
    width: auto;
    min-width: 270px;
    max-width: 300px;
    background-color: #000000;
    /*opacity: 0.9;*/
    z-index: 300 !important;
    color: white;
    font-family: "Segoe UI";
    font-size: 12px;
    border-radius: 10px;
    padding: 10px;
    /*overflow-y: scroll;*/
    /*max-height:calc(100vh - 280px);*/
    box-shadow: 0px 3px 6px #000000;
  }
  .interact-img:hover img {
    opacity: 0.8;
    transform: scale(1.05);
  }
  /*[data-tooltip]:before {*/
  /*  !* needed - do not touch *!*/
  /*  content: attr(data-tooltip);*/
  /*  position: absolute;*/
  /*  opacity: 0;*/

  /*  !* customizable *!*/
  /*  transition: all 0.15s ease;*/
  /*  padding: 10px;*/
  /*  color: #000000;*/
  /*  border-radius: 10px;*/
  /*  !*box-shadow: 2px 2px 1px silver;*!*/
  /*  height: 100px;*/
  /*  width: 200px;*/

  /*  top: -45px;*/
  /*}*/

  /*[data-tooltip]:hover:before {*/
  /*  !* needed - do not touch *!*/
  /*  opacity: 0.8;*/

  /*  !* customizable *!*/
  /*  background: grey;*/
  /*  margin-top: -50px;*/
  /*  margin-left: 20px;*/
  /*}*/

  /*[data-tooltip]:not([data-tooltip-persistent]):before {*/
  /*  pointer-events: none;*/
  /*}*/
  .mobile-header {
    display: none;
  }
  @media (max-width: 640px) {
    .battery-info-div-repey { right: 0px !important;}
    .date-picker {
      top: 18%;
      /*position: absolute;*/
      float: right;
      right: 0px;
      /*top: 50%;*/
      width: 50px;
      height: 166px;
      border-radius: 50px;
      background-color: #000000;
      color: #FFFFFF;
    }
    .desktop-header {
      display: none;
    }
    .mobile-header {
      display: block;
    }
    .header-div {
      height: 220px !important;
      margin: 10px;
      font-family: "Segoe UI Light";
      font-size: 30px;
      color: #FFFFFF;
    }
    .small-info {
      font-size: 12px !important;
    }
  }

  .chart-div {
    background: #202020 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 40px;
    /*height: 340px;*/
    padding-bottom: 4px;
  }
</style>
