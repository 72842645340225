<script>
  import { Line , mixins } from 'vue-chartjs';
  const { reactiveProp } = mixins;

  export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
      this.renderChart(this.chartData, this.options)
      // this.renderChart(
      //     {
      //         labels: [
      //             "1",
      //             "2",
      //             "3",
      //             "4",
      //             "5",
      //             "6",
      //             "7",
      //             "8",
      //             "9",
      //             "10",
      //             "11",
      //             "12",
      //             "13",
      //             "14",
      //             "15",
      //             "16",
      //             "17",
      //             "18",
      //             "19",
      //             "20"
      //         ],
      //         datasets: [
      //             {
      //                 label: "Напряжение, В",
      //                 data: [12.2, 12.2, 12.4, 12.8, 12.3, 13.1, 13.0, 12.4, 8.99, 12.5, 12.1, 4.01, 12.1, 12.8, 12.0, 12.4, 12.5, 0.0, 0.0],
      //                 backgroundColor: ['#929292', '#929292', '#929292', '#929292', '#929292', '#929292', '#929292', '#929292', '#88881c', '#929292', '#929292', '#771c1c',  '#929292', '#929292', '#929292', '#929292', '#929292'],
      //                 // borderColor: '#ffffff',
      //                 borderColor: 'rgba(142, 220, 40, 0.4)',
      //                 borderWidth: 2,
      //             }
      //         ]
      //     },
      //     { responsive: true, maintainAspectRatio: false }
      // );
    }
  };
</script>
