import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../components/HomePage'
import LoginPage from '../components/LoginPage'
import EditPage  from '../components/EditPage';
import DevicesPage from '../components/DevicesPage';
// import EmployeesPage from '../components/EmployeesPage';
import InvitesPage from '../components/InvitesPage';
import ProfilePage from '../components/ProfilePage';
import ThingsPage from '../components/ThingsPage';
import ThingPage from '../components/ThingPage';
import Registration from '../components/RegistrationPage';
import EquipmentEdit from '../components/EquipmentEdit';
import ThingEdit from '../components/ThingEdit';
import EmployeeEdit from "../components/EmployeeEdit";
// import RepeyBase from '../components/RepeyBase';
// import AgaveHub from '../components/devices/AgaveHub';
import MtAdminAuth from "../components/MtAdminAuth";
import SettingsPage from "../components/SettingsPage";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'main', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/facility/edit/:id', name: 'edit_facility', component: EditPage },
    { path: '/facility/add', name: 'add_facility', component: EditPage },
    { path: '/devices', name: 'devices', component: DevicesPage },
    // { path: '/employees', component: EmployeesPage },
    { path: '/invites', name: 'invites', component: InvitesPage },
    { path: '/profile', name: 'profile', component: ProfilePage },
    { path: '/settings', name: 'settings', component: SettingsPage },
    { path: '/facility/:id/things', name: 'things', component: ThingsPage },
    { path: '/facility/:facility_id/things/:thing_id', name: "thing", component: ThingPage },
    { path: '/invite', component: Registration },
    { path: '/facility/:facility_id/equipments/:equipment_id/edit', name: 'edit_equipment', component: EquipmentEdit },
    { path: '/facility/:facility_id/things/:thing_id/edit', name: 'edit_thing', component: ThingEdit },
    { path: '/facility/:facility_id/employees/:employee_id/edit', name: 'edit_employee', component: EmployeeEdit },

    { path: '/auth', component: MtAdminAuth },

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/invite', '/auth'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next({
      path: '/login',
      query: { returnUrl: to.path }
    });
  }

  next();
});
