import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const filesService = {
  thing_files_get,
  file_get
};

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]) + '&');
    }
    return keyValuePairs;
  }

async function thing_files_get(thingId, args) {
  let queryString = '';

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  if (args.types) {
    queryString += objToQueryString({
      types: args.types
    });
  }

  if (args.start) {
    queryString += objToQueryString({
      start: args.start
    });
  }

  if (args.limit) {
    queryString += objToQueryString({
      limit: args.limit
    });
  }

  return fetch(API_LOCATION + `/things/${thingId}/files?${queryString}` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function file_get(fileId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/files/${fileId}?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}
