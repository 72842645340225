import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const userService = {
  token_login,
  login,
  logout,
  getProfile,
  updateProfile,
  registration,
  updateProfilePhoto,
  getProfilePhoto
};

async function token_login(accessKeyId, renewalToken, secretAccessKey) {
  let user = {};

  user.authdata = window.btoa(accessKeyId + ':' + secretAccessKey);
  user.renewalToken = renewalToken;
  localStorage.setItem('user', JSON.stringify(user))

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/profile`, requestOptions)
    .then(responseHandler.handleResponse)
    .then(userData => {
      user.profile = {};
      user.profile.id = userData.result.id;
      user.profile.lastname = userData.result.lastname;
      user.profile.name = userData.result.name;
      user.profile.middlename = userData.result.middlename;
      user.profile.email = userData.result.email;
      user.profile.photo = userData.result.photo;
      localStorage.setItem('user', JSON.stringify(user));

      return user;
  });
}

async function login(email, password, locale) {
  const requestOptions = {
    method: 'POST',
    // mode: 'cors', // no-cors, *cors, same-origin
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "email": email,
      "password": password
    })
  };

  let user = {};

  return fetch(API_LOCATION + `/account/authorization/psw?` + new URLSearchParams({lang: locale}), requestOptions)
    // .then(handleResponse)
    .then(responseHandler.handleResponse)
    .then(authData => {
      if (authData) {
        let accessKeyId = authData.result.accessKeyId;
        let renewalToken = authData.result.renewalToken;
        let secretAccessKey = authData.result.secretAccessKey;

        user.authdata = window.btoa(accessKeyId + ':' + secretAccessKey);
        user.renewalToken = renewalToken;
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    })
    .then(user => {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      return fetch(API_LOCATION + `/profile`, requestOptions)
        // .then(handleResponse)
        .then(responseHandler.handleResponse)
        .then(userData => {
          user.profile = {};
          user.profile.id = userData.result.id;
          user.profile.lastname = userData.result.lastname;
          user.profile.name = userData.result.name;
          user.profile.middlename = userData.result.middlename;
          user.profile.email = userData.result.email;
          // user.profile.photo = userData.result.photo;
          localStorage.setItem('user', JSON.stringify(user));

          return user;
        });
    })
    .then(profileImage => {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      // console.log('*********************---------')

      return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions)
       .then(responseHandler.handleFileResponse)
       .then(resPhoto => {
          // console.log('*********************---------')
          // console.log(resPhoto)
          // // *************************************** store path to blob object in localstorage

          // let blob = URL.createObjectURL(resPhoto)

          // localStorage.setItem('profileImage', JSON.stringify(blob));

          // *************************************** Converting blob to base64 and store in localstorage

          if (resPhoto) {
            // console.log('*********************--------- resPhoto')
            var reader = new FileReader();
            reader.readAsDataURL(resPhoto);

            reader.onloadend = function () {
              let base64String = reader.result;
              // console.log('Base64 String - ', base64String);

              // console.log('*********************************************************************123')

              localStorage.setItem('profileImage', JSON.stringify(base64String));
            }
          } else {
            localStorage.setItem('profileImage', null);
          }

          return profileImage;
       })
    })

    // .then(profilePhoto => {
    //   const requestOptions = {
    //     method: 'GET',
    //     headers: authHeader()
    //   };

    //   return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions)
    //     .then(responseHandler.handleFileResponse)
    //     .then(blobPhoto => {
    //       console.log(profilePhoto)

    //       localStorage.setItem('profilePhoto', blobPhoto);

    //       return blobPhoto;
    //     })

    // });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.clear();
}

function getProfile() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/profile?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}

async function updateProfile(profile) {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "lastname": profile.lastname,
      "name": profile.name,
      "middlename": profile.middlename,
      "settings": profile.settings,
      // "organization": profile.organization
    })
  };

  let user = JSON.parse(localStorage.getItem('user'));

  return fetch(API_LOCATION + `/profile?` + new URLSearchParams({lang: 'ru',}), requestOptions)
    .then(responseHandler.handleResponse)
    .then(userData => {
      user.profile.lastname = userData.result.lastname;
      user.profile.name = userData.result.name;
      user.profile.middlename = userData.result.midllename;
      user.profile.settings = userData.result.settings;
      localStorage.setItem('user', JSON.stringify(user));

      return user;
    });
}

async function updateProfilePhoto(photo, filename) {
  let formData  = new FormData();

  formData.append('file', photo, filename);

  const requestOptions = {
    method: 'PUT',
    // headers: Object.assign(authHeader(), { 'Content-Type': 'multipart/form-data' }),
    headers: Object.assign(authHeader()),
    body: formData
  };

  // let user = JSON.parse(localStorage.getItem('user'));

  return fetch(API_LOCATION + `/profile?` + new URLSearchParams({lang: 'ru',}), requestOptions)
    .then(responseHandler.handleResponse)
    // .then(userData => {
    //   // user.profile.photo = userData.result.photo;
    //   console.log(userData);
    //   localStorage.setItem('user', JSON.stringify(user));

    //   return user;
    // });
    .then(answerData => {
      // console.log(answerData);
      return answerData;
    })
}

async function getProfilePhoto() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  // return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
  return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleFileResponse);
  // return fetch(API_LOCATION + `/profile/photo?` + new URLSearchParams({lang: 'ru',}), requestOptions);
}

async function registration(lastname, name, middlename, email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      "lastname": lastname,
      "name": name,
      "middlename": middlename,
      "email": email,
      "password": password
    })
  };

  let user = {};

  return fetch(API_LOCATION + `/account/registration?` + new URLSearchParams({lang: 'ru'}), requestOptions)
    .then(responseHandler.handleResponse)
    .then(authData => {
      if (authData) {
        let accessKeyId = authData.result.accessKeyId;
        let renewalToken = authData.result.renewalToken;
        let secretAccessKey = authData.result.secretAccessKey;

        user.authdata = window.btoa(accessKeyId + ':' + secretAccessKey);
        user.renewalToken = renewalToken;
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    })
    .then(user => {
      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      };

      return fetch(API_LOCATION + `/profile`, requestOptions)
        // .then(handleResponse)
        .then(responseHandler.handleResponse)
        .then(userData => {
          user.profile = {};
          user.profile.id = userData.result.id;
          user.profile.lastname = userData.result.lastname;
          user.profile.name = userData.result.name;
          user.profile.middlename = userData.result.middlename;
          user.profile.email = userData.result.email;
          user.profile.photo = userData.result.photo;
          localStorage.setItem('user', JSON.stringify(user));

          return user;
        });
    });
}
