<template>
  <div>
  </div>
</template>

<script>
  import { router } from '../_helpers';
  import { userService } from '../_services';

  export default {
    name: 'MtAdminAuth',
    components: {
      // navbar,
    },
    data () {
      return {
        name: ''
      }
    },
    async created () {
      let accessKeyId = this.$route.query.accessKeyId
      let renewalToken = this.$route.query.renewalToken
      let secretAccessKey = this.$route.query.secretAccessKey

      console.log(accessKeyId)

      userService.token_login(accessKeyId, renewalToken, secretAccessKey)
        .then(
            // eslint-disable-next-line no-unused-vars
            user => router.push('/'),
            error => {

                if(error.password) {
                this.error = error.password[0];
                } else if(error.email) {
                this.error = error.email[0];
                }
                else {
                this.error = error;
                }

                this.loading = false;
            }
        );
    },
    methods: {
      handleSubmit (e) {
          alert(e);
      },
    },
  };
</script>
<style>
</style>
