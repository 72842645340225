<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <div class="row">
        <div class="col-sm-6">
            <div class="card card-main transparent">
                <div class="card-body">
                    <div class="header-div m-0 desktop-header">
                        <div class="header-upper">
                            <div class="d-flex bd-highlight">
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a>
                                </div>
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <div v-if="thing.shadow.online">
                                        <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                                    </div>
                                    <div v-else>
                                        <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                                    </div>
                                </div>

                                <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
                                    {{ facility.name }}
                                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                                    <span v-else>
                                        <span v-if="thing.type.id == 'ModbusDevice'">, {{ thing.name }} <span class="serial-small">(№ {{ thing.number }})</span></span>
                                        <span v-else>
                                            , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                                        </span>
                                    </span>
                                </span>

                            </div>
                        </div>
                        <div class="header-body" style="padding: 0px;">
                            <div class="row">
                                <div class="col-6 d-flex align-items-center">
                                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="color: #ff0000"><span class="nowrap-span">⚠ Modbus устройство оффлайн</span></li>
                                        <li style="font-size: 14px;">Последнее обновление:</li>
                                        <li>{{ date }} {{ time }}</li>
                                    </ul>
                                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="font-size: 14px;">Обновлено:</li>
                                        <li>{{ date }}</li>
                                        <li>{{ time }}</li>
                                    </ul>
                                </div>
                                <div class="col-6 d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                                    <ul style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2 mb-2">
                                        <li style="font-size: 14px;">Интерфейс:</li>
                                        <li>Порт: {{ thing.shadow.interface.port }}</li>
                                        <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

<!-- MOBILE ********************************************************************** -->
                    <div class="header-div m-0 mobile-header" style="height: auto !important;">
                      <div class="header-upper pb-3">
                        <div class="d-flex bd-highlight">
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
                          </div>
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <div v-if="thing.shadow.online">
                              <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                            </div>
                            <div v-else>
                              <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                            </div>

                          </div>
                          <span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
                            {{ facility.name }}
                            <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                            <span v-else>
                            <span v-if="thing.type.id == 'ModbusDevice'">, {{ thing.name }} <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
                            <span v-else>
                              , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                            </span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px;">
                        <div class="d-flex">
                          <div class="d-flex align-items-center">
                            <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="color: #ff0000"><span class="nowrap-span">⚠ Устройство оффлайн</span></li>
                            <li style="font-size: 14px;">Последнее обновление:</li>
                            <li>{{ date }} {{ time }}</li>
                            </ul>
                            <ul v-else style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="font-size: 14px;">Обновлено:</li>
                            <li>{{ date }}</li>
                            <li>{{ time }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px; border-top: 1px solid black;">
                        <div class="d-flex">
                          <ul style="list-style: none; padding-left: 10px; margin-bottom: 0px;" class="mt-2 mb-2">
                              <li style="font-size: 14px;">Интерфейс:</li>
                              <li>Порт: {{ thing.shadow.interface.port }}</li>
                              <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                          </ul>
                        </div>
                      </div>

                    </div>
<!-- MOBILE ********************************************************************** -->

                    <div class="row mt-3">
                        <div class="overflow-auto detail-overflow">
                            <div class="card card-second transparent mt-0">
                                <div class="card-body">
                                    <div class="detailed-div">
                                        <vue-json-pretty
                                                :path="'res'"
                                                :data=thing.shadow.registers
                                                :showDoubleQuotes="false"
                                                :deep=2
                                                @click="handleClick">
                                        </vue-json-pretty>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
  import VueSimpleSpinner from 'vue-simple-spinner';
  import {facilitiesService, thingsService} from "../../_services";
  import VueJsonPretty from 'vue-json-pretty';

  export default {
    name: 'ModbusDevice',
    props: ['facilityId', 'thingId'],
    components: {
      VueSimpleSpinner,
      VueJsonPretty,
    },
    data () {
      return {
        // facilityId: this.$route.params.facility_id,
        // thingId: this.$route.params.thing_id,
        preloading: false,
        thing: '',
        facility: '',
        date: "",
        time: "",
        // details: '',
      }
    },
    async created() {
      this.preloading = true;
      await this.init();
      this.preloading = false;
      // this.getNow();

      setInterval(function () {
        this.warning_list = [];
        this.critical_list = [];
        this.getThing();
        this.getFacility();
        this.getNow();
      }.bind(this), 15000);
    },
    methods: {
      async init() {
        console.log('Rendering "ModbusDevice" component');
        await this.getThing();
        await this.getFacility();
        this.getNow();
      },
      async getThing() {
        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        // console.log(this.thing);
      },
      getNow: function() {
        let today = new Date(this.thing.updated + 'Z');
        this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      },
      async getFacility() {
        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facility = res.result.facility;
      },
      handleClick() {
        // alert('handleClick')
      }
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../../assets/SnowstormLight.woff2');
  }
  .card {
    margin-top: 20px;
  }
  .card-second {
  }
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  .subject-div1 {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 90px;
    /* width: 180px; */
    margin: 12px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 10px;*/
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    position: relative;
    z-index: 99;
  }
  .subject-div1:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .header-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /* height: 165px; */
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .header-upper {
    border-bottom: 1px solid black;
    padding: 10px
  }
  .back-img {
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 5px;
  }
  .back-img img{
    max-width:100%;
    height:auto;
  }
  .back-img-battery img{
    width:30px;
    height:auto;
  }
  .header-body {
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 20px;*/
    padding: 0px;
  }
  .subject-logo {
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subject-info {
    flex-grow: 7;
    font-size: 16px;
    padding: 0px;
    padding-top: 15px;
  }
  .my-bar {
    min-width: 650px;
    min-height: 340px;
    max-width: 950px;
    max-height: 820px;
    background-color: #202020;
    border-radius: 10px;
  }
  .bar-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-right: 40px;
    height: 340px;
  }
  .circle-35 {
    background: #8EDC28;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .circle-35.sort-linear {
    background: url(../../assets/sort_linear.svg) no-repeat center center #000;
  }
  .circle-35.sort-linear.active {
    background: url(../../assets/sort_linear_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-bars {
    background: url(../../assets/sort_bars.svg) no-repeat center center #000;
  }
  .circle-35.sort-bars.active {
    background: url(../../assets/sort_bars_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-cols {
    background: url(../../assets/sort_cols.svg) no-repeat center center #000;
  }
  .circle-35.sort-cols.active {
    background: url(../../assets/sort_cols_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.sort-linear {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-bars {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-cols {
    opacity: 0.5;
    cursor: pointer;
  }
  .background-text {
    z-index: 1;
  }
  .col-2 {
    display: flex;
    flex-direction: row;
  }
  .small-info {
    position: relative;
    font-size: 14px;
    font-family: "Segoe UI Light";
    letter-spacing: 0px;
    /*color: #FFFFFF;*/
    opacity: 1;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    display: block;
  }
  @media screen and (max-width: 1366px) {
    .small-info {
      width: 100px;
    }
    .col-md-3 {
      padding: 0px;
    }
  }
  .subject-info-values {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circle-50 {
    background: #000000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
  }
  .circle-50:hover {
    background-color: rgba(142,220,40,0.1);
  }
  .circle-50.active {
    background: #8EDC28;
  }
  .nowrap-span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .interact-img:hover img {
    opacity: 0.8;
    transform: scale(1.05);
  }
  .mobile-header {
    display: none;
  }
  @media (max-width: 640px) {
    .battery-info-div { right: 0px !important;}
    .date-picker {
      top: 18%;
      /*position: absolute;*/
      float: right;
      right: 0px;
      /*top: 50%;*/
      width: 50px;
      height: 166px;
      border-radius: 50px;
      background-color: #000000;
      color: #FFFFFF;
    }
    .desktop-header {
      display: none;
    }
    .mobile-header {
      display: block;
    }
    .header-div {
      height: 220px !important;
      margin: 10px;
      font-family: "Segoe UI Light";
      font-size: 30px;
      color: #FFFFFF;
    }
    .small-info {
      font-size: 12px !important;
    }
  }
  .detailed-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /*height: 170px;*/
    /* width: 180px; */
    /* margin: 10px; */
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 0px !important;
    /*padding: 10px;*/
    /* margin-bottom: 30px; */
    /* padding: 10px; */
  }
  .detail-overflow {
    position: relative;
    max-height: 560px;
    min-width: 500px;
  }
</style>
