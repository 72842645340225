<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
      <div v-else>
        <div v-if="thing.type.id === 'RepeyBase'">
          <!-- <repeybase v-bind:facilityId=facilityId v-bind:thingId=thingId></repeybase> -->
          <repeybase v-bind:facilityId=facilityId v-bind:thingId=thingId></repeybase>
        </div>
        <div v-else-if="thing.type.id === 'AgaveHub'">
          <!-- <h5>AGAVE-4G</h5> -->
          <agavehub v-bind:facilityId=facilityId v-bind:thingId=thingId></agavehub>
        </div>
        <div v-else-if="thing.type.id === 'Laurel'">
          <!-- <h5>Iris</h5> -->
          <laurel v-bind:facilityId=facilityId v-bind:thingId=thingId></laurel>
        </div>
        <div v-else-if="thing.type.id === 'ModbusDevice'">
          <modbusdevice v-bind:facilityId=facilityId v-bind:thingId=thingId></modbusdevice>
        </div>
        <div v-else-if="thing.type.id === 'Lyutik'">
          <!-- <modbusdevice v-bind:facilityId=facilityId v-bind:thingId=thingId></modbusdevice> -->
          <lyutik v-bind:facilityId=facilityId v-bind:thingId=thingId></lyutik>
        </div>
        <div v-else-if="thing.type.id === 'IrisMi'">
          <irismi v-bind:facilityId=facilityId v-bind:thingId=thingId></irismi>
        </div>
        <div v-else-if="thing.type.id === 'IrisO'">
          <iriso v-bind:facilityId=facilityId v-bind:thingId=thingId></iriso>
        </div>
        <div v-else-if="thing.type.id === 'BZP02'">
          <bzp02 v-bind:facilityId=facilityId v-bind:thingId=thingId></bzp02>
        </div>
        <div v-else-if="thing.type.id === 'BZP01'">
          <bzp01 v-bind:facilityId=facilityId v-bind:thingId=thingId></bzp01>
        </div>
        <div v-else-if="thing.type.id === 'Cactus'">
          <cactus v-bind:facilityId=facilityId v-bind:thingId=thingId></cactus>
        </div>
        <div v-else-if="thing.type.id === 'LimePlus'">
          <limeplus v-bind:facilityId=facilityId v-bind:thingId=thingId></limeplus>
        </div>
        <div v-else-if="thing.type.id === 'AlteyBZP'">
          <alteybzp v-bind:facilityId=facilityId v-bind:thingId=thingId></alteybzp>
        </div>
        <div v-else-if="thing.type.id === 'AlteyOZT'">
          <alteyozt v-bind:facilityId=facilityId v-bind:thingId=thingId></alteyozt>
        </div>
        <div v-else-if="thing.type.id === 'AlteyPLK'">
          <alteyplk v-bind:facilityId=facilityId v-bind:thingId=thingId></alteyplk>
        </div>
        <div v-else-if="thing.type.id === 'AlteyUZT'">
          <alteyuzt v-bind:facilityId=facilityId v-bind:thingId=thingId></alteyuzt>
        </div>
        <div v-else>
          <h5>Неизвестное устройство</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import Vue from 'vue';
  // import navbar from './Navbar.vue';
  import {thingsService} from "../_services";
  import repeybase from './devices/RepeyBase';
  import agavehub from './devices/AgaveHub';
  import laurel from './devices/Laurel';
  import modbusdevice from './devices/ModbusDevice';
  import lyutik from './devices/Lyutik';
  import irismi from './devices/IrisMi';
  import iriso from './devices/IrisO';
  import bzp02 from './devices/BZP02';
  import bzp01 from './devices/BZP01';
  import alteybzp from './devices/AlteyBZP';
  import alteyozt from './devices/AlteyOZT';
  import alteyplk from './devices/AlteyPLK';
  import alteyuzt from './devices/AlteyUZT';
  import cactus from './devices/Cactus';
  import limeplus from './devices/LimePlus'
  import VueSimpleSpinner from 'vue-simple-spinner'

  export default {
    name: 'ThingPage',
    components: {
      // navbar,
      VueSimpleSpinner,
      repeybase,
      agavehub,
      laurel,
      modbusdevice,
      lyutik,
      irismi,
      iriso,
      bzp02,
      bzp01,
      alteybzp,
      alteyozt,
      alteyplk,
      alteyuzt,
      cactus,
      limeplus,
    },
    data () {
      return {
        preloading: false,
        facilityId: this.$route.params.facility_id,
        thingId: this.$route.params.thing_id,
        thing: ''
      }
    },
    async created() {
      await this.init();
    },
    methods: {
      async init() {
        // console.log('initializing')
        await this.getThing();
      },
      async getThing() {
        this.preloading = true;

        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        this.preloading = false;
      }
    }
  }
</script>

<style>
  .container {
    max-width: 1920px;
    width: 100%;
  }
</style>