<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
            <div v-else class="card-body">
              <h5 class="card-title">{{ facility.name }}</h5>
              <div class="card-body header-div d-flex" style="height: 100px">
                <div class="back-img mb-3">
                  <a @click="$router.go(-1)" class="hover"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                </div>
                <div class="mt-2 ml-3">{{ $t('editemployeepage.info') }}:</div>
              </div>
              <div class="editform" style="height: 200px;">
                <div class="row" style="height: 200px;">

                  <div class="col-sm-3">
                    <div class="facility-img mt-0"></div>
                  </div>
                  <div class="col-sm-8" style="border-left: 1px solid black; padding: 10px;">
                    <p class="ml-3 mt-2">Имя сотрудника: {{ employee.user.name }}</p>
                      <div class="row">
                        <div class="col-7 mt-1">
                          Роль сотрудника в предприятии:
                        </div>
                        <div class="col-5">
                          <!-- <v-select class="style-chooser" :clearable="false" :options="options" v-model="employee.role.id" :value="getRoleText(employee.role.id)"></v-select> -->
                          <select class="form-control form-edit ml-2" style="font-size: 18px; width: 100%;" v-model="employee.role.id">
                            <option v-for="option in roleList" v-bind:key="option.id" v-bind:value="option.text">{{ getRoleText(option.id) }}</option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div class="col-sm-1" style="padding: 10px;">
                    <div class="circle confirm" @click.prevent="update_employee()"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  // import navbar from './Navbar.vue'
  import {employeesService, facilitiesService} from "../_services";
  import VueSimpleSpinner from 'vue-simple-spinner'

  export default {
    name: 'EmployeeEdit',
    components: {
      // navbar,
      VueSimpleSpinner
    },
    data () {
      return {
        facilityId: this.$route.params.facility_id,
        employeeId: this.$route.params.employee_id,
        employee: '',
        options: ['Employee', 'Admin'],
        facility: '',
        preloading: false,
        roleList: []
      }
    },
    async created () {
      this.preloading = true;
      this.init();
      await this.getRoles();
      await this.get_facility(this.facilityId);
      await this.get_employee(this.facilityId, this.employeeId);
      this.preloading = false;
    },
    methods: {
      init() {
        console.log('initializing');
      },
      async get_facility(facilityId) {
        let res = await facilitiesService.facility_get(facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log('dddddddd');
        console.log(res);

        this.facility = res.result.facility;
      },
      async get_employee(facilityId, employeeId) {
        let res = await employeesService.employee_get(facilityId, employeeId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.employee = res.result;
      },
      async update_employee() {
        let data = {"role": this.employee.role.id}

        let res = await employeesService.employee_update(this.facilityId, this.employeeId, data)

        if (res.error) {
          console.error(res.error);

          return;
        }

        console.log(res);

        this.employee = res.result;

        // window.location.href = '/facility/edit/' + this.facilityId;
        this.$router.go(-1);
      },
      async getRoles() {
        this.roleList = [{id: "Admin", text: "Admin"}, {id: "Employee", text: "Employee"}]
      },
      getRoleText(role_id) {
        // console.log(role_id);

        let values = {
          'Owner': 'Владелец',
          'Admin': 'Админ',
          'Employee': 'Сотрудник'
        }

        return values[role_id];
      },
    }
  }
</script>

<style>
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .hover:hover {
    cursor: pointer;
  }
</style>