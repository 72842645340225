<template>
  <div class="super-popup">
        <div>
          <croppa v-model="myCroppa"
            canvas-color="transparent"
            prevent-white-space
            :placeholder="'Выберите изображение'"
            :placeholder-font-size="14"
            :show-remove-button="false"
            @init="onInit"
            @new-image-drawn="onNewImage"
            @zoom="onZoom"
          ></croppa>
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <input type="range" @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal"> <!-- {{sliderVal}} -->
              <div class="circle-35 active rotate-right" @click="myCroppa.rotate()"></div>
              <div class="circle-35 active rotate-left" @click="myCroppa.rotate(-1)"></div>
              <div class="circle-35 active flip" @click="myCroppa.flipX()"></div>
              <div class="circle-35 active upload-file" @click="myCroppa.chooseFile()"></div>
            </div>
          </div>
          <div class="container mt-2">
            <div class="d-flex justify-content-center">
              <!-- <button @click="generateImage">Generate</button> -->
              <!-- <button @click="croppa_show = false">Cancel</button> -->
              <div @click="close" class="gray-button" style="width: 120px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 22px;">{{ $t('photoloader.cancel') }}</div>
              <div @click="generateImage" class="green-button ml-3" style="width: 120px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 12px;">{{ $t('photoloader.update') }}</div>
            </div>
          </div>
          <br>
          <img class="output" :src="imgUrl" >
        </div>
  </div>
</template>

<script>
export default {
    name: 'PhotoUploader',
    props: ['croppa_show'],
    data () {
        return {
            myCroppa: {},
            imgUrl: '',
            sliderVal: 0,
            sliderMin: 0,
            sliderMax: 0,
        }
    },
    async created() {
        console.log('created PhotoUploader...');
    },
    methods: {
      onInit() {
        this.myCroppa.addClipPlugin(function (ctx, x, y, w, h) {
          // console.log(x, y, w, h)
          ctx.beginPath()
          ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
          ctx.closePath()
        })

        this.myCroppa.chooseFile();
      },
      onNewImage() {
        this.sliderVal = this.myCroppa.scaleRatio
        this.sliderMin = this.myCroppa.scaleRatio / 2
        this.sliderMax = this.myCroppa.scaleRatio * 2
      },
      onSliderChange(evt) {
        var increment = evt.target.value
        this.myCroppa.scaleRatio = +increment
      },
      onZoom() {
        // To prevent zooming out of range when using scrolling to zoom
        if (this.sliderMax && this.myCroppa.scaleRatio >= this.sliderMax) {
          this.myCroppa.scaleRatio = this.sliderMax
        } else if (this.sliderMin && this.myCroppa.scaleRatio <= this.sliderMin) {
          this.myCroppa.scaleRatio = this.sliderMin
        }

        this.sliderVal = this.myCroppa.scaleRatio
      },
      async generateImage() {
        if (!this.myCroppa.hasImage()) {
          alert('Изображение не выбрано');
          return
        }

        const file = this.myCroppa.getChosenFile();

        let filename = file.name;

        this.myCroppa.generateBlob((blob) => {
            // console.log(filename);
            // console.log(blob);
            this.upload_image(blob, filename);
        })
      },
      upload_image(blob, filename) {
          this.$emit('upload_image', blob, filename);
      },
      close() {
        console.log('pressed close');
        this.$emit('close');
      },
    }
}
</script>

<style>
  .super-popup {
    width: 400px;
    height: 324px;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
      
    margin: auto;

    background-color: rgba(150,150,150,0.9);
    overflow:hidden;
    border-radius:10px;
    box-shadow: 0px 0px 10px #000;
  }
  input[type="range"] {
    -webkit-appearance: none;
    -moz-apperance: none;
    background-color: #444444;
    border-radius: 6px;
    height: 6px;
  }
  input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      background-color: #8EDC28;
      height: 18px;
      width: 18px;
      border-radius: 50px;
  }
  input[type=range] {
    width: 200px;
    cursor: pointer;
  }
  .circle-35.rotate-left.active {
    background: url(../assets/rotate-left.png) no-repeat center center #8EDC28;
  }
  .circle-35.rotate-right.active {
    background: url(../assets/rotate-right.png) no-repeat center center #8EDC28;
  }
  .circle-35.flip.active {
    background: url(../assets/flip.png) no-repeat center center #8EDC28;
  }
  .circle-35.upload-file.active {
    background: url(../assets/upload_file.png) no-repeat center center #8EDC28;
  }
  .circle-35.active:hover {
    cursor: pointer;
  }
  .circle-35.rotate-left.active:hover {
    background: url(../assets/rotate-left.png) no-repeat center center rgba(0, 0, 0, 0.4);
  }
  .circle-35.rotate-right.active:hover {
    background: url(../assets/rotate-right.png) no-repeat center center rgba(0, 0, 0, 0.4);
  }
  .circle-35.flip.active:hover {
    background: url(../assets/flip.png) no-repeat center center rgba(0, 0, 0, 0.4);
  }
  .circle-35.upload-file.active:hover {
    background: url(../assets/upload_file.png) no-repeat center center rgba(0, 0, 0, 0.4);
  }
</style>
