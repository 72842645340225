import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const thingsService = {
  things_get,
  things_registration,
  thing_registration_status,
  thing_update,
  thing_get,
  thing_delete,
  things_pairing,
  thing_pairing_status,
  thing_update_files,
  things_get_new,
  thing_update_files_status,
  things_reset_db,
  things_reboot,
  get_firmware_version,
  firmware_update,
  firmware_update_status,
  thing_reinit_db_status,
  thing_reboot_status,
  thing_add,
  modbus_command,
  modbus_command_status
};

// async function things_get(facilityId) {
//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader()
//   };
//
//   return fetch(API_LOCATION + `/facilities/${facilityId}/things`, requestOptions).then(responseHandler.handleResponse);
// }

function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]) + '&');
  }
  return keyValuePairs.join('&');
}

async function things_get_new(facilityId, data) {
  let queryString = '';

  if (data.equipmentId) {
    queryString += objToQueryString({
      equipments: data.equipmentId
    });
  }

  if (data.typeId) {
    queryString += objToQueryString({
      types: data.typeId
    });
  }

  if (data.hubId) {
    queryString += objToQueryString({
      hubs: data.hubId
    });
  }

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things?${queryString}`, requestOptions).then(responseHandler.handleResponse);
}

async function things_get(facilityId, equipmentId, hubId) {
  let queryString = null;

  if (equipmentId) {
    queryString = objToQueryString({
      equipments: equipmentId
    });
  }

  if (hubId) {
    queryString = objToQueryString({
      hubs: hubId
    });
  }

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things?${queryString}`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_get(facilityId, thingId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}`, requestOptions).then(responseHandler.handleResponse);
}

async function things_registration(facilityId, serialNumber) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      "serial": serialNumber
    })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/registration`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_registration_status(facilityId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/registration/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_update(facilityId, thingId, data) {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(
      data
// {
//       // "name": "string",
//       "equipment": equipmentId
//       }
    )
  };

  // data = {
  //   "name": "string",
  //   "equipment": equipmentId
  // }

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_delete(facilityId, thingId) {
  const requestOptions = {
    method: 'Delete',
    headers: authHeader()

  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}`, requestOptions).then(responseHandler.handleResponse);
}

// /facilities/{facilityId}/things/{thingId}/pair
async function things_pairing(facilityId, thingId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    // body: JSON.stringify({
    //   "serial": serialNumber
    // })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/pair`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_pairing_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/pair/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

async function modbus_command_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/modbus_command/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

// /facilities/{facilityId}/things/{thingId}/files/update

async function thing_reinit_db_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/reset/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_reboot_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/reboot/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_update_files(facilityId, thingId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/files/update`, requestOptions).then(responseHandler.handleResponse);
}

async function thing_update_files_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/files/update/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}

async function things_reset_db(facilityId, thingId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    // body: JSON.stringify({
    //   "serial": serialNumber
    // })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/reset`, requestOptions).then(responseHandler.handleResponse);
}

async function things_reboot(facilityId, thingId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    // body: JSON.stringify({
    //   "serial": serialNumber
    // })
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/reboot`, requestOptions).then(responseHandler.handleResponse);
}

async function get_firmware_version(facilityId, thingId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/firmware`, requestOptions).then(responseHandler.handleResponse);
}

async function firmware_update(facilityId, thingId) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/firmware/update`, requestOptions).then(responseHandler.handleResponse);
}


async function firmware_update_status(facilityId, thingId, reqId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/firmware/update/${reqId}`, requestOptions).then(responseHandler.handleResponse);
}


async function thing_add(facilityId, thingId, data) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(
      data
    )
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/add`, requestOptions).then(responseHandler.handleResponse);
}

async function modbus_command(facilityId, thingId, data) {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(
      data
    )
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/modbus_command`, requestOptions).then(responseHandler.handleResponse);
}
