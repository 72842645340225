<template>
  <div>
    <!-- <navbar></navbar> -->
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="card card-main transparent mt-0">
            <div class="card-body">
              <h5 class="card-title" style="margin-left: 10px;">{{ $t('profilepage.title') }}</h5>
              <div class="card-body header-div d-flex" style="height: auto !important;">
                <!-- <div class="back-img mb-3">
                  <a href="/"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                </div> -->
                <div class="back-img mb-3">
                    <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
                </div>
                <div class="mt-2 ml-3">{{ $t('profilepage.info') }}:</div>
              </div>
              <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
              <div v-else class="editform">
                <div class="row">
                  <div class="col-sm-3" style="position: relative;">
                    <img class="facility-img" :src="profileImage" @click.prevent="croppa_show = true">
                    <div class="edit-logo" @click.prevent="croppa_show = true"></div>
                  </div>
                  <div class="col-sm-8" style="border-left: 1px solid black; padding: 10px;">
                    <input type="text" v-model="profile.lastname" class="form-control form-edit ml-2 mb-2" placeholder="Фамилия" maxlength="100"/>
                    <input type="text" v-model="profile.name" class="form-control form-edit ml-2 mb-2" placeholder="Имя" maxlength="100"/>
                    <input type="text" v-model="profile.middlename" class="form-control form-edit ml-2 mb-2" placeholder="Отчество" maxlength="100"/>
                    <p class="m-2"><label>{{ $t('profilepage.email') }}: {{ profile.email }}</label></p>
                    <p class="m-2"><label>{{ $t('profilepage.password') }}: {{ profile.password }}</label></p>
                    <button type="button" class="btn btn-secondary m-2" @click="changePassword()">{{ $t('profilepage.password_change') }}</button>
                    <!-- <label class="small-text m-2">Full profile document: {{ profile }}</label> -->
                  </div>
                  <div class="col-sm-1" style="padding: 10px;">
                    <div class="circle confirm" @click.prevent="updateProfile"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backdrop" v-if="croppa_show == true">
      <PhotoUploader @close="closeModal" @upload_image="upload_profile_image"></PhotoUploader>
    </div>
    <!-- <div class="backdrop" v-if="croppa_show == true">
      <div class="super-popup">
        <div>
          <croppa v-model="myCroppa"
            canvas-color="transparent"
            prevent-white-space
            :placeholder="'Выберите изображение'"
            :placeholder-font-size="14"
            :show-remove-button="false"
            @init="onInit"
            @new-image-drawn="onNewImage"
            @zoom="onZoom"
          ></croppa>
          <div class="container">
            <input type="range" @input="onSliderChange" :min="sliderMin" :max="sliderMax" step=".001" v-model="sliderVal"> 
          </div>
          <div class="container">
            <div class="d-flex justify-content-center">
              <div @click="croppa_show = false" class="gray-button" style="width: 120px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 22px;">ОТМЕНА</div>
              <div @click="generateImage" class="green-button ml-3" style="width: 120px; height: 45px; font-size: 18px; padding-top: 10px; padding-left: 12px;">ОБНОВИТЬ</div>
            </div>
          </div>
          <br>
          <img class="output" :src="imgUrl" >
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
  // import navbar from './Navbar.vue'
  import Vue from 'vue';
  import Croppa from 'vue-croppa';
  import {userService} from "../_services";
  import VueSimpleSpinner from 'vue-simple-spinner';
  import PhotoUploader from './PhotoUploader.vue';

  Vue.use(Croppa);

  export default {
    name: 'ProfilePage',
    components: {
      // navbar,
      VueSimpleSpinner,
      PhotoUploader
    },
    data () {
      return {
        profile: '',
        preloading: false,
        myCroppa: {},
        imgUrl: '',
        croppa_show: false,
        sliderVal: 0,
        sliderMin: 0,
        sliderMax: 0,
        profileImage: null
      }
    },
    created () {
      this.preloading = true;
      this.init();
      this.get_profile();
      this.get_profile_photo();
      this.preloading = false;
    },
    methods: {
      init() {
        console.log('initializing');
      },
      // onInit() {
      //   this.myCroppa.addClipPlugin(function (ctx, x, y, w, h) {
      //     // console.log(x, y, w, h)
      //     ctx.beginPath()
      //     ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
      //     ctx.closePath()
      //   })
      // },
      // onNewImage() {
      //   this.sliderVal = this.myCroppa.scaleRatio
      //   this.sliderMin = this.myCroppa.scaleRatio / 2
      //   this.sliderMax = this.myCroppa.scaleRatio * 2
      // },
      // onSliderChange(evt) {
      //   var increment = evt.target.value
      //   this.myCroppa.scaleRatio = +increment
      // },
      // onZoom() {
      //   // To prevent zooming out of range when using scrolling to zoom
      //   if (this.sliderMax && this.myCroppa.scaleRatio >= this.sliderMax) {
      //     this.myCroppa.scaleRatio = this.sliderMax
      //   } else if (this.sliderMin && this.myCroppa.scaleRatio <= this.sliderMin) {
      //     this.myCroppa.scaleRatio = this.sliderMin
      //   }

      //   this.sliderVal = this.myCroppa.scaleRatio
      // },
      // async generateImage() {
      //   if (!this.myCroppa.hasImage()) {
      //     alert('Изображение не выбрано');
      //     return
      //   }

      //   const file = this.myCroppa.getChosenFile();

      //   let filename = file.name;

      //   // console.log(filename)

      //   this.myCroppa.generateBlob((blob) => {
      //     let res = userService.updateProfilePhoto(blob, filename);

      //     if (res.error) {
      //       console.error(res.error);

      //       return;
      //     }

      //     // console.log(res);

      //     this.croppa_show = false;

      //     // getProfilePhoto
      //     this.get_profile_photo();
      //     // setProfilePhoto to localStoreage
      //     this.set_profile_photo(); 
      //   })
      // },
      closeModal() {
        this.croppa_show = false;
      },
      async upload_profile_image(blob, filename) {
        let res = userService.updateProfilePhoto(blob, filename);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.croppa_show = false;

        this.get_profile_photo();
        this.set_profile_photo();
      },
      async set_profile_photo() {
        let res = await userService.getProfilePhoto();

        if (res.error) {
          console.error(res.error);

          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(res);

        reader.onloadend = function () {
          let base64String = reader.result;

          localStorage.setItem('profileImage', JSON.stringify(base64String));
        }

        this.$router.go(0);
      },
      async get_profile_photo() {
        let res = await userService.getProfilePhoto();

        if (res.error) {
          console.error(res.error);

          return;
        }

        // this.profile = res.result;
        // console.log('**************************** res');
        // console.log(res);

        // this.profileImage = URL.createObjectURL(res);

        if (res.message) {
          console.log(res.message);
        } else {
          this.profileImage = URL.createObjectURL(res);
        }
      },
      async get_profile() {
        let res = await userService.getProfile();

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.profile = res.result;
      },
      async updateProfile() {
        this.preloading = true;

        let res = await userService.updateProfile(this.profile);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.profile = res.profile;

        // await this.get_profile()
        this.preloading = false;

        this.$router.go(0);
      },
      changePassword() {
        alert('Смена пароля пока возможна только через Тех.поддержку');
      }
    }
  }
</script>

<style>
  .container {
    max-width: 1920px;
    width: 100%;
  }
  .croppa-container {
    background-color: white;
    /* border: 3px solid black */
  }
  /* .super-popup {
    width: 350px;
    height: 300px;
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
      
    margin: auto;

    background-color: rgba(150,150,150,0.9);
    overflow:hidden;
    border-radius:10px;
    box-shadow: 0px 0px 10px #000;
  } */
  .backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .croppa-container {
    /* height: 200px; */
    background-color: rgba(150,150,150,0.1);
    /* border: 3px solid red; */
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    backdrop-filter: blur(2px);
    /* box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(10px); */
    cursor: pointer;
  }
  .croppa-container.canvas {
    /* height: 200px; */
    background-color: rgba(222,0,0,0.8);
  }
  .edit-logo {
    position: relative;
    z-index: 9999;
    height: 34px;
    width: 34px;
    border-radius: 50px;
    /* background: url(../assets/small-pencil.svg) no-repeat center center rgba(222, 222, 222, 0.4); */
    top: 60px !important;
    left: 135px !important;
    background: url(../assets/small-pencil.svg) no-repeat center center rgba(256, 256, 256, 1);
  }
  .edit-logo:hover {
    background: url(../assets/small-pencil.svg) no-repeat center center rgba(200, 200, 200, 1);
    cursor: pointer;
  }
</style>
