import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const employeesService = {
  employee_get,
  employees_get,
  employee_update,
  employee_delete,
};

async function employees_get(facilityId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/employees`, requestOptions).then(responseHandler.handleResponse);
}

async function employee_get(facilityId, employeeId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/employees/${employeeId}`, requestOptions).then(responseHandler.handleResponse);
}

async function employee_update(facilityId, employeeId, data) {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(
      data
    )
  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/employees/${employeeId}`, requestOptions).then(responseHandler.handleResponse);
}

async function employee_delete(facilityId, employeeId) {
  const requestOptions = {
    method: 'Delete',
    headers: authHeader()

  };

  return fetch(API_LOCATION + `/facilities/${facilityId}/employees/${employeeId}`, requestOptions).then(responseHandler.handleResponse);
}