<template>
    <div>
        <!-- <navbar></navbar> -->
        <div class="container">
            <div class="row">
                <div class="col-sm-6 mb-4">
                    <div class="card card-main transparent mt-0">
                        <div class="card-body">
                            <h5 class="card-title" style="margin-left: 10px;">{{ $t('editpage.title') }}</h5>
                            <div class="card-body header-div d-flex" style="height: auto !important;">
                                <!-- <div class="back-img mb-3">
                                    <a @click="$router.go(-1)" class="hover"><img alt="focus" src="../assets/arrow_back.svg" style="width: 80%; height: 80%"></a>
                                </div> -->
                                <div class="back-img mb-3">
                                  <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
                                </div>
                                <div v-if="this.facilityId" class="mt-2 ml-3">{{ $t('editpage.info') }}:</div>
                                <div v-else class="mt-2 ml-3">{{ $t('editpage.info_add') }}:</div>
                            </div>
                            <div class="editform">
                                <!-- <div class="row" style="border-bottom: 1px solid black">
                                    <div class="col-1 col-sm-1 mt-3 mb-3" style="max-width: 60px">
                                        <img alt="focus" src="../assets/green_circle.svg">
                                    </div>
                                    <div class="col-8 col-sm-8 mt-3 mb-3">
                                        <input type="text" v-model="facility.name" class="form-control form-edit ml-2" placeholder="Название объекта" maxlength="50"/>
                                    </div>
                                    <div class="col-3 col-sm-3 text-right" style="border-left: 1px solid black">
                                        <div class="circle delete d-inline-block mt-3 mb-3" @click.prevent="back"></div>
                                        <div v-if="this.facilityId" class="circle confirm d-inline-block ml-3 mt-3 mb-3" @click.prevent="editFacility"></div>
                                        <div v-else class="circle confirm d-inline-block ml-2 mt-3 mb-3" @click.once="addFacility"></div>
                                    </div>
                                </div> -->
                                <div class="d-flex bd-highlight align-items-center" style="border-bottom: 1px solid black">
                                  <img alt="focus" src="../assets/green_circle.svg" class="p-2 bd-highlight m-1">
                                  <input type="text" v-model="facility.name" class="form-control form-edit p-2 bd-highlight m-1 mr-2" placeholder="Название объекта" maxlength="50"/>
                                  <div style="border-left: 1px solid black; padding-left: 70px;" class="mr-2 bd-highlight ml-auto d-flex align-items-center justify-content-end">
                                    <div class="circle delete d-inline-block mt-3 mb-3" @click.prevent="back"></div>
                                    <div v-if="this.facilityId" class="circle confirm d-inline-block ml-3 mt-3 mb-3" @click.prevent="editFacility"></div>
                                    <div v-else class="circle confirm d-inline-block ml-2 mt-3 mb-3" @click.once="addFacility"></div>
                                  </div>
                                </div>
                                <div class="row">
                                    <div v-if="this.facilityId" class="col-4 pt-3 pb-3 pr-4" style="position: relative;">
                                      <!-- <div class="facility-img"></div> -->
                                      <img v-if="facilityImage" class="facility-img" :src="facilityImage" @click.prevent="croppa_show = true">
                                      <img v-else class="facility-img" @click.prevent="croppa_show = true">
                                      <div class="edit-logo2" @click.prevent="croppa_show = true"></div>
                                    </div>
                                    <div v-else class="col-4 pt-3 pb-3 pr-4" style="position: relative;">
                                      <img class="facility-img">
                                    </div>
                                    <div class="col-8 pt-4 pb-5" style="border-left: 1px solid black">
                                        {{ $t('editpage.location') }}:
                                        <input type="text" v-model="facility.address" class="form-control form-edit ml-1 mt-3" style="font-size: 18px;" placeholder="Адрес" maxlength="150"/>
                                    </div>
                                </div>
                                <div class="row" style="border-top: 1px solid black; ">
                                  <div class="col-4 pt-3">

                                    <div v-if="this.facilityId">
                                      <div class="d-flex bd-highlight">
                                        <div class="p-2 bd-highlight">{{ $t('editpage.devices') }}:</div>
                                        <div class="p-2 bd-highlight ml-auto p-2">
                                          <div v-if="!device_bind_toggle" class="circle-30 add-employee" @click.prevent="toggleBindForm"></div>
                                          <div v-else class="circle-30 confirm-toggle" @click.prevent="toggleBindForm"></div>
                                        </div>
                                      </div>
                                      <div class="mb-3 mt-2 devices-div">
                                        <div v-if="devices.length == 0" class="small-text">К этому объекту еще не привязаны устройства.
                                        </div>
                                        <div v-else>
                                          <div v-for="(param) in devices" v-bind:key="param.id" class="small-text" style="font-size: 22px;">

                                            <div class="thing-div">
                                              <span class="name">
                                                <img alt="focus" src="../assets/online_circle.svg" v-if="param.shadow.online">
                                                <img alt="focus" src="../assets/offline_circle.svg" v-else>
                                                <span v-if="param.type.id === 'AgaveHub'" class="ml-1 small-text">{{ getDeviceTypeName(param.type.id) }} (№ {{ parseInt((param.serial).substring(3), 10) }})</span>
                                                <span v-else class="ml-1 small-text">{{ getDeviceTypeName(param.type.id) }} (№ {{ (param.serial).substring(3) }})</span>
                                              </span>
                                              <span class="buttons">
                                                <div class="d-flex bd-highlight mb-0 mt-1">
                                                  <div class="p-0 bd-highlight">
                                                   <a href="#" @click.prevent="delete_thing(param.id)"><div class="delete-button mt-1"></div></a>
                                                  </div>
                                                  <div class="p-0 bd-highlight">
                                                    <a :href="'/facility/' + facility.id + '/things/' + param.id + '/edit'" class="disabled"><div class="edit-button mt-1" style="-webkit-filter: grayscale(1); opacity: 0.4;"></div></a>
                                                  </div>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-else class="mt-2">
                                      <div class="d-flex bd-highlight">
                                        <div class="p-2 bd-highlight">{{ $t('editpage.devices') }}:</div>
                                      </div>
                                      <hr class="mt-1 mb-1">
                                      <div class="devices-div small-text">
                                        {{ $t('editpage.devices-alert') }}
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="this.facilityId" class="col-8 pt-3 pb-5" style="border-left: 1px solid black;">
                                    <div class="d-flex bd-highlight">
                                      <div class="p-2 bd-highlight">{{ $t('editpage.employees') }}:</div>
                                      <div class="p-2 bd-highlight ml-auto p-2">
                                        <div v-if="!employee_add_toggle" class="circle-30 add-employee" @click.prevent="add_show"></div>
                                        <div v-else class="circle-30 confirm-toggle" @click.prevent="add_show"></div>
                                      </div>
                                    </div>
                                    <hr class="mt-1 mb-1">
                                    <div v-if="employees.length == 0" class="small-text devices-div">
                                      У этого объекта-подстанции нет сотрудников
                                    </div>
                                    <div v-else>
                                      <div v-for="(employee) in employees" v-bind:key="employee.id" class="small-text" style="font-size: 14px;">

                                        <div class="row mt-2">
                                          <div class="col-sm-6">{{ employee.user.name }} {{ employee.user.middlename }} {{ employee.user.lastname }}</div>
                                          <div class="col-sm-4 d-flex align-items-end"><div v-if="employee.role.id === 'Owner'" class="crown"/>{{ getRoleText(employee.role.id) }}</div>
                                          <div class="col-sm-1"><a v-if="employee.role.id != 'Owner'" href="#" @click.prevent="delete_employee(employee.id)"><div class="delete-button"></div></a></div>
                                          <!-- <div class="col-sm-1"><a v-if="employee.role.id != 'Owner'" :href="'/facility/' + facility.id + '/employees/' + employee.id + '/edit'"><div class="edit-button"></div></a></div> -->
                                          <div class="col-sm-1" v-if="employee.role.id != 'Owner'" @click="$router.push({ name: 'edit_employee', params: { facility_id: facility.id, employee_id: employee.id }})" style="cursor: pointer;"><div class="edit-button"></div></div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="col-8 pt-4 pb-5" style="border-left: 1px solid black;">
                                    <div class="d-flex bd-highlight">
                                      <div class="p-2 bd-highlight">{{ $t('editpage.employees') }}:</div>
                                    </div>
                                    <hr class="mt-1 mb-1">
                                    <div class="small-text devices-div">
                                      {{ $t('editpage.employees-alert') }}
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6" v-show="employee_add_toggle">
                  <div class="card card-main transparent">
                    <div class="card-body">
                      <div class="add-employee-form">
                          <div class="d-flex justify-content-center"><h1>{{ $t('editpage.invite.title') }}:</h1></div>
                          <div class="m-2">{{ $t('editpage.invite.info') }}:</div>
                          <div class="d-flex bd-highlight mb-2">
                            <input type="text" v-model="email" class="form-control form-edit ml-0" style="font-size: 18px;" placeholder="email" required maxlength="80"/>
                            <!-- <input type="text" v-model="role" class="form-control form-edit ml-2" style="font-size: 18px;" placeholder="role"/>-->
                            <select class="form-control form-edit ml-2" style="font-size: 18px; width: 40%;" v-model="role">
                              <option v-for="option in roleList" v-bind:key="option.id" v-bind:value="option.text">{{ getRoleText(option.id) }}</option>
                            </select>
                            <div class="circle confirm ml-2" @click.prevent="sendInvite" style="width: 70px; margin-top: 3px;"></div>
                          </div>
                          <div class="small-text text-muted">{{ $t('editpage.invite.secondary') }}</div>
                          <div class="small-text text-muted">{{ $t('editpage.invite.third') }}</div>
                          <div class="small-text text-muted">{{ $t('editpage.invite.fourth') }}</div>
                          <hr class="m-2 mt-3">
                          <div>{{ $t('editpage.invite.sended') }}:</div>
                          <div v-if="invites.length != 0">
                            <div v-for="(invite) in invites" v-bind:key="invite.id" class="small-text row">
                              <div class="col-sm-7">{{ invite.email }}</div>
                              <div class="col-sm-3">{{ getRoleText(invite.role.id) }}</div>
                              <div class="col-sm-2"><a href="#" @click.prevent="deleteFacilityInvite(invite.id)">{{ $t('editpage.invite.delete') }}</a></div>
                            </div>
                          </div>
                          <div v-else class="small-text devices-div">
                            {{ $t('editpage.invite.empty') }}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" v-show="device_bind_toggle">
                    <div class="card card-main transparent">
                        <div class="card-body">
                            <div class="device-bind-form">
                                <div class="row">
                                    <div id="myCarousel" class="carousel slide" data-interval="false">
                                        <ol class="carousel-indicators">
                                            <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                                            <li data-target="#myCarousel" data-slide-to="1"></li>
                                            <li v-if="advanced_settings === true" data-target="#myCarousel" data-slide-to="2" ></li>
                                            <li v-if="advanced_settings === true" data-target="#myCarousel" data-slide-to="3"></li>
                                            <li v-if="advanced_settings === true" data-target="#myCarousel" data-slide-to="4"></li>
                                            <li v-if="advanced_settings === true" data-target="#myCarousel" data-slide-to="5"></li>
                                        </ol>
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <div>
                                                    <h1>{{ $t('editpage.device.title') }}:</h1>
                                                    <div class="img-wrapper mt-3">
                                                      <img alt="focus" src="../assets/styled_pairing_serial.png">
                                                    </div>
                                                    <p class="text-left">{{ $t('editpage.device.info') }}</p>
                                                    <p class="text-left text-muted">{{ $t('editpage.device.secondary') }}</p>
                                                    <div class="row">
                                                      <div class="col-sm-7">
                                                        <input type="text" v-model="new_serial_number" class="form-control form-edit ml-0" style="font-size: 18px;" placeholder="Серийный номер" required="true" maxlength="15"/>
                                                      </div>
                                                      <div class="col-sm-5">
                                                        <div v-if="this.new_serial_number.length > 0 && this.new_serial_number.length <= 6">
                                                          <a href="#myCarousel" @click.prevent="registerHub" style="text-decoration: none;" role="button" data-slide="next"><div class="green-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px;">{{ $t('editpage.device.pairbutton') }}</div></a>
                                                        </div>
                                                        <div v-else>
                                                          <div class="gray-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px;">{{ $t('editpage.device.pairbutton') }}</div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="carousel-item">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <div v-if="bind_status == false">
                                                  <h1>{{ $t('editpage.device.confirm') }}:</h1>
                                                  <div class="img-wrapper mt-3">
                                                    <img alt="focus" src="../assets/styled_pairing_button.png">
                                                  </div>
                                                  <p class="text-left">{{ $t('editpage.device.info-second') }}</p>
                                                  <p class="text-left text-muted">{{ $t('editpage.device.secondary-second') }}</p>
                                                  <p class="text-left text-muted">{{ $t('editpage.device.secondary-third') }} <a href="#myCarousel" @click.prevent="showAdvancedSettings" role="button" data-slide="next">{{ $t('editpage.device.link') }}</a></p>
                                                  <div class="row">
                                                    <div class="col-6">
                                                      <vue-simple-spinner v-if="bind_status == false" :speed="1.5" :size="55" :line-size="6" line-fg-color="#8edc28" line-bg-color="#555555" class="float-right"/>
                                                    </div>
                                                    <div class="col-6">
                                                      <p class="float-left mt-3">{{ $t('editpage.device.waiting') }}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div v-else>
                                                  <div class="img-wrapper mt-4 mb-4">
                                                    <img alt="focus" src="../assets/instr_mark2.png">
                                                  </div>
                                                  <p>Поздравляем! Привязка завершена успешно! Можете проверить это на <a :href="'/facility/' + facilityId + '/things' ">странице объекта</a>.</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="advanced_settings === true" class="carousel-item">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <h1>Шаг 3. Продвинутая настройка:</h1>
                                                <div class="img-wrapper mt-3">
                                                  <img alt="focus" src="../assets/styled_pairing_button.png">
                                                </div>
                                                <p class="text-left">Зажмите кнопку хаба примерно на 4 секунды. Когда индикатор станет синим - отпустите кнопку.</p>
                                                <p class="text-left text-muted">Через некотрое время индикатор начнет моргать - значит все хорошо, переходите к следующему шагу. </p>
                                                <a href="#myCarousel" role="button" data-slide="next" style="text-decoration: none; float: right;"><div class="green-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px;">Далее >></div></a>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="advanced_settings === true" class="carousel-item">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <h1>Шаг 4. Точка доступа:</h1>
                                                <div class="img-wrapper mt-3">
                                                  <img alt="focus" src="../assets/styled_pairing_wifi.png">
                                                </div>
                                                <p class="text-left">Подключитесь к точке доступа Wi-Fi "MT-AGAVE-4G-XXXXXX".</p>
                                                <p class="text-left text-muted">Подключиться можно с любого устройства, будь то смартфон, компьютер или планшет. Пароль - "12345678".</p>
                                                <a href="#myCarousel" role="button" data-slide="next" style="text-decoration: none; float: right;"><div class="green-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px;">Далее >></div></a>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="advanced_settings === true" class="carousel-item">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <h1>Шаг 5. Регистрация:</h1>
                                                <div class="img-wrapper mt-3">
                                                  <img alt="focus" src="../assets/styled_pairing_registration.png">
                                                </div>
                                                <p class="text-left">Введите в адресной строке браузера: "http://192.168.4.1:9000/".
                                                  <br>Откроется сервисное меню хаба. Перейдите во вкладку "Регистрация".
                                                  <br>Выберите подходящий вам тип сети и нажмите "Зарегистрировать".</p>
                                                <p class="text-left text-muted">После нажатия кнопки хаб перейдет в рабочий режим и запустит процесс привязки хаба к объекту. Нажмите "Далее", чтобы следить за статусом привязки.</p>
                                                <a href="#myCarousel" @click.prevent="thing_registration_status" role="button" data-slide="next" style="text-decoration: none; float: right;"><div class="green-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px;">Далее >></div></a>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="advanced_settings === true" class="carousel-item">
                                            <div class="container">
                                              <div class="carousel-caption">
                                                <h1>Шаг 6. Подтверждение:</h1>
                                                <vue-simple-spinner v-if="bind_status == false" :speed="1.5" size="160" :line-size="14" line-fg-color="#8edc28" line-bg-color="#555555" class="m-5"/>
                                                <div v-if="bind_status == false"><p>Ждем привязки...</p></div>
                                                <div v-else>
                                                  <div class="img-wrapper mt-4 mb-4">
                                                    <img alt="focus" src="../assets/instr_mark2.png">
                                                  </div>
                                                  <p>Поздравляем! Привязка завершена успешно! Можете проверить вкладку <a href="/devices">"Устройства"</a>.</p>
                                                </div>
                                                <a href="#" @click.prevent="toggleBindForm" style="text-decoration: none;"><div class="green-button" style="width: 250px; height: 45px; font-size: 18px; padding-top: 10px; display: inline-block;">Завершить</div></a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="backdrop" v-if="croppa_show == true">
          <PhotoUploader @close="closeModal" @upload_image="upload_facility_image"></PhotoUploader>
        </div>
    </div>
</template>

<script>
    // import { userService } from '../_services';
    import {facilitiesService, thingsService, invitesService} from '../_services';
    // import navbar from './Navbar.vue'
    import VueSimpleSpinner from 'vue-simple-spinner';
    import {employeesService} from "../_services/employees.service";
    import PhotoUploader from './PhotoUploader.vue';

    export default {
        name: 'EditPage',
        components: {
            // navbar,
            VueSimpleSpinner,
            PhotoUploader
        },
        data () {
            return {
              // facilityName: '',
              facilityId: '',
              facility: {},
              devices: [],
              new_serial_number: '',
              reqId: '',
              device_bind_toggle: false,
              bind_status: false,
              advanced_settings: false,
              employee_add_toggle: false,
              employees: [],
              email: '',
              role: 'Employee',
              invites: [],
              selectedRole: 'Employee',
              roleList: [],
              croppa_show: false,
              facilityImage: null
            }
        },
        created () {
            // this.user = JSON.parse(localStorage.getItem('user'));
            // this.getProfile();
            // this.getFacilityList();
            // console.log('TRYING...................')
            this.facilityId = this.$route.params.id;
            // console.log(this.$route.params.id);
            if (this.facilityId) {
                this.getFacility();
                this.getEmployees();
                this.getThingList(this.facilityId)
                this.get_facility_photo();
            }

            // this.getThingList(this.facilityId)
            // this.get_facility_photo();
            // this.getFacilityInvites()
        },
        methods: {
            init() {
                // console.log('initializing')
            },
            closeModal() {
              this.croppa_show = false;
            },
            upload_facility_image(blob, filename) {
              console.log(filename);
              console.log(blob);

              // let res = userService.updateProfilePhoto(blob, filename);
              let res = facilitiesService.updateFacilityPhoto(this.facilityId, blob, filename);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res);

              this.croppa_show = false;

              this.get_facility_photo();
            },
            async get_facility_photo() {
              let res = await facilitiesService.getFacilityPhoto(this.facilityId);

              if (res.error) {
                console.error(res.error);

                return;
              }

              if (res.message) {
                console.log(res.message);
              } else {
                this.facilityImage = URL.createObjectURL(res);
              }
            },
            confirm() {
              // window.location.href = '/'
              this.$router.push({ name: 'main' })
            },
            async addFacility() {
              if (!this.facility.name) {
                alert('Введите название объекта');
                return
              }

              let res = await facilitiesService.facility_add(this.facility);

              // console.log(res)

              if (res.error) {
                console.error(res.error);

                return;
              }

              // window.location.href = '/'
              this.$router.push({ name: 'main' })
            },
            async editFacility() {
              // console.log('this is editing');
                let res = await facilitiesService.facility_edit(this.facilityId, this.facility);

                // console.log(res)

                if (res.error) {
                    console.error(res.error);

                    return;
                }

                // window.location.href = '/'
                this.$router.push({ name: 'main' })
            },
            async getFacility() {
              let res = await facilitiesService.facility_get(this.facilityId);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // this.facility = res.result.facility.name;
              this.facility = res.result.facility;
            },
            async back() {
                // console.log('delete if id is exist');
                if (this.facilityId) {
                    await this.deleteFacility(this.facilityId)
                }
                // window.location.href = '/';
                this.$router.push({ name: 'main' })
            },
            async deleteFacility(facility_id) {
                if (facility_id === '5f3e2982b669a6b7b377d6fe') {
                    alert('Вам нельзя удалять этот объект!');
                } else {
                    let res = await facilitiesService.facility_delete(facility_id);

                    if (res.error) {
                      console.error(res.error);

                      return;
                    }
                }
            },
            async getThingList(facility_id) {
                let res = await thingsService.things_get(facility_id);

                if (res.error) {
                  console.error(res.error);

                  return;
                }

                // console.log(res.result.items);

                this.devices = res.result.items;
            },
            // pad(num, size) {
            //     num = num.toString();
            //     while (num.length < size) num = "0" + num;
            //     return num;
            // },
            async registerHub() {
              // alert(this.new_serial_number);
              this.bind_status = false;

              let serial_number = this.new_serial_number;

              if (this.new_serial_number[0] === 'A' && this.new_serial_number[1] === 'H' && this.new_serial_number[2] === 'A') {
                serial_number = this.new_serial_number;
              } else {
                // serial_number = 'AHA' + this.new_serial_number.padStart(6, '0');
                serial_number = 'AHA' + parseInt(this.new_serial_number, 10);
              }
              console.log(serial_number)

              let res = await thingsService.things_registration(this.facilityId, serial_number);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res);

              this.reqId = res.result.reqId

              // alert(this.reqId)

              await this.thing_registration_status()
            },
            toggleBindForm() {
              this.device_bind_toggle = !this.device_bind_toggle;
              this.employee_add_toggle = false;
              // console.log(this.device_bind_toggle)
              // alert(this.device_bind_toggle)
            },
            async thing_registration_status() {

              function delay(t) {
                return new Promise(resolve => setTimeout(resolve, t));
              }

              let res;
              do {
                await delay(2000);
                let result = await thingsService.thing_registration_status(this.facilityId, this.reqId)
                // console.log(result.result.state);
                res = result.result.state;
              } while(res != 'RESOLVED');

              this.bind_status = true;

              await this.getThingList(this.facilityId);
            },
            showAdvancedSettings() {
              this.advanced_settings = true;
            },
            add_show() {
              this.employee_add_toggle = !this.employee_add_toggle;
              this.device_bind_toggle = false;

              this.getRoles()
              this.getFacilityInvites()
            },
            async sendInvite() {
                let res = await invitesService.invite_send(this.facilityId, this.email, this.role);

                if (res.error) {
                  console.error(res.error);

                  return;
                }

                // console.log(res);
                //
                // alert(res);

                await this.getFacilityInvites();
            },
            async getFacilityInvites() {
              let res = await invitesService.invites_to_facility_get(this.facilityId);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res);

              this.invites = res.result.items;
            },
            async deleteFacilityInvite(inviteId) {
              let res = await invitesService.invite_delete(this.facilityId, inviteId);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res);

              await this.getFacilityInvites();
            },
            async getEmployees() {
              let res = await employeesService.employees_get(this.facilityId);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res);

              this.employees = res.result.items;
            },
            async getRoles() {
              // let res = await rolesService.roles_get(this.facilityId);
              //
              // if (res.error) {
              //   console.error(res.error);
              //
              //   return;
              // }
              this.roleList = [{id: "Admin", text: "Admin"}, {id: "Employee", text: "Employee"}]
            },
            get_role_translate(roleText) {
              if (roleText === 'Admin') {
                return 'Адинистратор'
              } else if (roleText === 'Employee') {
                return 'Сотрудник'
              } else if (roleText === 'Owner') {
                return 'Владелец'
              } else {
                return roleText
              }
            },
            edit_thing(param) {
              alert(param);
            },
            async delete_thing(thing_id) {
                let res = await thingsService.thing_delete(this.facilityId, thing_id);

                if (res.error) {
                  console.error(res.error);

                  return;
                }

                // console.log(res.result);

                // this.devices = res.result.items;

                await this.getThingList(this.facilityId);
            },
            getRoleText(role_id) {
              // console.log(role_id);

              let values = {
                'Owner': 'Владелец',
                'Admin': 'Админ',
                'Employee': 'Сотрудник'
              }

              return values[role_id];
            },
            async delete_employee(employee_id) {
              let res = await employeesService.employee_delete(this.facilityId, employee_id);

              if (res.error) {
                console.error(res.error);

                return;
              }

              // console.log(res.result);

              // this.devices = res.result.items;

              // await this.getThingList(this.facilityId);
            },
            getDeviceTypeName(thing_type) {
              let values = {
                'AgaveHub': 'AGAVE-4G',
                'RepeyBase': 'РЕПЕЙ',
                'Laurel': 'LAUREL-12',
                'IrisMi': 'ИРИС-МИ',
                'IrisO': 'ИРИС-О',
                'ModbusDevice': 'Устройство ModBus',
                'BZP02': 'БЗП-02',
                'Lyutik': 'ЛЮТИК',
                'BZP01': 'БЗП-01',
                'AlteyBZP': 'Алтей-БЗП',
                'AlteyOZT': 'Алтей-ОЗТ',
                'AlteyPLK': 'Алтей-ПЛК',
                'AlteyUZT': 'Алтей-УЗТ',
                'Cactus': 'Кактус',
                'LimePlus': 'Лайм+'
              }

              return values[thing_type];
            }
        }
    };
</script>

<style>
    .editform {
        /*min-height: 150px;*/
        /*max-height: 200px;*/
        /*border: 2px solid red;*/
        background: #202020 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        font-family: "Segoe UI Light";
        font-size: 20px;
        letter-spacing: 0px;
        color: #FFFFFF;
        /*padding: 10px;*/
        margin: 10px;
    }
    .device-bind-form {
      background: #202020 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      opacity: 1;
      font-family: "Segoe UI Light";
      font-size: 8px;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin: 10px;
      padding: 10px;
      margin-top: 75px;
    }
    .editform:hover {
        background: #2E2E2E 0% 0% no-repeat padding-box;
    }
    .form-edit {
        background: #000000 0% 0% no-repeat padding-box;
        /*width: 65%;*/
        border: #000000;
        font-family: "Segoe UI Light";
        font-size: 30px;
        color: #FFFFFF;
        height: 50px;
    }
    .form-edit:valid {
        background: #000000 0% 0% no-repeat padding-box;
        color: #FFFFFF;
    }
    .row {
        margin: 0px;
    }
    .btn-add {
        background-color: #8EDC28;
    }
    .green-button {
        background-color: #8EDC28;
        border-radius: 50px;
        color: #000000;
        font-family: "Segoe UI Regular";
        font-size: 16px;
        padding: 5px;
    }
    .green-button:hover {
        opacity: 0.8;
        cursor: pointer;
    }
    .gray-button {
      background-color: #7e7e7e;
      border-radius: 50px;
      color: #000000;
      font-family: "Segoe UI Regular";
      font-size: 16px;
      padding: 5px;
    }
    .gray-button:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    .devices-div {
        color: #aaaaaa;
        text-align: center;
        vertical-align: middle;
    }
    .device-div {
        /*margin: 5px;*/
        /*padding: 1px;*/
    }
    .modal-div {
      position: absolute;
      top: 370px;
      left: 850px;
      background-color: #9a1616;
      width: 300px;
      height: 200px;
      opacity: 1;
      border-radius: 10px;
      padding: 15px;
      /*font-family: "Mont Demo";*/
      font-family: "Comfortaa Light";
      font-size: 28px;
      color: #fff;
      z-index: 99999;
      text-align: center;
      /*vertical-align: middle;*/
      /*line-height: 45px;*/
      display: flex;
      justify-content: center;
      align-items: center;
    }
    /* GLOBAL STYLES
  -------------------------------------------------- */
    /* CUSTOMIZE THE CAROUSEL
    -------------------------------------------------- */

    /* Carousel base class */
    .carousel {
      /*width: 600px;*/
      width: 100%;
      /*margin-bottom: 4rem;*/
    }
    /* Since positioning the image, we need to help out the caption */
    .carousel-caption {
      bottom: 4rem;
      z-index: 10;
    }

    /* Declare heights because of positioning of img element */
    .carousel-item {
      height: 34rem;
      /*background-color: #777;*/
    }
    /*.carousel-item > img {*/
    /*  position: absolute;*/
    /*  top: 0;*/
    /*  left: 0;*/
    /*  min-width: 100%;*/
    /*  height: 32rem;*/
    /*}*/

    /* RESPONSIVE CSS
    -------------------------------------------------- */

    @media (min-width: 40em) {
      /* Bump up size of carousel content */
      .carousel-caption p {
        margin-bottom: 0.8rem;
        font-size: 1.00rem;
        line-height: 1.4;
      }

      .featurette-heading {
        font-size: 50px;
      }
    }

    @media (min-width: 62em) {
      .featurette-heading {
        margin-top: 7rem;
      }
    }
    .img-wrapper {
      /*width: 400px;*/
      height: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 8px;
    }
    .img-wrapper img {
      height: 100%;
      width: auto;
    }
    .circle-30.add-employee {
      background: url(../assets/plus-black.svg) no-repeat center center #8EDC28;
    }
    .add-employee-form {
      background: #202020 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      opacity: 1;
      font-family: "Segoe UI Light";
      font-size: 18px;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin: 10px;
      padding: 15px;
      margin-top: 75px;
    }
    .facility-img {
      background-image: url("../assets/photo.svg");
      background-position: center;
      background-repeat: no-repeat;
      border: 2px solid white;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      cursor: pointer;
      /*position: absolute;*/
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .container {
      max-width: 1920px;
      width: 100%;
    }
    .hover:hover {
      cursor: pointer;
    }
    .delete-button {
      margin-left: 5px;
      margin-right: 5px;
      background: url(../assets/delete.svg) no-repeat center center;
      width: 17px;
      height: 18px;
      background-size: cover;
      opacity: 0.9;
    }
    .delete-button:hover {
      transform: scale(1.1);
      opacity: 1;
    }
    .edit-button {
      margin-left: 5px;
      margin-right: 5px;
      background: url(../assets/pencil.svg) no-repeat center center;
      width: 17px;
      height: 18px;
      background-size: cover;
      opacity: 0.9;
    }
    .edit-button:hover {
      transform: scale(1.1);
      opacity: 1;
    }
    .thing-link {
      position: relative;
      /*height: 80px;*/
      display: block;
      font-family: "Segoe UI Light";
      /*font-size: 30px;*/
      letter-spacing: 0px;
      color: #FFFFFF;
      text-decoration: none;
    }
  .thing-div {
    display: flex;
    min-width: 0;
    justify-content: space-between;
  }
  .name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .buttons {
    flex-shrink: 0;
  }
  .circle-30.confirm-toggle {
    background: url(../assets/confirm-30.svg) no-repeat center center #8EDC28;
  }
  .small-text {
    font-size: 16px;
  }
  .crown {
    width: 12px;
    height: 12px;
    /*background: url(../assets/crown.svg) no-repeat center center #8EDC28;*/
    background: url(../assets/crown.svg) no-repeat center center;
    background-size: cover;
    margin-right: 6px;
    margin-bottom: 5px;
    filter: invert(98%) sepia(11%) saturate(6479%) hue-rotate(26deg) brightness(97%) contrast(78%);
  }
  .carousel-indicators,
  .carousel-indicators li {
    cursor: default;
    pointer-events: none;
  }
  .edit-logo2 {
    position: relative;
    z-index: 9999;
    height: 34px;
    width: 34px;
    border-radius: 50px;
    /* background: url(../assets/small-pencil.svg) no-repeat center center rgba(222, 222, 222, 0.4); */
    top: -18px !important;
    left: 162px !important;
    background: url(../assets/small-pencil.svg) no-repeat center center rgba(256, 256, 256, 1);
  }
  .edit-logo2:hover {
    background: url(../assets/small-pencil.svg) no-repeat center center rgba(200, 200, 200, 1);
    cursor: pointer;
  }
</style>
