import { API_LOCATION } from '../config';
import { authHeader } from '../_helpers';
import { responseHandler } from '../_services';

export const journalsService = {
  journals_get
};

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]) + '&');
    }
    return keyValuePairs;
  }

async function journals_get(thingId, args) {
  let queryString = '';

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  if (args.types) {
    queryString += objToQueryString({
      types: args.types
    });
  }

  if (args.timestampGte) {
    queryString += objToQueryString({
      timestampGte: args.timestampGte
    });
  }

  if (args.timestampLte) {
    queryString += objToQueryString({
      timestampLte: args.timestampLte
    });
  }

  if (args.start) {
    queryString += objToQueryString({
      start: args.start
    });
  }

  if (args.limit) {
    queryString += objToQueryString({
      limit: args.limit
    });
  }

  // return fetch(API_LOCATION + `/facilities/${facilityId}/things/${thingId}/journals?${queryString}` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
  return fetch(API_LOCATION + `/things/${thingId}/journals?${queryString}` + new URLSearchParams({lang: 'ru',}), requestOptions).then(responseHandler.handleResponse);
}
