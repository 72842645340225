<template>
    <div>
        <!-- <navbar></navbar> -->
        <div class="container">

            <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
            <div v-else>

                <div class="d-flex pt-2" style="padding-left: 20px;">
                    <div class="back-img mt-4 mr-3">
                        <a @click="$router.go(-1)" class="router-link"><img alt="back" src="../assets/arrow_back.svg" class="flexedImage"></a>
                    </div>
                    <h5 class="card-title mb-0">{{ facility.name }}</h5>
                </div>

                <div class="row">
                    <div class="col-sm-6 mb-3">
                        <div class="card card-second transparent mt-0">
                            <div class="card-body pb-0">
                                <div class="d-flex flex-row bd-highlight">

                                    <div class="mr-auto bd-highlight">
                                        <h1 class="card-second-title">{{ $t('thingspage.equipment') }}:</h1>
                                    </div>

                                    <div v-if="role.id == 'Owner' || role.id == 'Admin'" class="align-self-center mb-2">
                                        <div v-if="equipment_edit_toggle === false" class="circle-30 edit-equipment" @click.prevent="equipment_edit_toggle = !equipment_edit_toggle"></div>
                                        <div v-else class="circle-30 confirm-equipment" @click.prevent="equipment_edit_toggle = !equipment_edit_toggle"></div>
                                    </div>

                                    <div v-if="role.id == 'Owner' || role.id == 'Admin'" class="align-self-center mb-2 ml-3">
                                        <div v-if="equipment_add_toggle === false" class="circle-30 add-equipment" @click.prevent="equipment_add_toggle = !equipment_add_toggle"></div>
                                        <div v-else class="circle-30 close-add-equipment" @click.prevent="equipment_add_toggle = !equipment_add_toggle"></div>
                                    </div>

                                    <div v-if="show_all_toggle === false" class="circle-30 down-equipment align-self-center mb-2 ml-3" @click.prevent="showAll()" style="flex-shrink: 0;"></div>
                                    <div v-else class="circle-30 left-equipment align-self-center mb-2 ml-3" @click.prevent="showAll()" style="flex-shrink: 0;"></div>

                                </div>

                                <div v-if="equipment_add_toggle">
                                    <form class="form-signin d-flex" @submit.prevent="addEquipment">
                                        <input type="text" v-model="new_equipment_name" class="form-control form-edit" placeholder="Название оборудования" required="true"/>
                                        <button type="submit" class="btn btn-link p-0 ml-2"><div class="circle confirm"></div></button>
                                    </form>
                                </div>

                                <div class="overflow-auto subjects-div scroller pr-2">
                                    <div v-if="equipmentlist.length == 0">
                                        <div class="equipment-div mb-3">
                                            <label style="font-size: 18px; text-align: center; vertical-align: middle;" class="mt-1">
                                                На этом объекте не создано оборудования, нажмите "+" чтобы создать. Введите название, к примеру: "Батарея №1" или "Шкаф БЗШ1", и нажмите кнопку подтверждения.
                                            </label>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-for="equipment in equipmentlist" :key="equipment.name">
                                            <div v-if="equipment_edit_toggle">
                                                <div class="equipment-div mb-3">

                                                    <div class="d-flex mt-2">
                                                        <!-- <img alt="repey_online" src="../assets/green_circle.svg" class="align-self-center mr-2 ml-2"> -->

                                                        <img v-if="equipment.status === 'warning'" alt="repey_online" src="../assets/yellow_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else-if="equipment.status === 'critical'" alt="repey_online" src="../assets/red_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else-if="equipment.status === 'normal'" alt="repey_online" src="../assets/green_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else alt="repey_online" src="../assets/gray_circle.svg" class="align-self-center mr-2 ml-2">

                                                        <span class="wrap-span" style="margin-top: 3px;">{{ equipment.name }}</span>
                                                        <div class="circle delete mr-2 ml-auto" @click.prevent="deleteEquipment(equipment.id)" style="flex-shrink: 0;"></div>
                                                        <!-- <a :href="'/facility/' + facility.id + '/equipments/' + equipment.id + '/edit'"><div class="circle edit ml-2 mr-2"></div></a> -->
                                                        <div class="circle edit ml-2 mr-2" @click="$router.push({ name: 'edit_equipment', params: { facility_id:facility.id, equipment_id: equipment.id } })"></div>
                                                        <div v-if="equipment.things && equipment.things.length != 0" class="align-self-center p-2">
                                                            <div v-if="equipment.show" class="circle-30 down-equipment" @click.prevent="getEquipmentThings(equipment.id)" style="float: right;"></div>
                                                            <div v-else class="circle-30 left-equipment" @click.prevent="getEquipmentThings(equipment.id)" style="float: right;"></div>
                                                        </div>
                                                    </div>

                                                    <div v-if="equipment.show">
                                                        <div v-for="equipment_item in equipment.things" :key="equipment_item.id" class="m-2">
                                                            <div class="thing-flex-div mt-3 mb-2 d-flex align-items-center" style="width:auto">
                                                                <div v-if="equipment_item.type.id == 'RepeyBase'">
                                                                    <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                        <div v-if="equipment_item.shadow.logical_outputs_signals_state && equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154">
                                                                            <img v-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0'" alt="repey_online" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                            <img v-else-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1'" alt="repey_online" src="../assets/red_circle.svg" class="mr-2 ml-3">
                                                                            <img v-else alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                        </div>
                                                                    </div>
                                                                    <img v-else alt="repey_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                </div>
                                                                <div v-else-if="equipment_item.type.id == 'Laurel'">
                                                                    <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                        <img v-if="equipment_item.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                        <img v-else alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                    <div v-else>
                                                                        <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                </div>

                                                                <div v-else>
                                                                    <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                        <img alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                    <div v-else>
                                                                        <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                </div>

                                                                <!-- <span class="wrap-span">{{ getDeviceTypeName(equipment_item.type.id) }}<span class="serial-small"> (№ {{(equipment_item.serial).substring(3) }})</span></span> -->
                                                                <span class="wrap-span">{{ (equipment_item.type.id !== 'ModbusDevice') ? getDeviceTypeName(equipment_item.type.id) : equipment_item.name }}<span class="serial-small"> (№ {{(equipment_item.serial).substring(3) }})</span></span>

                                                                <div class="circle delete mr-2 ml-auto" @click.prevent="deleteThing(equipment_item.id)" style="flex-shrink: 0;"></div>
                                                                <!-- <a :href="'/facility/' + facility.id + '/things/' + equipment_item.id + '/edit'" class="disabled"><div class="circle edit ml-2 mr-2" style="-webkit-filter: grayscale(1); opacity: 0.4;"></div></a> -->
                                                                <!-- <a v-if="equipment_item.type.id != 'AgaveHub'" :href="'/facility/' + facility.id + '/things/' + equipment_item.id + '/edit'"><div class="circle edit ml-2 mr-2"></div></a> -->
                                                                <a v-if="equipment_item.type.id != 'AgaveHub'" @click="$router.push({ name: 'edit_thing', params: { facility_id: facility.id, thing_id: equipment_item.id }})"><div class="circle edit ml-2 mr-2"></div></a>
                                                                <div class="circle untie ml-2 mr-2" @click.prevent="untieThing(equipment_item.id)" style="flex-shrink: 0;"></div>
                                                                <v-select v-if="equipment_item.type.id == 'Laurel'" class="style-chooser ml-2 mr-2" :clearable="false" :options="group_options" @input="onSelectChangeGroup($event, equipment_item)" value="Группа" v-model="equipment_item.group" label="name"><span slot="no-options">Нет доступных групп</span></v-select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="equipment-div mb-3">
                                                    <div class="d-flex mt-2">
                                                        <!-- <img alt="repey_online" src="../assets/green_circle.svg" class="align-self-center mr-2 ml-2"> -->

                                                        <img v-if="equipment.status === 'warning'" alt="repey_online" src="../assets/yellow_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else-if="equipment.status === 'critical'" alt="repey_online" src="../assets/red_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else-if="equipment.status === 'normal'" alt="repey_online" src="../assets/green_circle.svg" class="align-self-center mr-2 ml-2">
                                                        <img v-else alt="repey_online" src="../assets/gray_circle.svg" class="align-self-center mr-2 ml-2">

                                                        <span class="wrap-span" style="margin-top: 3px;">{{ equipment.name }}</span>
                                                        <div v-if="equipment.things && equipment.things.length != 0" class="align-self-center ml-auto p-2">
                                                            <div v-if="equipment.show" class="circle-30 down-equipment" @click.prevent="getEquipmentThings(equipment.id)" style="float: right;"></div>
                                                            <div v-else class="circle-30 left-equipment" @click.prevent="getEquipmentThings(equipment.id)" style="float: right;"></div>
                                                        </div>
                                                    </div>
                                                    <div v-if="equipment.show">
                                                        <div v-for="equipment_item in equipment.things" :key="equipment_item.id" class="m-2">
                                                            <!-- <a class="div-link" :href="'/facility/' + facility.id + '/things/' + equipment_item.id"> -->
                                                            <a class="div-link" @click="$router.push({ name: 'thing', params: { facility_id: facility.id, thing_id: equipment_item.id }})">
                                                                <div class="thing-flex-div mt-3 mb-2 d-flex align-items-center" style="width:auto">

                                                                    <!-- <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                        <img v-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0'" alt="repey_online" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                        <img v-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1'" alt="repey_online" src="../assets/red_circle.svg" class="mr-2 ml-3">
                                                                        <img v-else alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                    <img v-else alt="repey_online" src="../assets/gray_circle.svg" class="mr-2 ml-3">

                                                                    <img v-if="equipment_item.shadow && equipment_item.shadow.online" alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                    <img v-else alt="repey_online" src="../assets/gray_circle.svg" class="mr-2 ml-3">-->

                                                                    <div v-if="equipment_item.type.id == 'RepeyBase'">
                                                                        <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                            <div v-if="equipment_item.shadow.logical_outputs_signals_state && equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154">
                                                                                <img v-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0'" alt="repey_online" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                                <img v-else-if="equipment_item.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1'" alt="repey_online" src="../assets/red_circle.svg" class="mr-2 ml-3">
                                                                                <img v-else alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                            </div>
                                                                        </div>
                                                                        <img v-else alt="repey_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                    <div v-else-if="equipment_item.type.id == 'Laurel'">
                                                                        <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                            <img v-if="equipment_item.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                            <img v-else alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                        </div>
                                                                        <div v-else>
                                                                            <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                        </div>
                                                                    </div>

                                                                    <div v-else>
                                                                        <div v-if="equipment_item.shadow && equipment_item.shadow.online">
                                                                            <img alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                        </div>
                                                                        <div v-else>
                                                                            <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                        </div>
                                                                    </div>

                                                                    <!-- <span class="wrap-span">{{ getDeviceTypeName(equipment_item.type.id) }}<span class="serial-small"> (№ {{(equipment_item.serial).substring(3) }})</span></span> -->
                                                                    <span class="wrap-span">{{ (equipment_item.type.id !== 'ModbusDevice') ? getDeviceTypeName(equipment_item.type.id) : equipment_item.name }}<span class="serial-small"> (№ {{(equipment_item.serial).substring(3) }})</span></span>

                                                                    <div v-if="equipment_item.type.id == 'RepeyBase'" class="ml-auto d-flex align-items-center justify-content-center" style="border-left: 1px solid black; width: 160px; margin-top: -17px; padding-top: 17px; margin-bottom: -17px; padding-bottom: 17px;">
                                                                        <div class="background-text unselectable">
                                                                            <span style="position: relative; left: 35px;">U</span>
                                                                        </div>
                                                                        <span v-if="equipment_item.shadow && equipment_item.shadow.online && equipment_item.shadow.values_in_physical_units && equipment_item.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981" class="inwrap-span" style="z-index: 1"> {{ (equipment_item.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                                                                        <span v-else class="inwrap-span" style="z-index: 2"> --.- V</span>
                                                                    </div>
                                                                    <div v-if="equipment_item.type.id == 'RepeyBase'" class="d-flex align-items-center justify-content-center" style="border-left: 1px solid black; width: 160px; margin-top: -17px; padding-top: 17px; margin-bottom: -17px; padding-bottom: 17px;">
                                                                        <div class="background-text unselectable">
                                                                            <span style="position: relative; left: 35px;">I</span>
                                                                        </div>
                                                                        <span v-if="equipment_item.shadow && equipment_item.shadow.online && equipment_item.shadow.values_in_physical_units && equipment_item.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983" class="inwrap-span" style="z-index: 1"> {{ (equipment_item.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                                                                        <span v-else class="inwrap-span" style="z-index: 2"> --.-- A</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-sm-6 order-first order-md-last"> -->
                    <div class="col-sm-6">
                        <div class="card card-second transparent mt-0">
                            <div class="card-body pb-0">
                                <div class="d-flex flex-row bd-highlight">

                                    <div class="mr-auto bd-highlight">
                                        <h1 class="card-second-title">{{ $t('thingspage.devices') }}:</h1>
                                    </div>

                                    <div v-if="role.id == 'Owner' || role.id == 'Admin'" class="align-self-center mb-2">
                                        <div v-if="things_edit_toggle === false" class="circle-30 edit-equipment" @click.prevent="things_edit_toggle = !things_edit_toggle"></div>
                                        <div v-else class="circle-30 confirm-equipment" @click.prevent="things_edit_toggle = !things_edit_toggle"></div>
                                    </div>

                                    <div v-if="role.id == 'Owner' || role.id == 'Admin'" class="align-self-center mb-2 ml-3">
                                        <a @click="$router.push({ name: 'edit_facility', params: { id:facility.id }})"><div class="circle-30 add-equipment"></div></a>
                                        <!-- <div class="circle edit m-2" @click="$router.push({ name: 'edit_facility', params: { id:facility.facility.id }})"></div> -->
                                    </div>

                                </div>

                                <div class="overflow-auto subjects-div scroller pr-2">

                                    <div v-if="thinglist.length == 0">
                                        <div class="equipment-div mb-3">
                                            <label style="font-size: 18px; text-align: center; vertical-align: middle;">
                                                К этому объекту не привязано ни одного устройства. Вы можете привязать устройства на странице "<a :href="'/facility/edit/' + facility.id">Редактирование объекта</a>".
                                            </label>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-for="(thing, i) in thinglist" :key="thing.id">
                                            <div v-if="!thing.equipment">
                                                <div v-if="things_edit_toggle">
                                                    <div class="thing-flex-div mb-3 d-flex align-items-center" style="width:auto">

                                                        <div v-if="thing.type.id == 'RepeyBase'">
                                                            <div v-if="thing.shadow && thing.shadow.online">
                                                                <div v-if="thing.shadow.logical_outputs_signals_state && thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154">
                                                                    <img v-if="thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0'" alt="repey_online" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                    <img v-else-if="thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1'" alt="repey_online" src="../assets/red_circle.svg" class="mr-2 ml-3">
                                                                    <img v-else alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                </div>
                                                            </div>
                                                            <img v-else alt="repey_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                        </div>

                                                        <div v-else-if="thing.type.id == 'Laurel'">
                                                            <div v-if="thing.shadow && thing.shadow.online">
                                                                <img v-if="thing.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                <img v-else alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                            </div>
                                                            <div v-else>
                                                                <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                            </div>
                                                        </div>

                                                        <div v-else>
                                                            <div v-if="thing.shadow && thing.shadow.online">
                                                                <img alt="thing_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                            </div>
                                                            <img v-else alt="thing_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                        </div>

                                                        <span v-if="thing.type.id === 'AgaveHub'" class="wrap-span">{{ getDeviceTypeName(thing.type.id) }}<span class="serial-small"> (№ {{ parseInt((thing.serial).substring(3), 10) }})</span></span>
                                                        <span v-else class="wrap-span">{{ (thing.type.id !== 'ModbusDevice') ? getDeviceTypeName(thing.type.id) : thing.name }}<span class="serial-small"> (№ {{(thing.serial).substring(3) }})</span></span>

                                                        <div class="d-flex align-items-center justify-content-end ml-auto" style="border-left: 1px solid black; min-width: 360px; margin-top: -17px; padding-top: 17px; margin-bottom: -17px; padding-bottom: 17px; padding-left: 10px;">
                                                            <div v-if="thinglist[i].equipment">
                                                                <v-select v-if="thing.type.id != 'AgaveHub'" class="style-chooser" label="name" :clearable="false" :options="options" v-model="thinglist[i].equipment.name" @input="onSelectChange($event, thing)"><span slot="no-options">Нет оборудования</span></v-select>
                                                            </div>
                                                            <div v-else>
                                                                <div class="d-flex align-items-center justify-content-end">

                                                                    <div class="circle delete mr-2 ml-auto" @click.prevent="deleteThing(thing.id)" style="flex-shrink: 0;"></div>
                                                                    <!-- <a v-if="thing.type.id != 'AgaveHub'" :href="'/facility/' + facility.id + '/things/' + thing.id + '/edit'"><div class="circle edit ml-2 mr-2"></div></a> -->
                                                                    <a v-if="thing.type.id != 'AgaveHub'" @click="$router.push({ name: 'edit_thing', params: { facility_id: facility.id, thing_id: thing.id }})"><div class="circle edit ml-2 mr-2"></div></a>
                                                                    <v-select v-if="thing.type.id != 'AgaveHub'" class="style-chooser ml-2 mr-2" label="name" :clearable="false" :options="options" @input="onSelectChange($event, thing)" value="Оборудование"><span slot="no-options">Нет оборудования</span></v-select>
                                                                    <v-select v-if="thing.type.id == 'Laurel'" class="style-chooser ml-2 mr-2" :clearable="false" :options="group_options" @input="onSelectChangeGroup($event, thing)" value="Группа" v-model="thing.group" label="name"><span slot="no-options">Нет доступных групп</span></v-select>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <!-- <a class="div-link" :href="'/facility/' + facility.id + '/things/' + thing.id"> -->
                                                    <a class="div-link" @click="$router.push({ name: 'thing', params: { facility_id: facility.id, thing_id: thing.id }})">
                                                        <div class="thing-flex-div mb-3 d-flex align-items-center" style="width:auto">

                                                            <div v-if="thing.type.id == 'RepeyBase'">
                                                                <div v-if="thing.shadow && thing.shadow.online">
                                                                    <div v-if="thing.shadow.logical_outputs_signals_state && thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154">
                                                                        <img v-if="thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0'" alt="repey_online" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                        <img v-else-if="thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1'" alt="repey_online" src="../assets/red_circle.svg" class="mr-2 ml-3">
                                                                        <img v-else alt="repey_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                    </div>
                                                                </div>
                                                                <img v-else alt="repey_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                            </div>

                                                            <div v-else-if="thing.type.id == 'Laurel'">
                                                                <div v-if="thing.shadow && thing.shadow.online">
                                                                    <img v-if="thing.shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1'" alt="laurel_yellow" src="../assets/yellow_circle.svg" class="mr-2 ml-3">
                                                                    <img v-else alt="laurel_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                </div>
                                                                <div v-else>
                                                                    <img alt="laurel_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                                </div>
                                                            </div>

                                                            <div v-else>
                                                                <div v-if="thing.shadow && thing.shadow.online">
                                                                    <img alt="thing_online" src="../assets/green_circle.svg" class="mr-2 ml-3">
                                                                </div>
                                                                <img v-else alt="thing_offline" src="../assets/gray_circle.svg" class="mr-2 ml-3">
                                                            </div>

                                                            <span v-if="thing.type.id === 'AgaveHub'" class="wrap-span">{{ getDeviceTypeName(thing.type.id) }}<span class="serial-small"> (№ {{ parseInt((thing.serial).substring(3), 10) }})</span></span>
                                                            <span v-else class="wrap-span">{{ (thing.type.id !== 'ModbusDevice') ? getDeviceTypeName(thing.type.id) : thing.name }}<span class="serial-small"> (№ {{(thing.serial).substring(3) }})</span></span>

                                                            <div v-if="thing.type.id == 'RepeyBase'" class="ml-auto d-flex align-items-center justify-content-center" style="border-left: 1px solid black; width: 160px; margin-top: -17px; padding-top: 17px; margin-bottom: -17px; padding-bottom: 17px;">
                                                                <div class="background-text unselectable">
                                                                    <span style="position: relative; left: 35px;">U</span>
                                                                </div>
                                                                <span v-if="thing.shadow && thing.shadow.online && thing.shadow.values_in_physical_units && thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981" class="inwrap-span" style="z-index: 1"> {{ (thing.shadow.values_in_physical_units.U_battery_voltage_0x0980_0x0981).toFixed(1) }} V</span>
                                                                <span v-else class="inwrap-span" style="z-index: 2"> --.- V</span>
                                                            </div>
                                                            <div v-if="thing.type.id == 'RepeyBase'" class="d-flex align-items-center justify-content-center" style="border-left: 1px solid black; width: 160px; margin-top: -17px; padding-top: 17px; margin-bottom: -17px; padding-bottom: 17px;">
                                                                <div class="background-text unselectable">
                                                                    <span style="position: relative; left: 35px;">I</span>
                                                                </div>
                                                                <span v-if="thing.shadow && thing.shadow.online && thing.shadow.values_in_physical_units && thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983" class="inwrap-span" style="z-index: 1"> {{ (thing.shadow.values_in_physical_units.I_battery_amperage_0x0982_0x0983).toFixed(2) }} A</span>
                                                                <span v-else class="inwrap-span" style="z-index: 2"> --.-- A</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Vue from 'vue';
  import vSelect from 'vue-select';
  // import navbar from './Navbar.vue'
  import {thingsService, facilitiesService, equipmentsService} from "../_services";
  import VueSimpleSpinner from 'vue-simple-spinner'

  Vue.component('v-select', vSelect)

  import 'vue-select/dist/vue-select.css';

  export default {
    name: 'ThingsPage',
    components: {
      // navbar,
      VueSimpleSpinner
    },
    data() {
      return {
        preloading: false,
        facilityId: this.$route.params.id,
        facility: '',
        thinglist: [],
        equipmentlist: [],
        options: [],
        equipment_add_toggle: false,
        new_equipment_name: '',
        equipment_items: [],
        equipment_edit_toggle: false,
        show_all_toggle: false,
        things_edit_toggle: false,
        things_add_toggle: false,
        role: '',
        // group_options: [('group_1', 'Группа №1'), ('group_2', 'Группа №2')]
        // group_options: [{id: 'group_1', name: 'Группа №1'}, {id: 'group_2', name: 'Группа №2'}]
        group_options: [{id: '', name: '- Без группы -'}, {id: 'group_1', name: 'Группа №1'}, {id: 'group_2', name: 'Группа №2'}]
      }
    },
    async created() {
      this.init();
      this.preloading = true;
      await this.getFacility();
      await this.getEquipments();
      await this.getThingList();
      await this.generateEquipmentList();
      await this.generateOptionsEquipmentList();
      await this.showAll();
      this.preloading = false;

      setInterval(function () {
        this.getThingList();
        this.generateEquipmentList();
        // this.getEquipments();
      }.bind(this), 15000);
    },
    methods: {
      init() {
        // console.log('initializing');
      },
      async getThingList() {
        // this.thinglist = []

        let res = await thingsService.things_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thinglist = res.result.items;

        // console.log('ThingList: ');
        // console.log(this.thinglist);
      },
      async getFacility() {
        this.thinglist = []

        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.role = res.result.role;
        this.facility = res.result.facility;
      },
      async addEquipment() {
        let res = await equipmentsService.equipments_add(this.facilityId, this.new_equipment_name);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res.result)

        this.equipment_add_toggle = false;

        await this.getEquipments();
        await this.getThingList();
        await this.generateEquipmentList();
        await this.generateOptionsEquipmentList();
        await this.getThingList();
      },
      async getEquipments() {
        // console.log('!!!!!!!!!!!!!!!!!! getEquipments')

        let res = await equipmentsService.equipments_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.equipmentlist = res.result.items;
      },

      async generateEquipmentList() {
        for (let equipment in this.equipmentlist) {
          this.equipmentlist[equipment].status = 'normal';

          let equipment_things_statuses = [];

          this.equipmentlist[equipment].things = [];

          for (let thing in this.thinglist) {
            if (this.thinglist[thing].equipment) {
              if (this.equipmentlist[equipment].id === this.thinglist[thing].equipment.id) {

                // if (this.thinglist[thing].type.id === 'RepeyBase') {
                //   if (this.thinglist[thing].shadow.online) {
                //     if (this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0') {
                //       this.thinglist[thing].status = 'warning';
                //       equipment_things_statuses.push('warning');
                //     }
                //     if (this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1') {
                //       this.thinglist[thing].status = 'critical';
                //       equipment_things_statuses.push('critical');
                //     }
                //   } else {
                //     this.thinglist[thing].status = 'offline';
                //     equipment_things_statuses.push('offline');
                //   }
                // }

                let equipment_thing_status = await this.setThingStatuses(thing);

                equipment_things_statuses.push(equipment_thing_status);

                this.equipmentlist[equipment].things.push(this.thinglist[thing]);
              }

              this.$set(this.equipmentlist[equipment], 'things', this.equipmentlist[equipment].things)
            }
          }


          if (equipment_things_statuses.includes('warning')) {
            this.equipmentlist[equipment].status = 'warning';
          }
          if (equipment_things_statuses.includes('critical')) {
            this.equipmentlist[equipment].status = 'critical';
          }
          if ([...new Set(equipment_things_statuses)].length == 1 && [...new Set(equipment_things_statuses)].includes('offline')) {
            this.equipmentlist[equipment].status = 'offline';
          }
          if (equipment_things_statuses.length == 0) {
            this.equipmentlist[equipment].status = 'offline';
          }

          // alert('equipment_things_statuses:' + equipment_things_statuses.length);

        }

        // console.log(this.equipmentlist);
      },

      async setThingStatuses(thing) {
          let equipment_thing_status = 'normal';

          if (this.thinglist[thing].type.id === 'RepeyBase') {
              if (this.thinglist[thing].shadow.online) {
                  if (this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1' && this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '0') {
                      this.thinglist[thing].status = 'warning';
                      equipment_thing_status = 'warning';
                  }
                  if (this.thinglist[thing].shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1') {
                      this.thinglist[thing].status = 'critical';
                      equipment_thing_status = 'critical';
                  }
              } else {
                  this.thinglist[thing].status = 'offline';
                  equipment_thing_status = 'offline';
              }
          }

          if (this.thinglist[thing].type.id === 'Laurel') {
              if (this.thinglist[thing].shadow.online) {
                  // equipment_thing_status = 'normal';
                  if (this.thinglist[thing].shadow.logic_output_signals.logic_output_signal_0x0144[9] == '1' && equipment_thing_status != 'critical') {
                     this.thinglist[thing].status = 'warning';
                      equipment_thing_status = 'warning'; 
                  }
              } else {
                  equipment_thing_status = 'offline';
              }
          }

          if (this.thinglist[thing].type.id === 'IrisMi') {
              console.log('status setter for IrisMi handled');
              if (this.thinglist[thing].shadow.online && equipment_thing_status != 'critical' && equipment_thing_status != 'warning') {
                  equipment_thing_status = 'normal';
              } else {
                  equipment_thing_status = 'offline';
              }
          }

          if (this.thinglist[thing].type.id === 'BZP02') {
              console.log('status setter for BZP02 handled');
              if (this.thinglist[thing].shadow.online && equipment_thing_status != 'critical' && equipment_thing_status != 'warning') {
                  equipment_thing_status = 'normal';
              } else {
                  equipment_thing_status = 'offline';
              }
          }

          if (this.thinglist[thing].type.id === 'Lyutik') {
              console.log('status setter for Lyutik handled');
              if (this.thinglist[thing].shadow.online && equipment_thing_status != 'critical' && equipment_thing_status != 'warning') {
                  equipment_thing_status = 'normal';
              } else {
                  equipment_thing_status = 'offline';
              }
          }

          return equipment_thing_status;
      },

      async getEquipmentThings(equipmentId) {
        for (let equipment in this.equipmentlist) {
          if (this.equipmentlist[equipment].id === equipmentId) {

            if (this.equipmentlist[equipment].show) {
              Vue.set(this.equipmentlist[equipment], 'show', false);

              return;
            }

            Vue.set(this.equipmentlist[equipment], 'show', true);
          }
        }

        // // let queryData = 'equipments=5fb4ef053e7014bf42c6f9f9'
        // // let queryData = 'equipments=' + equipmentId;
        //
        // // let res = await thingsService.things_get(this.facilityId, queryData);
        // let res = await thingsService.things_get(this.facilityId, equipmentId);
        //
        // if (res.error) {
        //   console.error(res.error);
        //
        //   return;
        // }
        //
        // console.log(res.result.items)
        //
        // this.equipment_items = res.result.items;
        //
        // for (let equipment in this.equipmentlist) {
        //   if (this.equipmentlist[equipment].id === equipmentId) {
        //
        //
        //     if (this.equipmentlist[equipment].show) {
        //       Vue.set(this.equipmentlist[equipment], 'show', false);
        //
        //       return;
        //     }
        //
        //     Vue.set(this.equipmentlist[equipment], 'things', res.result.items);
        //
        //     Vue.set(this.equipmentlist[equipment], 'show', true);
        //   }
        // }
        //
        // console.log('vvvvvvvvvvvvvv' + this.thinglist)
        //
        // for (let th in this.thinglist) {
        //   console.log('nnnnnnnnnnnnn' + this.thinglist[th])
        //   console.log(this.thinglist[th].equipment)
        // }
      },
      async onSelectChange(value, thing) {
        // alert(value.id);
        // alert(thing.id);
        // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        // console.log(value);
        // console.log(thing);
        // alert(thing);
        // alert(thing.id);

        let equipmentId = value.id;
        let thingId = thing.id;

        let data = {"equipment": equipmentId}

        let res = await thingsService.thing_update(this.facilityId, thingId, data);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res.result)

        // await this.getEquipments();
        await this.getThingList();
        await this.generateEquipmentList();
        await this.generateOptionsEquipmentList();
        await this.getThingList();
      },
      async onSelectChangeGroup(value, thing) {
        // console.log(value);
        // console.log(value.id);
        // console.log(thing);

        let data = {'group': value}

        let res = await thingsService.thing_update(this.facilityId, thing.id, data)

        if (res.error) {
            console.error(res.error);

            return;
        }

        console.log(res);
      },
      async deleteEquipment(equipmentId) {
        let res = await equipmentsService.equipments_delete(this.facilityId, equipmentId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res);

        await this.getEquipments();

        await this.getThingList();
        await this.generateEquipmentList();
        await this.generateOptionsEquipmentList();
        await this.getThingList();
      },
      // async editEquipment(equipment_id) {
      //   alert(equipment_id);
      // },
      async getThingValues() {
        // console.log('sad');
      },
      async untieThing(thingId) {
        let data = {"equipment": null}

        let res = await thingsService.thing_update(this.facilityId, thingId, data);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res);

        // await this.getEquipments();
        await this.getThingList();
        await this.generateEquipmentList();
        await this.generateOptionsEquipmentList();
        await this.getThingList();
      },
      async deleteThing(thingId) {
        let res = await thingsService.thing_delete(this.facilityId, thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        // console.log(res);

        // await this.getEquipments();
        await this.getThingList();
        await this.generateEquipmentList();
        await this.generateOptionsEquipmentList();
        await this.getThingList();
      },
      // async editThing(thingId) {
      //   alert(thingId);
      // },
      async showAll() {
        if (this.show_all_toggle == false) {
          for (let equipment in this.equipmentlist) {
            Vue.set(this.equipmentlist[equipment], 'show', true);
          }

          this.show_all_toggle = true;

          return ;
        }

        if (this.show_all_toggle == true) {
          for (let equipment in this.equipmentlist) {
            Vue.set(this.equipmentlist[equipment], 'show', false);
          }

          this.show_all_toggle = false;

          return ;
        }
      },
      async generateOptionsEquipmentList() {
        // console.log('.............................................');
        // console.log(this.equipmentlist);
        this.options = []

        for (let equipment in this.equipmentlist) {
          //   if (this.equipmentlist[equipment].things.length === 0) {
          //     this.options.push(this.equipmentlist[equipment])
          //   }
          this.options.push(this.equipmentlist[equipment])
        }
      },
      getDeviceTypeName(thing_type) {
        let values = {
          'AgaveHub': 'AGAVE-4G',
          'RepeyBase': 'РЕПЕЙ',
          'Laurel': 'LAUREL-12',
          'IrisMi': 'ИРИС-МИ',
          'IrisO': 'ИРИС-О',
          'ModbusDevice': 'Устройство ModBus',
          'BZP02': 'БЗП-02',
          'Lyutik': 'ЛЮТИК',
          'BZP01': 'БЗП-01',
          'AlteyBZP': 'Алтей-БЗП',
          'AlteyOZT': 'Алтей-ОЗТ',
          'AlteyPLK': 'Алтей-ПЛК',
          'AlteyUZT': 'Алтей-УЗТ',
          'Cactus': 'Кактус',
          'LimePlus': 'Лайм+',
        }

        if (values[thing_type]) {
          return values[thing_type];
        } else {
            return 'Неизвестное устройство'
        }
      },
      getThingStatus() {
          // try {
          //     if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[1] === '1') {
          //         battery_status = 'warning';
          //         warning_signalisation = 'warning';
          //     }
          // } catch (e) {
          //     console.log(e);
          // }
          //
          // try {
          //     if (this.thing.shadow.logical_outputs_signals_state.logic_out_signal_state_0x0154[0] === '1') {
          //         battery_status = 'critical';
          //         critical_signalisation = 'critical';
          //     }
          // } catch (e) {
          //     console.log(e);
          // }
          //
          // if (!this.thing.shadow.online) {
          //     battery_status = 'offline';
          // }
      }
    }
  }
</script>

<style>
    .card-second-title {
        font-family: "Snowstorm Light";
        font-size: 30px;
        color: #fff;
        /*margin-left: 10px;*/
    }
    .circle-30.add-equipment {
        /*display: inline-block;*/
        background: url(../assets/plus-black.svg) no-repeat center center #8EDC28;
    }
    .circle-30.left-equipment {
        /*display: inline-block;*/
        background: url(../assets/arrow-left.png) no-repeat center center #8EDC28;
    }
    .circle-30.down-equipment {
        /*display: inline-block;*/
        background: url(../assets/arrow-down.png) no-repeat center center #8EDC28;
    }
    .circle-30.add-equipment:hover {
        /*display: inline-block;*/
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .circle-30.edit-equipment {
        background: url(../assets/small-pencil.svg) no-repeat center center #8EDC28;
    }
    .circle-30.confirm-equipment {
        background: url(../assets/confirm-30.svg) no-repeat center center #8EDC28;
    }
    .circle-30.close-add-equipment {
        /*background: url(../assets/untie3.png) no-repeat center center #8EDC28;*/
        background: url(../assets/plus-black.svg) no-repeat center center #8EDC28;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .circle.untie {
        background: url(../assets/untie2.png) no-repeat center center #000000;
    }
    .circle.untie:hover {
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
    }
    .v-select {
        border-radius: 10px;
        /*background-color: #8EDC28;*/
        background-color: #333333;
        color: #FFFFFF;
        /*width: 150px;*/
        /*height: 50px;*/
        font-size: 18px;
        /*width: 160px;*/
        /*height: 45px;*/
        width: 160px;
    }
    .vs__selected-options {
        color: #FFFFFF;
        height: 38px;
        overflow: hidden;
        white-space: nowrap;
        /*width: 120px;*/
        display: block;
    }
    .vs__selected {
        color: #FFFFFF;
    }
    .style-chooser .vs__clear,
    .style-chooser .vs__open-indicator {
        fill: #111111;
    }

    .style-chooser .vs__dropdown-toggle,
    .style-chooser .vs__dropdown-menu {
        /*background: #dfe5fb;*/
        border: 1px solid black;
        color: #000000;
        background: #2E2E2E;
    }

    .style-chooser .vs__dropdown-toggle:hover,
    .style-chooser .vs__dropdown-menu:hover {
        cursor: pointer;
    }

    .style-chooser .vs__dropdown-option {
        color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
    }

    .equipment-div {
        /*position: relative;*/
        background: #282828 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        /*height: 80px;*/
        min-height: 80px;
        height: auto;
        /*margin: 10px;*/
        font-family: "Segoe UI Light";
        font-size: 30px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        padding: 5px;
        /*padding-bottom: 5px;*/
        border: 1px solid #1A1A1A;
    }
    /*.rotating {*/
    /*  display: inline-block;*/
    /*  transition: transform 1s ease-in-out;*/
    /*}*/
    /*.rotating:hover {*/
    /*  display: inline-block;*/
    /*  transform: rotate(-90deg);*/
    /*}*/
    /*.rotating-reverse {*/
    /*  display: inline-block;*/
    /*  transition: transform 1s ease-in-out;*/
    /*}*/
    /*.rotating-reverse:hover {*/
    /*  display: inline-block;*/
    /*  transform: rotate(90deg);*/
    /*}*/
    .container {
        max-width: 1920px;
        width: 100%;
    }
    .untie-button {
        float: right;
        width: 20px;
        height: 20px;
        background-color: #C40D0D;
        position: relative;
        /*top: -46px;*/
        /*left: 825px;*/
        right: -23px;
        z-index: 999999;
        border-radius: 50px;
        background-image: url("../assets/minus-small.svg");
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        vertical-align: middle;
    }
    .unselectable {
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .shorter {
        height:calc(100vh - 550px);
    }
    .serial-small {
        color: #777777;
        font-size: 20px;
        font-weight: bold;
    }
    .flexedImage {
        width: 100%;
    }
    .back-img {
        flex-shrink: 0;
    }
    @media (max-width: 640px) {
        .back-img { width: 30px !important; height: 30px !important; margin-top: 17px !important;}
        .flexedImage { width: 100%; margin-top: 0px;}
        .inwrap-span { font-size: 25px; }
        .background-text { font-size: 70px !important; margin-right: 50px; }
        .wrap-span { font-size: 25px; }
    }
    .thing-flex-div {
        padding-top: 15px;
        padding-bottom: 15px;
        background: #202020 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        /*height: 80px;*/
        /*margin: 10px;*/
        font-family: "Segoe UI Light";
        font-size: 30px;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
    .inwrap-span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /*display: inline-block;*/
        /*width: auto;*/
    }
    .background-text {
        position: absolute;
        /*top: 250px;*/
        /*left: 35px;*/
        /*bottom: 10px;*/
        /*right: 10px;*/
        z-index: 0;
        color: #191818;
        /*font-family: "Segoe UI Black";*/
        font-family: "Tahoma Bold";
        font-size: 90px;
        opacity: 0.5;
    }
    /*.form-control:focus {*/
    /*    background-color: #000000;*/
    /*    border-color: #8EDC28;*/
    /*    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(142, 220, 40, 0.6);*/
    /*}*/
    .form-control {
        font-size: 28px;
        padding-left: 20px;
    }
    .thing-flex-div:hover {
        background: #2E2E2E 0% 0% no-repeat padding-box;
    }
    .disabled {
        pointer-events: none;
    }
    .router-link {
        cursor: pointer;
    }
</style>
