<template>
    <section class="main-navbar-section">
        <nav class="navbar navbar-expand-lg navbar-dark" v-if="isLogged === true">
            <div class="container">
                <a class="navbar-brand mb-4 brand-login mr-2 ml-2" @click="goToMain" style="color: #FFFFFF; cursor: pointer;">{{ $t('navbar.title') }}<!-- <div class="logo-inner-circle"/> --></a>
                <div class="vertical-line"></div>
                <label class="ml-3 mb-0 desc-label" style="margin-top: 22px">{{ $t('navbar.info') }}</label>
                <div style="position: relative; border: none; width: 100%" class="navbar-toggler" aria-expanded="false" aria-label="Toggle navigation">
<!--                    <span class="navbar-toggler-icon"></span>-->
                    <div class="d-flex justify-content-end" style="position: relative;">
                        <div class="user-logo">
                            <img alt="focus" src="../assets/user.svg">
                        </div>
                        <a class="nav-link" href="#" id="dropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <label v-if="isLogged === true" class="user-nav mt-1"><span class="wrap-span" style="max-width: 180px;">{{username}}</span> <img alt="focus" src="../assets/triangle-filled.svg" style="position: absolute; top: 15px; margin-left: 2px; margin-bottom: 5px;"></label>
                            <label v-else>Login</label>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown2">
                            <a class="dropdown-item" href="" @click="$router.push({ name: 'profile' })">{{ $t('navbar.profile') }}</a>
                            <a class="dropdown-item" href="" @click="$router.push({ name: 'invites' })">{{ $t('navbar.invites') }}</a>
                            <a class="dropdown-item disabled" @click="$router.push({ name: 'devices' })">{{ $t('navbar.devices') }}</a>
                            <!-- <a class="dropdown-item" href="" @click="$router.push({ name: 'main' })">{{ $t('navbar.settings') }}</a> -->
                            <a class="dropdown-item" href="" @click="$router.push({ name: 'settings' })">{{ $t('navbar.settings') }}</a>
                            <!-- <a class="dropdown-item" href="http://editor.swagger.io/?url=https://raw.githubusercontent.com/pink-goose/anethum-api/master/sw.yaml">Документация API</a> -->
                            <a class="dropdown-item" href="" @click.prevent="signout">{{ $t('navbar.logout') }}</a>
                        </div>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
<!--                    <div class="vertical-line"></div>-->
<!--                    <label class="ml-3 mb-0 mt-4 desc-label">глобальный сервис</label>-->
<!--                    <div class="collapse navbar-collapse" id="main_navbar">-->
<!--                        <ul class="navbar-nav mr-auto">-->
<!--                        </ul>-->
                        <ul class="navbar-nav mr-auto"></ul>
                        <ul class="navbar-nav">
                            <li>
                                <div class="invite-logo mt-2 mr-4 pt-1" v-if="userInvites.length != 0">
                                  <a href="/invites"><img alt="focus" src="../assets/invite.png"></a>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                              <SwitchLanguage />
                            </li>
                            <!-- <li class="nav-item dropdown my-2 my-md-0 ml-3 mr-4">
                                <a class="lang-label nav-link mt-1" href="#" id="dropdown-lang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <label style="cursor: pointer; margin: 0px;" class="mt-1">РУС<img alt="focus" src="../assets/triangle-filled.svg" style="margin-left: 4px;"></label>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-lang">
                                    <a class="dropdown-item" href="#">РУС</a>
                                    <a class="dropdown-item disabled" href="#">ENG</a>
                                </div>
                            </li> -->
<!--                            <li class="d-flex ">-->
<!--                                <div class="user-logo">-->
<!--                                    <img alt="focus" src="../assets/user.svg">-->
<!--                                </div>-->
<!--                                <a class="nav-link" href="#" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                                    <label v-if="isLogged === true" class="user-nav">{{username}} <label class="dropdown-arrow">▽</label></label>-->
<!--                                    <label v-else>Login</label>-->
<!--                                </a>-->
<!--                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown">-->
<!--                                    <a class="dropdown-item" href="/profile">Профиль</a>-->
<!--                                    <a class="dropdown-item" href="/devices">Устройства</a>-->
<!--                                    <a class="dropdown-item disabled" href="#">Настройки</a>-->
<!--                                    <a class="dropdown-item" href="http://editor.swagger.io/?url=https://raw.githubusercontent.com/pink-goose/anethum-api/master/sw.yaml">Документация API</a>-->
<!--                                    <a class="dropdown-item" href="" @click.prevent="signout">Выйти</a>-->
<!--                                </div>-->
<!--                            </li>-->
                            <div class="d-flex">
                                <li class="ml-4">
                                    <div v-if="profileImage" style="width: 50px; height: 50px;" class="mt-1">
                                        <img class="avatar-img" :src="profileImage">
                                        <!-- <img alt="focus" src="../assets/user.svg"> -->
                                    </div>
                                    <div v-else class="user-logo mt-1">
                                      <img alt="focus" src="../assets/user.svg">
                                    </div>
                                </li>
                                <li class="nav-item dropdown my-2 my-md-0 ml-3 mr-3">
                                    <a class="nav-link" href="#" id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <label v-if="isLogged === true" class="user-nav mt-1"><span class="wrap-span" style="max-width: 300px;">{{username}}</span> <img alt="focus" src="../assets/triangle-filled.svg" style="position: absolute; top: 15px; margin-left: 2px; margin-top: 4px;"></label>
                                        <label v-else>Login</label>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown">
                                        <a class="dropdown-item" href="" @click="$router.push({ name: 'profile' })">{{ $t('navbar.profile') }}</a>
                                        <a class="dropdown-item" href="" @click="$router.push({ name: 'invites' })">{{ $t('navbar.invites') }}</a>
                                        <a class="dropdown-item disabled" @click="$router.push({ name: 'devices' })">{{ $t('navbar.devices') }}</a>
                                        <!-- <a class="dropdown-item" href="" @click="$router.push({ name: 'main' })">{{ $t('navbar.settings') }}</a> -->
                                        <a class="dropdown-item" href="" @click="$router.push({ name: 'settings' })">{{ $t('navbar.settings') }}</a>
                                        <!-- <a class="dropdown-item" href="http://editor.swagger.io/?url=https://raw.githubusercontent.com/pink-goose/anethum-api/master/sw.yaml">Документация API</a> -->
                                        <a class="dropdown-item" href="" @click.prevent="signout">{{ $t('navbar.logout') }}</a>
                                    </div>
                                </li>
                            </div>
                        </ul>
                </div>
            </div>
        </nav>
        <nav class="navbar navbar-expand-lg navbar-dark transparent" v-else>
            <div class="container">

                <a class="navbar-brand transparent-text mr-0 mb-3 ml-5 brand-login" href="#">Agave</a>
                <div class="vertical-line"></div>
                <div style="position: relative; border: none; width: 100%" class="navbar-toggler" aria-expanded="false" aria-label="Toggle navigation">
                <!--                <button style="position: relative;" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--                    <span class="navbar-toggler-icon"></span>-->
                    <div class="d-flex justify-content-end" style="position: relative;">
                        <button class="btn btn-primary-outline enter-button" @click='toggle = !toggle'><img alt="keyhole" src="../assets/keyhole.svg" class="mr-2 key-button">Войти</button>
                        <loginform v-show='!toggle' style="position: absolute; top: 80px; right: -30px; width: auto"></loginform>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent2">
                    <ul class="navbar-nav mr-auto">
                    </ul>
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item dropdown my-2 my-md-0 ml-3 mr-5">
                            <a class="lang-label nav-link" href="#" id="dropdown-lang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <label style="cursor: pointer; margin: 0px;" class="mt-1">РУС<img alt="focus" src="../assets/triangle-filled.svg" style="margin-left: 4px;"></label>
                            </a>
                            <SwitchLanguage />
                            <Multilingual />
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-lang">
                                <a class="dropdown-item" href="#">РУС</a>
                                <a class="dropdown-item disabled" href="#">ENG</a>
                            </div>
                        </li> -->
                        <li class="d-flex align-items-center">
                          <SwitchLanguage />
                        </li>
                        <li class="mr-5 ml-5" style="position: relative;">
                            <button class="btn btn-primary-outline enter-button"  @click='toggle = !toggle'><img alt="keyhole" src="../assets/keyhole.svg" class="mr-2 key-button">
                              {{ $t('message') }}
                            </button>
                            <loginform v-show='!toggle' style="position: absolute; top: 80px; left: -290px;"></loginform>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
  // import Vue from 'vue';
  import loginform from './LoginForm.vue'
  import {invitesService, userService} from '../_services';
  import SwitchLanguage from '@/components/SwitchLanguage.vue'

  export default {
    name: 'Navbar',
    components: {
      loginform,
      SwitchLanguage,
    },
    props: {
      msg: String
    },
    data () {
      return {
        // isLogged: this.checkIfIsLogged(),
        isLogged: false,
        username: '',
        toggle: true,
        userInvites: [],
        profileImage: null
      }
    },
    watch:{
      $route (){
        this.checkIfIsLogged();

        // console.log(to);
        // console.log(from)
      }
    },
    created () {
      // alert('created!');
      this.setUser();
      // this.setPhoto();

      if (this.isLogged) {
          this.getInvites();
      }

      // this.setPhoto();

      // console.log(localStorage);

      setInterval(function () {
        if (this.isLogged) {
            this.getInvites();
            // console.log('setInterval 3');
            if (!this.profileImage) {
              this.setPhoto();
            }
        }
      }.bind(this), 15000);

      setInterval(function () {
        if (this.isLogged) {
            if (!this.profileImage) {
              this.setPhoto();
            }
        }
      }.bind(this), 2000);
    },
    mounted () {
      // alert('mounted');
      this.setUser();
      this.checkIfIsLogged();
      // this.setPhoto();

      // this.get_profile_photo();
      // this.getInvites();
    },
    // beforeUpdate () {
    //   this.setUser();
    // },
    // beforeRouteUpdate() {
    //   alert('beforeRouteUpdate');
    //   this.checkIfIsLogged();
    // },
    // beforeRouteLeave () {
    //   this.setUser();
    // },
    // beforeDestroy () {
    //   this.setUser();
    // },
    methods: {
      goToMain() {
        if (this.$route.path != '/') {
          this.$router.push({ name: 'main'})
        }
      },
      async checkIfIsLogged () {
        let userdata = JSON.parse(localStorage.getItem('user'));

        // await this.getInvites();

        this.setUser();

        if (userdata) {
          this.isLogged = true;
          return true
        } else {
          this.isLogged = false;
          return false
        }
      },
      signout () {
        userService.logout();
        // this.$localStorage.remove('user');
        // this.isLogged = this.checkIfIsLogged();
        this.$router.push('/login')
      },
      async setUser() {
        let userdata = JSON.parse(localStorage.getItem('user'));
        if (userdata && userdata.profile && userdata.profile.name) {
            this.username = userdata.profile.lastname + ' ' + userdata.profile.name;
            this.isLogged = true;
            
            await this.setPhoto();
            // await this.get_profile_photo();
        }
        else {
            this.username = 'Unknown user';
        }
      },
      async setPhoto() {
        // let userImage = localStorage.getItem('profileImage');

        let userImage = await JSON.parse(localStorage.getItem('profileImage'));

        // console.log(userImage)
        
        // this.profileImage = URL.createObjectURL(userImage);
        this.profileImage = userImage;

        // console.log('end getting image from localstorage')
      },
      async get_profile_photo() {
        let res = await userService.getProfilePhoto();

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.profileImage = URL.createObjectURL(res);
      },
      async getInvites() {
        let res = await invitesService.invites_get();

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.userInvites = res.result.items;
      }
    }
  }
</script>

<style scoped>
    @font-face {
        font-family: 'Comfortaa normal';
        src: url('../assets/Comfortaa-Regular.woff2');
    }
    .dropdown-menu {
        color: #FFFFFF;
        background-color: #FFFFFF4D;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 0.8;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
    .dropdown-item {
        color: #FFFFFF;
    }
    .dropdown-item.disabled {
        color: #AAAAAA;
    }
    .dropdown-item:hover {
        color: #000000;
    }
    .btn-primary-outline {
        background-color: transparent;
        color: #fff;
        padding: 0;
    }
    .navbar {
      /*height: 100px;*/
      min-height: 100px;
      background: #131416 0% 0% no-repeat padding-box;
      padding-left: 30px;
      padding-right: 30px;
      opacity: 1;
    }
    .navbar-brand {
      top: 33px;
      left: 52px;
      width: 173px;
      height: 50px;
      text-align: left;
      /*!*font: 50px/74px "Segoe UI";*!*/
      /*font: 50px "Mont Demo";*/
      /*letter-spacing: 2.5px;*/
      /*color: #FFFFFF;*/
      /*opacity: 1;*/
      font: normal normal 200 50px/74px Mont DEMO;
      letter-spacing: 2.5px;
      color: #FFFFFF;
      opacity: 1;
    }
    .vertical-line {
      border-left: 2px solid black;
      height: 80px;
    }
    .footer {
      background: #050B1A 0% 0% no-repeat padding-box;
      height: 70px;
    }
    .desc-label {
      color: #fff;
      /* font-family: "Segoe UI"; */
      font: normal normal 300 25px/33px Segoe UI;
      font-size: 22px;
      letter-spacing: 0px;
      opacity: 1;
    }
    .logo-inner-circle {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      border: 1px solid white;
      position: relative;
      top: -41px;
      left: 70px;
    }
    .lang-label {
      width: 100px;
      height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent linear-gradient(180deg, #FFFFFF4D 0%, #FFFFFF33 100%) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-family: "Comfortaa normal";
      font-size: 20px;
      letter-spacing: 1.2px;
      color: #FFFFFF;
      opacity: 1;
      cursor: pointer;

      /*line-height: 40px;*/
      /*text-align: center;*/
      /*vertical-align: middle;*/
    }
    .lang-label:hover {
      background: transparent linear-gradient(180deg, #CCCCCC4D 0%, #FFFFFF33 100%) 0% 0% no-repeat padding-box;
    }
    /*.container {*/
    /*  width: 100%;*/
    /*  padding-right: 15px;*/
    /*  padding-left: 15px;*/
    /*  margin-right: auto;*/
    /*  margin-left: auto;*/
    /*}*/
    .transparent {
      border-width: 0px;
      -webkit-box-shadow: 0px 0px;
      box-shadow: 0px 0px;
      background-color: rgba(0,0,0,0.0);
      background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
      background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
      background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    }
    .transparent-text {
        opacity: 0.01;
    }
    .user-nav {
        /*display: inline-block;*/
        text-align: left;
        /*font: Light 20px/27px Segoe UI;*/
        font: 20px "Segoe UI Light";
        letter-spacing: 1px;
        color: #FFFFFF;
        opacity: 1;
    }
    .user-nav:hover,
    .user-nav:focus {
        color: #ccc;
        cursor: pointer;
    }
    .user-nav:hover img {
        filter: invert(12%) sepia(0%) saturate(1149%) hue-rotate(296deg) brightness(100%) contrast(84%);
    }
    .user-logo {
        width: 50px;
        height: 50px;
        /*background-color: #8EDC28;*/
        filter: invert(70%) sepia(100%) saturate(380%) hue-rotate(33deg) brightness(97%) contrast(88%);
    }
    .user-logo:hover,
    .user-logo:focus {
        filter: invert(81%) sepia(26%) saturate(1362%) hue-rotate(54deg) brightness(96%) contrast(92%);
    }
    .invite-logo {
      /*background-color: #8EDC28;*/
      filter: invert(70%) sepia(100%) saturate(380%) hue-rotate(33deg) brightness(97%) contrast(88%);
      /*filter: invert(18%) sepia(90%) saturate(7222%) hue-rotate(2deg) brightness(119%) contrast(122%);*/
      /*filter: invert(84%) sepia(13%) saturate(1240%) hue-rotate(161deg) brightness(89%) contrast(91%);*/
    }
    .invite-logo:hover,
    .invite-logo:focus {
      transform: scale(1.05);
      cursor: pointer;
    }
    @media screen and (max-width: 1920px) {
        .container {
            padding-right:0 !important;
            padding-left:0 !important;
            max-width: initial !important;
        }
    }
    .dropdown-arrow {
        font: 20px "Segoe UI Light";
    }
    .dropdown-item {
        cursor: pointer;
    }
    .enter-button {
        font: 20px "Segoe UI Light";
        font-size: 20px;
        letter-spacing: 1px;
        color: #FFFFFF;
        opacity: 1;
        width: 130px;
    }
    .container {
        max-width: 1920px;
        width: 100%;
    }
    @media (max-width: 992px) {
        .vertical-line {visibility: hidden; display: none;}
        .desc-label {visibility: hidden; display: none;}
        .brand-login {visibility: hidden; display: none;}
    }
    /*.key-button:hover {*/
    /*    filter: brightness(130%) contrast(100%);*/
    /*}*/
    .enter-button:hover {
        color: #9aec29;
        filter: brightness(130%) contrast(100%);
    }
    @media (max-width: 640px) {
        .wrap-span { font-size: 18px; }
    }
    .avatar-img {
      /* background-image: url("../assets/photo.svg"); */
      background-image: url("../assets/user.svg");
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid white;
      /* border: 1px solid #9aec29; */
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
      /*position: absolute;*/
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
</style>
