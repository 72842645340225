<template>
  <vue-simple-spinner v-if="this.preloading" size="80" :line-size="7" line-fg-color="#8edc28" line-bg-color="#111111" :speed="0.5" class="m-5"/>
  <div v-else>
    <div class="row">
        <div class="col-sm-6">
            <div class="card card-main transparent">
                <div class="card-body">
                    <div class="header-div m-0 desktop-header">
                        <div class="header-upper">
                            <div class="d-flex bd-highlight">
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" class="ml-1"></a>
                                </div>
                                <div class="back-img interact-img" style="flex-shrink: 0;">
                                    <div v-if="thing.shadow.online">
                                        <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                                    </div>
                                    <div v-else>
                                        <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                                    </div>
                                </div>

                                <span class="flex-grow-1 bd-highlight mt-2 nowrap-span">
                                    {{ facility.name }}
                                    <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                                    <span v-else>
                                        <span v-if="thing.type.id == 'AlteyBZP'">, Алтей-БЗП <span class="serial-small">(№ {{ thing.number }})</span></span>
                                        <span v-else>
                                            , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                                        </span>
                                    </span>
                                </span>

                                <div class="p-2 bd-highlight">
                                  <div class="circle-35 current-parameters" v-on:click="active_button = 'current-parameters'; get_event_logs(); get_system_logs();" v-bind:class="{ active: active_button == 'current-parameters' }"></div>
                                </div>
                                <div class="p-2 bd-highlight">
                                  <div class="circle-35 files" v-on:click="active_button = 'files'; getThingOscillogramFiles();" v-bind:class="{ active: active_button == 'files' }"></div>
                                </div>

                            </div>
                        </div>
                        <div class="header-body" style="padding: 0px;">
                            <div class="row">
                                <div class="col-6 d-flex align-items-center">
                                    <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="color: #ff0000"><span class="nowrap-span">⚠ Modbus устройство оффлайн</span></li>
                                        <li style="font-size: 14px;">Последнее обновление:</li>
                                        <li>{{ date }} {{ time }}</li>
                                    </ul>
                                    <ul v-else style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2">
                                        <li style="font-size: 14px;">Обновлено:</li>
                                        <li>{{ date }}</li>
                                        <li>{{ time }}</li>
                                    </ul>
                                </div>
                                <div class="col-6 d-flex align-items-center" style="border-left-color: black; border-left: 1px solid black;">
                                    <ul style="list-style: none; padding-left: 0px; margin-bottom: 0px;" class="mt-2 mb-2">
                                        <li style="font-size: 14px;">Интерфейс:</li>
                                        <li>Порт: {{ thing.shadow.interface.port }}</li>
                                        <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

<!-- MOBILE ********************************************************************** -->
                    <div class="header-div m-0 mobile-header" style="height: auto !important;">
                      <div class="header-upper pb-3">
                        <div class="d-flex bd-highlight">
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <a href="#" @click="$router.go(-1)"><img alt="back" src="../../assets/arrow_back.svg" style="flex-shrink: 0;"></a>
                          </div>
                          <div class="back-img interact-img" style="margin-top: 0px !important;">
                            <div v-if="thing.shadow.online">
                              <img alt="modbus_device_online" src="../../assets/green_circle.svg">
                            </div>
                            <div v-else>
                              <img alt="modbus_device_offline" src="../../assets/gray_circle.svg">
                            </div>

                          </div>
                          <span class="flex-grow-1 bd-highlight mt-2 nowrap-span" style="font-size: 20px !important;">
                            {{ facility.name }}
                            <span v-if="thing.equipment && thing.equipment.name">, {{ thing.equipment.name }}</span>
                            <span v-else>
                            <span v-if="thing.type.id == 'AlteyBZP'">, Алтей-БЗП <span class="serial-small">(№ {{ thing.serial.substring(3) }})</span></span>
                            <span v-else>
                              , {{ thing.type.id }} (№ {{ thing.serial.substring(3) }})
                            </span>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px;">
                        <div class="d-flex">
                          <div class="d-flex align-items-center">
                            <ul v-if="!thing.shadow.online" style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="color: #ff0000"><span class="nowrap-span">⚠ Алтей-БЗП оффлайн</span></li>
                            <li style="font-size: 14px;">Последнее обновление:</li>
                            <li>{{ date }} {{ time }}</li>
                            </ul>
                            <ul v-else style="list-style: none; padding-left: 10px; margin-bottom: 0px;">
                            <li style="font-size: 14px;">Обновлено:</li>
                            <li>{{ date }}</li>
                            <li>{{ time }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="header-body" style="padding: 0px; border-top: 1px solid black;">
                        <div class="d-flex">
                          <ul style="list-style: none; padding-left: 10px; margin-bottom: 0px;" class="mt-2 mb-2">
                              <li style="font-size: 14px;">Интерфейс:</li>
                              <li>Порт: {{ thing.shadow.interface.port }}</li>
                              <li>Адрес modbus: {{ thing.shadow.interface.slave_address }}</li>
                          </ul>
                        </div>
                      </div>

                    </div>
<!-- MOBILE ********************************************************************** -->

                    <div class="row mt-3">
                        <div class="overflow-auto detail-overflow">
                            <div class="card card-second transparent mt-0">
                                <div class="card-body">
                                    <div class="detailed-div">
                                        <vue-json-pretty
                                                :path="'res'"
                                                :data=thing.shadow.registers
                                                :showDoubleQuotes="false"
                                                :deep=2
                                                @click="handleClick">
                                        </vue-json-pretty>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
          <div class="card card-main transparent">
            <div class="card-body">
              <!-- <div v-if="active_button == 'current-parameters'"> -->
              <div v-if="active_button == 'current-parameters'">
                <!-- <div class="parameters-div">
                </div> -->
                <div class="parameters-div">
                  <div class="row">
                    <div class="col-6">
                      <div class="d-flex justify-content-between">
                        <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.eventjournal') }}</span>
                        <div class="circle-35 files journal-download" v-on:click="event_journal_datepicker_show = !event_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
                        <div v-if="event_journal_datepicker_show" class="journal-datepicker-div">
                          <div class="d-flex flex-column">
                            <span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать журнал событий</span>
                            <date-picker v-model="event_range" :model-config="modelConfig" is-range color="green" is-dark/>
                            <div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('event_log')">Скачать</div></div>
                          </div>
                        </div>
                      </div>
                      <hr style="background: #aaaaaa;">
                      <div class="journals-div overflow-auto scroller">
                        <span v-if="!event_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                        <div v-for="record in event_logs" :key="record.id">
                          <div class="row">
                            <div class="col-5 p-0">
                              <span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
                            </div>
                            <div class="col-7">
                              <p v-for="elem in getEventDescription(record.data.events)" :key="elem" style="font-size: 14px;">{{ elem }}</p>
                            </div>
                          </div>
                          <hr class="mt-0 mb-0 mr-2" style="background: #333;">
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="d-flex justify-content-between">
                        <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ $t('devices.laurelpage.messagejournal') }}</span>
                        <div class="circle-35 files journal-download" v-on:click="message_journal_datepicker_show = !message_journal_datepicker_show"></div><div class="journal-download-div" style="letter-spacing: 0.9px;">Скачать журнал</div>
                        <div v-if="message_journal_datepicker_show" class="journal-datepicker-div">
                          <div class="d-flex flex-column">
                            <span style="letter-spacing: 0.9px; font-size: 16px;" class="mb-2">Скачать системный журнал</span>
                            <date-picker v-model="message_range" :model-config="modelConfig" is-range color="green" is-dark/>
                            <div class="d-flex justify-content-end"><div class="download-journal-button mt-2" v-on:click="download_log('message_log')">Скачать</div></div>
                          </div>
                        </div>
                      </div>
                      <hr style="background: #aaaaaa;">
                      <div class="journals-div overflow-auto scroller">
                        <span v-if="!message_logs.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список журналов пуст</span>
                        <div v-for="record in message_logs" :key="record.id">
                          <div class="row">
                            <div class="col-5 p-0">
                              <span style="font-size: 14px;">{{ formatDate(record.data.timestamp) }}</span>
                            </div>
                            <div class="col-7">
                              <span style="font-size: 14px;">{{ getMessageDescription(record.data.Text) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="active_button == 'files'">
                <div class="parameters-div">
                  <div class="d-flex justify-content-between">
                    <span style="font-size: 26px; letter-spacing: 1.5px; font-family: 'Segoe UI Light'; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Осциллограммы</span>
                  </div>
                  <hr style="background: #aaaaaa;">
                  <div class="journals-div overflow-auto scroller">
                    <span v-if="!oscillogram_files.length" style="color: #aaaaaa;" class="d-flex justify-content-center">Список файлов пуст</span>
                    <div v-for="file in oscillogram_files" :key="file.id">
                      <div class="d-flex justify-content-between mt-1">
                        <!-- <span>{{ file.filename }}</span> -->
                        <span>{{ set_filename(file.timestamp) }}</span>
                        <div class="row">
                          <span data-toggle="tooltip" data-placement="top" v-bind:title="'Загружено: ' + toDate(file.updated)">{{ formatDate(parseInt(file.timestamp)) }}</span>
                          <!-- <span>{{ toDate(file.updated) }}</span> -->
                          <div class="download-journal-button ml-3" v-on:click="downloadFile(file)">Скачать</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import VueSimpleSpinner from 'vue-simple-spinner';
  import {facilitiesService, thingsService, journalsService, filesService} from "../../_services";
  import VueJsonPretty from 'vue-json-pretty';
  import moment from 'moment';
  import download from 'downloadjs';


  moment.locale('utc');


  export default {
    // name: 'Lyutik',
    name: 'AlteyBZP',
    props: ['facilityId', 'thingId'],
    components: {
      VueSimpleSpinner,
      VueJsonPretty,
    },
    data () {
      return {
        // facilityId: this.$route.params.facility_id,
        // thingId: this.$route.params.thing_id,
        preloading: false,
        thing: '',
        facility: '',
        date: "",
        time: "",
        // details: '',
        event_logs: [],
        message_logs: [],
        active_button: 'current-parameters',
        oscillogram_files: [],
        event_range: {
          start: new Date(new Date().setHours(0,0,0,0)),
          end: new Date(new Date().setHours(23,59,59,0)),
        },
        message_range: {
          start: new Date(new Date().setHours(0,0,0,0)),
          end: new Date(new Date().setHours(23,59,59,0)),
        },
        modelConfig: {
          start: {
            timeAdjust: '00:00:00',
          },
          end: {
            timeAdjust: '23:59:59',
          },
        },
        event_journal_datepicker_show: false,
        message_journal_datepicker_show: false,
        time_offset: 0
      }
    },
    async created() {
      this.preloading = true;
      await this.init();
      this.preloading = false;
      // this.getNow();

      setInterval(function () {
        this.warning_list = [];
        this.critical_list = [];
        this.getThing();
        this.getFacility();
        this.getNow();
      }.bind(this), 15000);
    },
    methods: {
      async init() {
        console.log('Rendering "AlteyBZP" component');
        await this.getThing();
        await this.getFacility();
        this.getNow();
        await this.get_event_logs();
        await this.get_system_logs();
        this.set_timeoffset()
      },
      async getThing() {
        let res = await thingsService.thing_get(this.facilityId, this.thingId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.thing = res.result;

        // console.log(this.thing);
      },
      set_timeoffset: function() {
        if (this.thing.shadow.registers.holding_registers['0x0110']) {
          this.time_offset = this.thing.shadow.registers.holding_registers['0x0110'];
        }
      },
      getNow: function() {
        let today = new Date(this.thing.updated + 'Z');
        this.date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        this.time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);
      },
      async getFacility() {
        let res = await facilitiesService.facility_get(this.facilityId);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.facility = res.result.facility;
      },
      handleClick() {
        // alert('handleClick')
      },
      async get_event_logs() {
        let args = {
          types: 'event_log',
          start: 0,
          limit: 50,
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.event_logs = res.result.items;
      },
      async get_system_logs() {
        let args = {
          types: 'message_log',
          start: 0,
          limit: 50,
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.message_logs = res.result.items;
      },
      formatDate(value) {
        if (value) {
          let time_with_offset = value + (this.time_offset * 60000)

          return moment.utc(time_with_offset).format("L LTS.SSS")
        }

        return value;
      },
      // formatDate(value) {
      //   if (value) {
      //     return moment.utc(value).format("L LTS.SSS")
      //   }

      //   return value;
      // },
      getEventDescription(events) {

        let answers = []

        for (var i = 0; i < events.length; i++) {

          if (events[i] == 1) {
            answers.push(i);
          }

        }

        let full_answers = answers.map(function(id) {
          
          let i = -1;

          if (id == 1 + i) {
            return 'Пуск ДЗ первой ступени'
          }
          if (id == 2 + i) {
            return 'Пуск ДЗ второй ступени'
          }
          if (id == 3 + i) {
            return 'Пуск ДЗ третьей ступени'
          }
          if (id == 4 + i) {
            return 'Пуск ДЗ четвертой ступени'
          }
          if (id == 5 + i) {
            return 'Срабатывание ДЗ первой ступени'
          }
          if (id == 6 + i) {
            return 'Срабатывание ДЗ второй ступени'
          }
          if (id == 7 + i) {
            return 'Срабатывание ДЗ третьей ступени'
          }
          if (id == 8 + i) {
            return 'Срабатывание ДЗ четвертой ступени'
          }
          if (id == 9 + i) {
            return 'Срабатывание ускоренной ДЗ'
          }
          if (id == 10 + i) {
            return 'Пуск ДЗДВ первой ступени'
          }
          if (id == 11 + i) {
            return 'Пуск ДЗДВ второй ступени'
          }
          if (id == 12 + i) {
            return 'Пуск ДЗДВ третьей ступени'
          }
          if (id == 13 + i) {
            return 'Пуск ДЗДВ четвертой ступени'
          }
          if (id == 14 + i) {
            return 'Срабатывание ДЗДВ первой ступени'
          }
          if (id == 15 + i) {
            return 'Срабатывание ДЗДВ второй ступени'
          }
          if (id == 16 + i) {
            return 'Срабатывание ДЗДВ третьей ступени'
          }
          if (id == 17 + i) {
            return 'Срабатывание ДЗДВ четвертой ступени'
          }
          if (id == 18 + i) {
            return 'Срабатывание ускоренной ДЗДВ'
          }
          if (id == 19 + i) {
            return 'Пуск ТО 1'
          }
          if (id == 20 + i) {
            return 'Срабатывание ТО 1'
          }
          if (id == 21 + i) {
            return 'Пуск ТО 2'
          }
          if (id == 22 + i) {
            return 'Срабатывание ТО 2'
          }
          if (id == 23 + i) {
            return 'Пуск МТЗ 1'
          }
          if (id == 24 + i) {
            return 'Срабатывание МТЗ 1'
          }
          if (id == 25 + i) {
            return 'Срабатываие ускоренной МТЗ 1'
          }
          if (id == 26 + i) {
            return 'Пуск МТЗ 2'
          }
          if (id == 27 + i) {
            return 'Срабатывание МТЗ 2'
          }
          if (id == 28 + i) {
            return 'Срабатывание ускоренной МТЗ 2'
          }
          if (id == 29 + i) {
            return 'Пуск защиты от перегрузки'
          }
          if (id == 30 + i) {
            return 'Перегрузка сигнал'
          }
          if (id == 31 + i) {
            return 'Перегрузка отключение'
          }
          if (id == 32 + i) {
            return 'Срабатывание разгрузки 1 очереди'
          }
          if (id == 33 + i) {
            return 'Срабатывание разгрузки 2 очереди'
          }
          if (id == 34 + i) {
            return 'Пуск ЛЗШ'
          }
          if (id == 35 + i) {
            return 'Срабатывание ЛЗШ'
          }
          if (id == 36 + i) {
            return 'ЛЗШ 1 неисправность'
          }
          if (id == 37 + i) {
            return 'ЛЗШ 2 неисправность'
          }
          if (id == 38 + i) {
            return 'Срабатывание ЗДЗ'
          }
          if (id == 39 + i) {
            return 'Неисправность ЗДЗ'
          }
          if (id == 40 + i) {
            return 'Пуск ЗОФ'
          }
          if (id == 41 + i) {
            return 'Сигнализация ЗОФ'
          }
          if (id == 42 + i) {
            return 'Отключение ЗОФ'
          }
          if (id == 43 + i) {
            return 'Пуск ОЗЗ 1'
          }
          if (id == 44 + i) {
            return 'Сигнализация ОЗЗ 1'
          }
          if (id == 45 + i) {
            return 'Отключение ОЗЗ 1'
          }
          if (id == 46 + i) {
            return 'Пуск ОЗЗ 2'
          }
          if (id == 47 + i) {
            return 'Срабатывание ОЗЗ 2'
          }
          if (id == 48 + i) {
            return 'Пуск ЗМН 1'
          }
          if (id == 49 + i) {
            return 'Срабатывание ЗМН 1'
          }
          if (id == 50 + i) {
            return 'Пуск ЗМН 2'
          }
          if (id == 51 + i) {
            return 'Срабатывание ЗМН 2'
          }
          if (id == 52 + i) {
            return 'ЗМН отключение'
          }
          if (id == 53 + i) {
            return 'Пуск ЗПП'
          }
          if (id == 54 + i) {
            return 'Срабатывание ЗПП'
          }
          if (id == 55 + i) {
            return 'Срабатывание ЗПП на отключение'
          }
          if (id == 56 + i) {
            return 'Пуск ЗПН 1'
          }
          if (id == 57 + i) {
            return 'Срабатывание ЗПН 1'
          }
          if (id == 58 + i) {
            return 'Пуск ЗПН 2'
          }
          if (id == 59 + i) {
            return 'Срабатывание ЗПН 2'
          }
          if (id == 60 + i) {
            return 'ЗПН отключение'
          }
          if (id == 61 + i) {
            return 'Срабатывание ДТО'
          }
          if (id == 62 + i) {
            return 'Пуск ДЗТ'
          }
          if (id == 63 + i) {
            return 'Срабатывание ДЗТ'
          }
          if (id == 64 + i) {
            return 'Пуск ЗПП'
          }
          if (id == 65 + i) {
            return 'Сигнализация ЗПП'
          }
          if (id == 66 + i) {
            return 'Отключение ЗПП'
          }
          if (id == 67 + i) {
            return 'Пуск ЗБР'
          }
          if (id == 68 + i) {
            return 'Сигнализация ЗБР'
          }
          if (id == 69 + i) {
            return 'Отключение ЗБР'
          }
          if (id == 70 + i) {
            return 'Тяжелый пуск'
          }
          if (id == 71 + i) {
            return 'Сигнализация перегрева 1'
          }
          if (id == 72 + i) {
            return 'Сигнализация перегрева 2'
          }
          if (id == 73 + i) {
            return 'Отключение по перегреву'
          }
          if (id == 74 + i) {
            return 'Пуск Мин ТЗ'
          }
          if (id == 75 + i) {
            return 'Сигнализация Мин ТЗ'
          }
          if (id == 76 + i) {
            return 'Отключение Мин ТЗ'
          }
          if (id == 77 + i) {
            return 'Пуск ЗАР'
          }
          if (id == 78 + i) {
            return 'Сигнализация ЗАР'
          }
          if (id == 79 + i) {
            return 'Отключение ЗАР'
          }
          if (id == 80 + i) {
            return 'Останов смазки подшипников'
          }
          if (id == 81 + i) {
            return 'Останов воздушного охлаждения'
          }
          if (id == 82 + i) {
            return 'Останов водяного охлаждения'
          }
          if (id == 83 + i) {
            return 'Снижения потока охлаждающей воды'
          }
          if (id == 84 + i) {
            return 'Вода в корпусе двигателя'
          }
          if (id == 85 + i) {
            return 'Запрет последующих пусков'
          }
          if (id == 86 + i) {
            return 'Отключение при потере SF Q'
          }
          if (id == 87 + i) {
            return 'Потеря SF Q'
          }
          if (id == 88 + i) {
            return 'Потеря SF ТТ'
          }
          if (id == 89 + i) {
            return 'Отключение при потере SF ТТ'
          }
          if (id == 90 + i) {
            return 'Срабатывание УРОВ'
          }
          if (id == 91 + i) {
            return 'Пуск АЧР 1'
          }          
          if (id == 92 + i) {
            return 'Срабатывание АЧР 1'
          }          
          if (id == 93 + i) {
            return 'Пуск АЧР 2'
          }          
          if (id == 94 + i) {
            return 'Срабатывание АЧР 2'
          }          
          if (id == 95 + i) {
            return 'Пуск ДАР'
          }
          if (id == 96 + i) {
            return 'Срабатывание ДАР'
          }
          if (id == 97 + i) {
            return 'Пуск АЧР внешний'
          }
          if (id == 98 + i) {
            return 'Срабатывание АЧР внешнее'
          }
          if (id == 99 + i) {
            return 'Пуск ЧАПВ'
          }
          if (id == 100 + i) {
            return 'Срабатывание ЧАПВ'
          }
          if (id == 101 + i) {
            return 'Пуск ЧАПВ внешний'
          }
          if (id == 102 + i) {
            return 'Срабатывание ЧАПВ внешнее'
          }
          if (id == 103 + i) {
            return 'Пуск АЧР 1 первой очереди'
          }
          if (id == 104 + i) {
            return 'Срабатывание АЧР 1 первой очереди'
          }
          if (id == 105 + i) {
            return 'Пуск АЧР 2 первой очереди'
          }
          if (id == 106 + i) {
            return 'Срабатывание АЧР 2 первой очереди'
          }
          if (id == 107 + i) {
            return 'Пуск ДАР первой очереди'
          }
          if (id == 108 + i) {
            return 'Срабатывание ДАР первой очереди'
          }
          if (id == 109 + i) {
            return 'Пуск АЧР 1 второй очереди'
          }
          if (id == 110 + i) {
            return 'Срабатывание АЧР 1 второй очереди'
          }
          if (id == 111 + i) {
            return 'Пуск АЧР 2 второй очереди'
          }
          if (id == 112 + i) {
            return 'Срабатывание АЧР 2 второй очереди'
          }
          if (id == 113 + i) {
            return 'Пуск ДАР второй очереди'
          }
          if (id == 114 + i) {
            return 'Срабатывание ДАР второй очереди'
          }
          if (id == 115 + i) {
            return 'Пуск АЧР 1 третьей очереди'
          }
          if (id == 116 + i) {
            return 'Срабатывание АЧР 1 третьей очереди'
          }
          if (id == 117 + i) {
            return 'Пуск АЧР 2 третьей очереди'
          }
          if (id == 118 + i) {
            return 'Срабатывание АЧР 2 третьей очереди'
          }
          if (id == 119 + i) {
            return 'Пуск ДАР третьей очереди'
          }
          if (id == 120 + i) {
            return 'Срабатывание ДАР третьей очереди'
          }
          if (id == 121 + i) {
            return 'Пуск АЧР 1 четвертой очереди'
          }
          if (id == 122 + i) {
            return 'Срабатывание АЧР 1 четвертой очереди'
          }
          if (id == 123 + i) {
            return 'Пуск АЧР 2 четвертой очереди'
          }
          if (id == 124 + i) {
            return 'Срабатывание АЧР 2 четвертой очереди'
          }
          if (id == 125 + i) {
            return 'Пуск ДАР четвертой очереди'
          }
          if (id == 126 + i) {
            return 'Срабатывание ДАР четвертой очереди'
          }
          if (id == 127 + i) {
            return 'Пуск АЧР 1 пятой очереди'
          }
          if (id == 128 + i) {
            return 'Срабатывание АЧР 1 пятой очереди'
          }
          if (id == 129 + i) {
            return 'Пуск АЧР 2 пятой очереди'
          }
          if (id == 130 + i) {
            return 'Срабатывание АЧР 2 пятой очереди'
          }
          if (id == 131 + i) {
            return 'Пуск ДАР пятой очереди'
          }
          if (id == 132 + i) {
            return 'Срабатывание ДАР пятой очереди'
          }
          if (id == 133 + i) {
            return 'Пуск АЧР 1 шестой очереди'
          }
          if (id == 134 + i) {
            return 'Срабатывание АЧР 1 шестой очереди'
          }
          if (id == 135 + i) {
            return 'Пуск АЧР 2 шестой очереди'
          }
          if (id == 136 + i) {
            return 'Срабатывание АЧР 2 шестой очереди'
          }
          if (id == 137 + i) {
            return 'Пуск ДАР шестой очереди'
          }
          if (id == 138 + i) {
            return 'Срабатывание ДАР шестой очереди'
          }
          if (id == 139 + i) {
            return 'Пуск АЧР 1 седьмой очереди'
          }
          if (id == 140 + i) {
            return 'Срабатывание АЧР 1 седьмой очереди'
          }
          if (id == 141 + i) {
            return 'Пуск АЧР 2 седьмой очереди'
          }
          if (id == 142 + i) {
            return 'Срабатывание АЧР 2 седьмой очереди'
          }
          if (id == 143 + i) {
            return 'Пуск ДАР седьмой очереди'
          }
          if (id == 144 + i) {
            return 'Срабатывание ДАР седьмой очереди'
          }
          if (id == 145 + i) {
            return 'Пуск АЧР 1 восьмой очереди'
          }
          if (id == 146 + i) {
            return 'Срабатывание АЧР 1 восьмой очереди'
          }
          if (id == 147 + i) {
            return 'Пуск АЧР 2 восьмой очереди'
          }
          if (id == 148 + i) {
            return 'Срабатывание АЧР 2 восьмой очереди'
          }
          if (id == 149 + i) {
            return 'Пуск ДАР восьмой очереди'
          }
          if (id == 150 + i) {
            return 'Срабатывание ДАР восьмой очереди'
          }
          if (id == 151 + i) {
            return 'Пуск АЧР 1 девятой очереди'
          }
          if (id == 152 + i) {
            return 'Срабатывание АЧР 1 девятой очереди'
          }
          if (id == 153 + i) {
            return 'Пуск АЧР 2 девятой очереди'
          }
          if (id == 154 + i) {
            return 'Срабатывание АЧР 2 девятой очереди'
          }
          if (id == 155 + i) {
            return 'Пуск ДАР девятой очереди'
          }
          if (id == 156 + i) {
            return 'Срабатывание ДАР девятой очереди'
          }
          if (id == 157 + i) {
            return 'Срабатывание ЧАПВ первой очереди'
          }
          if (id == 158 + i) {
            return 'Пуск ЧАПВ первой очереди'
          }
          if (id == 159 + i) {
            return 'Срабатывание ЧАПВ второй очереди'
          }
          if (id == 160 + i) {
            return 'Пуск ЧАПВ второй очереди'
          }
          if (id == 161 + i) {
            return 'Срабатывание ЧАПВ третьей очереди'
          }
          if (id == 162 + i) {
            return 'Пуск ЧАПВ третьей очереди'
          }
          if (id == 163 + i) {
            return 'Срабатывание ЧАПВ четвертой очереди'
          }
          if (id == 164 + i) {
            return 'Пуск ЧАПВ четвертой очереди'
          }
          if (id == 165 + i) {
            return 'Срабатывание ЧАПВ пятой очереди'
          }
          if (id == 166 + i) {
            return 'Пуск ЧАПВ пятой очереди'
          }
          if (id == 167 + i) {
            return 'Срабатывание ЧАПВ шестой очереди'
          }
          if (id == 168 + i) {
            return 'Пуск ЧАПВ шестой очереди'
          }
          if (id == 169 + i) {
            return 'Срабатывание ЧАПВ седьмой очереди'
          }
          if (id == 170 + i) {
            return 'Пуск ЧАПВ седьмой очереди'
          }
          if (id == 171 + i) {
            return 'Срабатывание ЧАПВ восьмой очереди'
          }
          if (id == 172 + i) {
            return 'Пуск ЧАПВ восьмой очереди'
          }
          if (id == 173 + i) {
            return 'Срабатывание ЧАПВ девятой очереди'
          }
          if (id == 174 + i) {
            return 'Пуск ЧАПВ девятой очереди'
          }
          if (id == 175 + i) {
            return 'Пуск АВР'
          }
          if (id == 176 + i) {
            return 'Срабатывание АВР на отключение ВВ'
          }
          if (id == 177 + i) {
            return 'Срабатывание АВР на включение СВ'
          }
          if (id == 178 + i) {
            return 'Пуск ВНР'
          }
          if (id == 179 + i) {
            return 'Срабатывание ВНР на включение ВВ'
          }
          if (id == 180 + i) {
            return 'Срабатывание ВНР на отключение СВ'
          }
          if (id == 181 + i) {
            return 'ВНР неуспешное'
          }
          if (id == 182 + i) {
            return 'ВНР успешное'
          }
          if (id == 183 + i) {
            return 'Команда оперативного включения'
          }
          if (id == 184 + i) {
            return 'Команда оперативного отключения'
          }
          if (id == 185 + i) {
            return 'Включение выключателя'
          }
          if (id == 186 + i) {
            return 'Неуспешная попытка включения'
          }
          if (id == 187 + i) {
            return 'Включение выключателя с КС'
          }
          if (id == 188 + i) {
            return 'Неуспешная попытка включения с КС'
          }
          if (id == 189 + i) {
            return 'Отключение выключателя'
          }
          if (id == 190 + i) {
            return 'Срабатывание защиты ЭВ, ЭО 1'
          }
          if (id == 191 + i) {
            return 'Срабатывание защиты ЭО 2'
          }
          if (id == 192 + i) {
            return 'Аварийное отключение'
          }
          if (id == 193 + i) {
            return 'Пуск АПВ шин'
          }
          if (id == 194 + i) {
            return 'Пуск АПВ 1'
          }
          if (id == 195 + i) {
            return 'Пуск АПВ 2'
          }
          if (id == 196 + i) {
            return 'Срабатывание АПВ'
          }
          if (id == 197 + i) {
            return 'АПВ 1 неуспешное'
          }
          if (id == 198 + i) {
            return 'АПВ 1 успешное'
          }
          if (id == 199 + i) {
            return 'АПВ 2 неуспешное'
          }
          if (id == 200 + i) {
            return 'АПВ 2 успешное'
          }
          if (id == 201 + i) {
            return 'Неисправность выключателя'
          }
          if (id == 202 + i) {
            return 'Авария шинки питания'
          }
          if (id == 203 + i) {
            return 'Пружина не заведена'
          }
          if (id == 204 + i) {
            return 'Низкая Т полюсов'
          }
          if (id == 205 + i) {
            return 'Неисправность цепей управления'
          }
          if (id == 206 + i) {
            return 'Неуспешное включение'
          }
          if (id == 207 + i) {
            return 'Неуспешное отключение'
          }
          if (id == 208 + i) {
            return 'Неисправность цепей напряжения'
          }
          if (id == 209 + i) {
            return 'Небаланс фаза А'
          }
          if (id == 210 + i) {
            return 'Небаланс фаза В'
          }
          if (id == 211 + i) {
            return 'Небаланс фаза С'
          }
          if (id == 212 + i) {
            return 'Неисправность цепей тока фазы А'
          }
          if (id == 213 + i) {
            return 'Неисправность цепей тока фазы В'
          }
          if (id == 214 + i) {
            return 'Неисправность цепей тока фазы С'
          }
          if (id == 215 + i) {
            return 'Аварийная сигнализация'
          }
          if (id == 216 + i) {
            return 'Предупредительная сигнализация'
          }
          if (id == 217 + i) {
            return 'Прекращение смазки подшипников'
          }
          if (id == 218 + i) {
            return 'Прекращение воздушного охлаждения'
          }
          if (id == 219 + i) {
            return 'Прекращение водяного охлаждения'
          }
          if (id == 220 + i) {
            return 'Срабатывание 2 ступени снижения элегаза Q'
          }
          if (id == 221 + i) {
            return 'Срабатывание 1 ступени снижения элегаза Q'
          }
          if (id == 222 + i) {
            return 'Срабатывание 2 ступени снижения элегаза ТТ'
          }
          if (id == 223 + i) {
            return 'Срабатывание 1 ступени снижения элегаза ТТ'
          }
          if (id == 224 + i) {
            return 'Внешний пуск УРОВ'
          }
          if (id == 225 + i) {
            return 'Отключение по частоте от внешнего устройства'
          }
          if (id == 226 + i) {
            return 'Включение по частоте от внешнего устройства'
          }
          if (id == 227 + i) {
            return 'Внешний пуск АВР'
          }
          if (id == 228 + i) {
            return 'Включение от внешнего устройства'
          }
          if (id == 229 + i) {
            return 'Отключение от внешнего устройства ДЗШ'
          }
          if (id == 230 + i) {
            return 'Отключение при срабатывании УРОВ нижестоящего выключателя'
          }
          if (id == 231 + i) {
            return 'Внешнее отключение СВ в цикле ВНР'
          }
          if (id == 232 + i) {
            return 'Внешнее отключение'
          }
          if (id == 233 + i) {
            return 'Отключение от иных внешних защит'
          }
          if (id == 234 + i) {
            return 'Внешний пуск АПВ шин'
          }
          if (id == 235 + i) {
            return 'Внешний пуск АПВ'
          }
          if (id == 236 + i) {
            return 'Пуск АОПЧ 1 по f'
          }
          if (id == 237 + i) {
            return 'Срабатывание АОПЧ 1 по f'
          }
          if (id == 238 + i) {
            return 'Пуск АОПЧ 2 по f'
          }
          if (id == 239 + i) {
            return 'Срабатывание АОПЧ 2 по f'
          }
          if (id == 240 + i) {
            return 'Срабатывание АОПЧ 2 по f'
          }
          if (id == 241 + i) {
            return 'Срабатывание АОПЧ 3 по f'
          }
          if (id == 242 + i) {
            return 'Пуск АОПЧ 1 по df'
          }
          if (id == 243 + i) {
            return 'Срабатывание АОПЧ 1 по df'
          }
          if (id == 244 + i) {
            return 'Пуск АОПЧ 2 по df'
          }
          if (id == 245 + i) {
            return 'Срабатывание АОПЧ 2 по df'
          }
          if (id == 246 + i) {
            return 'Пуск ЧДА 1 по f'
          }
          if (id == 247 + i) {
            return 'Срабатывание ЧДА 1 по f'
          }
          if (id == 248 + i) {
            return 'Пуск ЧДА 2 по f'
          }
          if (id == 249 + i) {
            return 'Срабатывание ЧДА 2 по f'
          }
          if (id == 250 + i) {
            return 'Пуск ЧДА 1 по df'
          }
          if (id == 251 + i) {
            return 'Срабатывание ЧДА 1 по df'
          }
          if (id == 252 + i) {
            return 'Пуск ЧДА 2 по df'
          }
          if (id == 253 + i) {
            return 'Срабатывание ЧДА 2 по df'
          }
          if (id >= 254 + i) {
            return
          }

          return id;
        });

        return full_answers;
      },
      uintToString(hex) {
        try {
          hex = hex.replace( /../g , hex2=>('%'+hex2));

          var string = decodeURIComponent(hex);

          return string;
        } catch(e) {
          console.log(e);

          return 'Cant decode';
        }
      },
      getMessageDescription(message) {
        let utf8 = this.uintToString(message);

        return utf8
      },
      async getThingOscillogramFiles() {
        // console.log('getThingOscillogramFiles handled');
        let args = {
          types: 'oscillogram_file',
          start: 0,
          limit: 50,
        }

        let res = await filesService.thing_files_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        this.oscillogram_files = res.result.items;

        // console.log(this.oscillogram_files)
      },
      _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }

        return bytes.buffer;
      },
      async downloadFile(file) {
        let res = await filesService.file_get(file.id);

        if (res.error) {
          console.error(res.error);
        }

        let item = res.result;

        var mybytes = this._base64ToArrayBuffer(item.base64);

        // download(mybytes, item.filename, "application/octet-stream");
        download(mybytes, this.set_filename(item.timestamp), "application/octet-stream");
      },
      set_filename(timestamp) {
        // 20210916_122053.cff
        // console.log(timestamp);

        let date = new Date(parseInt(timestamp));
        let mydate = (date.getFullYear().toString() 
                      + ('0' + (date.getMonth() + 1)).slice(-2) 
                      + ('0' + date.getDate()).slice(-2) 
                      + '_' 
                      + ('0' + date.getUTCHours()).slice(-2) 
                      + ('0' + date.getMinutes()).slice(-2) 
                      + ('0' + date.getSeconds()).slice(-2))

        let filename = mydate + '.cff';
        return filename;
      },
      async download_log(type) {
        let start_time = 0;
        let end_time = 0;

        if (type == 'event_log') {
          start_time = this.event_range.start.getTime();
          end_time = this.event_range.end.getTime();
        } else if (type == 'message_log') {
          start_time = this.message_range.start.getTime();
          end_time = this.message_range.end.getTime();
        }

        let args = {
          types: type,
          timestampGte: start_time,
          timestampLte: end_time,
          // start:,
          // limit:,
          // limit: 1000,
          limit: 10000
        }

        let res = await journalsService.journals_get(this.thingId, args);

        if (res.error) {
          console.error(res.error);

          return;
        }

        let temp_log = res.result.items;

        if (temp_log.length > 0){
          if (type == 'event_log') {
            await this.csvExportEvent(temp_log, res.result.filters);
          } else if ( type == 'message_log') {
            await this.csvExportMessage(temp_log, res.result.filters);
          }
        } else {
          alert('Журнал за указанный период пуст!');
        }
      },
      async csvExportEvent(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getEventDescription(item.data.events) + ";"

            return data_value
          })  // Object.values(item.data).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte / 1000).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte / 1000).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "ALTEY-BZP_" + this.thing.number + "_Журнал_событий_" + start_end_date + ".csv");
        link.click();
      },
      async csvExportMessage(arrData, filters) {
        let csvContent = "data:text/csv;charset=utf-8,\uFEFF";

        csvContent += [
          this.getTitles(arrData[0].data),
          ...arrData.map((item) => {
            let data_value = ''

            data_value += this.formatDate(item.data.timestamp) + ";"
            data_value += this.getMessageDescription(item.data.Text) + ";"

            return data_value
          }) // Object.values(item.data).join(";"))
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);

        let start_date = new Date(filters.timestampGte / 1000).toLocaleDateString("ru-Ru");
        let end_date = new Date(filters.timestampLte / 1000).toLocaleDateString("ru-Ru");

        let start_end_date = start_date === end_date ? end_date : start_date + "-" + end_date;

        link.setAttribute("download", "ALTEY-UZT_" + this.thing.number + "_Системный_журнал_" + start_end_date + ".csv");

        link.click();
      },
      getTitles(data) {

        let titles = {
          'events': 'События',
          'timestamp': 'Время',
          'MsgID': 'События',
        }

        let answ = ''

        for (let key in data) {
          if (titles[key]) {
            // answ += titles[key] + ';'
            // reversing
            answ = titles[key] + ';' + answ
          } else {
            // answ += key + ';'
          }
        }

        return answ
      },
      toDate(updated_date) {
        let today = new Date(updated_date + 'Z');
        let date = ("0" + today.getDate()).slice(-2) + '.' + ("0" + (today.getMonth() + 1)).slice(-2) + '.' + today.getFullYear();
        let time = ("0" + today.getHours()).slice(-2) + ":" + ("0" + today.getMinutes()).slice(-2) + ":" + ("0" + today.getSeconds()).slice(-2);

        return time + " | " + date
      },
      getReason(reason) {
        if (reason == 2) {
          return 'Неиспр. ЦУ'
        }
        if (reason == 3) {
          return 'Пуск осц. KIWI'
        }

        return reason;
      }      
    }
  }
</script>

<style>
  @font-face {
    font-family: 'Snowstorm Light';
    src: url('../../assets/SnowstormLight.woff2');
  }
  .card {
    margin-top: 20px;
  }
  .card-second {
  }
  .transparent {
    border-width: 0px;
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
    background-color: rgba(0,0,0,0.0);
    background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
    background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
  }
  .col-sm-6 {
    margin: 0px;
    padding: 0px;
  }
  .subject-div1 {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    height: 90px;
    /* width: 180px; */
    margin: 12px;
    font-family: "Segoe UI Light";
    font-size: 10px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 10px;*/
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    position: relative;
    z-index: 99;
  }
  .subject-div1:hover {
    background: #2E2E2E 0% 0% no-repeat padding-box;
  }
  .header-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /* height: 165px; */
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .header-upper {
    border-bottom: 1px solid black;
    padding: 10px
  }
  .back-img {
    width: 45px;
    height: 45px;
    display: inline-block;
    margin: 5px;
  }
  .back-img img{
    max-width:100%;
    height:auto;
  }
  .back-img-battery img{
    width:30px;
    height:auto;
  }
  .header-body {
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    /*padding: 20px;*/
    padding: 0px;
  }
  .subject-logo {
    flex-grow: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subject-info {
    flex-grow: 7;
    font-size: 16px;
    padding: 0px;
    padding-top: 15px;
  }
  .my-bar {
    min-width: 650px;
    min-height: 340px;
    max-width: 950px;
    max-height: 820px;
    background-color: #202020;
    border-radius: 10px;
  }
  .bar-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 10px;
    font-family: "Segoe UI Light";
    font-size: 30px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
    margin-right: 40px;
    height: 340px;
  }
  .circle-35 {
    background: #8EDC28;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .circle-35.sort-linear {
    background: url(../../assets/sort_linear.svg) no-repeat center center #000;
  }
  .circle-35.sort-linear.active {
    background: url(../../assets/sort_linear_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-bars {
    background: url(../../assets/sort_bars.svg) no-repeat center center #000;
  }
  .circle-35.sort-bars.active {
    background: url(../../assets/sort_bars_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35.sort-cols {
    background: url(../../assets/sort_cols.svg) no-repeat center center #000;
  }
  .circle-35.sort-cols.active {
    background: url(../../assets/sort_cols_black.svg) no-repeat center center #8EDC28;
  }
  .circle-35:hover.sort-linear {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-bars {
    opacity: 0.5;
    cursor: pointer;
  }
  .circle-35:hover.sort-cols {
    opacity: 0.5;
    cursor: pointer;
  }
  .background-text {
    z-index: 1;
  }
  .col-2 {
    display: flex;
    flex-direction: row;
  }
  .small-info {
    position: relative;
    font-size: 14px;
    font-family: "Segoe UI Light";
    letter-spacing: 0px;
    /*color: #FFFFFF;*/
    opacity: 1;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    display: block;
  }
  @media screen and (max-width: 1366px) {
    .small-info {
      width: 100px;
    }
    .col-md-3 {
      padding: 0px;
    }
  }
  .subject-info-values {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .circle-50 {
    background: #000000;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    vertical-align: middle;
    color: #FFFFFF;
    line-height: 50px;
    cursor: pointer;
  }
  .circle-50:hover {
    background-color: rgba(142,220,40,0.1);
  }
  .circle-50.active {
    background: #8EDC28;
  }
  .nowrap-span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .interact-img:hover img {
    opacity: 0.8;
    transform: scale(1.05);
  }
  .mobile-header {
    display: none;
  }
  @media (max-width: 640px) {
    .battery-info-div { right: 0px !important;}
    .date-picker {
      top: 18%;
      /*position: absolute;*/
      float: right;
      right: 0px;
      /*top: 50%;*/
      width: 50px;
      height: 166px;
      border-radius: 50px;
      background-color: #000000;
      color: #FFFFFF;
    }
    .desktop-header {
      display: none;
    }
    .mobile-header {
      display: block;
    }
    .header-div {
      height: 220px !important;
      margin: 10px;
      font-family: "Segoe UI Light";
      font-size: 30px;
      color: #FFFFFF;
    }
    .small-info {
      font-size: 12px !important;
    }
  }
  .detailed-div {
    background: #202020 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    /*height: 170px;*/
    /* width: 180px; */
    /* margin: 10px; */
    font-family: "Segoe UI Light";
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 0px !important;
    /*padding: 10px;*/
    /* margin-bottom: 30px; */
    /* padding: 10px; */
  }
  .detail-overflow {
    position: relative;
    max-height: 560px;
    min-width: 500px;
  }
</style>
